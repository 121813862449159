import 'webrtc-adapter';
import { Vector3, Quaternion } from 'three';

// Natscope imports
import { User } from './user';
import { SocketInterface, socketinterface_cons_type, socketinterface_functions_cons_type } from './socketInterface';
import { Room, ChiefRoom, PeerRoom } from './room';
import { typeOfSignal } from './signaling';
import { Moving, Cmd } from './messaging';
import { SocketId, updateUserInfos, updateUserInfosInRooms, checkUser, T_customRoomEventType, NatEvent } from './utils';
import { log, sockRcv, sockSnd } from './logMode';

let NodeRTCIceCandidate: any;
let NodeRTCSessionDescription: any;
if (typeof window !== 'undefined' || (typeof process !== 'undefined' && process.release.name === 'node')) {
	NodeRTCIceCandidate = require('wrtc').RTCIceCandidate;
	NodeRTCSessionDescription = require('wrtc').RTCSessionDescription;
}

export type myCustomEvent = {
	eventName: string;
	type: T_customRoomEventType;
	target?: string;
};

export type natscope_cons_type = {
	port: number;
	url: string;
	reconnection?: boolean;
	serverChief?: boolean;
	fromServer?: boolean;
	functions?: socketinterface_functions_cons_type | undefined;
};

//Events :
//When socket socketio open on Natscope
export type T_onSocketOpen = {};
//When socket socketio is close on Natscope
export type T_onSocketClose = {};
//When User connect to Natscope
export type T_onUserConnect = { user: User };
//When User leave Natscope
export type T_onUserDisconnect = { user: User };
//When User change name on Natscope
export type T_onUserChangeNick = {};
//When room is created
export type T_onRoomCreatedListener = { room: Room };
//When myroom is joined/ready
export type T_onMyRoomReady = { room: Room };

// export type NatCustomEventName =
//   | "onUserConnect"
//   | "onUserDisconnect"
//   | "onUserChangeNick"
//   | "onRoomCreatedListener"
//   | "onMyRoomReady";

export class NatscopeClient {
	/* Variables */
	private _port: number;
	private _url: string;
	private _reconnection: boolean;
	//private _socket: Socket;
	private _socketInterface: SocketInterface;
	private _id: string;
	private _me: User;
	private _myroom: ChiefRoom | PeerRoom | undefined;
	private _users: Map<SocketId, User>;
	private _rooms: Map<string, Room>;
	private _serverChief: boolean = false;
	private _fromServer: boolean = false;

	/* Variables Get/Set-er */
	get port(): number {
		return this._port;
	}
	set port(port: number) {
		this._port = port;
	}

	get url(): string {
		return this._url;
	}
	set url(myurl: string) {
		this._url = myurl;
	}

	get socketInterface(): SocketInterface {
		return this._socketInterface;
	}
	set socketInterface(myurl: SocketInterface) {
		this._socketInterface = myurl;
	}

	get id(): string {
		return this._id;
	}
	set id(id: string) {
		this._id = id;
	}

	get me(): User {
		return this._me;
	}
	set me(me: User) {
		this._me = me;
	}

	get myroom(): ChiefRoom | PeerRoom | undefined {
		return this._myroom;
	}
	set myroom(myroom: ChiefRoom | PeerRoom | undefined) {
		this._myroom = myroom;
	}

	get users(): Map<SocketId, User> {
		return this._users;
	}
	set users(users: Map<SocketId, User>) {
		this._users = users;
	}

	get rooms(): Map<string, Room> {
		return this._rooms;
	}
	set rooms(rooms: Map<string, Room>) {
		this._rooms = rooms;
	}

	get serverChief(): boolean {
		return this._serverChief;
	}
	set serverChief(serverChief: boolean) {
		this._serverChief = serverChief;
	}

	get fromServer(): boolean {
		return this._fromServer;
	}
	set fromServer(fromServer: boolean) {
		this._fromServer = fromServer;
	}

	public getRooms(): Map<string, Room> {
		return this._rooms;
	}

	public getMyRoom(): ChiefRoom | PeerRoom | undefined {
		return this._myroom;
	}

	public onSocketOpen: NatEvent<T_onSocketClose, any>;
	public onSocketClose: NatEvent<T_onSocketClose, any>;
	public onUserConnect: NatEvent<T_onUserConnect, any>;
	public onUserDisconnect: NatEvent<T_onUserDisconnect, any>;
	public onUserChangeNick: NatEvent<T_onUserChangeNick, any>;
	public onRoomCreatedListener: NatEvent<T_onRoomCreatedListener, any>;
	public onMyRoomReady: NatEvent<T_onMyRoomReady, any>;

	/* Constructor */
	public constructor({ port, url, reconnection = false, serverChief = false, fromServer = false, functions }: natscope_cons_type) {
		this.onSocketOpen = new NatEvent();
		this.onSocketClose = new NatEvent();
		this.onUserConnect = new NatEvent();
		this.onUserDisconnect = new NatEvent();
		this.onUserChangeNick = new NatEvent();
		this.onRoomCreatedListener = new NatEvent();
		this.onMyRoomReady = new NatEvent();

		// Connection infos
		this._port = port;
		this._url = url;
		this._reconnection = reconnection;

		// Client infos
		this._id = '';

		// Server recovery infos
		this._users = new Map<SocketId, User>();
		this._rooms = new Map<string, Room>();

		const complete_url = this._url + ':' + this._port.toString();

		let params = {
			url: complete_url,
			reconnection: this._reconnection,
		} as socketinterface_cons_type;

		if (functions !== undefined) {
			params.type = 'other';
			params.functions = {
				init: functions.init,
				emit: functions.emit,
				on: functions.on,
			};
		}

		log('start natscopecore');

		this._socketInterface = new SocketInterface(params);

		if (this._socketInterface === undefined) {
			alert('Problem with socket connection…');
			throw new Error('problem with socket connection…');
		}

		this._me = new User(this._socketInterface.id);

		this.serverChief = serverChief;
		this.fromServer = fromServer;

		/* Connection */
		this._socketInterface.on('connect', () => this.onConnect());
		this._socketInterface.on('isconnected', (data) => this.onIsConnected(data));
		this._socketInterface.on('isdisconnected', (data) => this.onIsDisconnected(data));

		/* Infos */
		this._socketInterface.on('yourid', (data) => this.onYourId(data));
		this._socketInterface.on('users', (data: Array<[SocketId, string]>) => this.onUsers(data));
		this._socketInterface.on('me_infos', (data: { me_infos: User; chiefId: string; groups: string }) => this.onMeInfos(data.me_infos, data.chiefId));
		this._socketInterface.on('users_infos', (data: { userInfos: User; chiefId: string }) => this.onUsersInfos(data.userInfos, data.chiefId));
		this._socketInterface.on('rooms', (data) => this.onRooms(data));

		/* Rooms */
		this._socketInterface.on('new_room', (data) => this.onNewRoom(data));
		this._socketInterface.on('room_exists', () => this.onRoomExists());
		this._socketInterface.on('room_unknown', (data) => this.onRoomUnknown(data));
		this._socketInterface.on('room_created', (data) => this.onRoomCreated(data)); // Server answer me that I can create my room !
		this._socketInterface.on('user_join_room', (data) => this.onUserJoinRoom(data)); // Server sent join request
		this._socketInterface.on('user_leave_room', (data) => this.onUserLeaveRoom(data)); // Server sent join request

		/* WebRTC */
		this._socketInterface.on('signaling', (signaling) => this.signaling(signaling));

		/* Disconnect */
		this._socketInterface.on('disconnect', () => {
			this.onSocketClose.emit({});
		});
	}

	/* Connection */
	private onConnect() {
		this.onSocketOpen.emit({});
		log(...sockRcv, 'onConnect', 'Connection to server OK');
	}

	private onIsConnected(data: any) {
		log(...sockRcv, 'onIsConnected');
		const [peerId, name] = data;

		log('[' + name + '] connected with id : ' + peerId);

		const p = new User(peerId, name);

		this.users = this.users.set(peerId, p);

		this.onUserConnect.emit({ user: p });
	}

	private onIsDisconnected(data: { clientId: SocketId; chiefId: SocketId }) {
		log(...sockRcv, 'onIsDisconnected');
		const { clientId } = data;

		this.onUserLeaveRoom(data);

		log('[' + clientId + '] disconnected.');

		const leaver = this.users.get(clientId);

		if (leaver) {
			this.users.delete(clientId);

			if (this.myroom instanceof ChiefRoom) {
				console.warn('Suppress!');
				this.myroom.disconnect_peer(clientId);
			}
			// If main player left
			if (this.myroom instanceof PeerRoom && clientId === this.myroom.chief.id) {
				this.myroom = undefined;
			}

			this.onUserDisconnect.emit({ user: leaver });
		} else {
			console.error('Disconnection of unknown user ?!');
		}
	}

	/* Infos */
	private onYourId(data: any) {
		log(...sockRcv, 'onYourId');

		const id = data[0];
		const name = data[1];

		log('[' + name + "] I'm id : " + id);

		this.id = id;
		this.me = new User(id, name);
		this.socketInterface.id = id;
	}

	private onUsers(data: Array<[SocketId, string]>) {
		log(...sockRcv, 'onUsers');

		const peersId: Array<[SocketId, string]> = data.filter((x: [SocketId, string]) => x[0] !== this.id);

		peersId.forEach((x: [SocketId, string]) => {
			const p = new User(x[0], x[1]);
			this.users = this.users.set(x[0], p);
		});

		log('Map of users : ', this.users);
	}

	private onMeInfos(userInfos: User, chiefId: SocketId) {
		log(...sockRcv, 'onMeInfos');

		updateUserInfos(this, userInfos);

		if (chiefId) updateUserInfosInRooms(this, userInfos, chiefId);
		this.onUserChangeNick.emit({});
	}

	private onUsersInfos(userInfos: User, chiefId: SocketId) {
		log(...sockRcv, 'onUsersInfos');

		updateUserInfos(this, userInfos);

		if (chiefId) updateUserInfosInRooms(this, userInfos, chiefId);
		this.onUserChangeNick.emit({});
	}

	private onRooms(rooms: Array<Room>) {
		log(...sockRcv, 'onRooms');

		log('Receiving all rooms', rooms);

		if (rooms.length > 0) {
			rooms.forEach((room) => {
				// const chiefFound = this.users.get(room.chief.id);
				// if (!chiefFound) this.users.set(room.chief.id, room.chief);

				const chiefFound = checkUser(this, room.chief);
				const creatorFound = checkUser(this, room.creator);

				const room_c = new Room({
					chief: chiefFound,
					creator: creatorFound,
					name: room.name,
					type: room.type,
					users: room.users,
				});
				this.rooms = this.rooms.set(room.chief.id, room_c);
			});

			/* ↓ delete room after refresh if necessary ↓ */
			for (let [key, value] of this.rooms) {
				let roomBool = false;

				rooms.forEach((room) => {
					if (value.name === room.name) {
						roomBool = true;
					}
				});

				if (!roomBool) {
					log('deleteRoom', value);
					this.rooms.delete(key);
				}
			}
			log('Receiving all peers id : ', this.users);
		} else {
			this.rooms = new Map();
			console.warn('No room created yet.');
		}
	}

	/* Rooms */
	private onNewRoom(room: Room) {
		log(...sockRcv, 'onNewRoom');

		const { chief, creator, name: roomName, type: roomType, users } = room;

		const chiefFound = checkUser(this, chief);
		const creatorFound = checkUser(this, creator);

		if (creatorFound) {
			const room_c = new Room({
				chief: chiefFound,
				creator: creatorFound,
				name: roomName,
				type: roomType,
				users: users,
			});

			this.rooms = this.rooms.set(chief.id, room_c);

			this.onRoomCreatedListener.emit({ room: room_c });

			log('New room ' + roomName + ' of type ' + roomType + ' created by ' + creatorFound.nick);
		} else {
			console.error('user not found');
		}
	}

	private onRoomExists() {
		log(...sockRcv, 'onRoomExists');
		console.warn('Room already exists…');
	}

	private onRoomUnknown(data: any) {
		log(...sockRcv, 'onRoomUnknown');
		console.warn('No room with this name : ', data);
	}

	private onRoomCreated(room: Room) {
		log(...sockRcv, 'onRoomCreated');

		const { chief, creator, name: roomName, type: roomType, users } = room;

		const creatorFound = checkUser(this, creator);
		const chiefFound = checkUser(this, chief);

		// if (this.me.id === creator.id) this.me.webRTC = creator.webRTC;

		let serverChief = false;
		if (creator.id !== chief.id) {
			serverChief = true;
		}

		if (this.me.id === chief.id) {
			this.myroom = new ChiefRoom({
				chief: chiefFound,
				creator: creatorFound,
				name: roomName,
				type: roomType,
				socketInterface: this.socketInterface,
				serverChief: serverChief,
				fromServer: this.fromServer,
			});
		} else {
			this.myroom = new PeerRoom({
				chief: chiefFound,
				creator: creatorFound,
				name: roomName,
				type: roomType,
				socketInterface: this.socketInterface,
				serverChief: serverChief,
				users: users,
				webRTC: this.me.webRTC && chiefFound.webRTC ? true : false,
				fromServer: this.fromServer,
			});
		}

		this.onRoomCreatedListener.emit({ room: this.myroom });
		this.onMyRoomReady.emit({ room: this.myroom });

		log('You, ' + creator.nick + ' is creator of room ' + roomName);
	}

	private onUserJoinRoom(data: { client: User; chiefId: string; allClients: Array<User> }) {
		log(...sockRcv, 'onUserJoinRoom');

		const { client, chiefId, allClients } = data;

		const userWhoJoined = this._users.get(client.id);
		if (userWhoJoined) Object.assign(userWhoJoined, client);

		if (client.id === this.me.id) Object.assign(this.me, client);

		const room = this._rooms.get(chiefId);

		if (room && userWhoJoined) {
			if (this.myroom && room.name === this.myroom.name) {
				this.myroom.users = allClients.slice();
				this.myroom.onUserJoin.emit({ user: userWhoJoined, room: this.myroom });
			}

			room.users = allClients.slice();
			this.rooms.set(chiefId, room);

			/*console.log(userWhoJoined.id === this.me.id)
      console.log(this.myroom)
      console.log(this.socketInterface)*/

			if (userWhoJoined.id === this.me.id && !this.myroom && this.socketInterface) {
				this.myroom = new PeerRoom({
					chief: room.chief,
					creator: room.creator,
					name: room.name,
					type: room.type,
					users: room.users,
					socketInterface: this.socketInterface,
					serverChief: room.serverChief,
					webRTC: this.me.webRTC && room.chief.webRTC ? true : false,
					fromServer: this.fromServer,
				});
			}

			if (client.id === this.me.id) {
				if (this.myroom) {
					this.onMyRoomReady.emit({ room: this.myroom });
				}
			}

			if (this.myroom && room.name === this.myroom.name && this.myroom instanceof ChiefRoom) {
				log('addConnection');
				const peerId = client.id;
				if (peerId !== this.me.id) {
					const userFound = this.users.get(peerId);
					if (userFound) this.myroom.addConnection(userFound, this.socketInterface);
				} else {
					console.warn("You're trying to join your own room…");
				}
			}
		}
	}

	private onUserLeaveRoom(data: { clientId: string; chiefId: SocketId }) {
		const { clientId, chiefId } = data;

		const userWhoLeft = this._users.get(clientId);
		const room = this._rooms.get(chiefId);

		if (room && userWhoLeft) {
			if (this.myroom && room.name === this.myroom.name) {
				const foundUser = this.myroom.users.findIndex((user) => user.id === clientId);

				if (foundUser !== -1) {
					this.myroom.users.splice(foundUser, 1);
				}

				this.myroom.onUserLeave.emit({ user: userWhoLeft, room: this.myroom });
			}

			const foundUser = room.users.findIndex((user) => user.id === clientId);

			if (foundUser !== -1) {
				room.users.splice(foundUser, 1);
				// this.rooms.set(chiefId, room);
			}
		}
	}

	/* WebRTC */
	private signaling(signaling: any) {
		log(...sockRcv, 'signaling');
		log('Receiving signal : ' + typeOfSignal(signaling));

		const signal = signaling.signal;

		//Receiving signal from a chief of room case ↓ TODO move somewhere
		if (this.myroom === undefined) {
			if (this.users.has(signal.from)) {
				const chief = this.users.get(signal.from);
				const enterRoom = this.rooms.get(signal.from);

				if (!chief) throw new Error('could not get chief');
				if (!enterRoom) throw new Error('could not get enterRoom');

				this.myroom = new PeerRoom({
					chief: chief,
					creator: enterRoom.creator,
					name: enterRoom.name,
					type: enterRoom.type,
					users: enterRoom.users,
					socketInterface: this.socketInterface!,
					serverChief: enterRoom.serverChief,
					webRTC: this.me.webRTC && chief.webRTC ? true : false,
					fromServer: this.fromServer,
				});
				log('Entering ' + this.myroom.name + ' with chief : ' + chief.nick);

				this.onMyRoomReady.emit({ room: this.myroom });
			}
		}

		//First time this peer is encountered ? Means you're entering a room.
		// if (this.myroom && !(this.myroom.peers.has(signal.from))) {
		//   alert("received signal from non-identified peer: " + signal.from)
		//   console.warn("received signal from non-identified peer: " + signal.from);
		//   let npc = new PeerConnect(signal.from, this.socket);
		//   this.myroom.peers.set(signal.from,new Peer(signal.from, this.socket, npc));
		// }

		if (this.myroom === undefined) {
			if (this.socketInterface && this.socketInterface.id) {
				alert(this.socketInterface.id + ': Signal with no room !');
				console.error(this.socketInterface.id + ': Signal with no room !');
				console.warn(signaling);
			} else {
				console.error('socketId undefined');
			}
		}

		if (!this.myroom) throw new Error('myroom undefined');
		let pc = null;

		pc = this.myroom.getPC(signal.from);

		if (!pc) throw new Error('could not find pc');

		let myRTCIceCandidate = null;
		let myRTCSessionDescription = null;

		if (this.fromServer) {
			myRTCIceCandidate = NodeRTCIceCandidate;
			myRTCSessionDescription = NodeRTCSessionDescription;
		} else {
			myRTCIceCandidate = RTCIceCandidate;
			myRTCSessionDescription = RTCSessionDescription;
		}

		switch (typeOfSignal(signaling)) {
			case 'Offer':
				//Send answer to offer
				pc.addConnection(signal);
				break;
			case 'Candidate':
				log('Switch Candidate');
				log('signal =', signal);
				if (signal.data !== null) {
					log('signal.data !== null');
					//Add Candidate
					pc.peerConnection
						.addIceCandidate(new myRTCIceCandidate(signal.data))
						.then(() => {
							log('Passage then');
							log(signal.from + ': ICECandidate added for signal');
						})
						.catch((err: any) => {
							log('Passage catch');
							console.error(err);
						});
				}
				break;
			case 'Answer':
				//Receive Answer
				pc.peerConnection.setRemoteDescription(new myRTCSessionDescription(signal.data));
				break;
		}
	}

	/* Public functions */
	public join_room(name: string = 'test_room', webRTC: boolean = true) {
		//Should we pass by the server or connect directly to the room chief ?
		//I choose to pass by the server, that way only a room can send offer to
		//someone who want to join

		log(...sockSnd, 'join_room', { roomName: name, webRTC: webRTC });
		this.socketInterface.emit('join_room', { roomName: name, webRTC: webRTC });
	}

	public leave_room(name: string = 'test_room') {
		//Should we pass by the server or connect directly to the room chief ?
		//I choose to pass by the server, that way only a room can send offer to
		//someone who want to join

		if (this.socketInterface) {
			log(...sockSnd, 'leave_room', name);
			this.socketInterface.emit('leave_room', name);
		}
	}

	public create_room({
		name = 'test_room',
		type,
		serverChief = false,
		webRTC = true,
		serverChiefWebRTC = true,
	}: {
		name: string;
		type?: string;
		serverChief?: boolean;
		webRTC?: boolean;
		serverChiefWebRTC?: boolean;
	}) {
		if (this.myroom === undefined) {
			log(...sockSnd, 'create_room');
			this.socketInterface.emit('create_room', {
				roomName: name,
				roomType: type,
				roomServerChief: serverChief,
				webRTC: webRTC,
				serverChiefWebRTC: serverChiefWebRTC,
			});
		} else {
			alert('You are already in a room ' + this.myroom.name);
		}
	}

	public me_infos(userToUpdate: User) {
		log(...sockSnd, 'me_infos');
		if (this.myroom) {
			this.socketInterface.emit('me_infos', userToUpdate, this.myroom.chief.id);
		} else {
			this.socketInterface.emit('me_infos', userToUpdate);
		}
	}

	public send_msg(msg: string) {
		if (this.myroom !== undefined) {
			this.myroom.broadcastMSG(msg);
		}
	}

	public send_pos(pos: Vector3, quat: Quaternion) {
		if (this.myroom !== undefined) {
			const moving: Moving = {
				nick: this.me.nick,
				id: this.me.id,
				pos: pos,
				quat: quat,
			};

			if (this.me.webRTC && this.myroom.onAir) {
				this.myroom.forward_cmd(this.id, new Cmd('move', moving));
			} else if (this.me.webRTC && this.myroom.chief.webRTC && !this.myroom.onAir) {
				console.error('this.myroom channel not opened');
			} else if (!this.me.webRTC) {
				const socketUsers = this.myroom.users.filter((user) => user.webRTC === false && user.id !== this.me.id);
				const arraySocketId = socketUsers.map((user) => user.id);

				if (arraySocketId.length > 0) {
					this.socketInterface.emit('onMove', { movingInfos: moving, arraySocketId: arraySocketId });
				}
			}
		}
	}
}
