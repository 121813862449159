import { createAction } from '@reduxjs/toolkit';
// types
import { USER_LANGUAGE, USER_SENSORS, USER_PLACE, USER_TOWERCOLOR, USER_PAWN } from './types';
import { PSphere } from '../sphere/pawn/PSphere';

export const setLanguage = createAction<string>(USER_LANGUAGE);
export const setSensors = createAction<boolean | null>(USER_SENSORS);
export const setPlace = createAction<string>(USER_PLACE);
export const setTowerColor = createAction<string>(USER_TOWERCOLOR);
export const setPawn = createAction<PSphere>(USER_PAWN);
