import React from 'react';

export default function parseText(text, color) {
	if (!text) {
		return "";
	}
	let seq = [];
	let arg = '';
	let next = 0;
	let finalSeq = [];
	let search = 0;
	for (let i = 0; i < text.length; i++) {
		if (next < 0) {
			next++;
		} else {
			switch (search) {
				// Start saving
				case 0:
					if (text[i] === '[') {
						seq.push(i);
						search = 1;
					}
					break;
				// Save in arg until reach ']' or '='
				case 1:
					if (text[i] !== ']' && text[i] !== '=' && text[i] !== '[') {
						arg += text[i];
					} else if (text[i] === '=') {
						seq.push(arg);
						arg = '';
						seq.push(i);
						search = 2;
					} else if (text[i] === ']') {
						seq.push(arg);
						arg = '';
						seq.push(i);
						search = 3;
					} else {
						seq = [];
						arg = '';
						seq.push(i);
						search = 1;
					}
					break;
				// If '=' is reached, save an other arg.
				case 2:
					if (text[i] !== ']') {
						arg += text[i];
					} else if (text[i] === ']') {
						seq.push(arg);
						arg = '';
						seq.push(i);
						search = 3;
					}
					break;
				// Search close balise
				case 3:
					if (text[i] === '[') {
						if (text[i + 1] && text[i + 1] === '/') {
							if (text.slice(i + 2).indexOf(seq[1] + ']') == 0) {
								seq.push(arg);
								arg = '';
								seq.push(i);
								next = (seq[1] + ']').length;
								seq.push(i + next + 2);
								finalSeq.push(seq);
								seq = [];
								search = 0;
							} else {
								arg += text[i];
							}
						} else {
							arg += text[i];
						}
					} else {
						arg += text[i];
					}
					break;
				default:
					console.log('Default ?!');
			}
		}
	}

	// Array of text kept.
	let textKept = [];
	let step = 0;
	for (let i = 0; i < finalSeq.length; i++) {
		textKept.push(text.slice(step, finalSeq[i][0]));
		step = finalSeq[i][finalSeq[i].length - 1];
	}
	textKept.push(text.slice(step));

	// Final text
	let finalText = [];
	for (let i = 0; i < textKept.length; i++) {
		finalText.push(
			React.createElement(
				'span',
				{
					key: 'parse' + i + '_1',
				},
				textKept[i],
			),
		);
		/*<Text id={"parse"+i+"_1"}>{textKept[i]}</Text>)*/
		if (finalSeq[i] && finalSeq[i][1]) {
			switch (finalSeq[i][1]) {
				case 'url':
					if (finalSeq[i][3] && finalSeq[i][5]) {
						finalText.push(
							React.createElement(
								'span',
								{
									key: 'parse' + i + '_url',
									style: {
										color: color,
										fontWeight: 'bold',
									},
									onClick: () => {
                                        window.open(finalSeq[i][3], '_blank').focus();
									},
								},
								finalSeq[i][5],
							),
						); /*
                            <Text id={"parse"+i+"_url"}style={{color: tower.color, fontWeight: 'bold'}} onPress={() => {
                                Linking.openURL(finalSeq[i][3]);
                              }}>{finalSeq[i][5]}</Text>)*/
					} else {
						console.log('Warding error !');
					}
					break;
				default:
					console.log('Warding unknown !');
			}
		}
	}

	return React.createElement(React.Fragment, {}, finalText);
}
