// React
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as THREE from 'three';
import { trads } from '../../datas/trads';
import getApiUrl from '../../utils/getApiUrl';
// CSS
import './viewer3d.css';

export function Viewer3D(props: { view3d: any; setViewer3d: any; movement: any; moveFinger: any; fingerTimeout: any; clearFinger: any }) {
	const { language, place, towerColor } = useSelector((state: any) => state.user);

	const [clickStatus, setClickStatus] = useState(false);
	const _clickStatus = useRef(clickStatus);

	useEffect(() => {
		_clickStatus.current = clickStatus;
	}, [clickStatus]);

	const _posStart = useRef<number[]>();
	const _pos = useRef<number[]>();

	const _v = useRef(); // Velocity

	const [loading, setLoading] = useState(true);
	const _loading = useRef(loading);

	useEffect(() => {
		_loading.current = loading;

		if (!loading) {
			let fingerElem = document.getElementById('finger');

			if (fingerElem) {
				fingerElem.style.opacity = '1';
				props.moveFinger();
			}

			props.fingerTimeout.current = setTimeout(() => {
				props.clearFinger();
			}, 4000);
		}
	}, [loading]);

	// // TODO : REMOVE THIS CODE AND FIND INTERVAL PROBLEM
	// useEffect(() => {
	// 	// Get a reference to the last interval + 1
	// 	const interval_id = window.setInterval(function () {}, Number.MAX_SAFE_INTEGER);

	// 	// Clear any timeout/interval up to that id
	// 	for (let i = 1; i < interval_id - 2; i++) {
	// 		window.clearInterval(i);
	// 	}
	// 	window.clearInterval(interval_id);
	// }, []);

	function updateMovie(timestamp: number) {
		if (!_clickStatus.current) {
			/*let newV = 0;

			if (_v.current > 0.1) {
				newV = _v.current * 0.1;
			} else if (_v.current < 0) {
				newV = _v.current * -0.1;
			} else {
				newV = 0;
			}
			setV(newV);*/
		} else {
			if (_pos.current && _posStart.current) {
				let d = _pos.current[0] - _posStart.current[0];

				_posStart.current = [_pos.current[0], _pos.current[1]];

				let videoElem = document.getElementById('videoViewer3D') as HTMLVideoElement;
				if (videoElem) {
					let currentTime = videoElem.currentTime;


					let newCurrentTime = currentTime - (d / timestamp) * 3000;

					while (newCurrentTime < 0) {
						newCurrentTime += videoElem.duration;
					}
					while (newCurrentTime > videoElem.duration) {
						newCurrentTime -= videoElem.duration;
					}

					videoElem.currentTime = newCurrentTime;
				}
			}
		}

		window.requestAnimationFrame(updateMovie);
	}

	useEffect(() => {
		if (props.view3d) {
			(async function () {
				await axios
					.get(props.view3d.url, {
						responseType: 'blob',
						onDownloadProgress: (progressEvent) => {
							console.log(progressEvent);
							const current = progressEvent.loaded;

							let percentCompleted = Math.floor((current / props.view3d.size) * 100);

							let elem = document.getElementById('viewer3DloadingAmountInside');

							if (elem) {
								elem.style.width = percentCompleted + '%';
							}
							console.log('completed: ', percentCompleted);
						},
					})
					.then((res) => {
						let myUrl = (window.URL || window.webkitURL).createObjectURL(res.data); // response.data.data

						let videoElem = document.getElementById('videoViewer3D') as HTMLVideoElement;
						if (videoElem) {
							videoElem.src = myUrl;
							videoElem.setAttribute('type', res.headers['content-type']);
							videoElem.load();
						}

						window.requestAnimationFrame(updateMovie);

						setLoading(false);

						return res.data;
					});
			})();
		}
	}, [props.view3d]);

	return (
		<div
			className='viewer3d'
			style={{
				height: 'calc(100% - 60px)',
				backgroundColor: '#090d18',
			}}
		>
			{loading && (
				<div
					id='viewer3Dloading'
					className='viewer3Dloading'
					style={{ color: 'white', position: 'absolute', zIndex: '50000', top: '0px', left: '0px' }}
				>
					<div className='viewer3DloadingText'>{trads.find((t) => t.id === language)?.loading}</div>
					<div id='viewer3DloadingAmount' className='viewer3DloadingAmount'>
						<div id='viewer3DloadingAmountInside' className='viewer3DloadingAmountInside' style={{ backgroundColor: towerColor }}></div>
					</div>
				</div>
			)}
			<video
				id='videoViewer3D'
				autoPlay
				playsInline
				width={window.innerWidth}
				height={window.innerHeight * 2}
				style={{
					position: 'absolute',
					width: '100%',
					//height: '100%',
					zIndex: '40000',
					backgroundColor: '#090d18',
				}}
				onMouseDown={(e: any) => {
					setClickStatus(true);

					let elem = document.getElementById('videoViewer3D') as HTMLVideoElement;

					if (elem) {
						elem.pause();
					}

					_pos.current = [e.clientX, e.clientY];
					_posStart.current = [e.clientX, e.clientY];
				}}
				onTouchStart={(e: any) => {
					props.clearFinger();
					setClickStatus(true);
					let elem = document.getElementById('videoViewer3D') as HTMLVideoElement;

					if (elem) {
						elem.pause();
					}
					_pos.current = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
					_posStart.current = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
				}}
				onMouseUp={(e: any) => {
					setClickStatus(false);
					//setPos([e.clientX, e.clientY]);
				}}
				onTouchEnd={(e: any) => {
					setClickStatus(false);
					//setPos([e.clientX, e.clientY]);
				}}
				onMouseMove={(e: any) => {
					_pos.current = [e.clientX, e.clientY];
				}}
				onTouchMove={(e: any) => {
					_pos.current = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
				}}
			></video>
		</div>
	);
}
