// Modules
import * as THREE from "three";
import { PerspectiveCamera, Vector3 } from "three";
import { degToRad, radToDeg } from "three/src/math/MathUtils";
import axios, { AxiosError } from "axios";

import {
	FikscopeEngine,
	Pawn,
	KeyBinding,
	Sphere,
	Sphere_cons_type,
	update_input_type,
	CameraComponent,
} from "../../fikscopeengine_modules/fikscopeengine-core";

import { LMSphere } from "../level/LMSphere";

/* import { AVrController } from "../../../fikscopeengine_modules/fikscopeengine-core/actors/AVrController"; */

export type Sphero_cons_type = {
	fe: FikscopeEngine;
	material: THREE.Material;
	pos: THREE.Vector3;
	quat: THREE.Quaternion;
	r?: number;
	mass?: number;
	friction?: number;
	physic?: boolean;
	speed?: number;
};

export class PSphere extends Pawn<Sphere> {
	public cameraComponent: CameraComponent;
	//public camera: THREE.PerspectiveCamera;
	public velocityX: number = 0;
	public velocityY: number = 0;
	public speed: number = 0.05;
	public frein: number = 0.998;
	public previousTime = new THREE.Clock().getDelta();
	public clock = new THREE.Clock();
	public delta = new THREE.Clock().getDelta();

	public level?: LMSphere;

	public offsetCamera = new THREE.Vector3(0, 0, 0);
	public offsetCameraGlobal = new THREE.Vector3(0, 0, 0);
	public offsetangle = 0;
	public targetangle = 0;
	public globalScannedAngle = 0;

	public authorization = false;
	private setCameraRotation: any;
	private moveCompass: any;

	public mouseClickStart: number = 0;
	public mousePositionOrigin = new THREE.Vector2();
	public mouseMoving: Boolean = false;
	public cameraOrigin = new THREE.Vector3();
	public mouseClickTiming = Date.now();
	public gestureBool = false;

	public fovStart: number = 90;

	public AR?: any;
	public ags: any;

	public editor: Boolean = true;

	public fe: FikscopeEngine;

	public plane: THREE.Mesh | undefined;

	public cameraScanQuit: any;

	public mode = "rotating"; // ['rotating', 'pointing']
	public changeMode: any;
	public pointingAngle = 0;

	public needResetRotation = true;

	constructor({
		fe,
		material,
		pos,
		quat,
		r = 1,
		mass = 0,
		friction = 1,
		physic = false,
		speed = 10,
		AR,
		sensors,
		editor,
		offsetangle = 0,
		ags,
		cameraScanQuit,
	}: any) {
		const actions = new Map([
			["up", new KeyBinding("KeyW")],
			["down", new KeyBinding("KeyS")],
			["jump", new KeyBinding("Space")],
			["left", new KeyBinding("KeyA")],
			["right", new KeyBinding("KeyD")],
		]);
		super({
			feObject: new Sphere({
				fe: fe,
				material: material,
				pos: pos,
				quat: quat,
				r: r,
				mass: mass,
				friction: friction,
				physic: physic,
				speed: speed,
			} as Sphere_cons_type),
			actions: actions,
		});

		this.cameraScanQuit = cameraScanQuit;
		this.AR = AR;
		this.editor = editor;
		this.fe = fe;

		// Camera setup
		const { clientWidth, clientHeight } = fe.renderer.domElement;

		const camera = new THREE.PerspectiveCamera(75, clientWidth / clientHeight, 1, 2000000);

		const urlParams = new URLSearchParams(window.location.search);
		const angle = urlParams.get("a");

		if (angle) {
			this.offsetangle = Number(angle);
		} else {
			this.offsetangle = 0;
		}

		// this.offsetangle = 90;

		let debugElemStartAngle = document.getElementById("debugElemStartAngle");
		if (debugElemStartAngle) {
			debugElemStartAngle.innerHTML = "Start angle : " + this.offsetangle + "°";
		}

		camera.up.set(0, 0, 1);
		camera.rotateOnWorldAxis(new Vector3(1, 0, 0), degToRad(this.offsetangle)); // 0 * (Math.PI / 180)

		camera.position.set(0, 0, 0);

		this.cameraComponent = new CameraComponent({
			camera,
			renderer: fe.renderer,
		});

		this.cameraComponent.setFunctionUpdate((camera: PerspectiveCamera) => {
			//this.cameraComponent.followPawn(this);
		});

		fe.cameraOperator.setCameraComponent(this.cameraComponent);

		// HERE Disable interactive
		this.controller.handleMouseButton = (e, code, pressed) => this.handleMouseButton(e, code, pressed);
		this.controller.handleMouseMove = (e, dx, dy) => this.handleMouseMove(e, dx, dy);
		this.controller.handleTouchMove = (e, dx, dy) => this.handleTouchMove(e, dx, dy);
		this.controller.handleKeyboardEvent = (e, c, p) => this.handleKeyboardEvent(e, c, p);

		this.controller.handleTouch = (e, code, pressed) => this.handleTouch(e, code, pressed);
		this.controller.handleMouseWheel = (e, dx) => this.handleMouseWheel(e, dx);

		// this.controller.handleGestureMove = (e, dx, dy) => this.handleGestureMove(e, dx, dy);
		// this.controller.handleGesture = (e, c, p) => this.handleGesture(e, c, p);

		this.bindFe(fe);

		//Handle pointlock
		if (fe.inputManager) {
			fe.inputManager.setPointerLock(false);
		}

		let planeGeo = new THREE.PlaneGeometry(1, 1);

		// Changing texture of the pawn
		const texturePawn = new THREE.TextureLoader().load("./assets/textures/compass_01.png");
		const planeMat = new THREE.MeshStandardMaterial({
			map: texturePawn,
			transparent: true,
			alphaTest: 0.1,
			side: THREE.DoubleSide,
		});

		this.plane = new THREE.Mesh(planeGeo, planeMat);

		this.feObject?.mesh.add(this.plane);

		// Set scale, rotation and position of the carrot
		this.plane.scale.set(500, 500, 500);
		this.plane.rotation.set(-degToRad(90), 0, 0);
		this.plane.position.set(0, 100, 0);

		let pivot = new THREE.Group();
		// this.feObject?.mesh.add(pivot);

		pivot.attach(this.plane);

		this.ags = [];

		for (let ag of ags) {
			const geometry = new THREE.SphereGeometry(0.01, 32, 16);
			const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
			const sphere = new THREE.Mesh(geometry, material);
			this.feObject?.mesh.add(sphere);

			let angle = ag.angle; //i*90

			let finalAngle = degToRad(90 - angle);
			let distance = 0.6;

			this.plane.attach(sphere);

			sphere.position.set(Math.cos(finalAngle) * distance, Math.sin(finalAngle) * distance, 0);

			this.ags.push({
				...ag,
				sphere: sphere,
			});
		}

		let debug = document.getElementById("debug");

		let setObjectQuaternion = (function () {
			var zee = new THREE.Vector3(0, 0, 1);
			var euler = new THREE.Euler();
			var q0 = new THREE.Quaternion();
			var q1 = new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)); // - PI/2 around the x-axis

			return function (quaternion: THREE.Quaternion, alpha: number, beta: number, gamma: number, orient: number) {
				euler.set(beta, alpha, -gamma, "YXZ"); // 'ZXY' for the device, but 'YXZ' for us
				quaternion.setFromEuler(euler); // orient the device
				quaternion.multiply(q1); // camera looks out the back of the device, not the top
				quaternion.multiply(q0.setFromAxisAngle(zee, -orient)); // adjust for screen orientation
			};
		})();

		const changeMode = (mode: string = "rotating", data?: any) => {
			let sphereElem = document.getElementById("sphere");

			switch (mode) {
				case "pointing":
					this.mode = "pointing";

					for (let layer of (this.fe.map as LMSphere).sphereObjects) {
						if (layer && layer[1] && layer[1].length > 0) {
							let layerPois = layer[1].find((l: any) => l.name === "pois");

							if (layerPois) {
								for (let o of layerPois.objects) {
									if (o.name !== data) {
										o.visible = false;
									} else {
										o.visible = true;
									}
								}
							}
						}
					}
					for (let s of this.level?.spheres) {
						for (let poi of s.pois) {
							let domElement = document.getElementById(poi.short);
							if (poi.short === data) {
								console.log("Poi " + poi.short + " found.", poi.angle);
								let newAngle = -(180 + 52 + Number(poi.angle));
								this.pointingAngle = newAngle;
							} else {
								if (domElement) {
									domElement.style.display = "none";
								}
							}
						}
					}

					if (sphereElem) {
						(sphereElem as HTMLElement).style.position = "absolute";
						(sphereElem as HTMLElement).style.zIndex = "81500";
					}

					break;
				case "rotating":
				default:
					this.mode = "rotating";

					for (let layer of (this.fe.map as LMSphere).sphereObjects) {
						if (layer && layer[1] && layer[1].length > 0) {
							let layerPois = layer[1].find((l: any) => l.name === "pois");

							if (layerPois) {
								for (let o of layerPois.objects) {
									o.visible = true;
								}
							}
						}
					}
					for (let s of this.level?.spheres) {
						for (let poi of s.pois) {
							let domElement = document.getElementById(poi.short);
							if (domElement) {
								domElement.style.display = "block";
							}
						}
					}

					if (sphereElem) {
						(sphereElem as HTMLElement).style.position = "relative";
						(sphereElem as HTMLElement).style.zIndex = "20000";
					}

					break;
			}
		};
		this.changeMode = changeMode;

		const setCameraRotation = (event: any, _this: any) => {
			if (
				event &&
				event.alpha !== null &&
				event.beta !== null &&
				event.gamma !== null &&
				this.AR.current &&
				this.mode === "rotating"
			) {
				var alphaOffset = 0;
				var screenOrientation = 0;

				var alpha = degToRad(event.alpha);

				var beta = event.beta ? degToRad(event.beta) : 0; // X'
				var gamma = event.gamma ? degToRad(event.gamma) : 0; // Y''

				let debugElemAngleOrient = document.getElementById("debugElemAngleOrient");
				if (debugElemAngleOrient) {
					debugElemAngleOrient.innerHTML = "angle orient =" + window.screen.orientation.angle;
				}

				var orient = window.screen.orientation.angle ? degToRad(-window.screen.orientation.angle) : 0; // O

				if (this.needResetRotation) {
					let worldRot = new THREE.Quaternion();
					this.cameraComponent.camera.getWorldQuaternion(worldRot);
					let worldRotEuler = new THREE.Euler().setFromQuaternion(worldRot);
					worldRotEuler.reorder("YZX");

					this.needResetRotation = false;

					let tmp = radToDeg(worldRotEuler.y) - this.targetangle;

					let debugElemResetRot = document.getElementById("debugElemResetRot");
					if (debugElemResetRot) {
						debugElemResetRot.innerHTML = "Reset Rot tmp : " + tmp;
					}

					this.offsetangle -= tmp;
				}

				setObjectQuaternion(this.cameraComponent.camera.quaternion, alpha, beta, gamma, orient);

				let angle = this.offsetCamera.z + this.offsetCameraGlobal.z;
				this.cameraComponent.camera.rotateOnWorldAxis(
					new Vector3(0, 1, 0),
					degToRad(this.offsetangle) + 2 * angle
				);

				// Rotate screen !!!
				//this.cameraComponent.camera.rotateOnAxis(new Vector3(0, 0, 1), degToRad(/*this.globalScannedAngle + this.offsetangle))
			} else {
				alpha = 0; //this.offsetangle;
				beta = Math.PI / 2;
				gamma = 0;

				orient = 0;

				setObjectQuaternion(this.cameraComponent.camera.quaternion, alpha, beta, gamma, orient);

				let angle = this.offsetCamera.z + this.offsetCameraGlobal.z;
				this.cameraComponent.camera.rotateOnWorldAxis(
					new Vector3(0, 1, 0),
					degToRad(this.offsetangle) + 2 * angle
				);
			}

			this.moveCompass();
		};

		this.setCameraRotation = setCameraRotation;

		const moveCompass = () => {
			let compass = document.getElementById("compassTop");

			if (compass) {
				pivot.quaternion.copy(this.cameraComponent.camera.quaternion);

				let worldRot = new THREE.Quaternion();
				this.cameraComponent.camera.getWorldQuaternion(worldRot);
				let worldRotEuler = new THREE.Euler().setFromQuaternion(worldRot);
				worldRotEuler.reorder("YZX");

				let str =
					"rotate3d(0, 0, 1, " +
					Math.round((((-worldRotEuler.y + degToRad(270) + degToRad(45)) * 180) / Math.PI) * 100) / 100 +
					"deg)";
				//alert(str)

				compass.style.transform = str;

				this.plane?.rotation.set(-degToRad(90), 0, -worldRotEuler.y + degToRad(180));

				for (let ag of this.ags) {
					let domElement = document.getElementById(ag.short);
					if (domElement) {
						let angle =
							Math.round((-worldRotEuler.y - degToRad(ag.angle) + degToRad(180) + degToRad(45)) * 1000) /
							1000;
						let x = Math.cos(angle);
						let y = Math.sin(angle);

						domElement.style.top = "calc(" + 50 + "% + " + Math.round(y * 350 * 0.45) + "px - 28px)"; //vector.y * 100 + '%';
						domElement.style.left = "calc(" + 50 + "% + " + Math.round(x * 350) + "px - 28px)"; //'calc(' + vector.x * 100 + '% - 28px)';
					}
				}
			}
		};

		this.moveCompass = moveCompass;

		this.moveCompass();

		this.update = (args: update_input_type) => {
			let debugElemActualOffsetAngle = document.getElementById("debugElemActualOffsetAngle");
			let debugElemActualAngle = document.getElementById("debugElemActualAngle");
			if (debugElemActualOffsetAngle) {
				debugElemActualOffsetAngle.innerHTML = "Act. offsetangle : " + this.offsetangle + "°";
			}
			if (debugElemActualAngle) {
				let worldRot = new THREE.Quaternion();
				this.cameraComponent.camera.getWorldQuaternion(worldRot);
				let worldRotEuler = new THREE.Euler().setFromQuaternion(worldRot);
				worldRotEuler.reorder("YZX");

				debugElemActualAngle.innerHTML = "Act. angle : " + radToDeg(worldRotEuler.y).toFixed(1) + "°";
			}

			let sensorsElem = document.getElementById("sensors");

			if (sensorsElem && sensorsElem.style.opacity === "1") {
				window.addEventListener("deviceorientation", (event) => {
					this.setCameraRotation(event, this);
				});

				sensorsElem.style.opacity = "0";
			}

			if (this.feObject) {
				this.delta = this.clock.getDelta();
				const sphere = this.feObject;

				let positionFinal = new Vector3(0, 0, 0);

				const actions = this.controller.actions;

				const action_up = actions.get("up");
				const action_down = actions.get("down");
				const action_left = actions.get("left");
				const action_right = actions.get("right");
				//const action_jump = actions.get('jump');

				// Movement to the top and down
				if (action_down && action_down.isPressed) {
					this.velocityY -= this.speed;
				} else {
					this.velocityY *= this.frein;
				}

				if (action_up && action_up.isPressed) {
					this.velocityY += this.speed;
				} else {
					this.velocityY *= this.frein;
				}

				// Movement to the right and left
				if (action_right && action_right.isPressed) {
					this.velocityX += this.speed;
				} else {
					this.velocityX *= this.frein;
				}

				if (action_left && action_left.isPressed) {
					this.velocityX -= this.speed;
				} else {
					this.velocityX *= this.frein;
				}

				positionFinal.setY((sphere.mesh.position.y += this.velocityY * this.delta));
				positionFinal.setX((sphere.mesh.position.x += this.velocityX * this.delta));

				sphere.setPosition({ position: positionFinal });

				var angle = Math.atan2(this.velocityY, this.velocityX);
				var degrees = (180 * angle) / Math.PI;

				//plane.rotation.set(0, 0, degToRad(360 + (Math.round(degrees) % 360)) - 80);

				if (this.mouseMoving) {
				}

				//camera.rotateOnWorldAxis(new Vector3(0, 1, 0), this.delta/2);
			}
		};
	}

	handleMouseMove(event: any, deltaX: number, deltaY: number) {
		if (this.feObject) {
			//this.cameraComponent.rotate(deltaX, deltaY);
			/*console.log("okmove")
			console.log(deltaX)*/

			//console.log(this.mousePositionOrigin.x - event.clientX);

			let moveX = event.clientX - this.mousePositionOrigin.x;
			let moveY = event.clientY - this.mousePositionOrigin.y;

			//console.log(moveX)

			this.cameraComponent.camera.rotation.set(this.cameraOrigin.x, this.cameraOrigin.y, this.cameraOrigin.z);
			this.cameraComponent.camera.rotateX(moveY / 400);
			this.cameraComponent.camera.rotateOnWorldAxis(new Vector3(0, 1, 0), moveX / 400);

			this.moveCompass();
		} else {
			console.warn("No object for pawn");
		}
	}

	handleMouseWheel(event: any, value: number) {
		event.preventDefault();
		//console.log(event);
		//Nothing for now
	}

	checkInterfaceClick(x: number, y: number) {
		let elems = document.getElementsByClassName("disableNaturalClick");

		if (Date.now() - this.mouseClickTiming < 300) {
		} else {
			this.mouseClickTiming = Date.now();

			for (let elem of elems) {
				let square = elem.getBoundingClientRect();

				if (x > square.left && x < square.left + square.width) {
					if (y > square.top && y < square.top + square.height) {
						(elem as HTMLElement).click();
						return true;
					}
				}
			}

			if ((document.getElementById("camera") as HTMLElement)?.style.display === "flex") {
				this.cameraScanQuit();
				return true;
			}

			if (
				(document.getElementById("audio") as HTMLAudioElement)?.currentTime > 0 &&
				document.getElementById("promptAudioGuid")?.style.display != "flex"
			) {
				let prompt = document.getElementById("promptAudioGuid");

				if (prompt) {
					prompt.style.display = "flex";

					let promptValidate = document.getElementById("promptAudioGuidValidate");
					let promptCancel = document.getElementById("promptAudioGuidCancel");

					if (promptValidate && promptCancel) {
						promptValidate.onclick = () => {
							this.level?.localize("");
							if (this.mode === "pointing") {
								this.changeMode("rotating");
								this.level?.localize("");
								this.level?.resetCompass();
							} else {
								this.level?.showCompass(false);
							}

							setTimeout(() => {
								if (prompt) {
									prompt.style.display = "none";

									if (promptValidate && promptCancel) {
										promptValidate.style.pointerEvents = "none";
										promptCancel.style.pointerEvents = "none";
									}
								}
							}, 200);
						};
						promptCancel.onclick = () => {
							if (prompt) {
								prompt.style.display = "none";

								setTimeout(() => {
									if (promptValidate && promptCancel) {
										promptValidate.style.pointerEvents = "none";
										promptCancel.style.pointerEvents = "none";
									}
								}, 200);
							}

							if (this.mode === "pointing") {
								this.level?.showCompass(true);
							} else {
								this.level?.showCompass(true);
							}
						};

						setTimeout(() => {
							if (promptValidate && promptCancel) {
								promptValidate.style.pointerEvents = "all";
								promptCancel.style.pointerEvents = "all";
							}
						}, 200);
					}
				}
				return true;
			}
		}
		return false;
	}

	checkPoiClick(x: number, y: number) {
		let pois = document.getElementsByClassName("disablePoiNaturalClick");

		let found = null;
		let alreadyClick = false;

		if (this.level) {
			// Editor
			if (this.editor) {
				// DIDNT WORK
				if (this.level.currentSelection !== null) {
					let foundEditor = null;

					for (let poi of pois) {
						let child = poi.children[0]?.children[0];
						if (child) {
							let square = child.getBoundingClientRect();

							if (x > square.left && x < square.left + square.width) {
								if (y > square.top && y < square.top + square.height) {
									//if (poi.id === this.level.currentSelection) {
									foundEditor = (this.level as LMSphere).data.pois
										.get((this.level as LMSphere).sphere)
										.find((i: any) => i.short === poi.id);
									//}
								}
							}
						}
					}
					if (foundEditor) {
						console.log("passage");
						let mousePosition = new THREE.Vector2(x / window.innerWidth, y / window.innerHeight);

						let xDifference = (mousePosition.x - foundEditor.positionScreen.x) * -1;
						let yDifference = mousePosition.y - foundEditor.positionScreen.y;
						console.log(xDifference + "    " + yDifference);
						console.log("Old position : " + foundEditor.linex + "    " + foundEditor.liney);
						let fov = (this.fe.camera as THREE.PerspectiveCamera).getEffectiveFOV();
						let newX =
							foundEditor.linex -
							xDifference * (fov / (this.fe.camera as THREE.PerspectiveCamera).aspect);
						let newY = foundEditor.liney - yDifference * fov;
						console.log(newX + "    " + newY);
						//console.log(foundEditor)
						// change position on API
						let headers = {
							headers: {
								//'Content-Type': 'multipart/form-data',
								Authorization:
									"JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MDQ0ZjA4ZWMzMGIzZDE4MjI5YTlmOTQiLCJpYXQiOjE2NjU1ODE5NzMsImV4cCI6MTY2NjE4Njc3M30.0O05aQZLqbiiVGp3aJps-A6wAKIontd3Zhl9v_LGgh8",
							},
						};
						/*let data = {
										linex:""+newX+"",
										liney:""+newY+""
									}*/
						axios
							.put(
								process.env.REACT_APP_CLIENT_API_URL + "/mpoi/update/" + foundEditor.id,
								{
									linex: "" + newX + "",
									liney: "" + newY + "",
								},
								headers
							)
							.then(function (response) {
								console.log(response);
							});
						if (this.level.setPoi) {
							this.level.setPoi(null);
							this.level.currentSelection = null;
						}
					}
				}
			} else {
				for (let poi of pois) {
					let child = poi.children[0]?.children[0];
					if (child) {
						let square = child.getBoundingClientRect();

						if (x > square.left && x < square.left + square.width) {
							if (y > square.top && y < square.top + square.height) {
								found = (this.level as LMSphere).data.pois
									.get((this.level as LMSphere).sphere)
									.find((i: any) => i.short === poi.id);
								if (this.level.currentSelection === poi.id) {
									alreadyClick = true;
								}
								//this.level.currentSelection = poi.id;
							}
						}
					}
				}
				if (found) {
					if (this.level.setPoi) {
						this.level.setPoi(found.short);
						if (this.level.setCard) {
							this.changeMode("rotating");
							this.level.localize("");
							this.level.resetCompass();
							this.level.setCard(found.mcard);
						}
					}
				} else {
					if (this.level.setPoi) {
						this.level.setPoi(null);
						this.level.currentSelection = null;
					}
				}
			}
		}

		// let found = null;
		// let distance = 10000;

		// let mousePosition = new THREE.Vector2(x / window.innerWidth, y / window.innerHeight);

		// let alreadyClick = false;

		// if (Date.now() - this.mouseClickTiming < 300) {
		// } else {
		// 	this.mouseClickTiming = Date.now();

		// 	if (this.level) {
		// 		if (this.editor && this.level.currentSelection !== null) {
		// 			let foundEditor = null;
		// 			for (let p of (this.level as LMSphere).data.pois.get((this.level as LMSphere).sphere)) {
		// 				if (p.short === this.level.currentSelection) {
		// 					foundEditor = p;
		// 				}
		// 			}
		// 			if (foundEditor) {
		// 				let xDifference = (mousePosition.x - foundEditor.positionScreen.x) * -1;
		// 				let yDifference = mousePosition.y - foundEditor.positionScreen.y;
		// 				console.log(xDifference + '    ' + yDifference);
		// 				console.log('Old position : ' + foundEditor.linex + '    ' + foundEditor.liney);
		// 				let fov = (this.fe.camera as THREE.PerspectiveCamera).getEffectiveFOV();
		// 				let newX = foundEditor.linex - xDifference * (fov / (this.fe.camera as THREE.PerspectiveCamera).aspect);
		// 				let newY = foundEditor.liney - yDifference * fov;
		// 				console.log(newX + '    ' + newY);
		// 				//console.log(foundEditor)
		// 				// change position on API
		// 				let headers = {
		// 					headers: {
		// 						//'Content-Type': 'multipart/form-data',
		// 						Authorization:
		// 							'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MDQ0ZjA4ZWMzMGIzZDE4MjI5YTlmOTQiLCJpYXQiOjE2NjU1ODE5NzMsImV4cCI6MTY2NjE4Njc3M30.0O05aQZLqbiiVGp3aJps-A6wAKIontd3Zhl9v_LGgh8',
		// 					},
		// 				};
		// 				/*let data = {
		// 					linex:""+newX+"",
		// 					liney:""+newY+""
		// 				}*/
		// 				axios
		// 					.put(
		// 						process.env.REACT_APP_CLIENT_API_URL + '/mpoi/update/' + foundEditor.id,
		// 						{
		// 							linex: '' + newX + '',
		// 							liney: '' + newY + '',
		// 						},
		// 						headers,
		// 					)
		// 					.then(function (response) {
		// 						console.log(response);
		// 					});
		// 				if (this.level.setPoi) {
		// 					this.level.setPoi(null);
		// 					this.level.currentSelection = null;
		// 				}
		// 			}
		// 		}

		// 		for (let p of (this.level as LMSphere).data.pois.get((this.level as LMSphere).sphere)) {
		// 			let newDistance = mousePosition.distanceTo(p.positionScreen);

		// 			if (newDistance < distance) {
		// 				distance = newDistance;
		// 				if (distance < 0.1) {
		// 					found = p;
		// 					if (this.level.currentSelection === p.short) {
		// 						alreadyClick = true;
		// 					}
		// 					this.level.currentSelection = p.short;
		// 					console.log(p);
		// 				}
		// 			}
		// 		}
		// 		if (found) {
		// 			if (this.level.setPoi) {
		// 				this.level.setPoi(found.short);
		// 				/*if (alreadyClick) {
		// 					console.log('already in');

		// 					if(this.editor){

		// 					}else{
		// 						if (this.level.setCard) {
		// 							this.level.setCard(found.mcard);
		// 						}
		// 					}

		// 				}*/
		// 				if (this.level.setCard) {
		// 					this.level.setCard(found.mcard);
		// 				}
		// 			}
		// 		} else {
		// 			if (this.level.setPoi) {
		// 				this.level.setPoi(null);
		// 				this.level.currentSelection = null;
		// 			}
		// 		}
		// 	}
		// }
	}

	handleTouchMove(event: any, deltaX: number, deltaY: number) {
		if (!this.gestureBool) {
			if (this.feObject) {
				//this.cameraComponent.rotate(deltaX, deltaY);
				/*console.log("okmove")
				console.log(deltaX)*/

				//console.log(this.mousePositionOrigin.x - event.clientX);

				let moveX = event.changedTouches[0].clientX - this.mousePositionOrigin.x;
				let moveY = event.changedTouches[0].clientY - this.mousePositionOrigin.y;

				//console.log(moveX)

				/*this.cameraComponent.camera.rotation.set(this.cameraOrigin.x, this.cameraOrigin.y, this.cameraOrigin.z);
				this.cameraComponent.camera.rotateX(moveY / 400);
				this.cameraComponent.camera.rotateOnWorldAxis(new Vector3(0, 1, 0), moveX / 400);*/

				this.offsetCamera.x = moveY / 400;
				this.offsetCamera.z = moveX / 400;

				let sensors = document.getElementById("sensors") as HTMLInputElement;

				let details = navigator.userAgent;
				let regexp = /android|iphone|kindle|ipad/i;
				let isMobileDevice = regexp.test(details);

				if (sensors.value !== "true" || isMobileDevice === false) {
					this.setCameraRotation(undefined, this);
				}
			} else {
				console.warn("No object for pawn");
			}
		}
	}

	handleTouch(event: any, code: string, pressed: boolean) {
		if (this.gestureBool === false) {
			if (event.type === "touchstart") {
				this.mouseMoving = true;
				this.mouseClickStart = Date.now();
				this.mousePositionOrigin.set(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
				this.cameraOrigin.set(
					this.cameraComponent.camera.rotation.x,
					this.cameraComponent.camera.rotation.y,
					this.cameraComponent.camera.rotation.z
				);
			} else if (event.type === "touchend") {
				let length = Date.now() - this.mouseClickStart;
				this.mouseMoving = false;

				if (length < 150) {
					if (!this.checkInterfaceClick(event.changedTouches[0].clientX, event.changedTouches[0].clientY)) {
						this.checkPoiClick(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
					}
				}

				let moveX = event.changedTouches[0].clientX - this.mousePositionOrigin.x;
				let moveY = event.changedTouches[0].clientY - this.mousePositionOrigin.y;

				this.offsetCamera.x = moveY / 400;
				this.offsetCamera.z = moveX / 400;
				this.offsetCameraGlobal.x += this.offsetCamera.x;
				this.offsetCameraGlobal.z += this.offsetCamera.z;
				this.offsetCamera.x = 0;
				this.offsetCamera.z = 0;
				//console.log("mouseUp")
			}

			// if (event.type === 'touchend') {
			// 	this.checkBox(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
			// }
		}
	}

	handleMouseButton(event: any, code: string, pressed: boolean) {
		//Nothing for now
		if (event.type === "mousedown") {
			//console.log("mouseDown")
			this.mouseMoving = true;
			this.mouseClickStart = Date.now();
			this.mousePositionOrigin.set(event.clientX, event.clientY);
			console.log(
				"Save as ",
				this.cameraComponent.camera.rotation.x,
				this.cameraComponent.camera.rotation.y,
				this.cameraComponent.camera.rotation.z
			);
			this.cameraOrigin.set(
				this.cameraComponent.camera.rotation.x,
				this.cameraComponent.camera.rotation.y,
				this.cameraComponent.camera.rotation.z
			);
		} else if (event.type === "mouseup") {
			let length = Date.now() - this.mouseClickStart;
			this.mouseMoving = false;

			if (length < 150) {
				if (!this.checkInterfaceClick(event.clientX, event.clientY)) {
					this.checkPoiClick(event.clientX, event.clientY);
				}
			}
			//console.log("mouseUp")
		}
	}

	handleGestureMove(event: any, deltaX: number, deltaY: number) {
		if (this.gestureBool) {
			//console.log("handleGestureMove")
			//if (this.feObject) {
			//var curDiff = Math.abs(event[0].clientX - event[1].clientX);
			//console.log(curDiff)
			console.log(event.scale);

			let newFocale = this.fovStart / event.scale;

			newFocale = Math.max(30, Math.min(90, newFocale));

			(this.fe.camera as THREE.PerspectiveCamera).fov = newFocale;
			(this.fe.camera as THREE.PerspectiveCamera).updateProjectionMatrix();

			((this.fe as FikscopeEngine).map as LMSphere).setZoom(newFocale);

			/*} else {
			console.warn('No object for pawn');
		}*/
		}
	}

	handleGesture(event: any, code: string, pressed: boolean) {
		//console.log("handle gesture start end")
		if (event.type === "gesturestart") {
			event.preventDefault();
			this.gestureBool = true;

			this.fovStart = (this.fe.camera as THREE.PerspectiveCamera).getEffectiveFOV();
		} else if (event.type === "gestureend") {
			setTimeout(() => {
				this.gestureBool = false;
			}, 300);
		}
	}

	handleKeyboardEvent(event: any, code: string, pressed: boolean) {
		//could be more effecient to only get from code…
		//this.actions.get(code) … up down left right is useless
		if (this.controller) {
			for (let binding of this.controller.actions.values()) {
				if (binding.eventCodes === code) {
					if (binding.isPressed !== pressed) {
						binding.isPressed = pressed;
					}
				}
			}
		} else {
			console.warn("[Sphero]: Controller undef ?");
		}
	}
}
