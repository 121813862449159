// React
import { useEffect, useState } from 'react';
// Redux
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../actions/user';
// CSS
import './introScreen.css';
// Assets
import montparnasseBG from '../../style/assets/montparnasse.png';
import berlinBG from '../../style/assets/berlin.png';
import rotterdamBG from '../../style/assets/rotterdam.png';
import chicagoBG from '../../style/assets/chicago.png';

import soundOnIcon from '../../style/assets/icons/soundOn.png';
import soundOffIcon from '../../style/assets/icons/soundOff.png';

import enFlag from '../../style/assets/flags/en.png';
import frFlag from '../../style/assets/flags/fr.png';
import deFlag from '../../style/assets/flags/de.png';
import esFlag from '../../style/assets/flags/es.png';
import nlFlag from '../../style/assets/flags/nl.png';
// Trads
import { trads } from '../../datas/trads.js';

export const IntroScreen = (props: { datas: any; next: () => void; sound: boolean; setSound: any }) => {
	const { language, place, towerColor } = useSelector((state: any) => state.user);

	const dispatch: Dispatch<any> = useDispatch();

	const [langs, setLangs] = useState<any[]>([]);

	useEffect(() => {
		let langsTmp = [];
		switch (place) {
			case 'montparnasse':
				langsTmp.push('fr');
				langsTmp.push('en');
				break;
			case 'berlin':
				langsTmp.push('de');
				langsTmp.push('en');
				break;
			case 'chicago':
				langsTmp.push('en');
				langsTmp.push('es');
				break;
			case 'rotterdam':
				langsTmp.push('nl');
				langsTmp.push('en');
				break;
		}

		setLangs(langsTmp);

		if (!langsTmp.includes(language)) {
			setLang(langsTmp[0]);
		}
	}, [place]);

	const backgrounds = new Map([
		['montparnasse', montparnasseBG],
		['berlin', berlinBG],
		['rotterdam', rotterdamBG],
		['chicago', chicagoBG],
	]);

	const flags = new Map([
		['en', enFlag],
		['fr', frFlag],
		['de', deFlag],
		['nl', nlFlag],
		['es', esFlag],
	]);

	const setLang = (lang: string) => {
		dispatch(setLanguage(lang));
	};

	return (
		<div
			id='introScreen'
			className='introScreen'
			// style={{
			// 	backgroundImage: 'url(' + backgrounds.get(place) + ')',
			// }}
		>
			<div
				className='introScreen-image clickable '
				onClick={() => {
					props.next();
				}}
				style={{
					backgroundImage: 'url(' + backgrounds.get(place) + ')',
				}}
			></div>
			<div className='introScreen-langSelection'>
				<div id='introScreen-langSelectionContent' className='introScreen-langSelectionContent'>
					<div className='introScreen-langSelectionTitle'>{trads.find((x: any) => x.id === language)?.selectLang}</div>
					{langs.map((elem) => {
						return (
							<div
								key={'introScreen-langSelection-button' + elem}
								className='btnStart introScreen-langSelection-button clickable'
								onClick={() => {
									if (language !== elem) {
										setLang(elem);
									}
									props.next();
								}}
							>
								<div className='introScreen-langSelection-buttonFlag' style={{ backgroundImage: 'url(' + flags.get(elem) + ')' }}></div>
								<div className='introScreen-langSelection-buttonText'>{trads.find((x: any) => x.id === elem)?.name.toUpperCase()}</div>
								{trads.find((x: any) => x.id === elem)?.id === language && <div className='introScreen-langSelection-buttonCheck'></div>}
							</div>
						);
					})}
				</div>
			</div>
			<div id='introScreen-langSelectionContent-loading' className='introScreen-langSelectionContent-loading'>
				<div className='introScreen-langSelectionContent-loadingText'>{trads.find((t) => t.id === language)?.loading}</div>
				<div className='introScreen-langSelectionContent-loadingAmount'>
					<div
						id='introScreen-langSelectionContent-loadingAmountInside'
						className='introScreen-langSelectionContent-loadingAmountInside'
						style={{ backgroundColor: towerColor }}
					></div>
				</div>
			</div>
			{/* <div
				className='soundButton clickable '
				onClick={() => {
					props.setSound(!props.sound);
				}}
				style={{
					position: 'absolute',
					width: '24px',
					height: '24px',
					top: '16px',
					right: '16px',
					backgroundImage: 'url(' + (props.sound ? soundOnIcon : soundOffIcon) + ')',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundPosition: 'center',
				}}
			></div> */}
		</div>
	);
};
