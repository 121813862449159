// CSS
import './privacypolicy.css';

export const Privacypolicy = (props: { language: string, goBack: any }) => {
	return (
		<div className='privacypolicy-topContainer'>
			<div className='videoStory-top' style={{
                position: 'fixed'
            }}>
				<div
					className='videoStory-back'
					onClick={() => {
						props.goBack();
					}}
				></div>
			</div>
			{ true /*props.language === "de" || props.language === "en" || props.language === "fr"*/ ?
                    <div>
                        <div className='credits-textSubtitle'>Privacy Policy{"\n"}</div>
                        <div className='credits-text'>
						    <div className='credits-default'>What happens with your personal data when you visit our website, when you make an order or a reservation or subscribe to our newsletter or if you visit our pages on Facebook and Instagram? Find out here in our Privacy Policy.</div>

                            <div className='credits-subtitle'><b>A. General information on how we deal with personal data</b></div>

                            <div className='credits-default'>In the following, we provide you with information about how we process personal data in certain situations. In this Part A you can find some general information. In the other parts you can find out about the individual situations in which we process data.</div>

                            <div className='credits-default'>Personal data are all data relating to an identified or identifiable natural person. This may include for example your name, address and email address, but also the IP address of the device you are using to access the Internet and your user behaviour on our website.</div>

                            <div className='credits-default'>Personal data are processed when you use our website, contact us by email or send us questions using a contact form, buy tickets or make a reservation.</div>



                            <div className='credits-subtitle'>1. Controller</div>

                            <div className='credits-default'>The controller responsible for the processing of your personal data within the meaning of the General Data Protection Regulation (GDPR) is</div>

                            <div className='credits-default'>Timescope<br />
                                87 boulevard Saint Michel<br />
                                75005 Paris<br /><br />

                                Telephone: +33784219474<br />
                                E-Mail: hello@timescope.co<br /></div>

                            <div className='credits-subtitle'>2. Data Protection Officer</div>

                            <div className='credits-default'>You can contact our data protection officer by email hello@timescope.co. We would expressly like to point out that when you use this email address the content is not noted exclusively by our data protection officer. If you wish to share confidential information with him, we would ask you to use this email address in the first instance to initiate direct contact.</div>

                            <div className='credits-subtitle'>3. Collaboration with third parties</div>

                            <div className='credits-default'>We sometimes use external service providers and partners for processing your data, for example for hosting the CMS of our website and dealing with customer feedback. We have taken the greatest care in selecting and commissioning them. The partners are either subject to our directions within the scope of contractual processing or have entered into other agreements with us in respect of data protection, for instance if we are jointly responsible for processing the data. We also collaborate with partners who are professionally sworn to secrecy, such as tax advisors, lawyers and other service providers.</div>

                            <div className='credits-subtitle'>4. Data transfer to third countries</div>

                            <div className='credits-default'>We sometimes use services whose providers are based in third countries (i.e. countries outside the European Union or European Economic Area) or process personal data there, that is, countries where the level of data protection is not in line with that in the European Union. If this is the case, and the European Union has not issued an adequacy decision (Article 45 of the GDPR) for these countries, we have taken appropriate precautions to guarantee a reasonable level of data protection. These include the standard contractual clauses of the European Union.</div>

                            <div className='credits-default'>Where this is not possible, we base the data transfer on the derogations in Article 49 of the GDPR, in particular your explicit consent or the necessity of the transfer for the performance of a contract or for the implementation of pre-contractual measures.</div>

                            <div className='credits-default'>If transfer to a third country is envisaged and there is no adequacy decision and no appropriate guarantees exist, it is possible and there is the risk that the authorities (especially secret services) in the third country in question may gain access to the transferred data to collect and analyse them, and that your rights as a data subject may not be able to be enforced. You will similarly be informed of this when we ask for your consent with the consent banner.</div>

                            <div className='credits-subtitle'>5. Storage period</div>

                            <div className='credits-default'>As a matter of principle, we only store personal data for as long as is necessary for fulfilling the purpose for which we have collected the data. After this we erase the data without undue delay unless we still need the data until the end of the statutory limitation period for purposes of evidence for claims under civil law or due to statutory storage obligations.</div>

                            <div className='credits-default'>For purposes of evidence we must keep your contract data for three years with effect from the end of the year in which our business relationship with you ends. Any claims there may be will not expire in line with the normal statutory limitation period before this time.</div>

                            <div className='credits-default'>Even after this we still need to store some of your data for bookkeeping reasons. We are legally obliged to do so in accordance with statutory documentation duties, especially those under the German Commercial Code [Handelsgesetzbuch] and the Tax Code [Abgabenordnung]. The periods stipulated there for safekeeping of documents are from two to ten years. Where relevant, we will let you know in the sections below relating to individual kinds of processing about the storage period applicable in those cases.</div>

                            <div className='credits-subtitle'>6. Your rights as a subject of data processing</div>

                            <div className='credits-default'>You have the following rights as a data subject:<br /><br />

                            the right to withdraw your consent once given;<br />
                            the right to object to the processing of your personal data (Article 21 of the GDPR);<br />
                            the right to obtain information about your personal data being processed by us (Article 15 of the GDPR);<br />
                            the right to obtain rectification of incorrect personal data about you stored by us (Article 16 of the GDPR);<br />
                            the right to obtain erasure of your personal data (Article 17 of the GDPR);<br />
                            the right to restrict the processing of your personal data (Article 18 of the GDPR);<br />
                            the right to portability of your personal data (Article 20 of the GDPR);<br />
                            the right to make a complaint to a supervisory authority (Article 77 of the GDPR).<br /></div>

                            <div className='credits-default'>To exercise your rights as described here you may at any time use the contact details provided above. This is also the case if you wish to obtain copies from us of guarantees proving the existence of an appropriate level of data protection. We will comply with your data protection request to the extent that the relevant legal requirements are met.</div>

                            <div className='credits-default'>Your enquiries regarding exercise of your data privacy rights and our replies to them will be kept for documentation purposes for a period of up to three years or, on a case-by-by case basis, longer than this if need be for the establishment, exercise or defence of legal claims. The legal basis for this is Article 6(1) (f) of the GDPR, based on our legitimate interest of defence against civil law claims according to Article 82 of the GDPR, avoidance of fines according to Article 83 of the GDPR and fulfilment of our duty of demonstrating compliance under Article 5(2) of the GDPR.</div>

                            <div className='credits-default'>You have the right to withdraw the consent you have given us at any time. The consequence of this will be that we will no longer continue the data processing into the future that is based on this consent. Your withdrawal of consent will not affect the lawfulness of processing carried out on the basis of your consent up to the time of its withdrawal.</div>

                            <div className='credits-default'>Insofar as we process your data on the basis of our legitimate interests, you have the right to lodge an objection at any time to the processing of your data on grounds relating to your particular situation. If you are objecting to the processing of your data for direct marketing purposes, you have a general right to object which will be honoured by us without reasons being given by you.</div>

                            <div className='credits-default'>If you wish to exercise your right to withdraw consent or to object, an informal notification to the above contact details is sufficient.</div>

                            <div className='credits-default'>Finally, you have the right to lodge a complaint to a data protection supervisory authority. You may, for example, exercise this right with a supervisory authority in the Member State of your habitual residence, place of work or place of the alleged infringement.</div>

                            <div className='credits-subtitle'>7. Automated decision-making</div>

                            <div className='credits-default'>We do not use automated decision-making or profiling.</div>

                            <div className='credits-subtitle'>8. Changes to the Privacy Policy</div>

                            <div className='credits-default'>We update this Privacy Policy from time to time, for instance when we update our website or if there are any changes in statutory or official regulations.</div>

                            <div className='credits-subtitle'>A. Data processing when you access our website</div>

                            <div className='credits-default'>Every time you visit our website, we collect connection data automatically transmitted by your browser to make your visit to our website possible. These connection data include the HTTP header information including the user agent, and comprise in particular:<br /><br />

                            the IP address of the requesting device,<br />
                            the method (e.g. GET, POST), date and time of the request,<br />
                            the address of the requested website and path of the requested file,<br />
                            if applicable the website or file you previously accessed (HTTP referrer),<br />
                            details of the browser and operating system you are using,<br />
                            the version of the HTTP protocol, HTTP status code, and size of the file being delivered,<br />
                            request information such as language, type of content, coding of the content and fonts.<br /></div>
                            <div className='credits-default'>Processing these connection details is absolutely necessary to make your visit to our website possible, to guarantee the long-term functionability and security of our systems and for generally looking after the administration of our website. The connection data are also stored in internal log files for the purposes described above, but only for as long as is necessary and limited to the content that is absolutely necessary, to enable us for instance to find the cause of repeated access requests or access requests made with criminal intent which endanger the stability and security of our website and to take the appropriate action.</div>

                            <div className='credits-default'>The legal basis for the processing of these data is Article 6(1) (f) of the GDPR.</div>

                            <div className='credits-default'>The data will be erased as soon as they are no longer needed for the purpose for which they were collected. If the data have been collected for the provision of the website, this will be when the relevant session is ended. If the data are stored in log files, this will be after no more than 30 days. It is possible to store data for longer than this on a case-by-case basis if we have a legitimate interest in doing so.</div>

                            <div className='credits-default'>We host the CMS for our website with OVH, 2 rue Kellermann 59100 Roubaix France. This company, with which we have signed a contractual processing agreement, has undertaken only to store the data in Europe.</div>

                            <div className='credits-subtitle'>B. Processing of data for general contact</div>
                            <div className='credits-default'>There are various different ways you can get in touch with us – using a contact form, by email, by telephone or fax or by post.</div>

                            <div className='credits-default'>We will use the data we collect when you do so only for the purpose of communicating with you. Depending on the way you choose to make contact with us these data will be your email address, your first name and surname, your telephone number, the date and time of your enquiry and the nature of your enquiry, as well as details of the contract if your enquiry with us is to do with entering into a contract or performing a contract.</div>

                            <div className='credits-default'>We normally base our processing of your data on Article 6(1) (f) of the GDPR. Our legitimate interest is in the effective processing of enquiries made to us and if applicable the initiation or processing of business relationships. If your aim in getting in touch with us is to enter into a contract, or if you get in touch with us to make an enquiry to do with an existing contract with us, the legal basis for processing your personal data is Article 6(1) (b) of the GDPR.</div>

                            <div className='credits-default'>When your data are no longer needed for processing your enquiry because the matter has been dealt with and your issue has been cleared up, your data will be routinely erased by us unless this is prevented by any statutory periods of safekeeping or legitimate interests in continued safekeeping of your data.</div>



                            <div className='credits-subtitle'>C. Online presence on social media</div>
                            <div className='credits-default'>We maintain an online presence on social media to enable us to communicate with customers and prospective customers there and provide information about our services. The data of users is normally processed by the social media providers in question for market research and advertising purposes. In this way, user profiles can be created on the basis of users’ interests. For this purpose, cookies and other identifiers are stored on the computers of the persons concerned. On the basis of these user profiles, advertising and other material is then displayed within the social media as well as on third party websites.</div>

                            <div className='credits-default'>It is possible that in the course of operating our online presence we may have recourse to information such as statistics provided by the social media providerson the use of our online presence. These statistics are aggregated and may include in particular demographical information (such as age, gender and region) as well as data regarding interactions with our online presence (such as likes) and the postings and content posted there. They may also provide information about the interests of our users and the content and topics that are especially relevant for them. We can also use this information to adapt the design of our online presence and the activities and content we present there in order to optimise it for our audience. You can find details and links to the social media data to which we have access as operator of an online presence in the list below. The collection and processing of these statistics are generally subject to the responsibility of a joint controller.</div>

                            <div className='credits-default'>The legal basis for this data processing is Article 6(1) (f) of the GDPR on the basis of our legitimate interest in effectively informing and communicating with our users, respectively Article 6(1) (b) of the GDPR, in order to stay in touch with our customers and provide them with information and the implementation of pre-contractual measures with prospective customers.</div>

                            <div className='credits-default'>If you have a social media account, we may be able to see the information and media you have made publicly accessible when we access your profile. In addition, the social media may also allow us to contact you. This may be by direct messaging or in a posting. Communication via social media in this case is subject to the responsibility as controller of the social media provider as messenger and platform service.</div>

                            <div className='credits-default'>You can find out the legal basis for data processing conducted by the social media providers in their own responsibility as controllers from the privacy policies of the relevant social media providers. Via the links provided below you can also find more information on the way each provider processes data and about how you can lodge an objection.</div>

                            <div className='credits-default'>We would point out that the most efficient way to make data privacy enquiries is to address them to the relevant social media providers directly, as only these providers have access to the data and can take the relevant measures directly. If you contact us with your enquiry, we will pass it on to the provider of the relevant social media. Below is a list with information on the social media on which we maintain an online presence:</div>

                            <div className='credits-default'>Facebook (Meta Platforms Ireland Ltd., 4 Grand Canal Square, Dublin 2, Ireland)<br />
                            Operation of the Facebook-Fan Page in joint responsibility as controller on the basis of an Agreement on Joint Processing of Personal Data (Page Insights Controller Addendum)<br />
                            Information about the page insights data being processed and on how to make contact with any data privacy enquiries: https://www.facebook.com/legal/terms/information_about_page_insights_data<br />
                            Privacy Policy: https://www.facebook.com/about/privacy/<br />
                            Opt-out: https://www.facebook.com/settings?tab=adsandhttp://www.youronlinechoices.com.<br />
                            Instagram (Meta Platforms Ireland Ltd., 4 Grand Canal Square, Dublin 2, Ireland)<br />
                            Instagram Business-Konto on the basis of an Agreement on Joint Processing of Personal Data (Page Insights Controller Addendum): https://www.facebook.com/legal/terms/page_controller_addendum<br />
                            Information about the page insights data being processed and on how to make contact with any data privacy enquiries: https://www.facebook.com/legal/terms/information_about_page_insights_data<br />
                            Privacy Policy: https://help.instagram.com/519522125107875<br />
                            Opt-out (statement): https://de-de.facebook.com/help/instagram/2885653514995517?locale=de_DE<br />
                            YouTube (Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland)<br />
                            Privacy Policy: https://policies.google.com/privacy<br />
                            Opt-out: https://www.google.com/settings/ads.<br />
                            Twitter (Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07 Ireland)<br />
                            Privacy Policy: https://twitter.com/de/privacy<br />
                            Opt-out: https://twitter.com/personalization.<br />
                            Pinterest(Pinterest Europe Ltd., Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Ireland)<br />
                            Privacy Policy: https://policy.pinterest.com/de/privacy-policy<br />
                            Notification settings: https://help.pinterest.com/de/article/edit-notification-settings<br />
                            LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Ireland)<br />
                            Operation of LinkedIn corporate site in joint responsibility as controller on the basis of an Agreement on Joint Processing of Personal Data(Page Insights Controller Addendum)<br />
                            Information about the page insights data being processed and on how to make contact with any data privacy enquiries: https://legal.linkedin.com/pages-joint-controller-addendum<br />
                            Privacy Policy: https://www.linkedin.com/legal/privacy-policy<br />
                            Opt-out: https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.<br />
                            Xing/Kununu (XING SE, Dammtorstrasse 30, 20354 Hamburg)<br />
                            Privacy Policy / opt-out: https://privacy.xing.com/de/datenschutzerklaerung.<br /></div>
 

                            <div className='credits-subtitle'>D. Tools we use on the website</div>

                            <div className='credits-subtitle'>1. Technologies we use</div>

                            <div className='credits-default'>This website uses various services and applications (together called “tools“) which are provided either by ourselves or by third parties. These include in particular tools which use technologies for storing information on your end device or for accessing it.</div>

                            <div className='credits-default'>Cookies: Information stored on your end device consisting essentially of a cookie name, a value, the storing domain and an expiry date. Session cookies are deleted at the end of the session, while persistent cookies are deleted at the set expiry time. Cookies may also be removed manually.</div>

                            <div className='credits-default'>Web Storage (Local Storage/ Session Storage): Information stored on your end device consisting of a name and a value. Information in Session Storage is deleted at the end of the session, while information in Local Storage has no expiry time and can in principle remain stored if no removal mechanism has been configured, such as storage of Local Storage with expiry time setting. Information in Local and Session Storage may also be removed manually.</div>
                            <div className='credits-default'>JavaScript: Programming code embedded or accessed on the website which sets for instance cookies or Web Storage or actively collects information from your end device on your user behaviour when visiting the website. JavaScript may also be used for “active fingerprinting“ and for creating user profiles. JavaScript can be blocked in your browser settings, but most services will then no longer work.</div>

                            <div className='credits-default'>Pixels: Tiny graphics automatically loaded by a service to allow website users to be recognised on their return by automatically transmitting the usual access data (essentially IP address and information about browser, operating system, language, fonts, addresses accessed and time of access) and, for instance, to identify the opening of an email or a visit to a website. This means that Pixels can be used for “passive fingerprinting“ and creating user profiles. You can prevent Pixels by blocking images for instance in emails, but this will greatly limit their display.</div>
                            <div className='credits-default'>Fingerprints are created using these technologies as well as just by setting up a connection to a site. These are user profiles which work without cookies or Web Storage but can still recognise returning visitors. Fingerprints created due to a connection set-up cannot be completely prevented manually.</div>

                            <div className='credits-default'>Most browsers are configured as standard to accept cookies, run scripts and display graphics. You can, however, generally change your browser settings to refuse all or certain cookies or to block scripts and graphics. If you completely block the storing of cookies, the display of graphics and the running of scripts, our services will probably not work properly or at all.</div>

                            <div className='credits-default'>Below is a list of the tools we use by category, including especially information about the providers of the tools, the storage period of cookies and information in Local Storage and Session Storage and the transfer of data to third parties. We also explain when we will ask for your voluntary consent for the use of the tools and how you can withdraw this consent.</div>

                            <div className='credits-subtitle'>2. Legal basis and withdrawal of consent</div>

                            <div className='credits-subtitle'>2.1. Legal basis</div>
                            <div className='credits-default'>We use tools necessary for operating the website on the basis of our legitimate interest pursuant to Article 6(1) (f) of the GDPR in order to provide the basic functioning of our website. In certain cases, these tools may also be necessary for the performance of a contract or for implementing pre-contractual measures; in this case, processing is on the basis of Article 6(1) (b) of the GDPR. Accessing and storing information on your end device is an absolute requirement in such cases and this is on the basis of the laws implementing the ePrivacy Directive in the EU Member States; in Germany, this is section 25(2) of the Protection of Data in Telecommunications and Telemedia Act.</div>

                            <div className='credits-default'>We use all other non-essential (optional) tools which provide additional functions on the basis of your consent pursuant to Article 6(1) (a) of the GDPR. Accessing and storing information on your end device is an absolute requirement in such cases and this is on the basis of the laws implementing the ePrivacy Directive in the EU Member States; in Germany, this is section 25(1) of the Protection of Data in Telecommunications and Telemedia Act. We will only process your data using these tools if we have obtained your consent beforehand.</div>

                            <div className='credits-default'>We refer to Part A (Data transfer to third countries), including with regard to the risks which may be involved, for instances where we need to transfer your personal data to third countries. We will inform you if standard contractual clauses or other guarantees have been entered into for the use of certain tools. If you have given your consent to the use of certain tools, we will transfer the data processed using these tools to third countries (also) on the basis of this consent pursuant to Article 49(1) (a) of the GDPR.</div>

                            <div className='credits-subtitle'>2.2. Obtaining your consent</div>
                            <div className='credits-default'>We use cookies on our website to obtain, manage and document any consent we may need from you for the processing of your personal data by these technologies.</div>

                            <div className='credits-default'>This is necessary pursuant to Article 6(1) (c) of the GDPR for meeting our legal obligation pursuant to Article 7(1) of the GDPR to demonstrate that you have consented to the processing of your personal data.</div>

                            <div className='credits-subtitle'>2.3. Withdrawing your consent or changing your selection</div>

                            <div className='credits-default'>You may withdraw your consent for certain tools at any time. To do so, just click on the following link: consent-settings.</div>

                            <div className='credits-default'>There you will also be able to change the selection of tools to the use of which you wish to give your consent and find additional information on the tools being used. Alternatively, you may address your withdrawal for certain tools directly to the provider.</div>

                            <div className='credits-subtitle'>3. Necessary tools</div>

                            <div className='credits-default'>We use certain tools to enable the basic functioning of our website (“necessary tools”). Without these tools we would not be able to provide our services. For this reason, necessary tools are used without consent. The legal basis for necessary tools is the necessity for pursuing our legitimate interests pursuant to Article 6(1) (f) of the GDPR or for performing a contract or implementing pre-contractual measures pursuant to Article 6(1) (b) of the GDPR. In such cases, accessing and storing information on your end device is absolutely necessary and is on the basis of the laws implementing the ePrivacy Directive in the EU Member States; in Germany, this is section 25(2) of the Protection of Data in Telecommunications and Telemedia Act.</div>

                            <div className='credits-default'>For the eventuality that your personal data are transferred to the USA or another third country, we additionally refer to Part A (Data transfer to third countries) with regard to the information being shared.</div>

                            {/*<div className='credits-subtitle'>3.1. Our own tools</div>

                            <div className='credits-default'>We use our own necessary tools which access information on your end device or store information on your end device, especially to store your language settings.</div>

                            <div className='credits-default'>We use the following cookies for this purpose:<br />
                            
                            Cookie name	Function	Domain	<br />
                            Storage period<br />
                            wp-wpml_current_language	<br />
                            This cookie stores your selected language setting.<br />
                            tv-turm.de	Session<br />
                            wpml_browser_redirect_test	<br />
                            This cookie checks whether cookies may be placed.<br />
                            tv-turm.de	Session<br />
                            _icl_visitor_lang_js	<br />
                            This cookie stores the referred language.<br />
                            tv-turm.de	3 days<br /></div>*/}
                            

                            <div className='credits-subtitle'>3.1. Google Tag Manager</div>

                            <div className='credits-default'>Our website uses Google TagManager, a service provided for persons from the European Economic Area and Switzerland by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland and for everyone else by Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA (together “Google”).</div>

                            <div className='credits-default'>The Google Tag Manager is used exclusively to manage website tools by adding WebsiteTags. A tag is an element that is placed in the source code of our website to execute a tool by using, for instance, scripts. If these are optional tools, they are tracked by Google Tag Manager only with your consent. GoogleTagManager uses JavaScript and on principle does not need to use cookies.</div>

                            <div className='credits-default'>The legal basis is Article 6(1) (f) of the GDPR, based on our legitimate interest in creating and managing multiple tags on our website in an uncomplicated manner.</div>

                            <div className='credits-default'>To guarantee stability and functionality when using Google Tag Manager, Google collects information about what tags are set up on our website. Google Tag Manager does not, however, store as a matter of principle any personal data beyond the mere setting up of the connection; in particular it does not store data on user behaviour or sites visited.</div>

                            <div className='credits-default'>We have signed a contractual processing agreement with Google Ireland Limited. For the eventuality that personal data are transferred to the USA, Google Ireland Limited and Google LLChave agreed standard contractual clauses (Implementing Decision (EU) 2021/914, ModuleThree) pursuant to Article 46(2) (c) of the GDPR.</div>

                            <div className='credits-default'>You can find out more about this in the information provided by Google about their Tag Manager: https://support.google.com/tagmanager/answer/6102821.</div>

                            <div className='credits-subtitle'>4. Analytical tools</div>

                            <div className='credits-default'>To improve our website, we use optional tools for statistical recording and analysis of general user behaviour on the basis of access data (“analytical tools”). We also use analytical services to evaluate the use of our various marketing channels.</div>

                            <div className='credits-default'>The legal basis for the analytical tools is your consent under Article 6(1) (a) of the GDPR. Access to and storage of information on your end device is then on the basis of the laws implementing the ePrivacy Directive in the EU Member States.</div>

                            <div className='credits-default'>In the eventuality that your personal data are transferred to the USA or other third countries, your consent also explicitly covers this data transfer (Article 49(1) (a) of the GDPR). For information about the risks involved, please see Part A (“Data transfer to third countries”).</div>

                            <div className='credits-subtitle'>4.1. Google Analytics</div>

                            <div className='credits-default'>Our website usesGoogle Analytics, a service provided for persons from Europe, the Middle East and Africa (EMEA) by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland and for everyone else by Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (together „Google”).</div>

                            <div className='credits-default'>Google Analytics uses JavaScript and Pixel to read information from your end device and cookies to store information on your end device. This is done to analyse your user behaviour and improve our website. We will use the information gained to analyse your use of our website and to collate reports on website activities for the website operators. The data collected in this connection may be transferred by Google to a server in the USA and stored there.</div>

                            <div className='credits-default'>We have made the following settings for Google Analytics to protect the privacy of your data:</div>

                            <div className='credits-default'>IP anonymisation (abbreviation of IP address before analysis);<br />
                            automatic erasure of old logs / limitation of storage period;<br />
                            deactivated marketing function (including Target Group RemarketingbyGA Audience);<br />
                            deactivated Remarketing;<br />
                            deactivated tracking across multiple devices and sites;<br />
                            deactivated data release to other Google products and services, Benchmarking, Technical Support, Account Manager.<br /></div>

                            <div className='credits-default'>The following data are processed by Google Analytics:<br /><br />

                            IP address;<br />
                            referrerURL (previously visited site);<br />
                            accessed sites (date, time, URL, title, length of stay);<br />
                            downloaded data;<br />
                            clicked links to other websites;<br />
                            if applicable, meeting of certain targets (Conversions);<br />
                            technical information: operating system; browsertype, version and language; device type and brand, model and resolution;<br />
                            approximate location (country and if applicable city, based on anonymised IP address).</div>

                            <div className='credits-default'>We have signed a contractual processing agreement with Google Ireland Limited regarding the use of Google Analytics. For the eventuality that personal data are transferred to the USA, Google Ireland Limited and Google LLC have agreed standard contractual clauses (Implementing Decision (EU) 2021/914, Module Three) pursuant to Article 46(2) (c) of the GDPR.</div>

                            <div className='credits-default'>You can find out more about this in the Google Privacy Policy here: https://support.google.com/analytics/answer/6004245.</div>

                            <div className='credits-default'>You can see details of which cookies are placed by Google Analytics and how long they are stored for on the Cookie banner under Cookie details.</div>
                            
 

                            <div className='credits-subtitle'>5. Marketing Tools</div>

                            <div className='credits-default'>We also use optional tools for marketing purposes (“marketing tools”). Some of the access data collected when you use our website is used to create user profiles, which in particular store details of the advertisements you have looked at or clicked on and, based on this, classify these into advertising categories, interests and preferences. Analysing and evaluating these access data enables us to show you personalised advertising in line with your actual interests and requirements on our website and on the websites of other providers. For this purpose, we analyse your user behaviour so that we can recognise you when you visit other sites and can address you personally on the basis of your use of our site (retargeting).</div>

                            <div className='credits-default'>The legal basis for the marketing tools is your consent under Article 6(1) (a) of the GDPR. Access to and storage of information on your end device is then on the basis of the laws implementing the ePrivacy Directive in the EU Member States; in Germany, this is section 25(1) of the Protection of Data in Telecommunications and Telemedia Act. For details of how you can withdraw your consent see 2.3. (“Withdrawing your consent or changing your selection”).</div>

                            <div className='credits-default'>In the eventuality that your personal data are transferred to the USA or other third countries, your consent also explicitly covers this data transfer (Article 49(1) (a) of the GDPR). For information about the risks involved, please see Part A (“Data transfer to third countries”).</div>

                            <div className='credits-default'>In the next section we explain the tools and the providers used for them in more detail. The data that are collected may in particular include:<br /><br />

                            the IP address of the device;<br />
                            the information from a cookie and inLocal or Session Storage;<br />
                            the device identification of mobile devices (e.g. device ID, advertising ID);<br />
                            referrerURL (previously visited site);<br />
                            accessed sites (date, time, URL, title, length of stay);<br />
                            downloaded data;<br />
                            clicked links to other websites;<br />
                            if applicable, meeting of certain targets (Conversions);<br />
                            technical information: operating system; browser type, version and language; device type and brand, model and resolution;<br />
                            approximate location (country and if applicable city, based on anonymised IP address).</div>


                            <div className='credits-default'>However, the data collected will be stored exclusively in a pseudonymised form so that you cannot be directly identified.</div>

                            <div className='credits-subtitle'>5.1. Meta-Pixel (formerly Facebook Pixel)</div>

                            <div className='credits-default'>For marketing purposes, our website uses the Meta business tool Meta Pixel by the social media network Meta, a service provided for persons outside the USA and Canada by Meta Platforms Ireland Ltd., 4 Grand Canal Square, Dublin 2, Ireland.</div>

                            <div className='credits-default'>We use Meta Pixel, a snippet of JavaScript code that allows us to track visitor activity on our website. This tracking is called ‘Conversion Tracking’.</div>

                            <div className='credits-default'>For this purpose, Meta Pixel collects and processes the following information (called ‘Event Data’):<br /><br />

                            information on visitor actions and activity on our website (e.g. searching for and displaying a product or purchasing a product);<br />
                            specific Pixel information such as the Pixel ID and the Meta cookie;<br />
                            information on buttons clicked on by website visitors;<br />
                            information contained in HTTP headers such as IP address, information on the web browser, the location of the page and the referrer;<br />
                            information on the status of deactivation / restriction of advertisement tracking.</div>


                            <div className='credits-default'>Some of these Event Data are stored as information on your end device. In addition, cookies are used via Meta Pixel, to store information on the end device you are using.</div>

                            <div className='credits-default'>The Event Data collected using Meta Pixel are used for addressing a target group (targeting) for our advertisements and to improve our delivery of advertisements, for personalising functions and content and for improving Meta products and making them secure.</div>

                            <div className='credits-default'>For this purpose, Event Data are collected on our website using Meta Pixel and transmitted to Meta. These Event Data are collected and transmitted by us and Meta as joint controllers within the meaning of Article 26 of the GDPR. We have signed an agreement with Meta on processing as joint controllers, which sets out the distribution of duties under data protection law between Meta and ourselves. Among other things, we and Meta have agreed in this Agreement that we are responsible for providing you under Articles 13 and 14 of the GDPR with all information on the joint processing of your personal data and that Meta is responsible for facilitating your rights as a data subject under Articles 15 to 20 of the GDPR with regard to your personal data stored by Meta following joint processing. Facebook Ireland is the sole controller with responsibility for processing transmitted data subsequently to transmission. You may access the agreement we have signed with Meta here.</div>

                            <div className='credits-default'>You can find out more about how Meta processes personal data, including the basis in law used by Meta, and the possibilities for exercising your rights against Meta in the Meta Privacy Policy.</div>

                            <div className='credits-default'>You can see details of which cookies are placed by Facebook Pixel and how long they are stored for on the Cookie banner under Cookie details.</div>

                            <div className='credits-subtitle'>5.2. TikTok Pixel</div>

                            <div className='credits-default'>For marketing purposes our website uses the TikTok advertiser tool ‘TikTok Pixel’ by the social media network TikTok, a service provided for users in the European Economic Area and Switzerland by TikTok Technology Limited, 10 Earlsfort Terrace, Dublin DO2 T380, Ireland.</div>

                            <div className='credits-default'>We use TikTok Pixel, which is a snippet of JavaScript code, to track visitor activity on our website. Similarly to Meta Pixel, TikTok Pixel collects and processes information for this purpose about website visitors or the end devices they are using (Event Data). The Event Data collected using TikTok Pixel are used for targeting our advertisements and improving our delivery of advertisements and for personalised advertising.</div>

                            <div className='credits-default'>For this purpose, the Event Data collected on our website using TikTok Pixel are transmitted to TikTok. Some of these Event Data are stored as information on your end device. In addition, cookies are used via TikTok Pixel to store information on the end device you are using.</div>

                            <div className='credits-default'>These Event Data are collected and transmitted by us and TikTok as joint controllers within the meaning of Article 26 of the GDPR. We have signed an agreement with TikTok on processing as joint controllers, which sets out the distribution of duties under data protection law between TikTok and ourselves. Among other things, we and TikTok have agreed in this Agreement that we are responsible for providing you under Articles 13 and 14 of the GDPR with all information on the joint processing of your personal data and that TikTok is responsible for facilitating your rights as a data subject under Articles 15 to 20 of the GDPR with regard to your personal data stored by TikTok following joint processing. You may access the agreement we have signed with TikTok here. TikTok is the sole controller with responsibility for processing transmitted data subsequently to transmission.</div>

                            <div className='credits-default'>You can find out more about how TikTok processes personal data, including the basis in law used by TikTok, and the possibilities for exercising your rights against TikTok in the TikTok Privacy Policy.</div>

                            <div className='credits-subtitle'>5.3. Google Ads Conversion Tracking and Ads Remarketing (formerly AdWords)</div>

                            <div className='credits-default'>Our website uses Google Ads, a service provided for persons from the European Economic Area and Switzerland by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, and for everyone else by Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA (together “Google”).</div>

                            <div className='credits-default'>With Google Ads, customer actions defined by us (such as clicking on an advertisement, accessing pages or downloads) are collected and analysed using GoogleAdsConversionTracking. We use GoogleAdsRemarketing to enable us to show you individualised advertising messages for our products on partner websites of Google.</div>

                            <div className='credits-default'>For this, the services use cookies, JavaScript, Pixel and other technologies. Google also processes data for improving and developing its own products and services, for aggregated statistical analysis of conversions and for improving the quality and precision of conversions. The data collected in this connection may be transferred by Google to a server in the USA for evaluation and stored there.</div>

                            <div className='credits-default'>If you use your Google account, Google may, depending on the settings in your Google account, link your web and app browser history with your Google account and use information from your Google account to personalise ads. If you do not want this attribution to your Google account, you will need to log out of Google before accessing our website.</div>

                            <div className='credits-default'>If you have not given your consent to the use of Google Ads, Google will only show you general advertisements, which have not been selected on the basis of the information collected about you on this website. As well as being able to withdraw your consent, you may also deactivate personalised ads in the Google advertising settings.</div>

                            <div className='credits-default'>You can find out more about this in Google’s information about the use of data and Privacy Policy.</div>

                            <div className='credits-subtitle'>6. External media</div>

                            <div className='credits-default'>We also use services to improve your experience of using our website and to enable us to offer your more functions. While these are not absolutely essential for the basic functions of our website, they do contribute to user-friendliness and allow the provision of more functions. These include the embedding of external content such as videos and maps.</div>

                            <div className='credits-default'>The legal basis for this is your consent under Article 6(1) (a) of the GDPR, which you give yourself using the consent banner or in the relevant tool by allowing its use separately via a banner placed above it (overlay). In these cases, access to and storing information on your end device is subject to consent and is on the basis of the laws implementing the ePrivacy Directive in the EU Member States.</div>

                            <div className='credits-default'>For details of how you can withdraw your consent see 2.3. (“Withdrawing your consent or changing your selection”). In the eventuality that your personal data are transferred to the USA or other third countries, your consent also explicitly covers this data transfer (Article 49(1) (a) of the GDPR). For information about the risks involved, please see Part A (“Data transfer to third countries”).</div>


                        </div>
                        <div className='privacypolicy-update'>Last amended: February 2023</div>
                        <div style={{ width: '100%', height: '64px' }}></div>
                    </div>
                :   null
                    /*<div>
                        <div className='credits-textSubtitle'>Politique de confidentialité{"\n"}</div>
                        <div className='credits-text'>
						    <div style={{ margin: '0 0 16px 0' }}>
                            What happens with your personal data when you visit our website, when you make an order or a reservation or subscribe to our newsletter or if you visit our pages on Facebook and Instagram? Find out here in our Privacy Policy.
                            </div>
                        </div>
                        <div className='privacypolicy-update'>Dernière mise à jour : 09 février 2023</div>
                        <div style={{ width: '100%', height: '64px' }}></div>
                    </div>*/
                }
		</div>
	);
};
