import * as THREE from "three";
import {
    Sphero,
    Sphero_cons_type,
    Spectator,
    Box,
    Box_cons_type,
    Player,
    Actor,
    FikscopeEngine,
    Pawn,
    FikscopeObject3D,
} from "../index";

import { log } from '../logMode';

export interface IFikscopeLevel {
    name: string;
    actors: Map<string, Actor>;
    initPos: THREE.Vector3;
    initQuat: THREE.Quaternion;
    onStart: (args: any) => void;
    onStop: (args: any) => void;
    onLoose: (arg: any) => void;
    onWin: (arg: any) => void;

    init(fe: FikscopeEngine): void;
    update(dt: number): void;
    /* replicate(): void; */

    // Some app doesn't have victory condition
    winGame?(arg: any): boolean;
    looseGame?(arg: any): boolean;

    // Defin pawn for a player
    pawnPlayer: (arg: any) => Pawn<FikscopeObject3D>;
    pawnPeer: (arg: any) => Pawn<FikscopeObject3D>;
    newPlayerPeer: (fe: FikscopeEngine, nick: string, id: string) => Player;
    newPlayer: (fe: FikscopeEngine, nick: string, id: string) => Player;
    removePlayer: (fe: FikscopeEngine, id: string) => any;
}

export class FikscopeLevel implements IFikscopeLevel {
    public name: string;
    public actors: Map<string, Actor>;
    public initPos: THREE.Vector3;
    public initQuat: THREE.Quaternion;
    public onStart: (args: any) => void;
    public onStop: (args: any) => void;

    ZERO_QUATERNION: THREE.Quaternion = new THREE.Quaternion(0, 0, 0, 1);

    constructor(args?: any) {
        this.actors = new Map<string, Actor>();
        this.initPos = new THREE.Vector3(0, 0, 3);
        this.initQuat = this.ZERO_QUATERNION;
        this.onStart = () => { };
        this.onStop = () => { };
        this.name = "DefaultMap";

        //if (fe) this.init(fe);
    }

    removePlayer(fe: FikscopeEngine, id: string): void {
        let leaver = fe.players.get(id);
        if (leaver) {
            if (leaver.pawn?.feObject?.mesh)
                fe.scene.remove(leaver.pawn.feObject.mesh);
            if (leaver.pawn) {
                /* leaver.pawn.remove() */
                leaver.pawn.feObject = undefined;
                leaver.pawn = undefined;
            }
            fe.players.delete(leaver.id);
        } else {
            console.error("Not found disconnected user " + id);
        }
    }

    newPlayer(fe: FikscopeEngine, nick: string, id: string): Player {
        let pawn = this.pawnPlayer(fe);
        let p = new Player(nick, id, pawn);
        fe.players.set(id, p);
        return p;
    }

    newPlayerPeer(fe: FikscopeEngine, nick: string, id: string): Player {
        let pawn = this.pawnPeer(fe);
        let p = new Player(nick, id, pawn);
        fe.players.set(id, p);
        return p;
    }

    pawnPlayer(fe: FikscopeEngine): Pawn<FikscopeObject3D> {
        //Maybe using color-hash
        var redMaterial = new THREE.MeshStandardMaterial({
            color: 0xff2222,
            depthWrite: true,
            depthTest: true,
            metalness: 0.8,
            opacity: 1,
        });
        var ZERO_QUATERNION = new THREE.Quaternion(0, 0, 0, 1);
        return new Sphero({
            fe: fe,
            material: redMaterial,
            pos: new THREE.Vector3().copy(this.initPos),
            quat: ZERO_QUATERNION,
            r: 1,
            mass: 100,
            friction: 0,
        } as Sphero_cons_type);
    }

    pawnPeer(fe: FikscopeEngine): Pawn<FikscopeObject3D> {
        var checkerMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            depthWrite: true,
            depthTest: true,
            metalness: 0.8,
        });
        var ZERO_QUATERNION = new THREE.Quaternion(0, 0, 0, 1);
        return new Sphero({
            fe: fe,
            material: checkerMaterial,
            pos: new THREE.Vector3().copy(this.initPos),
            quat: ZERO_QUATERNION,
            r: 1,
            mass: 100,
            friction: 0,
            physic: fe.peer_physic
            /* physic: false, //with/without physic */
        } as Sphero_cons_type);
    }

    winGame(arg: any): boolean {
        return false;
    }

    looseGame({ player }: { player: Player }): boolean {
        if (player && player.pawn) return player.pawn.position.z < -20;
        else return false;
    }

    onWin({ player }: { player: Player }): void {
        console.warn("Puffyland | FE - You Won !");
        player.move(this.initPos);
    }

    onLoose({ player }: { player: Player }): void {
        console.warn("Puffyland | FE - You Loose !");
        player.move(this.initPos);
    }

	update(dt: number){
		
	}

    init(fe: FikscopeEngine) {
        //Scene
        fe.scene.background = new THREE.Color(0xe1e1e1);
        //scene.fog = new THREE.Fog( 0xe1e1e1, 500, 3000);

        //Lighting
        var ambient = new THREE.AmbientLight(0xffffff, 1);
        fe.scene.add(ambient);

        var directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        fe.scene.add(directionalLight);

        // ground
        let col = new THREE.Color(0x999999);
        var mesh = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(50, 50),
            new THREE.MeshStandardMaterial({
                color: col,
                depthWrite: true,
                depthTest: true,
                metalness: 0,
            })
        );

        mesh.rotation.x = 0;
        mesh.receiveShadow = true;
        fe.scene.add(mesh);

        var grid = new THREE.GridHelper(50, 10, 0x000000, 0x000000);
        grid.rotation.x = Math.PI / 2;
        //Can be of types Material[] too… so opacity does not always exist
        (grid.material as THREE.Material).opacity = 0.2;
        (grid.material as THREE.Material).transparent = true;
        fe.scene.add(grid);

        //Materials
        var blueMaterial = new THREE.MeshStandardMaterial({
            color: 0x2222ff,
            depthWrite: true,
            depthTest: false,
            metalness: 0.8,
            transparent: true,
            opacity: 0,
        });

        var texture = new THREE.TextureLoader().load("ressources/checker.png");
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(4, 4);

        var ZERO_QUATERNION = new THREE.Quaternion(0, 0, 0, 1);

        //Ground (Transparent)
        this.actors.set(
            "ground",
            new Box({
                fe: fe,
                material: blueMaterial,
                pos: new THREE.Vector3(0, 0, -0.5),
                quat: ZERO_QUATERNION,
                w: 50,
                l: 50,
                h: 1,
                mass: 0,
                friction: 2,
            } as Box_cons_type)
        );

        if (!fe.gameState.bHasBegun && fe.INatscope && fe.INatscope.natscope.me) {
            let currentPlayer = this.newPlayer(
                fe,
                fe.INatscope.natscope.me.nick,
                fe.INatscope.natscope.me.id
            );

            if (currentPlayer.pawn) {
                this.actors.set('player', currentPlayer.pawn);
                if (currentPlayer.pawn.controller && fe.inputManager) {
                    fe.inputManager.setInputReceiver(currentPlayer.pawn.controller);
                }
            }


            if (fe.graphic_enabled) {
                //should not check here bcause cameraOperator should have been
                //initiated in init() here ! TODO
                fe.cameraOperator.setCameraComponent((currentPlayer.pawn as Spectator).cameraPawn);
            }
        } else if (fe.INatscope && !fe.INatscope.natscope.me) {
            console.warn('natscope.me undefined');
        }
        log("Puffyland | FE - Sandbox loaded");
    }
}


