// React
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
// Fe
import {
	FikscopeEngine,
	fikscope_input_type,
	GameStateBase,
} from "../fikscopeengine_modules/fikscopeengine-core";
// Level
import { LMSphere } from "./level/LMSphere";
// Assets
import headphonesIcon from "../style/assets/audioguid/headphones.png";
import { url } from "inspector";
import arrow from "../style/assets/icons/next.png";
import compassImg from "../style/assets/compass_01.png";

const TIMEOUT = 5000;

export function Sphere(props: any) {
	const { language, towerColor, sensors } = useSelector(
		(state: any) => state.user
	);
	// let lang = 'fr';

	const [data, setData] = useState({
		pois: new Map(),
	});

	const [init, setInit] = useState(false);

	let initFikscope = (editor: Boolean, data: any) => {
		const container = document.getElementById("viewport");

		const gs = new GameStateBase({
			map: new LMSphere({
				assets: props.assets,
				spheres: props.spheres,
				datas: props.datas,
				setData: setData,
				setPoi: props.setPoi,
				setCard: props.setCard,
				AR: props.AR,
				towerColor: towerColor,
				editor: editor,
				tempPois: data,
				ags: props.ags,
				lang: language,
				cameraScanQuit: props.cameraScanQuit,
				resetCompass: props.resetCompass,
				localize: props.localize,
				showCompass: props.showCompass,
				setVideo: props.setVideo,
			}),
		});

		const args: fikscope_input_type = {
			graphic_enabled: true,
			input_enabled: true,
			physic_enabled: true,
			container: container,
			multi: false,
			gameState: gs,
		};

		let fe = new FikscopeEngine(args);

		props.setFe(fe);

		setInit(true);
	};

	useEffect(() => {
		let editor = false;
		let data = null;

		if (editor) {
			axios
				.get(
					process.env.REACT_APP_CLIENT_API_URL +
						"/mpoi/all?skip=0&limit=500&searchMpoiByMbuilding=" +
						props.spheres[0].mbuilding,
					{}
				)
				.then(function (response) {
					if (response.data.mpois) {
						if (response.data.mpois.length > 0) {
							data = response.data.mpois;

							initFikscope(editor, data);
						}
					}
				});
		} else {
			initFikscope(editor, data);
		}
	}, [props.datas, props.assets, props.setCard]);

	useEffect(() => {}, [props.dispAudioGuids]);

	useEffect(() => {
		if (props.fe) {
			let promise = new Promise((resolve, reject) => {
				let time = Date.now();
				let interval = setInterval(() => {
					if (init) {
						clearInterval(interval);
						resolve(true);
					} else if (Date.now() - time > TIMEOUT) {
						clearInterval(interval);
						reject();
					}
				}, 200);
			});
			promise.then(() => {
				console.log("sphere", props.sphere);
				(props.fe.map as LMSphere).changeSphere(props.sphere);
			});
		}
	}, [props.sphere]);

	const [debugging, setDebugging] = useState<boolean>(true);

	return (
		<React.Fragment>
			<div
				id='sphere'
				style={{
					zIndex: 20000,
					width: "100%",
					height: "100%",
					opacity: "0",
					pointerEvents: "none",
				}}
			>
				<div
					id='transition'
					style={{
						position: "fixed",
						height: "100%",
						width: "100%",
						zIndex: 500000,

						opacity: "0",
						backgroundColor: "black",
						pointerEvents: "none",
					}}
				></div>
				<div
					id='camera'
					style={{
						display: "none",
						position: "absolute",
						width: "100%",
						height: "90%",
						top: 0,
						left: 0,
						zIndex: "90000",
						pointerEvents: "none",
					}}
				>
					<div
						id='cameraVideo'
						style={{
							position: "absolute",
							top: "8%",
							left: "20%",
							width: "60%",
							height: "50%",
							pointerEvents: "none",
						}}
					></div>
					{/*<video
					id="cameraVideo"
					width="80%"
					height="80%"
					autoPlay
				></video>*/}
				</div>

				<div
					style={{
						pointerEvents: "none",
						width: "100%",
						height: "100%",
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: 800,
					}}
				>
					<div
						id='compassTopContainer'
						className='compass'
						style={{
							/*opacity: dispCompass ? '1' : '0',*/
							opacity: "1",
							position: "absolute",
							height: "600px",
							width: "600px",
							/*zIndex: zIndexCompass,*/
							zIndex: 800,
							aspectRatio: "1/1",
							top: "0px",
							backgroundColor: "rgba(0, 0, 0, 0)",
							transformOrigin: "bottom center",
							transform:
								"perspective(1000px) translateY(-400px) translateZ(200px) rotateX(-65deg)",
							transformStyle: "preserve-3d",
							pointerEvents: "none",
						}}
					>
						<div
							id='compassTop'
							style={{
								position: "absolute",
								top: "0px",
								left: "0px",
								width: "100%",
								height: "100%",
								backgroundImage: "url(" + compassImg + ")",
								backgroundRepeat: "no-repeat",
								backgroundSize: "contain",
								transformOrigin: "center center",
								transformStyle: "preserve-3d",
							}}
						></div>
					</div>
				</div>

				{init ? (
					<React.Fragment>
						{/* <div
						id='audioguidList'
						className='audioguidList'
						style={{
							position: 'absolute',
							zIndex: 85000,
							width: '700px',
							height: '700px',
							top: '-350px',
							left: 'calc(50% - 350px)',
							pointerEvents: 'none',
							opacity: props.dispAudioGuids ? '1' : '0',
							transition: 'all 1s',
						}}
					>
						{props.ags &&
							props.ags.map((ag: any) => {
								return (
									<div
										key={ag.short}
										id={ag.short}
										className={'disableNaturalClick clickable '}
										onClick={() => {
											props.setAgSelected(ag);
										}}
										style={{
											position: 'absolute',
											zIndex: 95000,
											top: '0%',
											left: '0%',
											width: '56px',
											height: '56px',
											background: '#111827',
											backgroundImage: 'url(' + headphonesIcon + ')',
											backgroundPosition: 'center',
											backgroundRepeat: 'no-repeat',
											backgroundSize: '50%',
											border: '1px solid #1F2937',
											boxShadow: '0px 20px 32px rgba(17, 24, 39, 0.25), 0px 12px 12px rgba(17, 24, 39, 0.17)',
											borderRadius: '32px',
											pointerEvents: 'all',
											opacity: ag.msphere === props.datas.spheres.find((s: any) => s.short === props.sphere)._id ? '1' : '0',
										}}
									>
									</div>
								);
							})}
					</div> */}
						<div
							id='poiList'
							style={{
								opacity: "0",
								transition: "all 2s",
								position: "absolute",
								width: "100%",
								height: "100%",
								top: "0px",
								left: "0px",
								zIndex: 1000,
								pointerEvents: "none",
								transformStyle: "preserve-3d",
							}}
						>
							{data.pois?.get(props.sphere)?.map((p: any) => {
								let poi = props.datas.spheres
									.find((s: any) => s.short === props.sphere)
									?.pois.find((x: any) => x.short === p.short);
								let audio = props.datas.maudioguids.find(
									(ag: any) => ag.mpoi === poi._id
								);

								return (
									<div
										className='noSelect poiClick disablePoiNaturalClick '
										key={p.short}
										id={p.short}
										style={{
											position: "absolute",
											top: "0%",
											left: "0%",
											width: "fit-content",
											height: "fit-content",
											backgroundColor: "red",
										}}
									>
										<div
											className='noSelect'
											style={{
												position: "absolute",
												top: p.line ? "0px" : "-70px",
												left: "-150px",
												height: "50px",
												width: "300px",
												textAlign: "center",
												transition: "all 1s",
											}}
										>
											<span
												className='noSelect'
												style={{
													fontFamily: "TungstenNarrow-Semibold",
													fontSize: "20px",
													letterSpacing: "0.02rem",
													//backgroundColor: towerColor,
													backgroundColor:
														props?.poi === p.short ? "#FFFFFF" : towerColor,
													padding: "2px 6px 2px 6px",
													borderRadius: "5px",
													textTransform: "uppercase",
												}}
											>
												{
													poi.name?.translations.find(
														(t: any) => t.langage === language
													)?.value
												}
											</span>
											{p.mcard && (
												<div
													style={{
														position: "absolute",
														opacity: p.line ? "1" : "0",
														top: "24px",
														left: "50%",
														transform: "translateX(-50%)",
														height: "16px",
														width: "16px",
														textAlign: "center",

														backgroundColor: "black",
														borderRadius: "8px",

														display: "flex",
														flexDirection: "column",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<div
														style={{
															height: "100%",
															width: "100%",

															backgroundImage: "url(" + arrow + ")",
															backgroundPosition: "center",
															backgroundSize: "cover",
															backgroundRepeat: "no-repeat",
														}}
													></div>
												</div>
											)}
											{/*<div
											className='texteDescription noSelect'
											style={{
												position: 'absolute',
												opacity: p.line && poi === p.short ? '1' : '0',
												top: '50px',
												left: '0px',
												height: '50px',
												width: '300px',
												textAlign: 'center',
												transition: p.line && poi === p.short ? 'all 2s' : 'all 0.5s',
											}}
										>
											{p.description}
										</div>*/}
										</div>
										{audio && (
											<div
												className='clickable '
												style={{
													position: "absolute",
													top: p.line ? "-70px" : "-140px",
													left: "-28px",
													height: "56px",
													width: "56px",
													textAlign: "center",
													transition: "all 1s",
													pointerEvents: "all",

													background: "#111827",
													backgroundImage: "url(" + headphonesIcon + ")",
													backgroundPosition: "center",
													backgroundRepeat: "no-repeat",
													backgroundSize: "50%",
													border: "1px solid #1F2937",
													boxShadow:
														"0px 20px 32px rgba(17, 24, 39, 0.25), 0px 12px 12px rgba(17, 24, 39, 0.17)",
													borderRadius: "32px",
												}}
												onClick={() => {
													props.setAgSelected(audio);
												}}
											></div>
										)}
									</div>
								);
							})}
						</div>
					</React.Fragment>
				) : null}

				<div
					id='viewport'
					style={{
						margin: "auto",
						pointerEvents: "all",
					}}
				/>
			</div>
			{/* {debugging && (
				<div
					id='debugElem'
					onClick={() => {
						setDebugging(false);
					}}
					style={{
						pointerEvents: "all",
						bottom: "0px",
						left: "0px",
						zIndex: 200000,
						position: "absolute",
						width: "200px",
						overflow: "auto",
						backgroundColor: "#FF7F3A",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
					}}
				>
					<div style={{ margin: "5px", fontWeight: "600" }}>DEBUG</div>
					<div
						id='debugElemStartAngle'
						style={{ margin: "0 5px 5px 5px", fontWeight: "400" }}
					></div>
					<div
						id='debugElemActualAngle'
						style={{ margin: "0 5px 5px 5px", fontWeight: "400" }}
					></div>
					<div
						id='debugElemActualOffsetAngle'
						style={{ margin: "0 5px 5px 5px", fontWeight: "400" }}
					></div>
					<div
						id='debugElemScannedAngle'
						style={{ margin: "0 5px 5px 5px", fontWeight: "400" }}
					></div>
					<div
						id='debugElemAngleOrient'
						style={{ margin: "0 5px 5px 5px", fontWeight: "400" }}
					></div>
				</div>
			)} */}
			{/* {!debugging && (
				<div
					id='debugElem'
					onClick={() => {
						setDebugging(true);
					}}
					style={{
						pointerEvents: "all",
						bottom: "8px",
						left: "8px",
						zIndex: 200000,
						position: "absolute",
						width: "32px",
						height: "32px",
						backgroundColor: "#FF7F3A",
					}}
				></div>
			)}*/}
		</React.Fragment>
	);
}
