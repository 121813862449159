// React
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
// Redux
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "./actions/user";
// Components
import { Magnicity } from "./components/magnicity/Magnicity";

type OrientationLockType =
	| "any"
	| "landscape"
	| "landscape-primary"
	| "landscape-secondary"
	| "natural"
	| "portrait"
	| "portrait-primary"
	| "portrait-secondary";
interface ScreenOrientation extends EventTarget {
	lock(orientation: OrientationLockType): Promise<void>;
}

function App() {
	const dispatch: Dispatch<any> = useDispatch();
	const { language } = useSelector((state: any) => state.user);
	useEffect(() => {
		console.log("Magnicity | Start WebSite");

		let languageStart = "en";
		if (window.navigator.language === "fr-FR") {
			languageStart = "fr";
		}

		// if (language)

		dispatch(setLanguage(languageStart));
	}, [dispatch]);

	return (
		<div className='App'>
			<Routes>
				<Route path='/' element={<Magnicity />} />
				<Route path='/:place' element={<Magnicity />} />
			</Routes>
		</div>
	);
}

export default App;
