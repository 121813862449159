import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { trads } from '../../datas/trads';
import './popup.css';

export const Popup = (props: { setPopup: any, icon?: any; title: any; desc?: any }) => {
    const { language, place, towerColor } = useSelector((state: any) => state.user);

    useEffect(() => {
        let elem = document.getElementById('popup');

        if (elem) {
            if (!props.title || props.title === '') {
                elem.style.transition = 'top 2s';
                elem.style.top = '-100%';
            } else {
                elem.style.transition = 'top 0.5s';
                elem.style.top = '0%';
            }
        }

        let to = setTimeout(() => {
            props.setPopup(undefined);
        }, 5000)

        return () => clearTimeout(to);
    }, [props.title])

    let tradsCurrent = trads.find((x: any) => x.id === language);

	return (
		<div id='popup' className='popup'>
			<div className='popup-zone'>
				<div className='popup-zoneInside'>
					{props.icon && <div className='popup-zoneIcon' style={{backgroundImage: 'url('+props.icon+')'}}></div>}
					<div className='popup-zoneTitle' style={{
                        marginLeft: props.icon ? '32px' : '0px'
                    }}>{ props.title ? (tradsCurrent as any)[props.title] : '' }</div>
					<div className='popup-zoneDesc' style={{
                        marginLeft: props.icon ? '32px' : '0px'
                    }}>{ props.desc ? (tradsCurrent as any)[props.desc] : '' }</div>
				</div>
			</div>
		</div>
	);
};
