// React
import { configureStore } from '@reduxjs/toolkit';
// App Files
import {
	reducer as reducer_user,
	IUserState,
} from './reducer_user';
import {
	reducer as reducer_quiz,
	IQuizState,
} from './reducer_quiz';
import {
	reducer as reducer_sounds,
	ISoundsState,
} from './reducer_sounds';

export interface IRootState {
	user: IUserState;
	quiz: IQuizState;
	sounds: ISoundsState;
}

const store = configureStore({
	reducer: {
		user: reducer_user,
		quiz: reducer_quiz,
		sounds: reducer_sounds
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
