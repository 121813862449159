// React
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// Modules
import 'react-multi-carousel/lib/styles.css';

import { Swipe } from 'react-swipe-component';
// CSS
import './city.css';
// Utils
import { trads } from '../../datas/trads.js';
import getApiUrl from '../../utils/getApiUrl';

export const OtherPois = (props: { cityDatas: any; assets: any; setCard: any; setPoi: any; sphere: any }) => {
	const { language, place, towerColor } = useSelector((state: any) => state.user);

	const [hashtagsAll, setHashtagsAll] = useState<any[]>([]);
	const _hashtagsSelected = useRef<any[]>([]);
	const _hashtagsUnselected = useRef<any[]>([]);

	const [update, setUpdate] = useState(false);

	useEffect(() => {
		if (update === true) {
			setUpdate(false);
		}
	}, [update]);

	useEffect(() => {
		if (hashtagsAll.length > 0) {
			let hashtagsUnselected = [];

			for (let ht of hashtagsAll) {
				hashtagsUnselected.push({
					hashtag: ht,
					display: true,
				});
			}

			_hashtagsUnselected.current = hashtagsUnselected;
			setUpdate(true);
		}
	}, [hashtagsAll]);

	const _initPosLeft = useRef(0);
	const _isTouching = useRef(false);

	useEffect(() => {
		let hashtagsAllTmp: any[] = [];

		if (props.cityDatas) {
			for (let card of props.cityDatas.cards) {
				if (card.hashtag) {
					let hashtags = card.hashtag.translations
						?.find((t: any) => t.langage === language)
						?.value.replaceAll('#', '')
						.split(',');
					for (let hashtag of hashtags) {
						let hashtags2 = hashtag.split(' ');

						for (let hashtag2 of hashtags2) {
							let hashtagClean = hashtag2.trim();

							if (!hashtagsAllTmp.includes(hashtagClean) && hashtagClean != '') {
								hashtagsAllTmp.push(hashtagClean);
							}
						}
					}
				}
			}

			setHashtagsAll(hashtagsAllTmp);
		}
	}, [props.cityDatas]);

	const onSwipeEnd = () => {
		console.log('Swipe Ended');
		_isTouching.current = false;
	};

	const onSwipeListener = (p: any) => {
		let elems = document.getElementsByClassName('city-otherpois-tagsContent');

		for (let elem of elems) {
			if (!_isTouching.current) {
				let pos = elem.getBoundingClientRect();

				_initPosLeft.current = pos.left;
				_isTouching.current = true;
			}
			if (p.x !== 0) {
				let boundingRect = elem.getBoundingClientRect();

				if (elem) {
					let decal = Number(_initPosLeft.current) + Number(p.x);

					if (decal < -boundingRect.width + window.screen.width - 16) {
						decal = -boundingRect.width + window.screen.width - 16;
					} else if (decal > 0) {
						decal = 0;
					}
					(elem as HTMLElement).style.transform = 'translateX(' + decal + 'px)';

					let elemGradientLeft = document.getElementById('city-otherpois-tags-item-gradientLeft');
					if (elemGradientLeft) {
						if (-decal > 0) {
							elemGradientLeft.style.opacity = '1';
						} else {
							elemGradientLeft.style.opacity = '0';
						}
					}
					let elemGradientRight = document.getElementById('city-otherpois-tags-item-gradientRight');
					if (elemGradientRight) {
						if (decal >= -boundingRect.width + window.screen.width) {
							elemGradientRight.style.opacity = '1';
						} else {
							elemGradientRight.style.opacity = '0';
						}
					}
				}
			}
		}
	};

	return (
		<div className='city-otherpois'>
			<div className='city-otherpois-title'>{trads.find((t: any) => t.id === language)?.allExplorer}</div>
			{hashtagsAll.length > 0 && (
				<Swipe nodeName='div' className='city-otherpois-tags' onSwipeEnd={onSwipeEnd} onSwipe={onSwipeListener} detectTouch={true}>
					<div
						id='city-otherpois-tags-item-gradientLeft'
						className={'city-otherpois-tag '}
						style={{
							pointerEvents: 'none',
							position: 'absolute',
							borderRadius: 0,
							margin: 0,
							zIndex: 95000,
							left: '0px',
							background: 'linear-gradient(90deg, rgba(2,0,36,0.8) 0%, rgba(8,13,23,0) 20%, rgba(8,13,23,0) 100%)',
							top: '0px',
							width: '240px',
							height: '100%',
							opacity: '0',
							transition: 'opacity 0.5s',
						}}
					></div>
					<div
						id='city-otherpois-tags-item-gradientRight'
						className={'city-otherpois-tag '}
						style={{
							pointerEvents: 'none',
							position: 'absolute',
							borderRadius: 0,
							margin: 0,
							zIndex: 95000,
							left: 'calc(100vw - 240px)',
							background: 'linear-gradient(270deg, rgba(2,0,36,0.8) 0%, rgba(8,13,23,0) 20%, rgba(8,13,23,0) 100%)',
							top: '0px',
							width: '240px',
							height: '100%',
							transition: 'opacity 0.5s',
						}}
					></div>
					<div id='city-otherpois-tagsContent' className='city-otherpois-tagsContent'>
						{_hashtagsSelected.current.map((hashtag, idx) => {
							return (
								<div
									className='city-otherpois-tag'
									key={'hashtag-' + hashtag}
									onClick={() => {
										_hashtagsUnselected.current.find((ht) => ht.hashtag === hashtag).display = true;
										_hashtagsSelected.current.splice(_hashtagsSelected.current.indexOf(hashtag), 1);
										setUpdate(true);
									}}
								>
									<div className='city-otherpois-tagContent' style={{ marginLeft: idx === 0 ? '24px' : '12px' }}>
										<div className='city-otherpois-tagContentBrace' style={{ color: towerColor }}>
											#
										</div>
										<div className='city-otherpois-tagContentName'>{hashtag}</div>
										<div className='city-otherpois-tagContentAmount' style={{ color: towerColor }}>
											{
												props.cityDatas.cards
													.filter((c: any) => {
														let translations = c.hashtag?.translations.find((t: any) => t.langage === language)?.value;

														if (!translations) {
															return false;
														}

														let need = true;
														for (let tag of _hashtagsSelected.current) {
															if (!translations.includes(tag)) {
																need = false;
															}
														}

														return need;
													})
													.filter((c: any) =>
														c.hashtag?.translations.find((t: any) => t.langage === language)?.value.includes(hashtag),
													).length
											}
										</div>
									</div>
								</div>
							);
						})}
						{_hashtagsUnselected.current
							.filter((ht) => ht.display === true)
							.map((hashtag, idx) => {
								return (
									<div
										className='city-otherpois-tag'
										key={'hashtag-' + hashtag.hashtag}
										onClick={() => {
											_hashtagsSelected.current.push(hashtag.hashtag);
											_hashtagsUnselected.current.find((ht) => {
												return ht.hashtag === hashtag.hashtag;
											}).display = false;
											setUpdate(true);
										}}
									>
										<div
											className='city-otherpois-tagContentUnselected'
											style={{
												marginLeft: _hashtagsSelected.current.length === 0 && idx === 0 ? '24px' : '12px',
												backgroundColor: towerColor,
											}}
										>
											<div className='city-otherpois-tagContentBrace'>#</div>
											<div className='city-otherpois-tagContentNameUnselected'>{hashtag.hashtag}</div>
											<div className='city-otherpois-tagContentAmount'>
												{
													props.cityDatas.cards
														.filter((c: any) => {
															let translations = c.hashtag?.translations.find((t: any) => t.langage === language)?.value;

															if (!translations) {
																return false;
															}

															let need = true;
															for (let tag of _hashtagsSelected.current) {
																if (!translations.includes(tag)) {
																	need = false;
																}
															}

															return need;
														})
														.filter((c: any) =>
															c.hashtag?.translations.find((t: any) => t.langage === language)?.value.includes(hashtag.hashtag),
														).length
												}
											</div>
										</div>
									</div>
								);
							})}
					</div>
				</Swipe>
				// <SwipeableViews
				// 	index={index}
				// 	onSwitching={(index, type) => {
				// 		if (index > 0) {
				// 			setIndex(index);
				// 		} else {
				// 			setIndex(0);
				// 		}
				// 	}}
				// 	resistance={true}
				// 	style={{ padding: '0px', display: 'flex' }}
				// 	slideStyle={{ width: 'auto', display: 'flex', alignItems: 'center' }}
				// >
				// 	{_hashtagsSelected.current.map((hashtag, idx) => {
				// 		return (
				// 			<div
				// 				className='city-otherpois-tag'
				// 				key={'hashtag-' + hashtag}
				// 				onClick={() => {
				// 					console.log('click', hashtag);
				// 					_hashtagsUnselected.current.find((ht) => ht.hashtag === hashtag).display = true;
				// 					_hashtagsSelected.current.splice(_hashtagsSelected.current.indexOf(hashtag), 1);
				// 					setUpdate(true);
				// 				}}
				// 			>
				// 				<div className='city-otherpois-tagContent' style={{ marginLeft: idx === 0 ? '24px' : '12px', backgroundColor: towerColor }}>
				// 					<div className='city-otherpois-tagContentBrace'>#</div>
				// 					<div className='city-otherpois-tagContentName'>{hashtag}</div>
				// 					<div className='city-otherpois-tagContentAmount'>
				// 						{
				// 							props.cityDatas.cards
				// 								.filter((c: any) => {
				// 									let translations = c.hashtag?.translations.find((t: any) => t.langage === language)?.value;

				// 									if (!translations) {
				// 										return false;
				// 									}

				// 									let need = true;
				// 									for (let tag of _hashtagsSelected.current) {
				// 										if (!translations.includes(tag)) {
				// 											need = false;
				// 										}
				// 									}

				// 									return need;
				// 								})
				// 								.filter((c: any) =>
				// 									c.hashtag?.translations.find((t: any) => t.langage === language)?.value.includes(hashtag),
				// 								).length
				// 						}
				// 					</div>
				// 				</div>
				// 			</div>
				// 		);
				// 	})}
				// 	{_hashtagsUnselected.current
				// 		.filter((ht) => ht.display === true)
				// 		.map((hashtag, idx) => {
				// 			return (
				// 				<div
				// 					className='city-otherpois-tag'
				// 					key={'hashtag-' + hashtag.hashtag}
				// 					onClick={() => {
				// 						console.log('click', hashtag.hashtag);
				// 						_hashtagsSelected.current.push(hashtag.hashtag);
				// 						_hashtagsUnselected.current.find((ht) => {
				// 							return ht.hashtag === hashtag.hashtag;
				// 						}).display = false;
				// 						setUpdate(true);
				// 					}}
				// 				>
				// 					<div
				// 						className='city-otherpois-tagContentUnselected'
				// 						style={{
				// 							marginLeft: _hashtagsSelected.current.length === 0 && idx === 0 ? '24px' : '12px',
				// 							backgroundColor: towerColor,
				// 						}}
				// 					>
				// 						<div className='city-otherpois-tagContentBrace'>#</div>
				// 						<div className='city-otherpois-tagContentNameUnselected'>{hashtag.hashtag}</div>
				// 						<div className='city-otherpois-tagContentAmount'>
				// 							{
				// 								props.cityDatas.cards
				// 									.filter((c: any) => {
				// 										let translations = c.hashtag?.translations.find((t: any) => t.langage === language)?.value;

				// 										if (!translations) {
				// 											return false;
				// 										}

				// 										let need = true;
				// 										for (let tag of _hashtagsSelected.current) {
				// 											if (!translations.includes(tag)) {
				// 												need = false;
				// 											}
				// 										}

				// 										return need;
				// 									})
				// 									.filter((c: any) =>
				// 										c.hashtag?.translations.find((t: any) => t.langage === language)?.value.includes(hashtag.hashtag),
				// 									).length
				// 							}
				// 						</div>
				// 					</div>
				// 				</div>
				// 			);
				// 		})}
				// </SwipeableViews>
			)}

			<div className='city-otherpois-images'>
				{props.cityDatas.cards
					.filter((c: any) => {
						let translations = c.hashtag?.translations.find((t: any) => t.langage === language)?.value;

						if (!translations) {
							return false;
						}

						let need = true;
						for (let tag of _hashtagsSelected.current) {
							if (!translations.includes(tag)) {
								need = false;
							}
						}

						return need;
					})
					.map((card: any) => {
						let path = card.ffile.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'preview').path.replace('\\', '/');
						let finalPath = getApiUrl() + '/content/file/' + place + '/' + path;
						return (
							<div
								key={'city-otherpois-poi-' + card.short}
								className='city-otherpois-poi'
								onClick={() => {
									props.setPoi(props.sphere.pois.find((p: any) => p.mcard === card._id)?.short);
									props.setCard(card._id);
								}}
								style={{ backgroundImage: 'url(' + finalPath + ')' }}
							></div>
						);
					})}
			</div>
		</div>
	);
};
