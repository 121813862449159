import * as THREE from "three";
// import Ammo from "ammojs-typed";
import { FikscopeEngine } from "../index";
import { Object_cons_type, FikscopeObject3D } from "../engine/Object";
import { createTriangleShapeByBufferGeometry } from "../engine/utils";

export type Parallelepiped_cons_type = {
  fe: FikscopeEngine;
  material: THREE.Material;
  pos: THREE.Vector3;
  quat: THREE.Quaternion;
  slop: number;
  coef: number;
  mass?: number;
  friction?: number;
  physic?: boolean;
  body?: Ammo.btRigidBody;
  speed?: number;
  feUpdatable?: boolean;
};

export class Parallelepiped extends FikscopeObject3D {
  private _material: THREE.Material;
  private _slop: number;
  private _coef: number;

  private _mass: number;
  private _friction: number;

  get material(): THREE.Material {
    return this._material;
  }
  set material(material: THREE.Material) {
    this._material = material;
  }

  get mass(): number {
    return this._mass;
  }
  set mass(mass: number) {
    this._mass = mass;
  }

  get slop(): number {
    return this._slop;
  }
  set slop(slop: number) {
    this._slop = slop;
  }

  get coef(): number {
    return this._coef;
  }
  set coef(coef: number) {
    this._coef = coef;
  }

  get friction(): number {
    return this._friction;
  }
  set friction(friction: number) {
    this._friction = friction;
  }

  constructor({
    fe,
    material,
    pos,
    quat,
    slop,
    coef = 1,
    mass = 0,
    friction = 1,
    physic = true,
    feUpdatable = true,
  }: Parallelepiped_cons_type) {
    const geometry = new THREE.BufferGeometry();

    //     .e------f
    //   .' |    .'|
    //  a---+--b'  |
    //  |   |  |   |
    //  |  .g--+---h
    //  |.'    | .'
    //  c------d'
    let a = new THREE.Vector3(-coef, -coef, coef); //a
    let b = new THREE.Vector3(coef, -coef, coef); //b
    let c = new THREE.Vector3(-coef, -coef, -coef); //c
    let d = new THREE.Vector3(coef, -coef, -coef); //d

    let e = new THREE.Vector3(-coef, coef, coef + slop); //a'
    let f = new THREE.Vector3(coef, coef, coef + slop); //b'
    let g = new THREE.Vector3(-coef, coef, -coef + slop); //c'
    let h = new THREE.Vector3(coef, coef, -coef + slop); //d'

    const points = [
      //front face
      c,
      b,
      a,
      c,
      d,
      b,

      //right
      d,
      f,
      b,
      d,
      h,
      f,

      //back face
      h,
      e,
      f,
      h,
      g,
      e,

      //top
      e,
      b,
      f,
      b,
      e,
      a,

      //left
      a,
      e,
      g,
      g,
      c,
      a,

      //bottom
      c,
      g,
      d,
      d,
      g,
      h,
    ];

    geometry.setFromPoints(points);
    geometry.computeVertexNormals();
    // itemSize = 3 because there are 3 values (components) per vertex
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.copy(pos);
    mesh.quaternion.copy(quat);

    let body = undefined;

    if (fe.physic_enabled && physic) {
      let ammo = fe.ammo;
      let bt_shape = createTriangleShapeByBufferGeometry(geometry);
      var transform = new ammo.btTransform();
      transform.setIdentity();
      transform.setOrigin(new ammo.btVector3(pos.x, pos.y, pos.z));
      transform.setRotation(
        new ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w)
      );
      var motionState = new ammo.btDefaultMotionState(transform);

      var localInertia = new ammo.btVector3(0, 0, 0);
      bt_shape.calculateLocalInertia(mass, localInertia);

      var rbInfo = new ammo.btRigidBodyConstructionInfo(
        mass,
        motionState,
        bt_shape,
        localInertia
      );
      body = new ammo.btRigidBody(rbInfo);

      body.setFriction(friction);
      body.setRestitution(0.9);
      //body.setDamping(1, 1);

      //should be done outside too FIXME ↓
      fe.bulletWorld.physicsWorld.addRigidBody(body);

      if (mass > 0) {
        body.setActivationState(fe.bulletWorld!.DISABLE_DEACTIVATION);
      }
    }

    super({
      pos: pos,
      quat: quat,
      mesh: mesh,
      body: body,
      physic: physic,
      speed: 0,
    } as Object_cons_type);
    this.feUpdatable = feUpdatable;

    let oldupdate = this.update;
    this.update = (args: {
      timestep: number;
      auxTransform?: Ammo.btTransform;
    }) => {
      if (fe.bulletWorld)
        oldupdate({
          timestep: args.timestep,
          auxTransform: fe.bulletWorld.TRANSFORM_AUX,
        });
      else
        oldupdate({
          timestep: args.timestep,
        });
    };

    this._slop = slop;
    this._coef = coef;
    this._material = material;
    this._mass = mass;
    this._friction = friction;

    this.bindFe(fe);
  }
}

