// React
import React from 'react';
import { Html5Qrcode } from 'html5-qrcode';
// CSS
import './explorer.css';
// Assets
import compassIcon from '../../style/assets/icons/compass.png';
import binocularIcon from '../../style/assets/icons/binocular.png';
import qrIcon from '../../style/assets/icons/qrIcon.png';
import fingerSwipeIcon from '../../style/assets/icons/fingerSwipe.png';

// Fe
import { FikscopeEngine, fikscope_input_type, GameStateBase } from '../../fikscopeengine_modules/fikscopeengine-core';
// Level
import { LMSphere } from './../../sphere/level/LMSphere';
import { PSphere } from '../../sphere/pawn/PSphere';

export const Buttons = (props: any) => {
	let zoomIn = function () {
		if (props.fe) {
			console.log('ZOOMIN');
			let originalFov = (props.fe.camera as THREE.PerspectiveCamera).getEffectiveFOV();

			if (originalFov > 30) {
				let newZoom = Math.round(originalFov - 15);
				(props.fe.camera as THREE.PerspectiveCamera).fov = newZoom;
				(props.fe.camera as THREE.PerspectiveCamera).updateProjectionMatrix();

				((props.fe as FikscopeEngine).map as LMSphere).setZoom(newZoom);

				console.log('Newzoom=', newZoom);

				props.setPopup({
					title: 'zoomIn',
					desc: 'zoomInDesc' + newZoom,
					icon: binocularIcon,
				});
			} else {
				let newZoom = 90;
				(props.fe.camera as THREE.PerspectiveCamera).fov = newZoom;
				(props.fe.camera as THREE.PerspectiveCamera).updateProjectionMatrix();

				((props.fe as FikscopeEngine).map as LMSphere).setZoom(newZoom);

				props.setPopup({
					title: 'zoomOut',
					desc: 'zoomOutDesc',
					icon: binocularIcon,
				});
			}
		}
	};

	let cameraScan = function () {
		let videoElement = document.getElementById('camera');

		if (videoElement) {
			if (videoElement.style.display === 'flex') {
				props.cameraScanQuit();
				return;
			}
			videoElement.style.display = 'flex';
		}

		props.setPopup({
			title: 'cameraScan',
			desc: 'cameraScanDesc',
			icon: qrIcon,
		});

		const qrCodeSuccessCallback = (decodedText: any, decodedResult: any) => {
			console.log(decodedText);

			const url = new URL(decodedText);
			const params = new URLSearchParams(url.search);
			const myParam = params.get('a');

			props.changeOffsetAngle(myParam);

			props.cameraScanQuit();
		};

		const config = {
			fps: 10,
			qrbox: {
				width: 300,
				height: 300,
			},
		};

		let html5QrCode: Html5Qrcode | undefined = undefined;

		if (props.qrcode === undefined) {
			html5QrCode = new Html5Qrcode('cameraVideo');
			props.setQrcode(html5QrCode);
			html5QrCode.start({ facingMode: 'environment' }, config, qrCodeSuccessCallback, function () {});
		} else {
			props.qrcode.start({ facingMode: 'environment' }, config, qrCodeSuccessCallback, function () {});
		}
	};

	return (
		<div
			id='interfaceBtn'
			className='interface'
			style={{
				position: 'absolute',
				zIndex: '30002',
				width: '20%',
				height: 'calc(100% - 60px)',
				top: '0px',
				right: '0px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'end',
				pointerEvents: 'none',
				transformStyle: 'flat',
			}}
		>
			<div
				onClick={zoomIn}
				className='noSelect buttonRight disableNaturalClick '
				style={{
					backgroundImage: "url('" + binocularIcon + "')",
				}}
			></div>

			<div
				onClick={(e) => {
					e.stopPropagation();
					cameraScan();
				}}
				className='noSelect buttonRight disableNaturalClick '
				style={{
					backgroundImage: "url('" + qrIcon + "')",
					backgroundSize: '50%',
				}}
			></div>

			<div
				onClick={() => {
					props.changeAR();
				}}
				className='noSelect buttonRight disableNaturalClick '
				style={{
					backgroundImage: "url('" + fingerSwipeIcon + "')",
					backgroundSize: '50%',
				}}
			></div>
		</div>
	);
};
