// React
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// Modules
import { Swipe } from 'react-swipe-component';
// Components
import { PointPosition } from '../utils/pointPosition/PointPosition';
// CSS
import './city.css';
// Utils
import { trads } from '../../datas/trads.js';
import getApiUrl from '../../utils/getApiUrl';
// Assets
import playIcon from '../../style/assets/icons/playIcon.png';

export const Stories = (props: { cityDatas: any; assets: any; setVideo: any }) => {
	const { language, place, towerColor } = useSelector((state: any) => state.user);

	const [carousselIndex, setCarousselIndex] = useState<number>(0);

	const scroll = (e: any) => {
		let index = Math.round(e.target.scrollLeft / 144);
		setCarousselIndex(index);

		let elemsGradientLeft = document.getElementsByClassName('city-stories-caroussel-item-gradientLeft');
		for (let elemGradientLeft of elemsGradientLeft) {
			if (index > 0) {
				(elemGradientLeft as HTMLElement).style.opacity = '1';
			} else {
				(elemGradientLeft as HTMLElement).style.opacity = '0';
			}
		}
		let elemsGradientRight = document.getElementsByClassName('city-stories-caroussel-item-gradientRight');
		for (let elemGradientRight of elemsGradientRight) {
			if (index <= props.cityDatas.cards.length) {
				(elemGradientRight as HTMLElement).style.opacity = '1';
			} else {
				(elemGradientRight as HTMLElement).style.opacity = '0';
			}
		}
	};

	return (
		<div className='city-stories'>
			<PointPosition current={carousselIndex || 0} total={props.cityDatas.stories.length} />
			<div className='city-stories-title'>{trads.find((t) => t.id === language)?.watchStories}</div>
			<div
				className='city-stories-caroussel'
				style={{
					margin: '0 0 0 0',
				}}
			>
				<div
					id='city-stories-caroussel-item-gradientLeft'
					className={'city-stories-caroussel-item city-stories-caroussel-item-gradientLeft '}
					style={{
						margin: '0 0 0 -24px',
						pointerEvents: 'none',
						position: 'absolute',
						borderRadius: 0,
						zIndex: 95000,
						left: '0px',
						background: 'linear-gradient(90deg, rgba(31,41,55,0.8) 0%, rgba(31,41,55,0) 20%, rgba(8,13,23,0) 100%)',
						top: '0px',
						width: '240px',
						opacity: '0',
						transition: 'opacity 0.5s',
					}}
				></div>
				<div
					id='city-stories-caroussel-item-gradientRight'
					className={'city-stories-caroussel-item city-stories-caroussel-item-gradientRight '}
					style={{
						margin: '0 0 0 -24px',
						pointerEvents: 'none',
						position: 'absolute',
						borderRadius: 0,
						zIndex: 95000,
						left: 'calc(100vw - 240px)',
						background: 'linear-gradient(270deg, rgba(17, 24, 39, 0.8) 0%, rgba(17, 24, 39, 0) 20%, rgba(8,13,23,0) 100%)',
						top: '0px',
						width: '240px',
						transition: 'opacity 0.5s',
					}}
				></div>
				<div id='city-stories-caroussel' className='city-stories-caroussel' style={{ overflowX: 'auto' }} onScroll={(e) => scroll(e)}>
					<div
						id='city-stories-carousselContent'
						className='city-stories-carousselContent'
						style={{ width: (props.cityDatas.stories.length + 1) * 144 + 72 + 'px' }}
					>
						{props.cityDatas.stories.map((story: any, index: number) => {
							let path = story.media.data.subffiles
								.find((sf: any) => sf.status === 'active' && sf.channel === 'miniature')
								.path.replace('\\', '/');
							let finalPath = getApiUrl() + '/content/file/' + place + '/' + path;
							return (
								<div
									key={'city-stories-caroussel-item-' + index}
									onClick={() => {
										let elems = document.getElementsByClassName('city-stories-carousselContent');
										for (let elem of elems) {
											(elem as HTMLElement).style.transform = '';
										}

										console.log('Launch Story : ', story);

										let channelName = 'main_' + language;

										let pathMovie = story.media.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === channelName);
										if (!pathMovie) {
											pathMovie = story.media.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'main');
										}

										pathMovie = pathMovie.path;

										let pathMovieFinal = pathMovie.split('\\')[1];
										if (!pathMovieFinal) {
											pathMovieFinal = pathMovie.split('/')[1];
										}

										let finalPathMovie = getApiUrl() + '/content/file/' + pathMovieFinal + '?play';

										props.setVideo(finalPathMovie);
									}}
									className={'city-stories-caroussel-item '}
								>
									<div className={'city-stories-caroussel-itemContent '}>
										<div
											className={'city-stories-caroussel-itemContentInside '}
											style={{
												backgroundImage: index > carousselIndex - 3 && index < carousselIndex + 3 ? "url('" + finalPath + "')" : '',
											}}
										>
											<div
												className={'city-stories-caroussel-itemContentInsideIcon '}
												style={{
													backgroundImage: "url('" + playIcon + "')",
												}}
											></div>
										</div>
									</div>
									<div
										className={'city-stories-caroussel-desc '}
										style={{
											color: towerColor,
										}}
									>
										{story.name.translations.find((t: any) => t.langage === language)?.value}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
