// React
import { useState } from 'react';
import { useSelector } from 'react-redux';
// Components
import { PointPosition } from '../utils/pointPosition/PointPosition';
// CSS
import './city.css';
// Utils
import { trads } from '../../datas/trads.js';
import getApiUrl from '../../utils/getApiUrl';
// Assets
import building3dBg from '../../style/assets/building3d.png';

export const Buildings3D = (props: { cityDatas: any; assets: any; setViewer3d: any }) => {
	const { language, place, towerColor } = useSelector((state: any) => state.user);

	const [carousselIndex, setCarousselIndex] = useState<number>(0);

	const scroll = (e: any) => {
		let index = Math.round(e.target.scrollLeft / 144);
		setCarousselIndex(index);

		let elemsGradientLeft = document.getElementsByClassName('city-buildings3D-caroussel-item-gradientLeft');
		for (let elemGradientLeft of elemsGradientLeft) {
			if (index > 0) {
				(elemGradientLeft as HTMLElement).style.opacity = '1';
			} else {
				(elemGradientLeft as HTMLElement).style.opacity = '0';
			}
		}
		let elemsGradientRight = document.getElementsByClassName('city-buildings3D-caroussel-item-gradientRight');
		for (let elemGradientRight of elemsGradientRight) {
			if (index <= props.cityDatas.cards.length) {
				(elemGradientRight as HTMLElement).style.opacity = '1';
			} else {
				(elemGradientRight as HTMLElement).style.opacity = '0';
			}
		}
	};

	return (
		<div className='city-buildings3D'>
			<PointPosition current={carousselIndex || 0} total={props.cityDatas.m3dmodels.length} />
			<div className='city-buildings3D-title'>{trads.find((t) => t.id === language)?.iconicBuildings3D}</div>
			<div
				className='city-buildings3D-caroussel'
				style={{
					margin: '0 0 0 0',
				}}
			>
				<div
					id='city-buildings3D-caroussel-item-gradientLeft'
					className={'city-buildings3D-caroussel-item city-buildings3D-caroussel-item-gradientLeft '}
					style={{
						margin: '0 0 0 -24px',
						pointerEvents: 'none',
						position: 'absolute',
						borderRadius: 0,
						zIndex: 95000,
						left: '0px',
						background: 'linear-gradient(90deg, rgba(31,41,55,0.8) 0%, rgba(31,41,55,0) 20%, rgba(8,13,23,0) 100%)',
						top: '0px',
						width: '240px',
						opacity: '0',
						transition: 'opacity 0.5s',
					}}
				></div>
				<div
					id='city-buildings3D-caroussel-item-gradientRight'
					className={'city-buildings3D-caroussel-item city-buildings3D-caroussel-item-gradientRight '}
					style={{
						margin: '0 0 0 -24px',
						pointerEvents: 'none',
						position: 'absolute',
						borderRadius: 0,
						zIndex: 95000,
						left: 'calc(100vw - 240px)',
						background: 'linear-gradient(270deg, rgba(17, 24, 39, 0.8) 0%, rgba(17, 24, 39, 0) 20%, rgba(8,13,23,0) 100%)',
						top: '0px',
						width: '240px',
						transition: 'opacity 0.5s',
					}}
				></div>
				<div id='city-buildings3D-caroussel' className='city-buildings3D-caroussel' style={{ overflowX: 'auto' }} onScroll={(e) => scroll(e)}>
					<div
						id='city-buildings3D-carousselContent'
						className='city-buildings3D-carousselContent'
						style={{ width: (props.cityDatas.m3dmodels.length + 1) * 144 + 72 + 'px' }}
					>
						{props.cityDatas.m3dmodels.map((building: any, index: number) => {
							let path = building.ffile.data.subffiles
								.find((sf: any) => sf.status === 'active' && sf.channel === 'miniature')
								.path.replace('\\', '/');
							let finalPath = getApiUrl() + '/content/file/' + place + '/' + path;
							return (
								<div
									key={'city-buildings3D-caroussel-item-' + index}
									onClick={() => {
										console.log('Launch Building3d : ', building.short);

										let view = props.cityDatas.m3dmodels.find((m: any) => m.short === building.short);

										let tmp = view.ffile.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'viewer3d');
										let pathMovie = tmp.path;

										if (pathMovie) {
											let pathMovieFinal = pathMovie.split('\\')[1];
											if (!pathMovieFinal) {
												pathMovieFinal = pathMovie.split('/')[1];
											}

											let finalPathMovie = getApiUrl() + '/content/file/' + place + '/upload/' + pathMovieFinal;

											let movie = {
												url: finalPathMovie,
												size: tmp.size,
											};

											props.setViewer3d(movie);
										}
									}}
									className={'city-buildings3D-caroussel-item '}
								>
									<div
										className={'city-buildings3D-caroussel-itemContentInside '}
										style={{
											backgroundImage: "url('" + finalPath || building3dBg + "')",
										}}
									></div>
									<div
										className={'city-buildings3D-caroussel-desc '}
										style={{
											color: towerColor,
										}}
									>
										{building.name.translations.find((t: any) => t.langage === language)?.value}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
