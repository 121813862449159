import { Actor } from "./Actor";
import { Player } from "./Player";
import { FikscopeLevel } from "./FikscopeLevel";
import { Sandbox } from "../content/maps/Sandbox";

export type GameStateBase_cons_type = {
  players?: Map<string, Player>;
  bHasBegun?: boolean;
  map?: FikscopeLevel;
};

export class GameStateBase extends Actor {
  private _map: FikscopeLevel;
  private _players: Map<string, Player>;
  private _bHasBegun: boolean;

  get map(): FikscopeLevel {
    if (this._map === undefined) console.error("map not defined !");
    return this._map!;
  }

  set map(map: FikscopeLevel) {
    this._map = map;
  }

  get players(): Map<string, Player> {
    return this._players;
  }

  set players(players: Map<string, Player>) {
    this._players = players;
  }

  get bHasBegun(): boolean {
    return this._bHasBegun;
  }

  constructor({
    players = new Map<string, Player>(),
    bHasBegun = false,
    map = new Sandbox(),
  }: GameStateBase_cons_type) {
    super();
    this._players = players;
    this._bHasBegun = bHasBegun;
    this._map = map;
  }
}
