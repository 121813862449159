// CSS
import './city.css';
// Trads
import { trads } from '../../datas/trads';
import { useSelector } from 'react-redux';
import { AudioGuid } from '../audioguids/AudioGuid';

export const AudioGuids = (props: { ags: any, setAG: any }) => {
	const { language, place } = useSelector((state: any) => state.user);

	return (
		<div className='city-audioguids'>
			<div className='city-audioguids-title'>{trads.find((t) => t.id === language)?.audioguids}</div>
			<div className='city-audioguids-subtitle'>{trads.find((t) => t.id === language)?.audioguidsDesc.replace('%%', place === 'montparnasse' ? 'Paris' : place.replace(/^./, place[0].toUpperCase()))}</div>
			{props.ags && props.ags.map((ag: any) => {
				return (
                    <AudioGuid
                        key={ag.short}
                        ag={ag}
                        onClick={() => {
						    props.setAG(ag)
					    }}
                        close={() => {
						    props.setAG(null);
					    }}
                        closeDisplay={false}
                    />
				);
			})}
		</div>
	);
};
