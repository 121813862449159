import React, { useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// CSS
import './cookies.css';
// Utils
import { trads } from '../../datas/trads';

export const Cookies = (props: {
    setCookiePopup: (val: boolean) => void
    setPage: any,
    page: string
}) => {
	const { language } = useSelector((state: any) => state.user);

	const [cookies, setCookie /*, removeCookie*/] = useCookies(['cookiesAllowed']);

    const [display, setDisplay] = useState<boolean>(true)

    let goTo = () => {
        //console.log("goto")
        props.setPage("privacypolicyloading")
    }

    useEffect(() => {
        console.log("change page "+props.page)
        if(props.page == 'privacypolicyloading'){
            setDisplay(false)
        }else{
            setDisplay(true)
        }
    }, [props.page])

	return (
        <React.Fragment>
            {display ? <div className='cookies'>
                {/*<div className='cookies-title'>Ce site internet utilise des cookies.{trads.find((t) => t.id === language)?.cookiesTitle}</div>*/}
                <div className='cookies-desc'>
                    {trads.find((t) => t.id === language)?.cookiesContent} <a onClick={() => goTo()}>{trads.find((t) => t.id === language)?.cookiesReadPrivacyPolicy}</a>
                </div>
                <div className='cookies-buttons'>
                    <div
                        className='cookies-button'
                        onClick={() => {
                            props.setCookiePopup(false);
                        }}
                    >
                        <div className='cookies-buttonText'>{trads.find((t) => t.id === language)?.cookiesDenied}</div>
                    </div>
                    <div
                        className='cookies-button'
                        onClick={() => {
                            setCookie('cookiesAllowed', true);
                            props.setCookiePopup(false);
                        }}
                    >
                        <div className='cookies-buttonText'>{trads.find((t) => t.id === language)?.cookiesAccept}</div>
                    </div>
                </div>
            </div> : null}
        </React.Fragment>
	);
};
