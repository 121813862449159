import { update_type } from "./FikscopeEngine";

let count = 0;

function getGuid() {
  count++;
  return count;
}

type FEguid = number;
//All objects that can be placed in a level/map using an editor
export class Actor {
  //Global unique identifier FIXME (should be same over network ?)
  private guid: FEguid;

  //Editor properties ↓

  // is editable with the editor ?
  private bEditable: boolean;

  //Networking properties ↓

  // this actor will be loaded on network clients during load map
  private bNetLoadOnClient: boolean;

  // If true, when the actor is spawned it will be sent to the client
  // but receive no further replication updates from the server afterwards.
  private bNetTemporary: boolean;

  // If true, this actor will replicate to remote machines
  private bReplicates: boolean;

  // Used to determine what rate to throttle down to when
  // replicated properties are changing infrequently
  private MinNetUpdateFrequency: number; // per sec

  // Square of the max distance from the client's viewpoint
  // that this actor is relevant and will be replicated.
  private NetCullDistanceSquared: number; //float

  // How often this actor will be considered for replication,
  // used to determine NetUpdateTime
  private NetUpdateFrequency: number; // per sec

  // Events FIXME I think it's for editing…
  // private onClicked: (e) => void;
  // private onInputTouchBegin: (e) => void;

  // Pointers objects

  // Actors inside this one
  private Children: Array<Actor>;

  private replicate: (args: any) => void;

  public move: (args: any) => void;
  public update: update_type;
  public setPosition: (args: any) => void;
  public feUpdatable: boolean;

  // Default values
  constructor() {
    this.guid = getGuid();
    this.bEditable = true;
    this.bNetLoadOnClient = true;
    this.bNetTemporary = false;
    this.bReplicates = true;
    this.Children = new Array<Actor>();
    this.MinNetUpdateFrequency = 20;
    this.NetCullDistanceSquared = 1.0;
    this.NetUpdateFrequency = 100;
    this.feUpdatable = true;

    this.replicate = () => {};
    this.move = (args: any) => {
      console.warn("Actor with no move implemented");
    };
    /* log("Default : move all children"); */
    /* this.Children.forEach((c) => { */
    /*   c.move(args); */
    /* }); */
    /* }; */
    this.update = (args: any) => {
      console.warn("Actor with no update implemented");
    };
    /* log("Default : update all children"); */
    /* this.Children.forEach((c) => { */
    /*   c.update(args); */
    /* }); */
    /* }; */
    this.setPosition = (args: any) => {
      console.warn("Actor with no setPosition implemented");
    };
    /* log("Default : update all children"); */
    /* this.Children.forEach((c) => { */
    /*   c.setPosition(args); */
    /* }); */
    /* }; */
  }

  public addChild(a: Actor) {
    this.Children.push(a);
  }
}
