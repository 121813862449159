// Natscope imports
import { SocketInterface } from "./socketInterface"
import { PeerConnect } from "./signaling";
import { SocketId } from "./utils";
import { log } from "./logMode";

export type peer_cons_type = {
  id: SocketId,
  socketInterface: SocketInterface,
  pc?: PeerConnect,
  nick: string,
  groups: string,
  fromServer: boolean
};

//User will have more stuff soon…
export class User {
  public id: SocketId;
  public nick: string;
  public group?: string;
  public groups: string;
  public webRTC?: boolean;
  public version?: string;

  constructor(id: SocketId = 'tempId', nick: string = 'Anonymous', groups: string = "", webRTC: boolean = true, version?: string) {
    this.id = id;
    this.nick = nick;
    this.webRTC = webRTC;
    this.groups = groups;
    this.version = version;
  }
}

//A peer is a client with PeerConnection
export class Peer extends User {
  private pc: PeerConnect;

  constructor({
    id,
    socketInterface,
    pc,
    nick = 'Anonymous',
    groups = "",
    fromServer
  }: peer_cons_type) {
    super(id, nick, groups);
    log("get myid and nick", id, nick);
    if (pc === undefined) {
      log("pc is undefined");
      this.pc = new PeerConnect(this.id, socketInterface, fromServer);
      log("create PeerConnect", this.pc);
    } else {
      log("pc is defined");
      this.pc = pc;
    }
  }

  public setPeerConnect(pc: PeerConnect) {
    this.pc = pc;
  }

  public getPeerConnect(): PeerConnect {
    try {
      if (this.pc === undefined) {
        alert("No PeerConnect yet for " + this.id);
        throw new Error("No PeerConnect yet for " + this.id);
      }
      return this.pc;
    } catch (err) {
      throw err;
    }
  }
}
