// CSS
import './cgu.css';
// Assets
import cguIcon from '../../style/assets/icons/cgu.png';

export const Cgu = (props: { language: string; goBack: any }) => {
	return (
		<div className='cgu-topContainer'>
			<div className='videoStory-top' style={{ position: 'fixed' }}>
				<div
					className='videoStory-back'
					onClick={() => {
						props.goBack();
					}}
				></div>
			</div>

			<div
				style={{
					position: 'relative',
					width: '60px',
					height: '60px',
					marginTop: '10px 0 20px 0',
					left: '50%',
					top: '64px',
					transform: 'translateX(-50%)',
					backgroundImage: 'url(' + cguIcon + ')',
					backgroundPosition: 'center',
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
				}}
			></div>

			{props.language === 'de' ? (
				<div>
					<div className='cgu-textSubtitle'>Allgemeine Nutzungsbedingungen</div>
					<div className='cgu-textBold'>Artikel 1 – Begriffsbestimmungen</div>
					<div className='cgu-text'>
						<div>Definition der im Folgenden genannten Begriffe:</div>
						<div>- „Anwendung“: Anwendung Magnicity mit allen Seiten und Ansichten</div>
						<div>- „Herausgeber“: Natürliche oder juristische Person, die für die Herausgabe und den Inhalt der Anwendung verantwortlich ist</div>
						<div>- „Nutzer“: Mobiler Nutzer, der die Anwendung besucht und nutzt</div>
					</div>
					<div className='cgu-textBold'>
						Artikel 2 – Angaben gemäß dem Gesetz über das Vertrauen in die digitale Wirtschaft und Gegenstand der Anwendung
					</div>
					<div className='cgu-text'>
						<div>Herausgeber dieser Anwendung ist das Unternehmen Timescope SAS.</div>
						<div>
							Rechtliche Informationen über den Herausgeber der Anwendung, einschließlich Kontaktinformationen und etwaige Kapital- und
							Registrierungsinformationen, sind im Impressum der Anwendung angegeben. Informationen über die Erfassung und Verarbeitung
							personenbezogener Daten (Richtlinie und Erklärung) finden Sie in der Datenschutzerklärung der Anwendung.
						</div>
						<div>
							Die Anwendung Magnicity ist ein neues Medium, das sich mit Städten und ihren Denkmälern beschäftigt und die besten
							Augmented-Reality- und Virtual-Reality-Technologien nutzt, um ein innovatives, intuitives und universelles Betrachtungserlebnis zu
							bieten.
						</div>
						<div>
							Für die Nutzung der Anwendung muss der Nutzer der Gesamtheit dieser allgemeinen Nutzungsbedingungen annehmen und bestätigen, sie
							vollständig zur Kenntnis genommen zu haben. Diese Annahme gilt als gleichwertig mit einer handschriftlichen Unterschrift des
							Nutzers. Der Nutzer erkennt den Beweiswert der automatischen Aufzeichnungssysteme des Herausgebers der Anwendung an und verzichtet,
							sofern er nicht das Gegenteil beweisen kann, auf das Recht, diese im Streitfall anzufechten.
						</div>
						<div>
							Die Annahme dieser allgemeinen Nutzungsbedingungen setzt die Rechtsfähigkeit des Nutzers voraus. Ist der Nutzer minderjährig oder
							nicht rechtsfähig, erklärt er, dass er die Erlaubnis eines Vormunds, Betreuers oder gesetzlichen Vertreters hat.
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 3 – Entlastung des Herausgebers im Rahmen der Ausführung des vorliegenden Vertrags</div>
					<div className='cgu-text'>
						<div>
							Im Falle der Unmöglichkeit des Zugriffs auf die Anwendung, aufgrund von technischen Problemen oder Problemen jeglicher Art, kann der
							Nutzer keinerlei Schadenersatz oder Entschädigung verlangen. Die in der Anwendung vorhandenen Hypertext-Links können auf andere
							Anwendungen oder Websites verweisen, und der Herausgeber der Anwendung kann nicht für Verstöße des Inhalts dieser Websites und
							Anwendungen gegen die geltende Gesetzgebung haftbar gemacht werden. Ebenso kann der Herausgeber nicht dafür haftbar gemacht werden,
							wenn dem Nutzer durch die Nutzung dieser Websites oder Anwendungen Schaden zugefügt wird.
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 4 – Rechte an geistigem Eigentum in Bezug auf die Elemente der Anwendung</div>
					<div className='cgu-text'>
						<div>
							Alle Elemente der Anwendung sind Eigentum des Herausgebers oder eines beauftragten Dritten oder werden vom Herausgeber mit
							Genehmigung des Eigentümers verwendet. Das Kopieren der Logos, Text-, Bild- oder Videoinhalte (diese Aufzählung ist nicht
							abschließend) ist strengstens untersagt und wird als Fälschung angesehen. Jeglicher Verstoß kann auf Initiative des Herausgebers der
							Anwendung oder seines Beauftragten die Löschung des Kontos des betroffenen Mitglieds nach sich ziehen, ohne Vorankündigung oder
							Entschädigung und ohne dass diese Löschung als Schaden zu Lasten des Mitglieds anerkannt werden kann. Die Quellenangaben der in der
							vorliegenden Anwendung verwendeten Elemente (Bilder, Fotos, Inhalte) finden Sie hier: .
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 5 – Haftungsbeschränkung</div>
					<div className='cgu-text'>
						<div>
							Insbesondere im Rahmen des Online-Verkaufs, ist der Herausgeber der Anwendung nur durch die Sorgfaltspflicht gebunden. Er kann nicht
							für Schäden verantwortlich gemacht werden, die aus der Anwendung resultieren, wie z. B. Datenverlust, Eindringen, Viren, Ausfall des
							Dienstes oder sonstige. Der Herausgeber der Anwendung, Timescope, kann nicht für die Nichterfüllung des Vertrages haftbar gemacht
							werden, die durch das Auftreten eines Ereignisses höherer Gewalt und insbesondere im Falle von Katastrophen bedingt ist, die durch
							Überschwemmungen oder Brände verursacht werden. Der Nutzer erkennt ausdrücklich an, dass er die Anwendung auf eigenes Risiko und
							unter seiner alleinigen Verantwortung nutzt. Die Anwendung stellt dem Nutzer Informationen zu Informationszwecken zur Verfügung, die
							Unvollkommenheiten, Fehler, Auslassungen, Ungenauigkeiten und sonstige Ambivalenzen aufweisen können. Auf keinen Fall kann Timescope
							haftbar gemacht werden für:
						</div>
						<div>
							- alle direkten oder indirekten Schäden, insbesondere im Hinblick auf entgangenen Gewinn, entgangene Einnahmen, Verlust von Kunden,
							Datenverlust usw., die sich aus der Nutzung der Anwendung oder im Gegenteil aus der Unmöglichkeit der Nutzung ergeben können
						</div>
						<div>
							- Fehlfunktion, Nichtverfügbarkeit des Zugangs, Missbrauch, fehlerhafte Konfiguration des Geräts des Nutzers oder die Verwendung
							eines vom Nutzer wenig genutzten oder veralteten Geräts
						</div>
						<div>- den Inhalt von Werbung und anderen Links oder externen Quellen, auf die der Nutzer von der Anwendung aus zugreifen kann</div>
					</div>
					<div className='cgu-textBold'>Artikel 6 – Zugriff auf die Anwendung über eine Internetverbindung</div>
					<div className='cgu-text'>
						<div>
							Der Herausgeber kann nicht für die technische Nichtverfügbarkeit der Verbindung verantwortlich gemacht werden, sei es aufgrund von
							höherer Gewalt, Wartung, Aktualisierung, Änderung, Eingriff des Hosts, eines internen oder externen Streiks, eines Netzwerkausfalls,
							eines Stromausfalls oder einer fehlerhaften Konfiguration bzw. Verwendung der Endgeräte des Nutzers.
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 7 – Verschiedene Bestimmungen</div>
					<div className='cgu-text'>
						<div>
							Die vorliegenden allgemeinen Nutzungsbedingungen unterliegen der Anwendung des französischen Rechts. Sie können jederzeit durch den
							Herausgeber oder seinen Beauftragten geändert werden. Für den Nutzer gelten die allgemeinen Nutzungsbedingungen, die am Tag seines
							Kaufs oder seiner Verbindung mit der Anwendung in Kraft sind. Der Herausgeber verpflichtet sich, alle seine früheren allgemeinen
							Nutzungsbedingungen aufzubewahren und jedem Nutzer auf Anfrage zuzusenden. Mit Ausnahme der Bestimmungen über die öffentliche
							Ordnung können alle Streitigkeiten, die im Rahmen der Ausführung der vorliegenden allgemeinen Nutzungsbedingungen entstehen könnten,
							vor jeder gerichtlichen Maßnahme der Einschätzung des Herausgebers im Hinblick auf eine gütliche Einigung unterworfen werden. Es
							wird ausdrücklich darauf hingewiesen, dass Anträge auf gütliche Einigung die Fristen für die Klageerhebung nicht aufheben. Für alle
							Rechtsstreitigkeiten im Zusammenhang mit der Durchführung dieses Vertrages sind, soweit nicht öffentlich-rechtliche Vorschriften
							entgegenstehen, die Gerichte des Gerichtsstandes des Berufungsgerichtes zuständig.
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 8 – Verwendung von Cookies und auf dem Endgerät hinterlegten Dateien</div>
					<div className='cgu-text'>
						<div>
							Der Begriff „Cookie“ wird hier im weitesten Sinne verwendet und umfasst jede Datei, die auf dem Endgerät des Nutzers hinterlegt
							wird, um ihn zu identifizieren oder um Informationen dauerhaft auf dem Endgerät zu speichern. Ein „Cookie“ ermöglicht die
							Identifizierung des Nutzers, die Personalisierung seiner Abfrage und die Beschleunigung der Anzeige der Anwendung dank der
							Speicherung einer Datendatei auf seinem Gerät. Die Anwendung wird „Cookies“ voraussichtlich hauptsächlich dazu verwenden, um 1) der
							Anwendung zu ermöglichen, sich an die Aktivitäten und Einstellungen des Nutzers in der Anwendung zu erinnern, 2) statistische
							Informationen über das Surfverhalten zu erfassen, um die Erfahrung des Nutzers zu verbessern, und 3) den Zugriff auf das Konto eines
							Mitglieds und auf Inhalte zu ermöglichen, die ohne Anmeldung nicht zugänglich sind. Der Nutzer bestätigt, dass er von dieser Praxis
							Kenntnis hat und ermächtigt den Herausgeber, sie zu verwenden. Der Nutzer kann die Annahme von Cookies verweigern, indem er die
							Einstellungen auf seinem Gerät oder der Anwendung ändert. Der Herausgeber kann in diesem Fall jedoch nicht garantieren, dass die
							Anwendung wie erwartet funktioniert, und haftet nicht für Fehlfunktionen der Anwendung.
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 9 – Rahmen für die Gültigkeit der Bedingungen</div>
					<div className='cgu-text'>
						<div>
							Sollte eine Bestimmung der allgemeinen Nutzungsbedingungen als rechtswidrig, nichtig oder aus anderen Gründen als nicht durchsetzbar
							erachtet werden, so gilt diese Bestimmung als von den allgemeinen Nutzungsbedingungen abtrennbar und beeinträchtigt nicht die
							Gültigkeit und Durchsetzbarkeit der übrigen Bestimmungen. Diese Bedingungen beschreiben die gesamte Vereinbarung zwischen dem Nutzer
							und dem Herausgeber. Sie ersetzen alle früheren oder gleichzeitigen schriftlichen oder mündlichen Vereinbarungen. Die allgemeinen
							Nutzungsbedingungen können vom Nutzer selbst nicht abgetreten, übertragen oder unterlizenziert werden. In Gerichts- oder
							Verwaltungsverfahren, die sich auf die Nutzungsbedingungen beziehen, kann eine gedruckte Version der Nutzungsbedingungen und aller
							in elektronischer Form übermittelten Mitteilungen verlangt werden. Die Parteien vereinbaren, dass der gesamte Schriftverkehr im
							Zusammenhang mit diesen allgemeinen Nutzungsbedingungen in französischer Sprache geführt wird.
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 10 – Mitteilungen</div>
					<div className='cgu-text'>
						<div>
							Sämtliche Benachrichtigungen oder Mitteilungen in Bezug auf diese allgemeinen Nutzungsbedingungen, das Impressum oder die
							Datenschutzerklärung müssen schriftlich erfolgen und persönlich, per Einschreiben, per Post oder durch einen anderen national
							anerkannten Kurierdienst, der eine regelmäßige Sendungsverfolgung ermöglicht, zugestellt werden oder alternativ per E-Mail an die im
							Impressum der Anwendung angegebenen Adressen unter Angabe Ihres vollständigen Namens, Ihrer Kontaktdaten und des Gegenstands der
							Mitteilung übermittelt werden.
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 11 – Beanstandungen</div>
					<div className='cgu-text'>
						<div>
							Sämtliche Beanstandungen im Zusammenhang mit der Nutzung der Anwendung, der Seiten der Anwendung in sozialen Netzwerken oder den
							allgemeinen Nutzungsbedingungen, dem Impressum oder der Datenschutzerklärung müssen innerhalb von 365 Tagen ab dem Datum, an dem das
							Problem aufgetreten ist, geltend gemacht werden, ungeachtet anderslautender Gesetze oder Bestimmungen. Wird eine solche Beanstandung
							nicht innerhalb von 365 Tagen geltend gemacht, ist diese für immer gerichtlich verjährt.
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 12 – Ungenauigkeiten</div>
					<div className='cgu-text'>
						<div>
							Es ist möglich, dass die gesamte Anwendung oder Teile davon Ungenauigkeiten oder Fehler aufweisen oder Informationen, die nicht mit
							diesen allgemeinen Nutzungsbedingungen, dem Impressum oder der Datenschutzerklärung der Anwendung übereinstimmen. Darüber hinaus ist
							es möglich, dass unbefugte Änderungen durch Dritte an der Anwendung oder an damit verbundenen Diensten (soziale Netzwerke...)
							vorgenommen werden. In einer solchen Situation hat der Nutzer die Möglichkeit, sich per Post oder per E-Mail unter den im Impressum
							der Anwendung angegebenen Adressen an den Herausgeber zu wenden, möglichst mit einer Beschreibung des Fehlers und seiner Position in
							der Anwendung sowie ausreichenden Informationen, um ihn zu kontaktieren.
						</div>
					</div>
					<div className='cgu-textBold'>Artikel 13 – Geolokalisierung</div>
					<div className='cgu-text'>
						<div>
							Gemäß Artikel L. 34-1-V des französischen Gesetzes über die Post und die elektronische Kommunikation muss die Anwendung, da sie
							Standortdaten sammelt, dem Nutzer die Möglichkeit bieten, zum Zeitpunkt der Installation seine ausdrückliche Zustimmung geben und
							diese Entscheidung später wieder ändern zu können. Die Nutzung der Geolokalisierungsfunktion der Anwendung erfordert die vorherige
							ausdrückliche Zustimmung des Nutzers zur Geolokalisierung. Dazu muss der Nutzer die Geolokalisierungsfunktion direkt in den
							Einstellungen seines mobilen Endgeräts aktivieren und ihrer Verwendung durch die Anwendung zustimmen. Diese Funktion kann jederzeit
							und kostenlos deaktiviert oder aktiviert werden. Nach Annahme der Geolokalisierungsfunktion des Geräts und der Anwendung per GPS
							kann der Nutzer die folgenden Dienste nutzen: Die Geolokalisierung ermöglicht es der Anwendung, dem Nutzer standardmäßig den Inhalt
							des Landes, in dem er sich befindet, oder des nächstgelegenen Landes vorzuschlagen. Die Anwendung Magnicity umfasst Inhalte von 4
							Standorten in 4 verschiedenen Ländern (Frankreich, Deutschland, Niederlande und USA). Die Geolokalisierung hindert den Nutzer nicht
							daran, unabhängig von seinem geografischen Standort freien Zugang zu den Inhalten der anderen Seiten zu haben. Die Geolokalisierung
							ermöglicht außerdem, den Standort des Aussichtspunktes des Nutzers zu bestimmen, so dass er in vollem Umfang von dem
							Augmented-Reality-Erlebnis profitieren kann. Diese Geolokalisierungsdaten werden nicht auf Servern gespeichert und sind nur für die
							sofortige Verwendung bestimmt. Das Endgerät errechnet dann seine eigene Position. Die Deaktivierung der Geolokalisierungsfunktion
							der Anwendung und/oder der Geolokalisierungsfunktion des Endgeräts blockiert die von der Anwendung angebotenen und damit verknüpften
							Dienste sowie die Anzeige von geografisch ausgerichteter Werbung.
						</div>
					</div>

					<div className='cgu-update'>Letzte Aktualisierung: 16. April 2021</div>
					<div style={{ width: '100%', height: '64px' }}></div>
				</div>
			) : (
				<div>
					<div className='cgu-textSubtitle'>Conditions Générales d'Utilisation</div>
					<div className='cgu-textBold'>Article 1 - Définitions</div>
					<div className='cgu-text'>
						<div>On désignera par la suite:</div>
						<div>- &#39;Application&#39; : l&#39;application Magnicity et l&#39;ensemble de ses pages et écrans</div>
						<div>- &#39;Editeur&#39; : La personne, morale ou physique, responsable de l&#39;édition et du contenu de l&#39;application</div>
						<div>- &#39;Utilisateur&#39; : Le mobinaute visitant et utilisant l&#39;application</div>
					</div>
					<div className='cgu-textBold'>
						Article 2 - Mentions imposées par la loi de confiance dans l’économie numérique et objet de l&#39;application
					</div>
					<div className='cgu-text'>
						<div>La présente application est éditée par Timescope SAS.</div>
						<div>
							Les informations légales concernant l&#39;éditeur de l&#39;application, notamment les coordonnées et les éventuelles informations de
							capital et d&#39;immatriculation, sont fournies dans les mentions légales de l&#39;application.
						</div>
						<div>
							Les informations concernant la collecte et le traitement des données personnelles (politique et déclaration) sont fournies dans la
							charte de données personnelles de l&#39;application. L’Application Magnicity est un nouveau média porté sur la ville et ses
							monuments, qui met le meilleur des technologies de réalité augmentée et de réalité virtuelle au service d’une expérience
							d’observation innovante, intuitive, et universelle.
						</div>
						<div>
							L&#39;utilisation de l&#39;application suppose l’acceptation, par l&#39;utilisateur, de l’intégralité des présentes conditions
							générales, qu&#39;il reconnaît du même fait en avoir pris pleinement connaissance. Cette acceptation sera réputée avoir la même
							valeur qu’une signature manuscrite de la part de l&#39;utilisateur. L&#39;utilisateur reconnaît la valeur de preuve des systèmes
							d’enregistrement automatique de l’éditeur de l&#39;application et, sauf pour lui d’apporter une preuve contraire, il renonce à les
							contester en cas de litige.
						</div>
						<div>
							L’acceptation des présentes conditions générales suppose de la part des utilisateurs qu’ils jouissent de la capacité juridique
							nécessaire pour cela. Si l&#39;utilisateur est mineur ou ne dispose pas de cette capacité juridique, il déclare avoir l’autorisation
							d’un tuteur, d’un curateur ou de son représentant légal.
						</div>
					</div>
					<div className='cgu-textBold'>
						Article 3 - Exonération de la responsabilité de l’éditeur dans le cadre de l’exécution du présent contrat
					</div>
					<div className='cgu-text'>
						<div>
							En cas d’impossibilité d’accès à l&#39;application, en raison de problèmes techniques ou de toutes natures, l&#39;utilisateur ne
							pourra se prévaloir d’un dommage et ne pourra prétendre à aucune indemnité.
						</div>
						<div>
							Les liens hypertextes présents sur l&#39;application peuvent renvoyer sur d&#39;autres applications ou sur des sites internet et la
							responsabilité de l’éditeur de l&#39;application ne saurait être engagée si le contenu de ces sites et applications contrevient aux
							législations en vigueur. De même la responsabilité de l’éditeur ne saurait être engagée si l&#39;utilisation de ces sites ou
							applications, par l&#39;utilisateur, lui causait un préjudice.
						</div>
					</div>
					<div className='cgu-textBold'>Article 4 - Droits de propriété intellectuelle relatifs aux éléments de l&#39;application</div>
					<div className='cgu-text'>
						<div>
							Tous les éléments de l&#39;application appartiennent à l&#39;éditeur ou à un tiers mandataire, ou sont utilisés par l&#39;éditeur
							avec l&#39;autorisation de leur propriétaire. Toute copie des logos, contenus textuels, pictographiques ou vidéos, sans que cette
							énumération ne soit limitative, est rigoureusement interdite et s’apparente à de la contrefaçon. Tout membre qui se rendrait
							coupable de contrefaçon serait susceptible de voir son compte supprimé sans préavis ni indemnité et sans que cette suppression ne
							puisse lui être constitutive d’un dommage, sans réserve d’éventuelles poursuites judiciaires ultérieures à son encontre, à
							l’initiative de l’éditeur de l&#39;application ou de son mandataire.La présente application utilise des éléments (images,
							photographies, contenus) dont les crédits reviennent à : .
						</div>
					</div>
					<div className='cgu-textBold'>Article 5 - Limitation de responsabilité</div>
					<div className='cgu-text'>
						<div>
							L&#39;éditeur de l&#39;application, notamment dans le processus de vente en ligne, n’est tenu que par une obligation de moyens ; sa
							responsabilité ne pourra être engagée pour un dommage résultant de l&#39;application tel que perte de données, intrusion, virus,
							rupture du service, ou autres. L&#39;éditeur de l&#39;application, Timescope , ne saurait être tenu pour responsable de
							l&#39;inexécution du contrat conclu, due à la survenance d&#39;un événement de force majeure et notamment en cas de catastrophes
							causées par inondations ou incendies. L&#39;utilisateur admet expressément utiliser l&#39;application à ses propres risques et sous
							sa responsabilité exclusive. L&#39;application fournit à l&#39;utilisateur des informations à titre indicatif, avec des
							imperfections, erreurs, omissions, inexactitudes et autres ambivalences susceptibles d&#39;exister. En tout état de cause, Timescope
							ne pourra en aucun cas être tenu responsable :
						</div>
						<div>
							- de tout dommage direct ou indirect, notamment en ce qui concerne les pertes de profits, le manque à gagner, les pertes de
							clientèle, de données pouvant entre autres résulter de l&#39;utilisation de l&#39;application, ou au contraire de
							l&#39;impossibilité de son utilisation
						</div>
						<div>
							- d&#39;un dysfonctionnement, d&#39;une indisponibilité d&#39;accès, d&#39;une mauvaise utilisation, d&#39;une mauvaise
							configuration du périphérique de l&#39;utilisateur, ou encore de l&#39;emploi d&#39;un périphérique peu usité ou obsolète par
							l&#39;utilisateur
						</div>
						<div>
							- du contenu des publicités et autres liens ou sources externes accessibles par l&#39;utilisateur à partir de l&#39;application
						</div>
					</div>
					<div className='cgu-textBold'>Article 6 - Accès à l&#39;application par connexion Internet</div>
					<div className='cgu-text'>
						<div>
							La responsabilité de l&#39;éditeur ne peut être engagée en raison d&#39;une indisponibilité technique de la connexion, qu&#39;elle
							soit due notamment à un cas de force majeure, à une maintenance, à une mise à jour, à une modification, à une intervention de
							l&#39;hébergeur, à une grève interne ou externe, à une panne de réseau, à une coupure d&#39;alimentation électrique, ou encore à une
							mauvaise configuration ou utilisation du périphérique de l&#39;utilisateur.
						</div>
					</div>
					<div className='cgu-textBold'>Article 7 - Clauses diverses</div>
					<div className='cgu-text'>
						<div>
							Les présentes conditions générales sont soumises à l’application du droit Français. Elles peuvent être modifiées à tout moment par
							l’éditeur ou son mandataire. Les conditions générales applicables à l’utilisateur sont celles en vigueur au jour de son achat ou de
							sa connexion sur l&#39;application. L’éditeur s’engage bien évidemment à conserver toutes ses anciennes conditions générales et à
							les faire parvenir à tout utilisateur qui en ferait la demande. Sauf dispositions d’ordre public, tous litiges qui pourraient
							survenir dans le cadre de l’exécution des présentes conditions générales pourront avant toute action judiciaire être soumis à
							l’appréciation de l’éditeur en vue d’un règlement amiable. Il est expressément rappelé que les demandes de règlement amiable ne
							suspendent pas les délais ouverts pour intenter les actions judiciaires. Sauf disposition contraire, d’ordre public, toute action
							judiciaire relative à l’exécution du présent contrat devra être soumise à la compétence des juridictions du ressort de la Cour
							d’appel saisie.
						</div>
					</div>
					<div className='cgu-textBold'>Article 8 - Utilisation de Cookies et de fichiers déposés sur le périphérique</div>
					<div className='cgu-text'>
						<div>
							Le mot « Cookie » est ici utilisé au sens large et englobe tout fichier déposé sur le périphérique de l&#39;utilisateur afin de
							l&#39;identifier ou de sauvegarder des informations de manière durable sur le périphérique. Un « Cookie » permet
							l&#39;identification de l&#39;utilisateur, la personnalisation de sa consultation et l&#39;accélération de l’affichage de
							l&#39;application grâce à l&#39;enregistrement d&#39;un fichier de données sur son périphérique. L&#39;application est susceptible
							d&#39;utiliser des « Cookies » principalement pour 1) permettre à l&#39;application de mémoriser les actions et réglages de
							l&#39;utilisateur dans l&#39;application, 2) obtenir des statistiques de navigation afin d&#39;améliorer l&#39;expérience de
							l&#39;Utilisateur, et 3) permettre l&#39;accès à un compte de membre et à du contenu qui n&#39;est pas accessible sans connexion.
							L&#39;Utilisateur reconnaît être informé de cette pratique et autorise l&#39;éditeur à y recourir. L&#39;Utilisateur peut refuser
							l&#39;enregistrement de « Cookies » en changeant les réglages de son périphérique ou de l&#39;application, mais l&#39;éditeur ne
							peut alors garantir que l&#39;application fonctionnera comme attendu, et ne prendra aucune responsabilité en cas de
							non-fonctionnement de l&#39;application.
						</div>
					</div>
					<div className='cgu-textBold'>Article 9 - Encadrement des conditions</div>
					<div className='cgu-text'>
						<div>
							Si une disposition des Conditions générales est jugée illégale, nulle ou pour toute autre raison inapplicable, alors cette
							disposition sera réputée divisible des Conditions et n&#39;affectera pas la validité et l&#39;applicabilité des dispositions
							restantes. Ces présentes conditions décrivent l’ensemble de l’accord entre l’utilisateur et l&#39;éditeur. Elles remplacent tous
							accords antérieurs ou contemporains écrits ou oraux. Les conditions générales ne sont pas cessibles, transférables ou
							sous-licenciable par l’utilisateur lui- même. Une version imprimée des Conditions et de tous les avis donnés sous forme électronique
							pourra être demandée dans des procédures judiciaires ou administratives en rapport avec les conditions générales. Les parties
							conviennent que toute la correspondance relative à ces conditions générales doit être rédigée dans la langue française.
						</div>
					</div>

					<div className='cgu-textBold'>Article 10 - Notifications</div>
					<div className='cgu-text'>
						<div>
							Toute notification ou avis concernant les présentes conditions générales, les mentions légales ou la charte de données personnelles
							doit être faite par écrit et doit être remis en mains propres, courrier recommandé ou certifié, par Poste ou tout autre service de
							messagerie reconnu au niveau national qui permet de suivre régulièrement ses forfaits, ou encore par mail aux adresses indiquées
							dans les mentions légales de l&#39;application, en précisant vos noms, prénoms, coordonnées et objet de l’avis.
						</div>
					</div>
					<div className='cgu-textBold'>Article 11 - Réclamations</div>
					<div className='cgu-text'>
						<div>
							Toute réclamation liée à l&#39;utilisation de l&#39;application, des pages de l&#39;application sur des réseaux sociaux éventuels ou
							les conditions générales, mentions légales ou charte de données personnelles doit être déposée dans les 365 jours suivant le jour
							d’origine du problème source de réclamation, et ce indépendamment de toute loi ou règle de droit contraire. Dans le cas où une telle
							réclamation n’aurait pas été déposée dans les 365 jours suivants, une telle réclamation sera à jamais inapplicable en justice.
						</div>
					</div>
					<div className='cgu-textBold'>Article 12 - Inexactitudes</div>
					<div className='cgu-text'>
						<div>
							Il peut être possible que se trouvent, dans l’ensemble de l&#39;Application, et dans une mesure restreinte, des inexactitudes ou des
							erreurs, ou des informations qui soient en désaccord avec les présentes Conditions Générales, les Mentions Légales ou la Charte de
							Données personnelles de l&#39;Application. En outre, il est possible que des modifications non autorisées soient faites par des
							tiers sur l&#39;Application ou sur des services annexes (réseaux sociaux…).
						</div>
						<div>
							Dans une telle situation, l&#39;Utilisateur a la possibilité de contacter l&#39;Editeur par courrier postal ou par mail aux adresses
							indiquées dans les mentions légales de l&#39;Application, avec si possible une description de l&#39;erreur et son emplacement dans
							l&#39;application, ainsi que des informations suffisantes permettant de le contacter.
						</div>
					</div>

					<div className='cgu-textBold'>Article 13 - Géolocalisation</div>
					<div className='cgu-text'>
						<div>
							Conformément à l&#39;article L. 34-1-V du code des postes et des communications électroniques, l&#39;application collectant des
							données de localisation, elle doit permettre à l&#39;utilisateur de rendre son accord exprès lors de l&#39;installation et de
							modifier ce choix par la suite.
						</div>
						<div>
							L&#39;utilisation de la fonctionnalité de géolocalisation de l&#39;application nécessite le consentement préalable exprès de
							l&#39;utilisateur à être géolocalisé. Pour cela l&#39;utilisateur devra activer, s&#39;il le souhaite, la fonction de
							géolocalisation directement dans les réglages de son terminal mobile et accepter que l&#39;application puisse y avoir recours. Cette
							fonctionnalité peut, à tout moment, et sans frais, être désactivée ou activée. Grâce à l&#39;acceptation de la fonction de
							géolocalisation par GPS du périphérique et de l&#39;application, les services suivants sont offerts à l&#39;utilisateur : La
							géolocalisation permet à l’application de proposer par défaut à l’utilisateur, le contenu du pays où il se trouve ou le contenu du
							pays le plus proche.
						</div>
						<div>
							L’application Magnicity comporte le contenu de 4 sites qui se trouvent dans 4 pays différents (la France, l’Allemagne, les Pays-Bas
							et les Etats-Unis). La géolocalisation n’empêche pas l’utilisateur d’avoir un accès libre au contenu des autres sites et ceci
							quelque soit sa localisation géographique. La géolocalisation permet également de situer l’utilisateur sur l’observatoire afin de
							permettre l’utilisateur de bénéficier pleinement de l’expérience de réalité augmentée. Ces données de géolocalisation ne sont pas
							conservées sur des serveurs et ne sont utilisées que de manière instantanée..
						</div>
						<div>Le périphérique calcule alors lui-même sa position.</div>
						<div>
							La désactivation de géolocalisation par l&#39;application et/ou de la géolocalisation du périphérique bloque les services offerts
							par l&#39;application qui y sont liés et l&#39;affichage de publicités géociblées.
						</div>

						<div className='cgu-update'>Dernière mise à jour : 16 avril 2021</div>
						<div style={{ width: '100%', height: '64px' }}></div>
					</div>
				</div>
			)}
		</div>
	);
};
