// Modules
import * as THREE from 'three';

import LatLon from 'geodesy/latlon-nvector-spherical.js';

// GJ modules
import { Pawn, FikscopeObject3D, FikscopeEngine, FikscopeLevel, Actor, Player, Sphero_cons_type } from '../../fikscopeengine_modules/fikscopeengine-core';

// Project
import { PSphere } from './../pawn/PSphere';

import { log } from '../../logMode';
import { createElement } from 'react';
import getApiUrl from '../../utils/getApiUrl';

export type LMSphere_cons_type = {
	assets: any;
	spheres?: any;
	datas: any;
	setData: any;
	setPoi?: Function;
	setCard?: Function;
	AR: any;
	towerColor: string;
	editor: Boolean;
	tempPois: any;
	ags: any;
	lang: any;
	cameraScanQuit: any;
	resetCompass: any;
	localize: any;
	showCompass: any;
	setVideo: any;
};

export class LMSphere extends FikscopeLevel {
	actors: Map<string, Actor>;
	name: string;

	initPos: THREE.Vector3 = new THREE.Vector3(0, 0, 1.5);
	ZERO_QUATERNION: THREE.Quaternion = new THREE.Quaternion(0, 0, 0, 1);
	initQuat: THREE.Quaternion = this.ZERO_QUATERNION;
	onStart: (args: any) => void;
	onStop: (args: any) => void;

	fe?: FikscopeEngine;

	AR: any;

	assets: any;
	spheres: any;
	datas: any;
	data: any;
	setData: any;
	sphere?: string;
	public sphereObjects: Map<string, any>;
	public setPoi: Function | undefined;
	public setCard: Function | undefined;
	public zoom: number;
	public zoomOriginal: number;
	//public zoomIn: Function | undefined;
	//public zoomOut: Function | undefined;

	public currentSelection: string | null = null;
	public resetCompass: any;
	public localize: any;
	public showCompass: any;
	public setVideo: any;

	public towerColor: string;

	editor: Boolean;
	tempPois: any;
	ags: any;
	lang: string = 'en';

	cameraScanQuit: any;

	constructor({
		assets,
		spheres,
		datas,
		setData,
		setPoi,
		setCard,
		AR,
		towerColor,
		editor,
		tempPois,
		ags,
		lang,
		cameraScanQuit,
		resetCompass,
		localize,
		showCompass,
		setVideo,
	}: LMSphere_cons_type) {
		super();

		this.assets = assets;
		this.spheres = spheres;
		this.sphereObjects = new Map();
		this.setPoi = setPoi;
		this.setCard = setCard;
		this.datas = datas;
		this.data = { pois: new Map() };
		this.setData = setData;
		this.zoom = 60;
		this.zoomOriginal = 90;
		this.cameraScanQuit = cameraScanQuit;
		this.resetCompass = resetCompass;
		this.localize = localize;
		this.showCompass = showCompass;

		this.actors = new Map<string, Actor>();
		this.onStart = (e) => this.start();
		this.onStop = () => {
			console.warn('stop');
		};
		this.name = LMSphere.name;
		this.pawnPlayer = (fe) => this.defaultPawnPlayer(fe);

		this.AR = AR;
		this.towerColor = towerColor;

		this.editor = editor;
		this.tempPois = tempPois;
		this.ags = ags;
		this.lang = lang;
		this.setVideo = setVideo;
	}

	changeLang = (lang: string) => {
		this.lang = lang;
		this.changeSphere(this.sphere);
	}

	defaultPawnPlayer(fe: FikscopeEngine): Pawn<FikscopeObject3D> {
		var redMaterial = new THREE.MeshStandardMaterial({
			color: 0xff2222,
			depthWrite: true,
			depthTest: true,
			metalness: 0.8,
			opacity: 1,
		});
		var ZERO_QUATERNION = new THREE.Quaternion(0, 0, 0, 1);

		return new PSphere({
			fe: fe,
			material: redMaterial,
			pos: new THREE.Vector3(0, 0, 1.5),
			quat: ZERO_QUATERNION,
			r: 0.0001,
			mass: 100,
			friction: 0,
			AR: this.AR,
			editor: this.editor,
			offsetangle: this.datas.spheres[0].offsetangle,
			ags: this.ags,
			cameraScanQuit: this.cameraScanQuit,
		} as Sphero_cons_type);
	}

	start(): void {
		log('start LPSandbox');
		/* log(this.stopWatch) */
		/* this.stopWatch.mystart(); */
	}

	//Should be something like GameState/PlayerState as input instead…
	winGame({ player }: { player: Player }): boolean {
		/*if (player && player.pawn) {
			return player.pawn.position.x > 40 && player.pawn.position.z > -1;
		} else {
			return false;
		}*/
		return false;
	}

	looseGame({ player }: { player: Player }): boolean {
		if (player && player.pawn) {
			return player.pawn.position.z < -20;
		} else {
			return false;
		}
	}

	onLoose({ player }: { player: Player }): void {
		console.warn('Puffyland | FE - You Loose !');
		player.move(this.initPos);
	}

	onWin({ player }: { player: Player }): void {
		console.warn('Puffyland | FE - You Won !');
		player.move(this.initPos);
	}

	update(dt: number) {
		if (this.data) {
			if (this.data.pois && this.data.pois.has(this.sphere)) {
				for (let p of this.data.pois.get(this.sphere)) {
					let domElement = document.getElementById(p.short);

					if (domElement) {
						//console.log(p);
						//domElement.style.top = "10%"
						if (this.fe && this.fe.camera) {
							var po = new THREE.Vector3(p.positionTitle[0], p.positionTitle[1], p.positionTitle[2]);
							var vector = po.project(this.fe.camera);
							vector.x = (vector.x + 1) / 2;
							vector.y = -(vector.y - 1) / 2;
							if (vector.z > 1) {
								vector.x = -20;
								vector.y = -20;
								if (this.currentSelection === p.short) {
									this.currentSelection = null;
									if (this.setPoi) {
										this.setPoi(null);
									}
								}
							}
							(p.positionScreen as THREE.Vector2).set(vector.x, vector.y);
							domElement.style.top = vector.y * 100 + '%';
							domElement.style.left = vector.x * 100 + '%';

							//if (p.short === 'p-bercy-') {
							let poiList = document.getElementById('poiList');

							if (true) {
								let vector = new THREE.Vector3(p.sprite.position.x, p.sprite.position.y, p.sprite.position.z).project(this.fe.camera);

								if (vector.z > 1 || p.disp === false) {
									p.sprite.scale.set(0, 0, 0);
									domElement.classList.remove('poiClickVisible');
								} else {
									let distanceTemp = new THREE.Vector2(vector.x, vector.y / 2).distanceTo(new THREE.Vector2(0, 0));

									let dist2d = Math.min(1, Math.max(0, (1 - distanceTemp * 2 * (this.zoom / this.zoomOriginal)) * 1.3));

									/*if(p.short === "p-bercy-"){
										console.log(dist2d)
									}*/

									let cylinderThickness = this.zoom / this.zoomOriginal;
									let actualLength = p.cylinder.scale.y / cylinderThickness;

									if (dist2d > 0.8) {
										actualLength += 0.1 * (50 * dt);
										if (actualLength > 1) {
											actualLength = 1;
										}

										if (poiList?.style.opacity !== '1') {
											cylinderThickness = 0;
											actualLength = 0;
										}

										p.cylinder.scale.set(cylinderThickness * 0.4, actualLength * (this.zoom / this.zoomOriginal), cylinderThickness * 0.4);
									} else {
										let cylinderThickness = this.zoom / this.zoomOriginal;

										actualLength -= 0.1 * (50 * dt);
										if (actualLength < 0 || poiList?.style.opacity !== '1') {
											actualLength = 0;
											cylinderThickness = 1;
										}

										p.cylinder.scale.set(cylinderThickness * 0.4, actualLength * (this.zoom / this.zoomOriginal), cylinderThickness * 0.4);
									}

									let temp3d = Math.max(dist2d, 0.8) * (p.distance3d / 10) * Math.min(1, this.zoom / this.zoomOriginal);
									// if (poiList?.style.opacity !== '1') {
									// 	temp3d = 0;
									// }
									if (vector.y > 0.65) {
										let scale = p.sprite.scale;
										scale.x = scale.x * 0.9;
										scale.y = scale.y * 0.9;
										scale.z = scale.z * 0.9;
										if (scale.x < 0.1) {
											scale.x = 0;
											scale.y = 0;
											scale.z = 0;
										}
										p.sprite.scale.set(scale.x, scale.y, scale.z);
									} else {
										p.sprite.scale.set(temp3d, temp3d, temp3d);
									}

									domElement.style.opacity = String(actualLength < 0.9 ? 0 : (actualLength - 0.9) * 10);

									//if (dist2d > 0.9) {
									domElement.classList.add('poiClickVisible');

									//} else {
									//	domElement.classList.remove('poiClickVisible');
									//}

									//console.log(temp3d)
								}

								//console.log(vector)

								//console.log(p)
							}
						}
					}
				}
			}
		}
	}

	displayLayer = (order: number) => {
		console.log('Display Layer ' + order);
		if (this.sphere) {
			if (this.sphereObjects.has(this.sphere)) {
				let actualDisp = this.sphereObjects.get(this.sphere).find((l: any) => l.order === order).display;
				this.sphereObjects.get(this.sphere).find((l: any) => l.order === order).display = !actualDisp;

				for (let layer of this.sphereObjects.get(this.sphere)) {
					for (let o of layer.objects) {
						if (layer.display === false) {
							o.visible = false;
						} else {
							o.visible = true;
						}
					}
				}
			}
		}
	};

	layerButton = (layer: any, container: any) => {
		let div = document.createElement('div');
		div.innerHTML = '' + layer.name.translations.find((t: any) => t.langage === this.lang)?.value;
		div.id = 'layerButton' + container.order;
		div.classList.add('layerButton');
		div.style.background = this.towerColor;
		div.style.border = '1px solid ' + this.towerColor;
		if (container.display === true) {
			div.classList.remove('unSelectedLayer');
		} else {
			div.classList.add('unSelectedLayer');
			div.style.border = '1px solid '+this.towerColor+' !important';
			//div.style.color = this.towerColor;
		}
		div.onclick = () => {
			if (layer.mstory) {
				let story = this.datas?.stories?.find((s: any) => s._id === layer.mstory);
				let channelName = 'main_' + this.lang;

				if (story) {
					let pathMovie = story.media.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === channelName);
					if (!pathMovie) {
						pathMovie = story.media.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'main');
					}

					pathMovie = pathMovie.path;

					let pathMovieFinal = pathMovie.split('\\')[1];
					if (!pathMovieFinal) {
						pathMovieFinal = pathMovie.split('/')[1];
					}

					let finalPathMovie = getApiUrl() + '/content/file/' + pathMovieFinal + '?play';

					this.setVideo(finalPathMovie);
				}
			} else {
				div.classList.toggle('unSelectedLayer');
				this.displayLayer(container.order);
			}
		};

		return div;
	};

	changeSphere = (sphere?: string) => {
		if (sphere) {
			this.sphere = sphere;
			let sphereId = this.datas.spheres.find((s: any) => s.short === sphere)._id;

			let layers: any = [];
			if (sphereId) {
				if (this.datas.mlayers && this.datas.mlayers.length > 0) {
					for (let layer of this.datas.mlayers.filter((l: any) => l.msphere === sphereId && l.status === 'active')) {
						layers.push(layer);
					}
				}
			}

			for (let s of this.sphereObjects.values()) {
				for (let l of s) {
					for (let o of l.objects) {
						this.fe?.scene.remove(o);
					}
				}
			}

			if (this.sphereObjects.has(sphere)) {
				for (let l of this.sphereObjects.get(sphere)) {
					for (let o of l.objects) {
						this.fe?.scene.add(o);
					}
				}
				for (let l of this.sphereObjects.get(sphere)) {
					if (l.display === false) {
						for (let o of l.objects) {
							o.visible = false;
						}
					}
				}
			}

			let layersElem = document.getElementById('layers');

			if (layersElem) {
				layersElem.innerHTML = '';
				for (let layer of layers.sort((l1: any, l2: any) => l1.order - l2.order)) {
					let sphereTmp = this.sphereObjects.get(sphere);

					if (sphereTmp) {
						layersElem.appendChild(
							this.layerButton(
								layer,
								sphereTmp.find((s: any) => s.order === layer.order),
							),
						); // LAYERS ACTIVATION
					}
				}
			}

			if (this.fe) {
				let fov = (this.fe.camera as THREE.PerspectiveCamera).getEffectiveFOV();
				this.setZoom(fov);
			}
		}
	};

	setZoom(z: number) {
		this.zoom = z;
		console.log('zoom ' + this.zoom);

		if (this.data) {
			if (this.data.pois && this.data.pois.has(this.sphere)) {
				for (let p of this.data.pois.get(this.sphere)) {
					this.updatePositionTitle(p);
				}
			}
		}
	}

	updatePositionTitle(p: any) {
		p.positionTitle[0] = p.position[0] + (p.positionTitleMax[0] - p.position[0]) * (this.zoom / this.zoomOriginal);
		p.positionTitle[1] = p.position[1] + (p.positionTitleMax[1] - p.position[1]) * (this.zoom / this.zoomOriginal);
		p.positionTitle[2] = p.position[2] + (p.positionTitleMax[2] - p.position[2]) * (this.zoom / this.zoomOriginal);

		//p.positionTitleMax = new THREE.Vector3(positionTitle[0], positionTitle[1], positionTitle[2]);
	}

	init(fe: FikscopeEngine) {
		//Scene
		log('init fe');

		this.fe = fe;

		let currentPlayer = null;

		if (fe.INatscope && fe.INatscope.natscope.me) {
			currentPlayer = this.newPlayer(fe, fe.INatscope.natscope.me.nick, fe.INatscope.natscope.me.id);
		} else {
			currentPlayer = this.newPlayer(fe, 'me', 'me');
		}

		if (currentPlayer.pawn) {
			this.actors.set('player', currentPlayer.pawn);
			this.actors.set('editor', currentPlayer.pawn);

			(currentPlayer.pawn as PSphere).level = this;

			if (currentPlayer.pawn.controller) {
				if (fe.inputManager) {
					fe.inputManager.setInputReceiver(currentPlayer.pawn.controller);
				}
			} else {
				console.error('No Controller for current player pawn');
			}
		}

		for (let sphere of this.spheres) {
			let sphereObjects = [];

			// BACKGROUND SPHERE
			let objects = [];
			const textureLeft = this.assets.find(
				(elem: any) => elem.name === sphere.short + '-left' + (sphere.short === 'chicago-districts' ? '_woLines' : ''),
			).file;
			const sphereLeftMaterial = new THREE.MeshStandardMaterial({
				//color: "#FFFFFF",
				transparent: false,
				side: THREE.DoubleSide,
				map: textureLeft,
			});

			const textureRight = this.assets.find(
				(elem: any) => elem.name === sphere.short + '-right' + (sphere.short === 'chicago-districts' ? '_woLines' : ''),
			).file;
			const sphereRightMaterial = new THREE.MeshStandardMaterial({
				//color: "#FFFFFF",
				transparent: false,
				side: THREE.DoubleSide,
				map: textureRight,
			});

			const sphereLeft = this.assets.find((elem: any) => elem.name === 'sphere360_left').file.clone();
			sphereLeft.traverse(function (child: any) {
				if ((child as THREE.Mesh).isMesh) {
					(child as THREE.Mesh).material = sphereLeftMaterial;
					if ((child as THREE.Mesh).material) {
						//((child as THREE.Mesh).material as THREE.MeshBasicMaterial).transparent = false
					}
				}
			});
			sphereLeft.scale.set(-1000, 1000, 1000);
			objects.push(sphereLeft);

			const sphereRight = this.assets.find((elem: any) => elem.name === 'sphere360_right').file.clone();
			sphereRight.traverse(function (child: any) {
				if ((child as THREE.Mesh).isMesh) {
					(child as THREE.Mesh).material = sphereRightMaterial;
					if ((child as THREE.Mesh).material) {
						//((child as THREE.Mesh).material as THREE.MeshBasicMaterial).transparent = false
					}
				}
			});
			sphereRight.scale.set(-1000, 1000, 1000);
			objects.push(sphereRight);

			sphereObjects.push({
				name: 'main',
				order: 0,
				orderz: 0,
				objects: objects,
				display: true,
			});

			// LAYERS SPHERES
			let layers: any = [];
			if (this.datas.mlayers && this.datas.mlayers.length > 0) {
				for (let layer of this.datas.mlayers.filter((l: any) => l.msphere === sphere._id && l.status === 'active')) {
					layers.push(layer);
				}
			}

			for (let layer of layers) {
				console.log('layer', layer);
				let objects = [];

				let texture1 = this.assets.find((elem: any) => elem.name === layer.short + '-left');
				let texture2 = this.assets.find((elem: any) => elem.name === layer.short + '-right');

				if (texture1 && texture2) {
					const textureLeft = this.assets.find((elem: any) => elem.name === layer.short + '-left').file;
					const sphereLeftMaterial = new THREE.MeshStandardMaterial({
						//color: "#FFFFFF",
						map: textureLeft,
						side: THREE.DoubleSide,
						transparent: true,
						alphaTest: 0.2,
					});

					const textureRight = this.assets.find((elem: any) => elem.name === layer.short + '-right').file;
					const sphereRightMaterial = new THREE.MeshStandardMaterial({
						//color: "#FFFFFF",
						map: textureRight,
						side: THREE.DoubleSide,
						transparent: true,
						alphaTest: 0.2,
					});

					const sphereLeft = this.assets.find((elem: any) => elem.name === 'sphere360_left').file.clone();
					sphereLeft.traverse(function (child: any) {
						if ((child as THREE.Mesh).isMesh) {
							(child as THREE.Mesh).material = sphereLeftMaterial;
							if ((child as THREE.Mesh).material) {
								//((child as THREE.Mesh).material as THREE.MeshBasicMaterial).transparent = true
							}
						}
					});
					sphereLeft.scale.set(-(1000 - (200 - layer.orderz)), 1000 - (200 - layer.orderz), 1000 - (200 - layer.orderz));
					objects.push(sphereLeft);

					const sphereRight = this.assets.find((elem: any) => elem.name === 'sphere360_right').file.clone();
					sphereRight.traverse(function (child: any) {
						if ((child as THREE.Mesh).isMesh) {
							(child as THREE.Mesh).material = sphereRightMaterial;
							if ((child as THREE.Mesh).material) {
								//((child as THREE.Mesh).material as THREE.MeshBasicMaterial).transparent = true
							}
						}
					});
					sphereRight.scale.set(-(1000 - (200 - layer.orderz)), 1000 - (200 - layer.orderz), 1000 - (200 - layer.orderz));
					objects.push(sphereRight);

					sphereObjects.push({
						name: layer.short,
						order: layer.order,
						orderz: layer.orderz,
						objects: objects,
						display: layer.default === true ? true : false,
					});
				} else if (layer.mstory) {
					sphereObjects.push({
						name: layer.short,
						order: layer.order,
						orderz: layer.orderz,
						objects: [],
						display: layer.default === true ? true : false,
					});
				}
			}

			// POIs SPHERE
			let poiObjects = [];
			const texturePoi = this.assets.find((elem: any) => elem.name === sphere.short + '-poi').file;

			texturePoi.wrapS = THREE.RepeatWrapping;
			texturePoi.wrapT = THREE.RepeatWrapping;
			texturePoi.repeat.set(1 / 8, 1 / 8);

			texturePoi.offset.x = 7 / 8;
			texturePoi.offset.y = 4 / 8;

			let pois = [];

			for (let p of this.editor ? this.tempPois : sphere.pois) {
				if (
					this.editor
						? p.published === 'active' && p.msphere && p.msphere.short == sphere.short && p.name?.translations[0].value
						: p.published === 'active'
				) {
					let temp = {
						id: p._id,
						short: p.short,
						mcard: p.mcard,
						name: p.name?.translations[0].value || 'Undefined', //p.name.translations[0].value,
						description: p.description || 'Undefined', //p.name.translations[0].value,

						angle: Number(p.angle),
						elevation: Number(p.elevation),
						offset: Number(p.offset),
						offsetangle: Number(p.offsetangle),
						offsetelevation: Number(p.offsetelevation),
						location: p.location.coordinates,
						origin: this.datas.location.coordinates,
						originElevation: this.datas.elevation,
						linex: Number(p.linex),
						liney: Number(p.liney),

						line: false,
						position: [0, 0, 0],
						positionTitle: [0, 0, 0],
						positionTitleMax: [0, 0, 0],

						positionScreen: new THREE.Vector2(-300, -300),

						distance3d: 0,
						distanceLine: 0,
						sprite: new THREE.Sprite(),
						cylinder: new THREE.Group(),
					};

					if (Number(p.linex) !== 0 || Number(p.liney) !== 0) {
						temp.line = true;
					}

					pois.push(temp);
				}
			}

			this.data.pois.set(sphere.short, pois);

			for (let p of pois) {
				let material = new THREE.SpriteMaterial({
					map: texturePoi,
					color: 0xffffff,
					transparent: true,
					alphaTest: 0.2,
				});
				let sprite = new THREE.Sprite(material);
				sprite.name = p.short;
				poiObjects.push(sprite);
				p.sprite = sprite;

				let originLatLon = new LatLon(p.origin[1], p.origin[0]);
				let distance = originLatLon.distanceTo(new LatLon(p.location[1], p.location[0]));
				let angleTemp = -Number(p.angle) + Number(37) + Number(p.offsetangle);

				//console.log(distance)

				let degToRad = function (deg: number) {
					return deg * (Math.PI / 180.0);
				};

				let position = [0, 0, 0];
				let positionTitle = [0, 0, 0];

				position[0] = -Math.cos(degToRad(angleTemp)) * distance;
				position[1] = -Number(p.originElevation) + Number(p.elevation) + Number(p.offsetelevation); // +_this.pois[i].alt
				position[2] = Math.sin(degToRad(angleTemp)) * distance;

				positionTitle[0] = -Math.cos(degToRad(angleTemp - Number(p.linex))) * distance;
				positionTitle[1] =
					-Number(p.originElevation) + Number(p.elevation) + Number(p.offsetelevation) + distance * Math.tan(degToRad(Number(p.liney))); //Number(p.liney) * 10; // +_this.pois[i].alt
				positionTitle[2] = Math.sin(degToRad(angleTemp - Number(p.linex))) * distance;

				let distanceLine = new THREE.Vector3(position[0], position[1], position[2]).distanceTo(
					new THREE.Vector3(positionTitle[0], positionTitle[1], positionTitle[2]),
				);

				p.distanceLine = distanceLine;

				let distance3d = new THREE.Vector3(position[0], position[1], position[2]).distanceTo(new THREE.Vector3(0, 0, 0));
				let scale3d = distance3d / 10;
				p.distance3d = distance3d;

				if (sprite) {
					sprite.scale.set(scale3d, scale3d, scale3d);
					sprite.position.set(position[0], position[1], position[2]);
				}

				p.position = [position[0], position[1], position[2]];

				const group = new THREE.Group();
				group.name = p.short;
				group.position.set(position[0], position[1], position[2]);
				poiObjects.push(group);

				const g = new THREE.CylinderGeometry(0.03 * scale3d, 0.03 * scale3d, distanceLine, 8);
				const m = new THREE.MeshBasicMaterial({ transparent: true, color: new THREE.Color(this.towerColor), opacity: 0.8 });
				const cylinder = new THREE.Mesh(g, m);
				cylinder.position.set(0, 0 + distanceLine / 2, 0);
				p.cylinder = group;
				group.add(cylinder);

				p.positionTitle = [positionTitle[0], positionTitle[1], positionTitle[2]];
				p.positionTitleMax = [positionTitle[0], positionTitle[1], positionTitle[2]];

				group.lookAt(new THREE.Vector3(positionTitle[0], positionTitle[1], positionTitle[2]));
				group.rotateX(degToRad(90));

				this.updatePositionTitle(p);
			}

			sphereObjects.push({
				name: 'pois',
				order: 0,
				orderz: 100,
				objects: poiObjects,
				display: true,
			});

			this.sphereObjects.set(sphere.short, sphereObjects);
		}

		this.setData(this.data);

		//Lighting
		var ambient = new THREE.AmbientLight(0xffffff, 1);
		fe.scene.add(ambient);
		var directionalLight = new THREE.DirectionalLight(0xffffff, 0);
		fe.scene.add(directionalLight);

		log('Magnicity | Map - sphere loaded !');
	}
}
