// CSS
import './legals.css';

export const Legals = (props: { language: string, goBack: any }) => {
	return (
		<div className='legals-topContainer'>
			<div className='videoStory-top' style={{
                position: 'fixed'
            }}>
				<div
					className='videoStory-back'
					onClick={() => {
						props.goBack();
					}}
				></div>
			</div>
			{props.language === "de" ?
                    <div>
                        <div className='credits-textSubtitle'>IMPRESSUM{"\n"}</div>
                        <div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>
                            Herausgeber der Anwendung Magnicity ist das Unternehmen Timescope SAS, eine vereinfachte Aktiengesellschaft mit
                            einem Kapital in Höhe von 2432 €, eingetragen im Handels- und Gesellschaftsregister von Paris unter der SIREN-
                            Nummer 812525780, mit Sitz in 87 Boulevard Saint Michel, 75005 Paris, Frankreich.</div>
                            <div style={{ margin: '0 0 16px 0' }}>Umsatzsteueridentnummer: FR41812525780.</div>
                            <div style={{ margin: '0 0 16px 0' }}>Verantwortlich für die Veröffentlichung: Adrien Sadaka, Vorstandsvorsitzender von Timescope SAS, erreichbar unter
                            der Rufnummer 0680128926 oder der E-Mail-Adresse adrien@timescope.co.</div>
                            <div style={{ margin: '0 0 16px 0' }}>Informationen über die Erfassung und Verarbeitung personenbezogener Daten (Richtlinie und Erklärung) finden Sie
                            in der Datenschutzerklärung der Anwendung.</div>
                        </div>
                        <div className='legals-update'>Letzte Aktualisierung: 16. April 2021</div>
                        <div style={{ width: '100%', height: '64px' }}></div>
                    </div>
                :
                    <div>
                        <div className='credits-textSubtitle'>MENTIONS LÉGALES{"\n"}</div>
                        <div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>
                            L&#39;application Magnicity est éditée par Timescope SAS, au capital de 2432 €, entreprise répertoriée dans SIRENE sous le
                            numéro d’immatriculation 812525780 auprès du RCS de Paris et dont le siège social est sis au 87 Boulevard Saint Michel
                            , 75005 Paris .</div>
                            <div style={{ margin: '0 0 16px 0' }}>N° de TVA intracommunautaire : FR41812525780.</div>
                            <div style={{ margin: '0 0 16px 0' }}>Directeur de la publication :</div>
							<div style={{ margin: '0 0 16px 0' }}>Magnicity Muriel Lemesre, CMO Magnicity, joignable à l&#39;adresse
                            marketing@m56group.com.</div>
							<div style={{ margin: '0 0 16px 0' }}>Adrien Sadaka, Président de Timescope SAS, joignable à l&#39;adresse
                            hello@timescope.co.</div>
                            <div style={{ margin: '0 0 16px 0' }}>Les informations concernant la collecte et le traitement des données personnelles (politique et déclaration) sont
                            fournies dans la charte de données personnelles de l&#39;application.</div>
                        </div>
                        <div className='legals-update'>Dernière mise à jour : 16 avril 2021</div>
                        <div style={{ width: '100%', height: '64px' }}></div>
                    </div>
                }
		</div>
	);
};
