// React
import { createReducer } from '@reduxjs/toolkit';
// Types
import { QUIZ_PROGRESS, IAction } from '../actions/types';

export interface IQuizState {
	progress: any;
}

const initialState: IQuizState = {
	progress: [],
};

export const reducer = createReducer(initialState, (builder) => {
	builder.addCase(QUIZ_PROGRESS, (state, action: IAction) => {
		state.progress = action.payload;
	});
});
