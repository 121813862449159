// Natscope imports
import { User, Peer } from "./user";
import { SocketInterface } from "./socketInterface"
import { PeerConnect } from "./signaling";
import { replacer, reviver, SocketId, NatEvent, customRoomEvent, chatMessageEmit, eventEmit } from "./utils";
import {
  Cmd,
  DataType,
  Command,
  answer_sCmd,
  delete_sCmd,
  send_safeMessage,
  send_safeEvent,
  send_safeCmd,
  send_data,
  send_cmd,
  Moving,
} from "./messaging";
import { log, sockRcv, dataChanRcv } from "./logMode";
import { myCustomEvent } from "./client";

// Create natRoom.tsx file ↓

export type room_cons_type = {
  chief: User,
  creator: User,
  name: string,
  type: string,
  users?: Array<User>,
  nbPeer?: number,
  fromServer?: boolean,
  serverChief?: boolean
};

export type peerRoom_cons_type = {
  chief: User,
  creator: User,
  name: string,
  type: string,
  users?: Array<User>,
  socketInterface: SocketInterface,
  fromServer: boolean,
  serverChief?: boolean,
  webRTC: boolean
};

export type chiefRoom_cons_type = {
  chief: User,
  creator: User,
  name: string,
  type: string,
  socketInterface: SocketInterface,
  fromServer: boolean,
  serverChief?: boolean
}

//When a User join a room
export type T_onUserJoin = { user: User, room: Room };
//When a User leave a room
export type T_onUserLeave = { user: User, room: Room };
//When receiving position
export type T_onUserPos = Moving;
//When receiving data
export type T_onData = DataType;
//When receiving chat message
export type T_onChatMessage = { sender: User, created: Date, updated?: Date, data: string };
export class Room {

  public events: Map<string, customRoomEvent<any, any>>
  public chief: User;
  public creator: User;
  public name: string;
  public nbPeer: number;
  public onAir: boolean;
  public map: any;
  public type: string;
  public users: Array<User>;
  public fromServer: boolean;
  public serverChief: boolean;
  public chat: Array<T_onChatMessage>;

  public onUserJoin: NatEvent<T_onUserJoin, any>
  public onUserLeave: NatEvent<T_onUserLeave, any>
  public onUserPos: NatEvent<T_onUserPos, any>
  public onData: NatEvent<T_onData, any>
  public onChatMessage: NatEvent<T_onChatMessage, any>

  public constructor({
    chief,
    creator,
    name,
    type = 'notype',
    users,
    fromServer = false,
    serverChief = false,
    nbPeer = 0
  }: room_cons_type) {

    this.events = new Map<string, customRoomEvent<any, any>>();

    this.onUserJoin = new NatEvent();
    this.onUserLeave = new NatEvent();
    this.onUserPos = new NatEvent();
    this.onData = new NatEvent();
    this.onChatMessage = new NatEvent();

    this.map = null;
    this.chief = chief;
    this.creator = creator;
    this.name = name;
    this.type = type;
    this.nbPeer = nbPeer;
    this.onAir = false;
    this.chat = [];
    users ? this.users = users : this.users = [creator];
    this.fromServer = fromServer;
    this.serverChief = serverChief;
  }

  public addEvent({ eventName, type, target = "" }: myCustomEvent) {
    this.events.set(eventName, new customRoomEvent(type, target));
  }

}

export class ChiefRoom extends Room {
  public peers: Map<SocketId, Peer>;
  public socketInterface: SocketInterface;

  public constructor({
    chief,
    creator,
    name = '',
    type = 'notype',
    socketInterface,
    fromServer
  }: chiefRoom_cons_type) {
    if (name === '') {
      super({ chief, creator, name: chief.nick + '_room', type });
    } else {
      super({ chief, creator, name, type });
    }
    this.peers = new Map<SocketId, Peer>();
    this.users = [creator];
    this.socketInterface = socketInterface;
    this.fromServer = fromServer;

    initRoomSocketListeners(socketInterface, this);
  }

  public addConnection(p: User, s: SocketInterface) {
    log(...sockRcv, this.name + " Connecting with " + p.nick);

    if (p.webRTC) {
      const newPeer = new Peer({
          id: p.id,
          socketInterface: s,
          nick: p.nick,
          groups: p.groups,
          fromServer: this.fromServer
      });
      const pc = newPeer.getPeerConnect();

      pc.peerConnection.ondatachannel = (e) => {
        log(...sockRcv, this.name + ": DataChannel received from " + p.nick);
        pc.peerSocketId = p.id;
        pc.dataChannel = e.channel;
        pc.dataChannel.onopen = (e) => this.dataChannelOpened(e, p.nick);
        pc.dataChannel.onclose = (e) => this.dataChannelClosed(e, p.nick);
        pc.dataChannel.onmessage = (e) => this.dataChannelOnMessage(e, newPeer);

        //send_cmd(newPeer, new Cmd("join", this.chief));
        this.forward_cmd(p.id, new Cmd("join", p));
      };

      pc.connect();
      this.peers.set(p.id, newPeer);
    }

    // still useful ?
    // this.onUserJoin.emit({ user: p, room: this });
  }

  public callEvent(eventName: string, target: string, ...args: any) {
    const myEvent = this.events.get(eventName);
    if (myEvent) {
      eventEmit(this, eventName, myEvent, target, args);
    }
  }

  private handleCmd(msg: Cmd, fromPeer: Peer) {
    if (msg.cmd === "move") {
      this.onMove(msg.data as Moving);
    } else if (msg.cmd === "msg") {
      log(...dataChanRcv, "Received message :", msg.data);
      const toSend = JSON.stringify({
        sender: {
          nick: fromPeer.nick,
          id: fromPeer.id,
        },
        data: msg.data,
      }, replacer);
      this.broadcastMSG(toSend);
    } else if (msg.cmd === "data") {
      log(...sockRcv, "Received data :", msg.data);
      this.onData.emit(msg.data);
    } else if (msg.cmd === "event") {
      const data = JSON.parse(msg.data);
      const myEvent = this.events.get(data.eventName);

      if (myEvent && myEvent.type !== 'unreplicated') {
        myEvent.emit(data.args);
      }

      eventEmit(this, data.eventName, data.type, data.args);
    } else {
      console.warn("NV - Message data object not recognized…");
      log(msg.data);
    }
  }

  private dataChannelOnMessage(e: MessageEvent, fromPeer: Peer) {
    try {
      let msg = JSON.parse(e.data, reviver);
      /* console.warn(msg); */

      //For the moment SafeMessages handle only string data
      if (msg.hasOwnProperty("token")) {
        // log(nick + "[Peer]: get SafeMessage = " + e.data);
        //Receiving accuse
        if (msg.received) {
          delete_sCmd(msg.token);
        } else {
          //Sending accuse
          answer_sCmd(fromPeer, msg);
          //What to do with safe message data ?
          this.handleCmd(msg as Cmd, fromPeer);
        }
        //Normal messaging
      } else {
        /* log("Receiving not SafeMessage") */
        msg = msg.cmd as Cmd;
        this.handleCmd(msg, fromPeer);
        // log(nick + "[Peer]: get Message = " + e.data);
      }
    } catch (error) {
      log("NV - Brut message received :" + e.data);
    }
  }

  private onMove(data: Moving) {
    // log("Received position :");
    // log(data);
    this.onUserPos.emit(data)
  }

  private dataChannelClosed(_: Event, nick: string) {
    console.warn(nick + ": [Chief] dataChannel closed !");
    this.onAir = false;
  }

  private dataChannelOpened(_: Event, nick: string) {
    console.warn(nick + ": [Chief] dataChannel opened !");
    this.onAir = true;
  }

  public disconnect_peer(pid: SocketId) {
    if (this.peers.has(pid)) {
      const leaver = this.peers.get(pid);
      if (leaver) {
        this.peers.delete(pid);
        log(leaver.nick + " has disconnected");
        this.onUserLeave.emit({ user: leaver, room: this })
      }
    }
  }

  public broadcastMSG(msg: string) {
    try {
      chatMessageEmit(this, msg);
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.error(err.message);
      }
    }
  }

  public broadcastData(data: any) {
    this.peers.forEach((p) => send_data(p, data));
  }

  public safeBroadcastData(data: DataType) {
    this.peers.forEach((p) => send_safeCmd(p, "data", data));
  }

  public forward_data(fromid: SocketId, data: DataType) {
    this.peers.forEach((p) => {
      if (p.id !== fromid) send_data(p, data);
    });
  }

  public forward_cmd(fromid: SocketId, cmd: Cmd) {
    this.peers.forEach((p) => {
      if (p.id !== fromid) send_cmd(p, cmd);
    });
  }

  public send_to(pid: string, data: DataType, cmd: Command = undefined) {
    if (this.peers.has(pid)) {
      const peerFound = this.peers.get(pid);
      if (peerFound) {
        const p = peerFound;
        send_safeCmd(p, cmd, data);
      } else {
        console.warn('could not find peer');
      }
    } else console.error("User " + pid + " not found !");
  }

  public getPC(pid: SocketId) {
    const foundPeer = this.peers.get(pid);
    if (foundPeer) {
      return foundPeer.getPeerConnect();
    } else {
      console.error('could not get peer');
    }
  }
}

export class PeerRoom extends Room {
  public chiefPeer: Peer | undefined;
  public chief: User;
  public socketInterface: SocketInterface;
  public webRTC: boolean;

  public constructor({
    chief,
    creator,
    name = '',
    type = 'notype',
    socketInterface,
    users,
    webRTC,
    fromServer
  }: peerRoom_cons_type) {
    super({ chief, creator, name, type });
    if (users) this.users = users;

    this.chief = chief;
    this.socketInterface = socketInterface;
    this.fromServer = fromServer;
    this.webRTC = webRTC;

    if (webRTC) {
      this.chiefPeer = new Peer({
          id: chief.id,
          socketInterface: socketInterface,
          nick: chief.nick,
          groups: chief.groups,
          fromServer: this.fromServer
      });
      const pc = this.chiefPeer.getPeerConnect();

      pc.dataChannel.onopen = (e) => this.dataChannelOpened(e, this.chief.nick);
      pc.dataChannel.onclose = (e) => this.dataChannelClosed(e, this.chief.nick);
      pc.dataChannel.onmessage = (e) => this.dataChannelOnMessage(e, this.chief.nick);
    } else {
      initRoomSocketListeners(socketInterface, this);
    }
  }

  public callEvent(eventName: string, target: string, ...args: any) {
    const toSend = JSON.stringify({ eventName: eventName, args: args })
    if (this.chiefPeer) {
      send_safeEvent(this.chiefPeer, toSend);
    } else {
      const myEvent = this.events.get(eventName);
      if (myEvent) {
        eventEmit(this, eventName, myEvent, target, args);
      }
    }
  }

  public broadcastMSG(msg: string) {
    if (this.chiefPeer) {
      send_safeMessage(this.chiefPeer, msg);
    } else {
      chatMessageEmit(this, msg);
    }
  }

  public forward_data(_: SocketId, data: any) {
    if (this.chiefPeer) send_data(this.chiefPeer, data);
  }

  public forward_cmd(_: SocketId, cmd: Cmd) {
    if (this.chiefPeer) send_cmd(this.chiefPeer, cmd);
  }

  public broadcastData(data: any) {
    if (this.chiefPeer) send_data(this.chiefPeer, data);
  }

  public getPC(_: SocketId = ""): PeerConnect | void {
    if (this.chiefPeer) return this.chiefPeer.getPeerConnect();
  }

  private handleCmd(msg: Cmd) {
    if (msg.cmd === "move") {
      // log(nick + "[Peer] Received position :", msg.data);
      const moving = msg.data as Moving;
      //Only receiving from chief
      //we should have a corresponding nick with the position

      // console.warn('onUserPos PeerRoom', data);

      this.onUserPos.emit(moving);

    } else if (msg.cmd === "join") {
      log(...sockRcv, "Received new player :", msg.data);
      const user = msg.data as User;

      // still useful ?
      // this.onUserJoin.emit({ user: user, room: this });

      this.users.push(user);
    } else if (msg.cmd === "msg") {
      log(...sockRcv, "Received message :", msg.data);
      try {
        const toSend = JSON.parse(msg.data as string);

        const toSendChat = {
          sender: {
            id: toSend.sender.id,
            nick: toSend.sender.nick,
            groups: toSend.sender.groups
          },
          created: new Date(),
          data: toSend.data,
        };

        this.chat.push(toSendChat);

        this.onChatMessage.emit(toSendChat);

      } catch (err) {
        console.error(err);
      }
    } else if (msg.cmd === "data") {
      log(...sockRcv, "Received data :");
      // console.warn(msg.data)
      this.onData.emit(msg.data)

    } else if (msg.cmd === "event") {
      const data = JSON.parse(msg.data);
      const myEvent = this.events.get(data.eventName);
      if (myEvent) {
        myEvent.emit(data.args);
      }
    } else {
      console.warn("NV - Message data object not recognized…");
      log(msg.data);
    }
  }

  //Second arg is nick of user sending
  private dataChannelOnMessage(e: MessageEvent, _: string) {
    try {
      let msg = JSON.parse(e.data, reviver);

      //For the moment SafeMessages handle only string data
      if (msg.hasOwnProperty("received")) {
        // log(nick + "[Peer]: get SafeMessage = " + e.data);
        //Receiving accuse
        if (msg.received) {
          delete_sCmd(msg.token);
        } else {
          //Sending accuse
          if (this.chiefPeer) answer_sCmd(this.chiefPeer, msg);
          //What to do with safe message data ?
          this.handleCmd(msg as Cmd)
        }
        //Normal messaging
      } else {
        msg = msg.cmd as Cmd;
        //What to do when receiving Moving info
        this.handleCmd(msg)
        // log(nick + "[Peer]: get Message = " + e.data);
      }
    } catch (error) {
      console.error(error);
      console.warn("NV - Brut message received :" + e.data);
    }
  }

  private dataChannelClosed(_: Event, nick: string) {
    console.warn("NV - " + nick + ": [Peer] dataChannel closed !");
    this.onAir = false;
  }

  private dataChannelOpened(_: Event, nick: string) {
    console.warn("NV - " + nick + ": [Peer] dataChannel opened !");
    this.onAir = true;
  }
}

const initRoomSocketListeners = (socketInterface: SocketInterface, _this: PeerRoom | ChiefRoom) => {
  socketInterface.on('onChatMessage', (data) => {
    log(sockRcv, 'onChatMessage');
    _this.chat.push(data);
    if (!_this.fromServer) {
      _this.onChatMessage.emit(data);
    }
  });

  // socketInterface.on('onMove', (moving) => {
  //   log(sockRcv, 'onMove');
  //   if (!_this.fromServer) {
  //     _this.onUserPos.emit(moving);
  //   }
  // });

  socketInterface.on('onEvent', (event) => {
    const myEvent = _this.events.get(event.eventName);
    if (!_this.fromServer && myEvent) {
      myEvent.emit(event.args);
    }
  });
}