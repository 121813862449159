// CSS
import './credits.css';

export const Credits = (props: { language: string; goBack: any }) => {
	return (
		<div className='credits-topContainer'>
			<div
				className='videoStory-top'
				style={{
					position: 'fixed',
				}}
			>
				<div
					className='videoStory-back'
					onClick={() => {
						props.goBack();
					}}
				></div>
			</div>
			{props.language === 'de' ? (
				<div>
					<div className='credits-textSubtitle'>QUELLENANGABEN{'\n'}</div>
					<div className='credits-textBold'>PARIS</div>
					<div className='credits-text'>
						<div>Story Weltausstellung:</div>
						<div>
							Library of Congress (Usa), Universitäts und Landesbibliothek Darmstadt (All), Paris Musées, Rijksmuseum (Pays-Bas), USMC PHOTOGRAPH
							Archives and Special Collections (Usa) , BNF (France), AD92 (France),DR
						</div>
					</div>
					<div className='credits-text'>
						<div>Story Tour Montparnasse : :</div>
						<div>Ina, GaumontPathé Archives, Magnicity</div>
					</div>
					<div className='credits-text'>
						<div>Story Viertel Montparnasse:</div>
						<div>BNF, Paris Musées, Koninklijke Bibliotheek, GaumontPathé Archives .</div>
					</div>
					<div className='credits-textBold'>BERLIN</div>
					<div className='credits-text'>
						<div>Story Berliner Mauer:</div>
						<div>
							National Archives Records Administration (USA), Transit Films Bundesarchiv (All), Mémorial du mur de Berlin (All), PIXABAY and
							Wikimedias CC OTFW
						</div>
					</div>
					<div className='credits-text'>
						<div>Story Rundgang:</div>
						<div>
							Magnicity, Karl-Heinz Kraemer, Alpha Press GmbH ,Deutsche Post, Karl Anton, www.visitBerlin (CC BY-SA 3.0), Festival of
							Lights-Zander Partner (CC BY-SA 3.0),Wikimedias CC Schreibschaf (Mathias Lück)/ ProhibitOnions/ Taxiarchos228 Bundesarchiv, Bild
							183-E1216-0042-001Ulricj Kohls.
						</div>
					</div>
					<div className='credits-text'>
						<div>Story Die 20er Jahre:</div>
						<div>
							National Archives Records Administration (USA), Berlin, symphonie d&#39;une grande ville, 1927 (Walther Rutthman
							1887-1941)archive.org
						</div>
					</div>
					<div className='credits-text'>
						<div>Hotsposts {'&'} filters : </div>
						<div>
							STUDIO KOHLMEIR studio kohlmeier / studiokohlmeier.com CC BY-SA 1.0 fi / CC BY-SA 3.0 de Tomas Val on Unsplash Mihail Macri on
							Unsplash
						</div>
					</div>
					<div className='credits-textBold'>ROTTERDAM</div>
					<div className='credits-text'>
						<div>Story Rundgang:</div>
						<div>Magnicity,Pixabay,Wikimedias CC Hanno Lans, Dennis Jarvis,</div>
					</div>
					<div className='credits-text'>
						<div>Story Krieg:</div>
						<div>
							Nederlands Instituut voor Militaire Historie, Nederlands Instituut voor Beeld en Geluid (0penbeelden), Rijksdienst voor het
							Cultureel Erfgoed (CC),National Archives Records Administration (USA), Nederlands Instituut voor Beeld en Geluid, Rijksdienst voor
							het Cultureel Erfgoed (CC), Magnicity. Wikimedias CC Mascdman, Rokus, Gabriele Giuseppini,
						</div>
					</div>
					<div className='credits-text'>
						<div>Story Architektur:</div>
						<div>
							Magnicity, Pixabay,MRDV,,OMA, @Ossip van Duivenbode,@Iris van den Broek, Wikimedias CC W. Bulach, Carlos Zinato on Unsplash, Hanno
							Lans, Rob Oo, GraphyArchy, F.Eveleens, Raul Ayres, Fred Romero, Ben Bender, Timelezz, Sandra Fauconnier, Massimo Catarinella
						</div>
					</div>
					<div className='credits-textBold'>CHICAGO</div>
					<div className='credits-text'>
						<div>Story Der große Brand:</div>
						<div>Chicago History Museum/ Chicago Historical Society/Library of Congress/</div>
					</div>
					<div className='credits-text'>
						<div>Hancock Center :</div>
						<div>
							Magnicity,Chicago History Museum,Chicago Historical Society, Wikimedias CC Conal Gallagher, Alvesgaspar, James Willamor, Joe Ravi,
							Piotrus, Michael Freitag
						</div>{' '}
					</div>
					<div className='credits-text'>
						<div>Story Lake Michigan:</div>
						<div>
							Magnicity,Library of Congress, Chicago History Museum, NOAA, Chicago Office of Emergency Management and Communications, MRCC
							Illinois Edu Storm, Pxhere, Pixabay, Wikimedias CC Conal Gallagher, James Willamor, R. Boed,TacoBeans44 on Reddit, DR
						</div>
					</div>
					<div style={{ width: '100%', height: '64px' }}></div>
				</div>
			) : (
				<div>
					<div className='credits-textSubtitle'>Credits</div>
					<div className='credits-textBold'>Paris :</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Exposition Universelle :</div>
						<div>
							Library of Congress (Usa), Universitäts und Landesbibliothek Darmstadt (All), Paris Musées, Rijksmuseum (Pays-Bas), USMC PHOTOGRAPH
							Archives and Special Collections (Usa) , BNF (France), AD92 (France),DR
						</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Tour Montparnasse :</div>
						<div>Ina, GaumontPathé Archives, Magnicity</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Quartier Montparnasse :</div>
						<div>BNF, Paris Musées, Koninklijke Bibliotheek, GaumontPathé Archives .</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Unsplash, Google/creative Commons, Shutterstock</div>
					</div>

					<div className='credits-textBold'>Berlin :</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Mur :</div>
						<div>
							National Archives Records Administration (USA), Transit Films Bundesarchiv (All), Mémorial du mur de Berlin (All), PIXABAY and
							Wikimedias CC OTFW
						</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Tour :</div>
						<div>
							Magnicity, Karl-Heinz Kraemer, Alpha Press GmbH ,Deutsche Post, Karl Anton, www.visitBerlin (CC BY-SA 3.0), Festival of
							Lights-Zander Partner (CC BY-SA 3.0),Wikimedias CC Schreibschaf (Mathias Lück)/ ProhibitOnions/ Taxiarchos228 Bundesarchiv, Bild
							183-E1216-0042-001Ulricj Kohls.
						</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Story Années Folles :</div>
						<div>
							National Archives Records Administration (USA), Berlin, symphonie d&#39;une grande ville, 1927 (Walther Rutthman
							1887-1941)archive.org
						</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Hotsposts {'&'} filters :</div>
						<div>
							STUDIO KOHLMEIR studio kohlmeier / studiokohlmeier.com CC BY-SA 1.0 fi / CC BY-SA 3.0 de Tomas Val on Unsplash Mihail Macri on
							Unsplash
						</div>
					</div>

					<div className='credits-textBold'>Rotterdam :</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Tour :</div>
						<div>Magnicity,Pixabay,Wikimedias CC Hanno Lans, Dennis Jarvis,</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Guerre :</div>
						<div>
							Nederlands Instituut voor Militaire Historie, Nederlands Instituut voor Beeld en Geluid (0penbeelden), Rijksdienst voor het
							Cultureel Erfgoed (CC),National Archives Records Administration (USA), Nederlands Instituut voor Beeld en Geluid, Rijksdienst voor
							het Cultureel Erfgoed (CC), Magnicity. Wikimedias CC Mascdman, Rokus, Gabriele Giuseppini,
						</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Architecture :</div>
						<div>
							Magnicity, Pixabay,MRDV,,OMA, @Ossip van Duivenbode,@Iris van den Broek, Wikimedias CC W. Bulach, Carlos Zinato on Unsplash, Hanno
							Lans, Rob Oo, GraphyArchy, F.Eveleens, Raul Ayres, Fred Romero, Ben Bender, Timelezz, Sandra Fauconnier, Massimo Catarinella
						</div>
					</div>
					<div className='credits-textBold'>Chicago :</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Incendie :</div>
						<div>Chicago History Museum/ Chicago Historical Society/Library of Congress/</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Hancock Center :</div>
						<div>
							Magnicity,Chicago History Museum,Chicago Historical Society, Wikimedias{'\n'}
							CC Conal Gallagher, Alvesgaspar, James Willamor, Joe Ravi, Piotrus, Michael{'\n'}
							Freitag
						</div>
					</div>
					<div className='credits-text'>
						<div style={{ margin: '0 0 16px 0' }}>Lake Michigan :</div>
						<div>
							Magnicity,Library of Congress, Chicago History Museum, NOAA, Chicago{'\n'}
							Office of Emergency Management and Communications, MRCC Illinois Edu{'\n'}
							Storm, Pxhere, Pixabay, Wikimedias CC Conal Gallagher, James Willamor, R.{'\n'}
							Boed,TacoBeans44 on Reddit, DR
						</div>
					</div>
					<div style={{ width: '100%', height: '64px' }}></div>
				</div>
			)}
		</div>
	);
};
