// React
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
// Redux
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { setPlace, setTowerColor, setSensors } from '../../actions/user';
import { setProgressSound, setReadySound } from '../../actions/sounds';
import { useCookies } from 'react-cookie';
// CSS
import './magnicity.css';
// Components
import { IntroScreen } from '../intro/IntroScreen';
import { Explorer } from '../explorer/Explorer';
// Utils
import { Loader } from '../../sphere/loader/loader';
import { trads } from '../../datas/trads';
import { Cookies } from '../cookies/Cookies';

const places = ['montparnasse', 'berlin', 'chicago', 'rotterdam'];

export const Magnicity = () => {
	const navigate = useNavigate();
	const dispatch: Dispatch<any> = useDispatch();

	const [sound, setSound] = useState(true);

	const { place: placeUrl} = useParams();
	const [place, setLocalPlace] = useState('');

	const { progress } = useSelector((state: any) => state.quiz);
	const { language, towerColor, sensors } = useSelector((state: any) => state.user);
	const sounds = useSelector((state: any) => state.sounds);

	const [cookies, setCookie/*, removeCookie*/] = useCookies(['cookiesAllowed']);
	const [cookiePopup, setCookiePopup] = useState(true);

	useEffect(() => {
		//console.log(sounds)
		//console.log("YES")
	}, [sounds]);

	let askSensors = () => {
		function isSafari() {
			if (navigator.userAgent.match('CriOS')) {
				return false;
			}

			let test = navigator.vendor.match(/[Aa]+pple/g);

			if (test) {
				if (test.length > 0) {
					return true;
				}
			}

			return false;
		}

		if (isSafari()) {
			if (window.matchMedia('(pointer:coarse)').matches) {
				// alert("is safari");

				(DeviceMotionEvent as any)
					.requestPermission()
					.then((response: any) => {
						if (response === 'granted') {
							dispatch(setSensors(true));
						} else {
							dispatch(setSensors(false));
						}
					})
					.catch((err: any) => {
						dispatch(setSensors(false));
					});
			} else {
				dispatch(setSensors(false));
			}
		} else {
			//alert("not safari");

			if ((DeviceMotionEvent as any).hasOwnProperty('requestPermission')) {
				(DeviceMotionEvent as any)
					.requestPermission()
					.then((response: any) => {
						if (response === 'granted') {
							dispatch(setSensors(true));
						} else {
							dispatch(setSensors(false));
						}
					})
					.catch((err: any) => {
						dispatch(setSensors(false));
					});
			} else {
				dispatch(setSensors(true));
			}
		}
	};

	useEffect(() => {
		let sensorsElem = document.getElementById('sensors') as HTMLInputElement;

		if (sensors === true) {
			if (sensorsElem) {
				sensorsElem.style.opacity = '1';
				sensorsElem.value = 'true';
			}
		} else {
			if (sensorsElem) {
				sensorsElem.value = 'false';
			}
		}
	}, [sensors]);

	let _next = useRef(() => {
		askSensors();
		let langElem = document.getElementById('introScreen-langSelectionContent');
		let loadingElem = document.getElementById('introScreen-langSelectionContent-loading');

		if (langElem && loadingElem) {
			langElem.style.opacity = '0';
			loadingElem.style.opacity = '1';
		}
	});

	useEffect(() => {
		let intervalPercent: NodeJS.Timer;

		let url = window.location.href;
		let placeTmp = 'montparnasse';

		if (url.includes('app.360chicago.com')) {
			placeTmp = 'chicago';
		} else if (url.includes('app.tourmontparnasse56.com')) {
			placeTmp = 'montparnasse';
		} else if (url.includes('app.tv-turm.de')) {
			placeTmp = 'berlin';
		} else if (url.includes('app.euromast.nl')) {
			placeTmp = 'rotterdam';
		} else {
			if (!placeUrl || placeUrl === '' || !places.includes(placeUrl)) {
				navigate('/montparnasse', { replace: true });
				window.location.reload();
			} else {
				placeTmp = placeUrl;
			}
		}

		let loader = new Loader(dispatch, placeTmp, setDatas, progress);

		intervalPercent = setInterval(() => {
			if (loader.progress >= 100) {
				clearInterval(intervalPercent);
				_next.current = () => {
					askSensors();

					let introScreenElem = document.getElementById('introScreen');

					if (introScreenElem) {
						introScreenElem.style.opacity = '0';

						let sphereElem = document.getElementById('sphere');

						if (sphereElem) {
							sphereElem.style.opacity = '1';
							sphereElem.style.pointerEvents = 'all';
						}

						setTimeout(() => {
							setPage('explorer');
						}, 1000);
					}
				};
			} else {
				let percentLoad = document.getElementById('introScreen-langSelectionContent-loadingAmountInside');

				if (percentLoad) {
					percentLoad.style.width = loader.progress + '%';
				}
			}
		}, 1000);

		loader.waitReady().then(() => {
			//askSensors();

			let loaderAudio = new Loader(dispatch, placeTmp, setAssetsSound, progress, true, setProgressSound);

			loaderAudio.waitReady().then(() => {
				dispatch(setReadySound(true));
				setAssetsSound(loaderAudio.assetsList);
			});

			setAssets(loader.assetsList);
			console.log('↳ assets: ', loader.assetsList);
			setLoading(false);

			let loadingElem = document.getElementById('introScreen-langSelectionContent-loading');

			if (loadingElem && loadingElem.style.opacity === '1') {
				_next.current();

				let introScreenElem = document.getElementById('introScreen');

				if (introScreenElem) {
					introScreenElem.style.opacity = '0';

					let sphereElem = document.getElementById('sphere');

					if (sphereElem) {
						sphereElem.style.opacity = '1';
						sphereElem.style.pointerEvents = 'all';
					}

					setTimeout(() => {
						setPage('explorer');
					}, 1000);
				}
			}
		});

		let towerColorLocal = '#2BCEF0';
		switch (placeTmp) {
			case 'berlin':
				towerColorLocal = '#F30C69';
				break;
			case 'chicago':
				towerColorLocal = '#1DD761';
				break;
			case 'rotterdam':
				towerColorLocal = '#F4C057';
		}

		if (placeTmp) {
			console.log('↳ place: ', placeTmp);

			setLocalPlace(placeTmp)
			dispatch(setPlace(placeTmp));
			dispatch(setTowerColor(towerColorLocal));
		}

		return () => {
			if (intervalPercent) {
				clearInterval(intervalPercent);
			}
		};
	}, []);

	const [loading, setLoading] = useState(true); // Display Loading screen
	const [page, setPage] = useState<string>('intro');

	// Datas
	const [datas, setDatas] = useState<any>(null); // All datas from tower
	const [assets, setAssets] = useState(null); // Assets list
	const [assetsSound, setAssetsSound] = useState(null); // All sounds from tower

	let towerName = 'montparnasse';
	switch (place) {
		case 'berlin':
			towerName = language === 'en' ? 'TV Tower' : 'Berliner Fernsehturm';
			break;
		case 'chicago':
			towerName = '360 CHICAGO';
			break;
		case 'rotterdam':
			towerName = 'Euromast';
	}

	useEffect(() => {
		let elem = document.getElementById('audiobg') as HTMLAudioElement;

		if (sound) {
			if (elem) {
				elem.muted = false;
			}
		} else {
			if (elem) {
				elem.muted = true;
			}
		}
	}, [sound]);

	useEffect(() => {
		if (page === 'intro') {
			let video = document.getElementById('videoIntro') as HTMLVideoElement;

			if (video) {
				let videoContainer = document.getElementById('videoIntroContainer') as HTMLElement;
				video.onended = () => {
					if (videoContainer) {
						videoContainer.style.display = 'none';
					}
				};
				//video.play();
			}
		}
	}, [page]);

	useEffect(() => {
		if (cookies.cookiesAllowed) {
			// If cookies are enabled !
			console.log('Cookies enabled !')

            let googleid = null;

            if (window.location.href.includes('app.360chicago.com')){
                googleid = 	"GTM-PSKG42P";
            }else if(window.location.href.includes('app.tourmontparnasse56.com')){
                googleid = 	"GTM-NCZXBC";
            }else if(window.location.href.includes('app.tv-turm.de')){
                googleid = 	"GTM-M7Q5FJ7";
            }else if(window.location.href.includes('app.euromast.nl')){
                googleid = 	"GTM-KSCSPL";
            }

            if(googleid !== null){
                ReactGA.initialize(googleid);
            }
		}
	}, [cookies])

	return (
		<div id='magnicity' className='magnicity'>
			<div
				id='debugLoader'
				style={{
					zIndex: 30000,
					position: 'absolute',
					height: '100%',
					width: '100%',
					color: 'white',
					pointerEvents: 'none',
				}}
			></div>
			{/* <div className='audiobg'>
				<audio autoPlay crossOrigin='anonymous' id='audiobg'></audio>
			</div> */}
			<input id='sensors' style={{ display: 'none' }}></input>
			<div
				id='videoIntroContainer'
				className='videoIntro'
				style={{
					position: 'absolute',
					zIndex: 28000,
					width: '100%',
					height: '100%',
					backgroundColor: '#080B18',
				}}
			>
				<video
					id='videoIntro'
					muted
					autoPlay
					playsInline
					height={window.innerHeight * 2}
					src={'/assets/videos/intro.mp4'}
					style={{
						position: 'absolute',
						zIndex: '28000',
						height: '100%',
						left: '50%',
						transform: 'translateX(-50%)',
					}}
				></video>
			</div>
			<div id='magnicity-welcome' className='magnicity-welcome'>
				<div className='magnicity-welcome-text'>{trads.find((t: any) => t.id === language)?.welcomeTo}</div>
				<div
					className='magnicity-welcome-place'
					style={{
						color: towerColor,
					}}
				>
					{place === 'montparnasse' ? 'paris' : place}
				</div>
				<div
					className='magnicity-welcome-towerName'
					style={{
						backgroundColor: towerColor,
					}}
				>
					<div
						className='magnicity-welcome-towerNameContent'
						style={{
							backgroundColor: towerColor,
						}}
					>
						{towerName}
					</div>
				</div>
			</div>
			{page === 'intro' && (
				<IntroScreen
					datas={datas}
					sound={sound}
					setSound={setSound}
					next={() => {
						let elem = document.getElementById('audiobg');

						if (elem) {
							(elem as HTMLAudioElement).play();
						}
						_next.current();
					}}
				/>
			)}
			{!loading && <Explorer
                page={page} 
                setPage={setPage}
                datas={datas}
                assets={assets}
                assetsSound={assetsSound}
            />}
			{/* <div className='changeOrientation'>{trads.find((t: any) => t.id === language)?.deviceOrientation}</div> */}
			{!cookies.cookiesAllowed && cookiePopup && <Cookies
                setCookiePopup={setCookiePopup}
                setPage={setPage}
                page={page}
            />}
		</div>
	);
};
