import { FikscopeEngine } from "./FikscopeEngine";
import { Pawn } from "./Pawn";
import { FikscopeObject3D } from "./Object";
import { Vector3 } from "three";

export class Player {
    //Player can be manipulated locally or just manipulated by server
    // - if FE is an instance of chief, all players can be moved here
    // - if FE is an instance of peer, only the main player is owned
    public _pawn: Pawn<FikscopeObject3D> | undefined;
    private _id: string;
    private _nick: string;

    get nick(): string {
        return this._nick;
    }

    set nick(nick: string) {
        this._nick = nick;
    }

    get id(): string {
        return this._id;
    }

    set id(id: string) {
        this._id = id;
    }

    get pawn(): Pawn<FikscopeObject3D> | undefined {
        if (this._pawn === undefined)
            console.error("Puffyland | FE - Pawn not def for Player " + this.nick);
        return this._pawn!;
    }

    set pawn(pawn: Pawn<FikscopeObject3D> | undefined) {
        this._pawn = pawn;
    }

    constructor(nick: string, id: string, pawn: Pawn<FikscopeObject3D>) {
        this._pawn = pawn;
        this._id = id;
        this._nick = nick;
    }

    move(pos: Vector3) {
        this.pawn?.move({ pos: pos });
    }

    bindFe(fe: FikscopeEngine) {
        this.pawn!.bindFe(fe);
    }
}
