// CSS
import './menu.css';
// Assets
import towerIcon from '../../style/assets/icons/towerIcon.png';
import viewIcon from '../../style/assets/icons/viewIcon.png';
import cityIcon from '../../style/assets/icons/cityIcon.png';
import towerIconGlow from '../../style/assets/icons/towerIcon.png';
import viewIconGlow from '../../style/assets/icons/viewIcon.png';
import cityIconGlow from '../../style/assets/icons/cityIcon.png';
import { trads } from '../../datas/trads';
import { useSelector } from 'react-redux';

export const Menu = (props: {
	page: string;
	setPage: (page: string) => void;
	card: any;
	setCard: any;
	setVideo: any;
	setViewer3d: any;
	setQuizPage: any;
	playing: boolean;
	setAgState: any;
	setAgSelected: any;
	setLecture: any;
	clearFinger: any;
	cameraScanQuit: any;
	localize: any;
	resetCompass: any;
	showCompass: any;
}) => {
	const { language } = useSelector((state: any) => state.user);

	return (
		<div id='menu' className='menu' style={{ pointerEvents: 'none' }}>
			<div className='menu-content'>
				<div
					className='menu-button clickable '
					onClick={() => {
						props.resetCompass();

						let prompt = document.getElementById('promptAudioGuid');
						let promptValidate = document.getElementById('promptAudioGuidValidate');
						let promptCancel = document.getElementById('promptAudioGuidCancel');

						if (props.playing && prompt) {
							prompt.style.display = 'flex';

							if (promptValidate && promptCancel) {
								promptValidate.onclick = () => {
									if (prompt) {
										prompt.style.display = 'none';

										setTimeout(() => {
											if (promptValidate && promptCancel) {
												promptValidate.style.pointerEvents = 'none';
												promptCancel.style.pointerEvents = 'none';
											}
										}, 200);
									}

									props.setLecture(false);
									props.setAgSelected(null);
									props.setVideo(null);
									props.setViewer3d(null);
									props.setCard(null);
									props.setQuizPage(null);
									props.clearFinger();
									props.cameraScanQuit();
									props.localize('');
									props.setPage('tower');
								};

								promptCancel.onclick = () => {
									if (prompt) {
										prompt.style.display = 'none';

										setTimeout(() => {
											if (promptValidate && promptCancel) {
												promptValidate.style.pointerEvents = 'none';
												promptCancel.style.pointerEvents = 'none';
											}
										}, 200);
									}
								};
							}

							setTimeout(() => {
								if (promptValidate && promptCancel) {
									promptValidate.style.pointerEvents = 'all';
									promptCancel.style.pointerEvents = 'all';
								}
							}, 200);
						} else {
							props.setLecture(false);
							props.setAgSelected(null);
							props.setVideo(null);
							props.setViewer3d(null);
							props.setCard(null);
							props.setQuizPage(null);
							props.clearFinger();
							props.cameraScanQuit();
							props.localize('');
							props.setPage('tower');
						}
					}}
				>
					<div
						className={(props.page === 'tower' ? 'glow ' : 'unselect ') + 'menu-button-img '}
						style={{
							backgroundImage: `url(${towerIcon})`,
						}}
					></div>
					<div className={(props.page === 'tower' ? 'glow ' : '') + 'menu-button-text'}>{trads.find((t) => t.id === language)?.tower}</div>
				</div>
				<div
					className='menu-button clickable '
					onClick={() => {
						if (props.page !== 'explorer' || props.card !== 'null') {
							props.resetCompass();
							let prompt = document.getElementById('promptAudioGuid');
							let promptValidate = document.getElementById('promptAudioGuidValidate');
							let promptCancel = document.getElementById('promptAudioGuidCancel');

							if (props.playing && prompt) {
								prompt.style.display = 'flex';

								if (promptValidate && promptCancel) {
									promptValidate.onclick = () => {
										if (prompt) {
											prompt.style.display = 'none';

											setTimeout(() => {
												if (promptValidate && promptCancel) {
													promptValidate.style.pointerEvents = 'none';
													promptCancel.style.pointerEvents = 'none';
												}
											}, 200);
										}

										props.setAgState('all');
										props.setVideo(null);
										props.setViewer3d(null);
										props.setCard(null);
										props.setQuizPage(null);
										props.clearFinger();
										props.cameraScanQuit();
										props.localize('');
										props.showCompass(false);
										props.setPage('explorer');
									};

									promptCancel.onclick = () => {
										if (prompt) {
											prompt.style.display = 'none';

											setTimeout(() => {
												if (promptValidate && promptCancel) {
													promptValidate.style.pointerEvents = 'none';
													promptCancel.style.pointerEvents = 'none';
												}
											}, 200);
										}
									};
								}

								setTimeout(() => {
									if (promptValidate && promptCancel) {
										promptValidate.style.pointerEvents = 'all';
										promptCancel.style.pointerEvents = 'all';
									}
								}, 200);
							} else {
								props.setAgState('all');
								props.setVideo(null);
								props.setViewer3d(null);
								props.setCard(null);
								props.setQuizPage(null);
								props.clearFinger();
								props.cameraScanQuit();
								props.localize('');
								props.showCompass(false);
								props.setPage('explorer');
							}
						}
					}}
				>
					<div
						className={(props.page === 'explorer' ? 'glow ' : 'unselect ') + 'menu-button-img '}
						style={{
							backgroundImage: `url(${viewIcon})`,
						}}
					></div>
					<div className={(props.page === 'explorer' ? 'glow ' : '') + 'menu-button-text'}>{trads.find((t) => t.id === language)?.view}</div>
				</div>
				<div
					className='menu-button clickable '
					onClick={() => {
						props.resetCompass();
						let prompt = document.getElementById('promptAudioGuid');
						let promptValidate = document.getElementById('promptAudioGuidValidate');
						let promptCancel = document.getElementById('promptAudioGuidCancel');

						if (props.playing && prompt) {
							prompt.style.display = 'flex';

							if (promptValidate && promptCancel) {
								promptValidate.onclick = () => {
									if (prompt) {
										prompt.style.display = 'none';

										setTimeout(() => {
											if (promptValidate && promptCancel) {
												promptValidate.style.pointerEvents = 'none';
												promptCancel.style.pointerEvents = 'none';
											}
										}, 200);
									}

									props.setLecture(false);
									props.setAgSelected(null);
									props.setVideo(null);
									props.setViewer3d(null);
									props.setCard(null);
									props.setQuizPage(null);
									props.cameraScanQuit();
									props.localize('');
									props.setPage('city');
								};

								promptCancel.onclick = () => {
									if (prompt) {
										prompt.style.display = 'none';
										props.showCompass();

										setTimeout(() => {
											if (promptValidate && promptCancel) {
												promptValidate.style.pointerEvents = 'none';
												promptCancel.style.pointerEvents = 'none';
											}
										}, 200);
									}
								};
							}

							setTimeout(() => {
								if (promptValidate && promptCancel) {
									promptValidate.style.pointerEvents = 'all';
									promptCancel.style.pointerEvents = 'all';
								}
							}, 200);
						} else {
							props.setLecture(false);
							props.setAgSelected(null);
							props.setVideo(null);
							props.setViewer3d(null);
							props.setCard(null);
							props.setQuizPage(null);
							props.clearFinger();
							props.cameraScanQuit();
							props.localize('');
							props.setPage('city');
						}
					}}
				>
					<div
						className={(props.page === 'city' ? 'glow ' : 'unselect ') + 'menu-button-img '}
						style={{
							backgroundImage: `url(${cityIcon})`,
						}}
					></div>
					<div className={(props.page === 'city' ? 'glow ' : '') + 'menu-button-text'}>{trads.find((t) => t.id === language)?.city}</div>
				</div>
			</div>
		</div>
	);
};
