// React
import { url } from 'inspector';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setQuizProgress } from '../../actions/quiz';
import { trads } from '../../datas/trads';
import getApiUrl from '../../utils/getApiUrl';
// CSS
import './quizPage.css';
// Assets
import quizTop from '../../style/assets/quiz/quizTop.png';
import quizBot from '../../style/assets/quiz/quizBot2.png';
import quizBlur from '../../style/assets/quiz/blur.png';
import quizBlurFull from '../../style/assets/quiz/blurFull.png';

export const QuizPage = (props: { assets: any; setQuizPage: any }) => {
	const { progress } = useSelector((state: any) => state.quiz);
	const { language, towerColor, place } = useSelector((state: any) => state.user);

	const dispatch = useDispatch<any>();

	let quiz = progress.find((qP: any) => qP.city === place);

	const [preQuiz, displayPreQuiz] = useState(false);
	const [endQuiz, displayEndQuiz] = useState(false);

	useEffect(() => {
		if (!quiz.answers || quiz.answers.length <= 0) {
			displayPreQuiz(true);
		}
	}, []);

	const resetQuiz = () => {
		let pTmp = [...progress];

		let city = pTmp.find((p: any) => p.city === place);
		let cityProgress = {
			...city,
			answers: [],
			score: 0,
		};

		let indexCity = pTmp.findIndex((p: any) => p.city === place);
		if (indexCity >= 0) {
			pTmp.splice(indexCity, 1);
		}
		pTmp.push(cityProgress);

		dispatch(setQuizProgress(pTmp));
	};

	const [backgroundImg, setBackgroundImg] = useState('');

	const [qNbr, setQuestion] = useState(0);

	const updateQuestion = () => {
		let answers = [...progress.find((qP: any) => qP.city === place).answers];
		let i = 0;
		for (i; i < quiz.questions.length; i++) {
			let a = answers.findIndex((a: any) => a.q === i);
			if (a < 0) {
				break;
			}
		}
		setQuestion(i);

		if (i < quiz.questions.length) {
			let path = quiz.questions[i].media.data.subffiles.find((sf: any) => sf.channel === 'preview' && sf.status === 'active')?.path;

			if (path) {
				path = path.replace('\\', '/');
				path = getApiUrl() + '/content/file/' + place + '/' + path;

				setBackgroundImg(path);
			}
		} else {
			displayEndQuiz(true);
		}
	};

	useEffect(() => {
		updateQuestion();
	}, [preQuiz]);

	const [explanations, displayExplanations] = useState<boolean | null>(null);

	useEffect(() => {
		let elem = document.getElementById('quizPage-quizExplanations');

		if (elem) {
			let br = elem.getBoundingClientRect();

			if (br.top + br.height > window.screen.height * 0.6) {
				let arrowElem = document.getElementById('quiz-arrowBot');

				if (arrowElem) {
					arrowElem.style.opacity = '1';
				}
			} else {
				let arrowElem = document.getElementById('quiz-arrowBot');

				if (arrowElem) {
					arrowElem.style.opacity = '0';
				}
			}
		}
	}, [explanations]);

	const cutFunction = (string = '') => {
		let res = [];
		let cutted = string.split(' ');

		let part = '';
		let i = 0;
		while (i < cutted.length) {
			if (part.length + cutted[i].length > 20) {
				res.push(part);
				part = '';
			} else {
				part += cutted[i] + ' ';
				i++;
			}
		}
		res.push(part);
		return res;
	};

	const answer = (value: number) => {
		let pTmp = [...progress];
		let answers = [...progress.find((qP: any) => qP.city === place).answers];
		let question = progress.find((qP: any) => qP.city === place).questions[qNbr];

		let proposals = question.proposal.translations.find((t: any) => t.langage === language)?.value.split(';');

		let score = 0;
		let buttonClicked = document.getElementById('quizPage-quizBot-button' + proposals[value]);
		let rightAnim = document.getElementById('quizPage-rightAnim');
		let wrongAnim = document.getElementById('quizPage-wrongAnim');
		let blurFullElem = document.getElementById('quizPage-quizBlurFull');

		if (question?.answer === value) {
			// Animation Success
			if (buttonClicked) {
				buttonClicked.style.backgroundColor = '#4ADE80';
				buttonClicked.style.boxShadow = '0px 4px 0px #374151';

				if (rightAnim) {
					rightAnim.style.display = 'flex';

					if (blurFullElem) {
						blurFullElem.style.display = 'flex';
					}
				}
			}
			score++;
		} else {
			// Animation Fail
			if (buttonClicked) {
				buttonClicked.style.backgroundColor = '#F4C057';
				buttonClicked.style.border = '1px solid #EE7136';
				buttonClicked.style.boxShadow = '0px 4px 0px #EE7136';

				if (wrongAnim) {
					wrongAnim.style.display = 'flex';

					if (blurFullElem) {
						blurFullElem.style.display = 'flex';
					}
				}
			}
		}

		let a = answers.findIndex((a: any) => a.q === qNbr);
		if (a >= 0) {
			answers.splice(a, 1);
		}

		let answer = {
			q: qNbr,
			a: value,
		};

		answers.push(answer);

		let city = pTmp.find((p: any) => p.city === place);
		let cityProgress = {
			...city,
			answers: answers,
			score: city.score + score,
		};

		let indexCity = pTmp.findIndex((p: any) => p.city === place);
		if (indexCity >= 0) {
			pTmp.splice(indexCity, 1);
		}
		pTmp.push(cityProgress);

		dispatch(setQuizProgress(pTmp));

		setTimeout(() => {
			if (rightAnim) {
				rightAnim.style.display = 'none';
			}
			if (wrongAnim) {
				wrongAnim.style.display = 'none';
			}

			displayExplanations(score ? true : false);
		}, 2000);
	};

	return (
		<div
			className='quizPage'
			onScroll={(e) => {
				let elem = document.getElementById('quizPage-quizExplanations');

				if (elem) {
					let br = elem.getBoundingClientRect();

					if (br.top + br.height > window.screen.height * 0.6) {
						let arrowElem = document.getElementById('quiz-arrowBot');

						if (arrowElem) {
							arrowElem.style.opacity = '1';
						}
					} else {
						let arrowElem = document.getElementById('quiz-arrowBot');

						if (arrowElem) {
							arrowElem.style.opacity = '0';
						}
					}
				}
			}} /*onClick={() => props.setQuizPage(false)}*/
		>
			<div className='videoStory-top'>
				<div className='videoStory-back' onClick={() => props.setQuizPage(null)}></div>
			</div>
			{preQuiz && (
				<div className='quizPage-prequiz'>
					<div className='quizPage-prequiz-top' style={{ zIndex: 92000 }}>
						<div className='quizPage-prequiz-title'>{trads.find((x: any) => x.id === language)?.pauseQuiz}</div>
						<div className='quizPage-prequiz-subtitle'>
							{trads.find((x: any) => x.id === language)?.didYouKnowBefore +
								(place !== 'montparnasse' ? place : 'paris') +
								trads.find((x: any) => x.id === language)?.didYouKnowAfter}
						</div>
					</div>
					<div className='quizPage-prequiz-image'></div>
					<div className='quizPage-prequiz-bottom' style={{ zIndex: 92000 }}>
						<div className='quizPage-prequiz-desc'>{trads.find((x: any) => x.id === language)?.answerWell}</div>
						<div
							className='quizPage-prequiz-button'
							onClick={() => {
								displayPreQuiz(false);
							}}
						>
							<div className='quizPage-prequiz-buttonText'>{trads.find((x: any) => x.id === language)?.start}</div>
						</div>
					</div>
				</div>
			)}
			<div id='quiz-arrowBot' className='quiz-arrowBot'></div>
			{qNbr < quiz.questions.length && (
				<div className='quizPage-quizTop'>
					<div className='quizPage-quizTop-number'>{qNbr + 1 + ' / ' + quiz.questions.length}</div>
					<div className='quizPage-quizTop-text'>{quiz.questions[qNbr].question.translations.find((t: any) => t.langage === language).value}</div>
				</div>
			)}
			{qNbr < quiz.questions.length && (
				<div
					className='quizPage-quizImg'
					style={{
						backgroundColor: towerColor,
						backgroundImage: 'url(' + backgroundImg + ')',
					}}
				>
					<div
						id='quizPage-quizBlur'
						className='quizPage-quizBlur'
						style={{
							width: '100%',
							backgroundImage: 'url(' + quizBlur + ')',
							backgroundSize: '100% 100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<div
							id='quizPage-quizBlurFull'
							style={{
								width: '100%',
								height: 'fit-content',
								backgroundImage: 'url(' + quizBlurFull + ')',
								backgroundSize: '100% 100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								opacity: explanations !== null ? '1' : '0',
							}}
						>
							<div className='quizPage-quizAnswer'>
								{
									quiz.questions[qNbr].proposal.translations.find((t: any) => t.langage === language)?.value.split(';')[
										quiz.questions[qNbr].answer
									]
								}
							</div>
							<div id='quizPage-quizExplanations' className='quizPage-quizExplanations'>
								{quiz.questions[qNbr].comment.translations.find((t: any) => t.langage === language)?.value}
							</div>
						</div>
					</div>
				</div>
			)}
			{qNbr < quiz.questions.length && (
				<div
					className='quizPage-quizBot'
					style={
						{
							//backgroundImage: 'url(' + quizBot + ')',
						}
					}
				>
					<div id='quizPage-rightAnim' className='quizPage-rightAnim'>
						{trads.find((x: any) => x.id === language)?.goodAnswerAnim}
					</div>
					<div id='quizPage-wrongAnim' className='quizPage-wrongAnim'>
						{trads.find((x: any) => x.id === language)?.badAnswerAnim}
					</div>
					{explanations === null &&
						quiz.questions[qNbr].proposal.translations
							.find((t: any) => t.langage === language)
							.value.split(';')
							.map((proposal: any, index: number) => {
								return (
									<div
										key={'quizPage-quizBot-button' + proposal}
										id={'quizPage-quizBot-button' + proposal}
										className='quizPage-quizBot-button clickable'
										style={{
											margin: (index === 0 ? '32px' : '0.8em') + ' 0 0 0',
										}}
										onClick={() => {
											let elems = document.getElementsByClassName('quizPage-quizBot-button');

											for (let elem of elems) {
												(elem as HTMLElement).style.pointerEvents = 'none';
											}
											answer(index);
										}}
									>
										<div className='quizPage-quizBot-buttonText'>{proposal}</div>
									</div>
								);
							})}
					{explanations !== null && (
						<div
							className='quizPage-quizBot-button clickable'
							style={{
								margin: '32px 0 0 0',
							}}
							onClick={() => {
								updateQuestion();
								displayExplanations(null);
							}}
						>
							<div className='quizPage-quizBot-buttonText'>
								{qNbr < quiz.questions.length - 1
									? trads.find((t: any) => t.id === language)?.nextQuestion
									: trads.find((t: any) => t.id === language)?.endQuestion}
							</div>
						</div>
					)}
				</div>
			)}
			{endQuiz && (
				<div className='quizPage-endquiz'>
					<div className='quizPage-endQuizTop'>
						<div className='quizPage-prequiz-title'>{trads.find((t: any) => t.id === language)?.quizOver}</div>
						{cutFunction(trads.find((t: any) => t.id === language)?.forPlaying).map((elem: any, index: any) => {
							return (
								<span
									key={'quizPage-prequiz-subtitle' + index}
									className='quizPage-prequiz-subtitle'
									style={{
										backgroundColor: towerColor,
									}}
								>
									{elem}
									<br />
								</span>
							);
						})}
					</div>

					<div
						className='quizPage-endQuizImg'
						style={{
							backgroundColor: towerColor,
							backgroundImage: 'url(' + props.assets.find((a: any) => a.name === 'quiz')?.url?.replace('\\', '/') + ')',
						}}
					>
						<div
							id='quizPage-quizBlur'
							className='quizPage-quizBlur'
							style={{
								width: '100%',
								height: '100%',
								backgroundImage: 'url(' + quizBlur + ')',
								backgroundSize: '100% 100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<div
								style={{
									width: '100%',
									height: '100%',
									backgroundImage: 'url(' + quizBlurFull + ')',
									backgroundSize: '100% 100%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{trads.find((x: any) => x.id === language)?.score}
								<div className='quizPage-endquiz-desc'>
									{progress.find((qP: any) => qP.city === place)?.score + ' '}
									<span style={{ color: towerColor, fontWeight: 300 }}>{'/'}</span>
									{' ' + progress.find((qP: any) => qP.city === place)?.questions.length}
								</div>
							</div>
						</div>
					</div>

					<div className='quizPage-quizBot'>
						<div
							className='quizPage-quizBot-button clickable '
							onClick={() => {
								resetQuiz();
								setQuestion(0);
								displayEndQuiz(false);
								displayPreQuiz(true);
							}}
						>
							<div className='quizPage-quizBot-buttonText'>{trads.find((x: any) => x.id === language)?.playAgain}</div>
						</div>
						<div
							className='quizPage-quizBot-button clickable '
							onClick={() => {
								displayEndQuiz(false);
								props.setQuizPage(false);
							}}
						>
							<div className='quizPage-quizBot-buttonText'>{trads.find((x: any) => x.id === language)?.backMenu}</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
