// React
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// Modules
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// Components
import { PointPosition } from '../utils/pointPosition/PointPosition';
// CSS
import './tower.css';
// Utils
import { trads } from '../../datas/trads.js';
import getApiUrl from '../../utils/getApiUrl';
// Assets
import playIcon from '../../style/assets/icons/playIcon.png';
import { Swipe } from 'react-swipe-component';

export const Discover = (props: { cityDatas: any; assets: any; setCard: any; setVideo: any; setViewer3d: any; setPoi: any; sphere: any }) => {
	const { language, place, towerColor } = useSelector((state: any) => state.user);

	const [carousselIndex, setCarousselIndex] = useState<number>(0);

	const scroll = (e: any) => {
		let index = Math.round(e.target.scrollLeft / 144);
		setCarousselIndex(index);

		let elemsGradientLeft = document.getElementsByClassName('tower-discover-caroussel-item-gradientLeft');
		for (let elemGradientLeft of elemsGradientLeft) {
			if (index > 0) {
				(elemGradientLeft as HTMLElement).style.opacity = '1';
			} else {
				(elemGradientLeft as HTMLElement).style.opacity = '0';
			}
		}
		let elemsGradientRight = document.getElementsByClassName('tower-discover-caroussel-item-gradientRight');
		for (let elemGradientRight of elemsGradientRight) {
			if (index <= props.cityDatas.cards.length) {
				(elemGradientRight as HTMLElement).style.opacity = '1';
			} else {
				(elemGradientRight as HTMLElement).style.opacity = '0';
			}
		}
	};

	return (
		<div className='tower-discover'>
			<PointPosition current={carousselIndex || 0} total={props.cityDatas.stories.length} />
			<div className='tower-discover-title'>{trads.find((t) => t.id === language)?.discoverTower}</div>
			<div
				className='tower-discover-caroussel'
				style={{
					margin: '0 0 0 0',
				}}
			>
				<div
					id='tower-discover-caroussel-item-gradientLeft'
					className={'tower-discover-caroussel-item tower-discover-caroussel-item-gradientLeft '}
					style={{
						margin: '0 0 0 -24px',
						pointerEvents: 'none',
						position: 'absolute',
						borderRadius: 0,
						zIndex: 95000,
						left: '0px',
						background: 'linear-gradient(90deg, rgba(31,41,55,0.8) 0%, rgba(31,41,55,0) 20%, rgba(8,13,23,0) 100%)',
						top: '0px',
						width: '240px',
						height: '100%',
						opacity: '0',
						transition: 'opacity 0.5s',
					}}
				></div>
				<div
					id='tower-discover-caroussel-item-gradientRight'
					className={'tower-discover-caroussel-item tower-discover-caroussel-item-gradientRight '}
					style={{
						margin: '0 0 0 -24px',
						pointerEvents: 'none',
						position: 'absolute',
						borderRadius: 0,
						zIndex: 95000,
						left: 'calc(100vw - 240px)',
						background: 'linear-gradient(270deg, rgba(17, 24, 39, 0.8) 0%, rgba(17, 24, 39, 0) 20%, rgba(8,13,23,0) 100%)',
						top: '0px',
						width: '240px',
						height: '100%',
						transition: 'opacity 0.5s',
					}}
				></div>
				<div id='tower-discover-caroussel' className='tower-discover-caroussel' style={{ overflowX: 'auto' }} onScroll={(e) => scroll(e)}>
					<div
						id='tower-discover-carousselContent'
						className='tower-discover-carousselContent'
						style={{ width: (props.cityDatas.stories.length + 1) * 144 + 72 + 'px' }}
					>
						{/* Story */}
						{props.cityDatas.mstory && (
							<div
								onClick={() => {
									let elems = document.getElementsByClassName('tower-discover-carousselContent');

									for (let elem of elems) {
										(elem as HTMLElement).style.transform = '';
									}

									let story = props.cityDatas.stories.find((s: any) => s.short === props.cityDatas.mstory.short);

									let channelName = 'main_' + language;

									let pathMovie = story.media.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === channelName);
									if (!pathMovie) {
										pathMovie = story.media.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'main');
									}

									pathMovie = pathMovie.path;

									let pathMovieFinal = pathMovie.split('\\')[1];
									if (!pathMovieFinal) {
										pathMovieFinal = pathMovie.split('/')[1];
									}

									let finalPathMovie = getApiUrl() + '/content/file/' + pathMovieFinal + '?play';

									props.setVideo(finalPathMovie);
								}}
								className={'tower-discover-caroussel-item '}
							>
								<div className={'tower-discover-caroussel-itemContent '}>
									<div
										className={'tower-discover-caroussel-itemContentInside '}
										style={{
											backgroundImage:
												"url('" +
												getApiUrl() +
												'/content/file/' +
												place +
												'/' +
												props.cityDatas.stories
													.find((s: any) => s.short === props.cityDatas.mstory.short)
													?.media.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'miniature')
													.path.replace('\\', '/') +
												"')",
										}}
									>
										<div
											className={'tower-discover-caroussel-itemContentInsideIcon '}
											style={{
												backgroundImage: "url('" + playIcon + "')",
											}}
										></div>
									</div>
								</div>
								<div
									className={'tower-discover-caroussel-desc '}
									style={{
										color: towerColor,
									}}
								>
									{
										props.cityDatas.stories
											.find((s: any) => s.short === props.cityDatas.mstory.short)
											.name.translations.find((t: any) => t.langage === language)?.value
									}
								</div>
							</div>
						)}

						{props.cityDatas.m3dmodel && (
							<div
								onClick={() => {
									let elems = document.getElementsByClassName('tower-discover-carousselContent');

									for (let elem of elems) {
										(elem as HTMLElement).style.transform = '';
									}

									console.log('Launch Building3d : ', props.cityDatas.m3dmodel.short, props.cityDatas);

									let view = props.cityDatas.m3dmodels.find((m: any) => m.short === props.cityDatas.m3dmodel.short);

									let tmp = view.ffile.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'viewer3d');
									let pathMovie = tmp.path;

									if (pathMovie) {
										let pathMovieFinal = pathMovie.split('\\')[1];
										if (!pathMovieFinal) {
											pathMovieFinal = pathMovie.split('/')[1];
										}

										let finalPathMovie = getApiUrl() + '/content/file/' + place + '/upload/' + pathMovieFinal;

										let movie = {
											url: finalPathMovie,
											size: tmp.size,
										};

										props.setViewer3d(movie);
									}
								}}
								className={'tower-discover-caroussel-item '}
							>
								<div className={'tower-discover-caroussel-itemContent '}>
									<div
										className={'tower-discover-caroussel-itemContentInside '}
										style={{
											backgroundImage:
												"url('" +
												getApiUrl() +
												'/content/file/' +
												place +
												'/' +
												props.cityDatas.m3dmodels
													.find((m: any) => m.short === props.cityDatas.m3dmodel.short)
													?.ffile.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'miniature')
													.path.replace('\\', '/') +
												"')",
										}}
									></div>
								</div>
								<div
									className={'tower-discover-caroussel-desc '}
									style={{
										color: towerColor,
									}}
								>
									{/* {
										props.cityDatas.m3dmodels
											.find((s: any) => s.short === props.cityDatas.m3dmodel.short)
											.name.translations.find((t: any) => t.langage === language)?.value
									} */}
									{trads.find((t) => t.id === language)?.view3D}
								</div>
							</div>
						)}

						{props.cityDatas.mcard && (
							<div
								onClick={() => {
									let elems = document.getElementsByClassName('tower-discover-carousselContent');

									for (let elem of elems) {
										(elem as HTMLElement).style.transform = '';
									}

									props.setPoi(props.sphere.pois.find((p: any) => p.mcard === props.cityDatas.mcard._id)?.short);
									props.setCard(props.cityDatas.mcard._id);
								}}
								className={'tower-discover-caroussel-itemCard '}
							>
								<div className={'tower-discover-caroussel-itemContentCard '}>
									<div
										className={'tower-discover-caroussel-itemContentInsideCard '}
										style={{
											backgroundImage:
												"url('" +
												getApiUrl() +
												'/content/file/' +
												place +
												'/' +
												props.cityDatas.cards
													.find((c: any) => c.short === props.cityDatas.mcard.short)
													?.ffile.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'preview')
													.path.replace('\\', '/') +
												"')",
										}}
									></div>
								</div>
								<div
									className={'tower-discover-caroussel-desc '}
									style={{
										color: towerColor,
									}}
								>
									{
										props.cityDatas.cards
											.find((c: any) => c.short === props.cityDatas.mcard.short)
											.name.translations.find((t: any) => t.langage === language)?.value
									}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
