// React
import React, { useState, useEffect, useRef, Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Html5Qrcode, Html5QrcodeScannerState } from "html5-qrcode";
// GJ modules
import { FikscopeEngine } from "../../fikscopeengine_modules/fikscopeengine-core/fikscope";
// Project
import { Menu } from "../menu/Menu";
import { Filters } from "./Filters";
import { Sphere } from "./../../sphere";
import { Details } from "./../details/Details";
import { Buttons } from "./Buttons";
import { City } from "../city/City";
import { Tower } from "../tower/Tower";
import { Popup } from "../popup/Popup";
// CSS
import "./explorer.css";
import { Viewer3D } from "./Viewer3D";
// Assets
import finger from "../../style/assets/icons/fingerSwipe.png";
import { QuizPage } from "../quiz/QuizPage";
import { AudioGuid } from "../audioguids/AudioGuid";
import { trads } from "../../datas/trads";
import getApiUrl from "../../utils/getApiUrl";
import { PSphere } from "../../sphere/pawn/PSphere";
import { Credits } from "../credits/Credits";
import { Cgu } from "../cgu/Cgu";
import { Legals } from "../legals/Legals";
import { Privacypolicy } from "../privacypolicy/Privacypolicy";
import { setCurrentSound } from "../../actions/sounds";

import compassImg from "../../style/assets/compass_01.png";
import { LMSphere } from "../../sphere/level/LMSphere";
import { Euler, Quaternion, Vector3 } from "three";
import { degToRad, radToDeg } from "three/src/math/MathUtils";

export function Explorer(props: any) {
	const { language, towerColor, place } = useSelector(
		(state: any) => state.user
	);
	const { ready } = useSelector((state: any) => state.sounds);

	useEffect(() => {
		if (props.datas?.spheres) {
			setSphere(props.datas.spheres[0].short);
		}
	}, [props.datas]);

	useEffect(() => {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (isMobile) {
			let elems = document.getElementsByClassName('landscapeDisabled');

			for (let elem of elems) {
				elem.classList.add('landscapeDisabledMobile');
			}
		}
	}, []);

	// Fe
	const [fe, setFe] = React.useState<null | FikscopeEngine>(null);
	// Datas
	const [sphere, setSphere] = useState<any>(null); // Sphere datas
	// App State
	const [poi, setPoi] = useState(null);
	const [card, setCard] = useState(null); // Actual selected card
	const [video, setVideo] = useState(null);
	const [viewer3d, setViewer3d] = useState(null);
	const [quizPage, setQuizPage] = useState(false);
	const [qrcode, setQrcode] = React.useState<Html5Qrcode | undefined>(
		undefined
	);

	const [dispCompass, setDispCompass] = useState(false);
	const [zIndexCompass, setZIndexCompass] = useState("20001");
	const [dispAudioGuids, setDispAudioGuids] = useState(false);

	const _qrcode = useRef<Html5Qrcode | undefined>(undefined);

	useEffect(() => {
		if (fe && fe.map && language) {
			(fe.map as LMSphere).changeLang(language);
		}
	}, [fe, language]);

	useEffect(() => {
		_qrcode.current = qrcode;
	}, [qrcode]);

	const [AR, disableAR] = useState(true);
	const _AR = useRef(AR);
	const [agState, setAgState] = useState("all");
	const [agSelected, setAgSelected] = useState(null);
	const [first, setFirst] = useState(true);

	const _to = useRef<NodeJS.Timeout | null>(null);
	const _to2 = useRef<NodeJS.Timeout | null>(null);

	const [audioDuration, setAudioDuration] = useState(0);

	const setLecture = (play = true) => {
		if (_to.current) {
			clearInterval(_to.current);
		}
		if (_to2.current) {
			clearInterval(_to2.current);
		}

		let elemAudio = document.getElementById("audio") as HTMLAudioElement;
		let elemIconPlayer = document.getElementById(
			"city-audioguids-cardIconCircleButton"
		);

		if (elemAudio) {
			if (!elemAudio.paused) {
				elemAudio.pause();

				if (elemIconPlayer) {
					elemIconPlayer.classList.remove(
						"city-audioguids-cardIconCirclePause"
					);
					elemIconPlayer.classList.add("city-audioguids-cardIconCirclePlay");
				}
			} else if (elemAudio.paused) {
				elemAudio.play();

				if (elemIconPlayer) {
					elemIconPlayer.classList.remove("city-audioguids-cardIconCirclePlay");
					elemIconPlayer.classList.add("city-audioguids-cardIconCirclePause");
				}
			}
		}
	};

	const hidePlayer = () => {
		let elem = document.getElementById("audioguid");
		let elemAudio = document.getElementById("audio") as HTMLAudioElement;

		if (elemAudio) {
			elemAudio.currentTime = 0;
		}

		if (elem) {
			elem.style.top = "-100%";
		}

		_to2.current = setTimeout(() => {
			setAgSelected(null);
			setAgState("all");
		}, 1000);
	};

	useEffect(() => {
		let elem = document.getElementById("audioguid");
		let elemAudioguidList = document.getElementById("audioguidList");

		if (agState === "player") {
			if (elemAudioguidList) {
				elemAudioguidList.style.opacity = "0";
			}

			if (elem) {
				elem.style.top = "120px";
			}

			if (ready) {
				_to.current = setTimeout(() => {
					setAgState("all");
				}, 4000);
			}
		} else {
			if (elemAudioguidList) {
				elemAudioguidList.style.opacity = "1";
			}

			if (elem) {
				elem.style.top = "-100%";
			}

			hidePlayer();
		}

		return () => {
			if (_to.current) {
				clearInterval(_to.current);
			}
			if (_to2.current) {
				clearInterval(_to2.current);
			}
		};
	}, [agState]);

	useEffect(() => {
		if (agSelected) {
			setAgState("player");
		} else {
			setAgState("all");
		}

		let elemAudio = document.getElementById("audio") as HTMLAudioElement;

		if (elemAudio) {
			//setAudioDuration(props.assetsSound.find((a: any) => a.name === (agSelected as any).short + '-main_' + language)?.file.duration);
		}
	}, [agSelected]);

	const showMenu = [
		"explorer",
		"tower",
		"city",
		"credits",
		"cgu",
		"legals",
		"privacypolicy",
	];

	useEffect(() => {
		_AR.current = AR;
	}, [AR]);

	let _movement = useRef<NodeJS.Timer | undefined>();
	let _fingerTimeout = useRef<NodeJS.Timeout | undefined>();

	const [fingerAnim, setFingerAnim] = useState(false);

	const moveFinger = () => {
		let pos = 0;
		let dir = -1;

		_movement.current = setInterval(() => {
			let fingerElem = document.getElementById("finger");

			if (fingerElem) {
				fingerElem.style.transform = "translateX(" + pos + "%)";
			}

			if (pos > 20 && dir === 1) {
				dir = -1;
			} else if (pos < -20 && dir === -1) {
				dir = 1;
			} else {
				pos += 1 * dir;
			}
		}, 20);
	};

	useEffect(() => {
		if (fingerAnim) {
			let fingerElem = document.getElementById("finger");

			if (fingerElem) {
				fingerElem.style.opacity = "1";
				moveFinger();
			}

			_fingerTimeout.current = setTimeout(() => {
				if (_movement.current) {
					if (fingerElem) {
						fingerElem.style.opacity = "0";
					}
					clearInterval(_movement.current);
				}
			}, 2000);
		}

		return () => {
			if (_movement.current) {
				let fingerElem = document.getElementById("finger");

				if (fingerElem) {
					fingerElem.style.transition = "opacity 0s";
					fingerElem.style.opacity = "0";
				}
				clearInterval(_movement.current);
			}
			clearTimeout(_fingerTimeout.current);
		};
	}, [fingerAnim]);

	const clearFinger = () => {
		if (_fingerTimeout.current) {
			clearTimeout(_fingerTimeout.current);
		}

		let fingerElem = document.getElementById("finger");

		if (_movement.current) {
			clearInterval(_movement.current);
			if (fingerElem) {
				fingerElem.style.transition = "opacity 0s";
				fingerElem.style.opacity = "0";
				setTimeout(() => {
					if (fingerElem) {
						fingerElem.style.transition = "opacity 1s";
					}
				}, 1000);
			}
		}
	};

	const [popup, setPopup] = useState<{
		title: string;
		desc: string;
		icon: any;
	}>({
		title: "",
		desc: "",
		icon: null,
	});

	const changeAR = () => {
		setFingerAnim(false);

		if (AR) {
			setPopup({
				title: "disableAR",
				desc: "disableARDesc",
				icon: finger,
			});

			// let fingerElem = document.getElementById("finger");

			// if (fingerElem) {
			// 	fingerElem.style.opacity = "1";
			// 	moveFinger();
			// }

			// _fingerTimeout.current = setTimeout(() => {
			// 	if (_movement.current) {
			// 		if (fingerElem) {
			// 			fingerElem.style.opacity = "0";
			// 		}
			// 		clearInterval(_movement.current);
			// 	}
			// }, 4000);
			setFingerAnim(true);
		} else {
			setPopup({
				title: "enableAR",
				desc: "enableARDesc",
				icon: finger,
			});
		}
		disableAR(!AR);
	};

	useEffect(() => {
		if (video) {
			let videoElem = document.getElementById("videoStory");

			if (videoElem) {
				(videoElem as HTMLVideoElement).play();
			}
		}
	}, [video]);

	useEffect(() => {
		if (props.page === "explorer") {
			setTimeout(
				() => {
					// Animation beggining experience
					let welcomeElem = document.getElementById("magnicity-welcome");

					if (welcomeElem) {
						welcomeElem.style.transform = "translateY(-50%) translateX(-50%)";
						welcomeElem.style.opacity = "0";
					}

					if (first === true) {
						setTimeout(() => {
							showCompass(false);
						}, 500);
					}

					setTimeout(
						() => {
							let menuElem = document.getElementById("menu");
							let filtersElem = document.getElementById("filters");
							let buttonsElem = document.getElementById("interfaceBtn");

							if (menuElem && filtersElem && buttonsElem) {
								menuElem.style.transition = first ? "all 1s" : "all 0s";
								menuElem.style.opacity = "1";
								filtersElem.style.transition = first ? "all 1s" : "all 0s";
								filtersElem.style.opacity = "1";
								buttonsElem.style.transition = first ? "all 1s" : "all 0s";
								buttonsElem.style.opacity = "1";
							}

							setTimeout(
								() => {
									setFirst(false);

									if (welcomeElem) {
										welcomeElem.style.display = "none";

										let poiListElem = document.getElementById("poiList");

										if (poiListElem) {
											poiListElem.style.transition = first
												? "all 1s"
												: "all 0s";
											poiListElem.style.opacity = "1";
										}

										setTimeout(
											() => {
												let agList = document.getElementById("audioguidList");

												if (agList) {
													if (agState !== "player") {
														agList.style.opacity = "1";
													} else {
														agList.style.opacity = "0";
													}
												}
											},
											first ? 1000 : 0
										);
									}
								},
								first ? 1500 : 0
							);
						},
						first ? 1000 : 0
					);
				},
				first ? 1500 : 0
			);
		}
	}, [props.page]);

	let cameraScanQuit = function () {
		if (
			_qrcode.current !== undefined &&
			(_qrcode.current.getState() == Html5QrcodeScannerState.SCANNING ||
				_qrcode.current.getState() == Html5QrcodeScannerState.PAUSED)
		) {
			console.log("STOP CAMERA");
			_qrcode.current.stop();
		}

		let videoElement = document.getElementById("camera");

		if (videoElement) {
			videoElement.style.display = "none";
		}
	};

	const changingAngle = [
		[302, 200], // ns 180 -> 200
		[300, 205], // 160 -> 205
		[289, 210], // ns 140 -> 210

		[200, 260], // 100 -> 260
		[220, 280], // 90 -> 280
		[238, 300], // 70 -> 300

		[140, 320],
		[135, 330],
		[125, 340],
		[120, 10],
		[115, 30],
		[110, 40], // 60 -> 40

		[20, 80],
		[38, 100],
		[58, 110],
		[61, 120],

		[316, 130],
		[315, 150],
		[314, 180],
		[313, 200],
	];

	const changeOffsetAngle = (value: number) => {
		if (fe?.map?.actors?.has("player")) {
			let debugElemScannedAngle = document.getElementById(
				"debugElemScannedAngle"
			);
			if (debugElemScannedAngle) {
				debugElemScannedAngle.innerHTML = "Scanned angle : " + Number(value);
			}

			let newValue = Number(value);
			if (place === "montparnasse") {
				for (let i = 0; i < changingAngle.length; i++) {
					if (changingAngle[i][0] === Number(value)) {
						newValue = changingAngle[i][1];
						break;
					}
				}
			}

			(fe.map.actors.get("player") as PSphere).targetangle = newValue;
			(fe.map.actors.get("player") as PSphere).needResetRotation = true;
		}
	};

	const localize = (poi: string) => {
		let pawn = fe?.map.actors.get("player") as PSphere;
		if (pawn) {
			if ((pawn as PSphere).mode === "rotating" && poi && poi != "") {
				(pawn as PSphere).changeMode("pointing", poi);
			} else {
				(pawn as PSphere).changeMode("rotating");
			}
		}
	};

	const resetCompass = () => {
		// let sphereTop = document.getElementById('sphereTop');
		// if (sphereTop) {
		// 	sphereTop.style.display = 'none';
		// }

		setDispCompass(false);
		//compassElem.style.opacity = '0';
		setZIndexCompass("20001");

		let audioguidListElem = document.getElementById("audioguidList");
		if (audioguidListElem) {
			setDispAudioGuids(false);
		}
	};

	const showCompass = (backButton: boolean) => {
		let sphereTop = document.getElementById("sphereTop");
		if (sphereTop) {
			if (backButton) {
				sphereTop.style.display = "flex";
				sphereTop.style.zIndex = "99000";
			} else {
				sphereTop.style.display = "none";
				sphereTop.style.zIndex = "99000";
			}
		}

		setDispCompass(true);
		if (backButton) {
			setZIndexCompass("82000");
		} else {
			setZIndexCompass("20001");
		}

		let audioguidListElem = document.getElementById("audioguidList");
		if (audioguidListElem) {
			if (backButton) {
				setDispAudioGuids(true);
			} else {
				setDispAudioGuids(true);
			}
		}
	};

	return (
		<React.Fragment>
			<div className='landscapeDisabled'>
				<div>{trads.find((t) => t.id === language)?.noLandscape}</div>
			</div>
			<div
				id='sphereTop'
				className='videoStory-top'
				style={{ display: "none" }}
			>
				<div
					className='videoStory-back clickable'
					onClick={() => {
						let prompt = document.getElementById("promptAudioGuid");
						let promptValidate = document.getElementById(
							"promptAudioGuidValidate"
						);
						let promptCancel = document.getElementById("promptAudioGuidCancel");

						if (
							(document.getElementById("audio") as HTMLAudioElement)
								?.currentTime > 0 &&
							prompt
						) {
							prompt.style.display = "flex";

							if (promptValidate && promptCancel) {
								promptValidate.onclick = () => {
									let prompt = document.getElementById("promptAudioGuid");

									if (prompt) {
										prompt.style.display = "none";
									}
									setLecture(false);
									setAgSelected(null);
									resetCompass();
									localize("");
								};
								promptCancel.onclick = () => {
									let prompt = document.getElementById("promptAudioGuid");

									if (prompt) {
										prompt.style.display = "none";
									}
								};
							}

							setTimeout(() => {
								if (promptValidate && promptCancel) {
									promptValidate.style.pointerEvents = "all";
									promptCancel.style.pointerEvents = "all";
								}
							}, 200);
						} else {
							setLecture(false);
							setAgSelected(null);
							resetCompass();
							localize("");
						}
					}}
				></div>
			</div>
			{/* Reticle */}
			<div className='reticle' />
			{/* Compass */}

			{/* Prompt */}
			<div id='promptAudioGuid' className='promptAudioGuid'>
				<div className='promptAudioGuidText'>
					{trads.find((t) => t.id === language)?.exitAudioguid}
				</div>
				<div
					id='promptAudioGuidValidate'
					className='promptAudioGuidValidate'
					style={{ pointerEvents: "none" }}
					onClick={() => {
						let prompt = document.getElementById("promptAudioGuid");

						if (prompt) {
							prompt.style.display = "none";
						}
						setLecture(false);
						setAgSelected(null);
					}}
				>
					{trads.find((t) => t.id === language)?.yes}
				</div>
				<div
					id='promptAudioGuidCancel'
					className='promptAudioGuidCancel '
					style={{ pointerEvents: "none" }}
					onClick={() => {
						let prompt = document.getElementById("promptAudioGuid");

						if (prompt) {
							prompt.style.display = "none";
						}
					}}
				>
					{trads.find((t) => t.id === language)?.no}
				</div>
			</div>

			{/* VIDEO */}
			{video && (
				<div className='videoStory-container'>
					<div className='videoStory-top'>
						<div
							className='videoStory-back clickable'
							onClick={() => {
								clearFinger();
								setVideo(null);
							}}
						></div>
					</div>
					<video
						autoPlay
						playsInline
						id='videoStory'
						style={{
							position: "absolute",
							width: "100%",
							height: "calc(100% - 60px)",
							zIndex: "40000",
							backgroundColor: "#090d18",
						}}
						onEnded={() => {
							setVideo(null);
						}}
					>
						<source src={video} type='video/mp4' />
					</video>
				</div>
			)}

			{/* AUDIO */}
			{agSelected && (
				<audio
					id='audio'
					src={
						props.assetsSound
							? props.assetsSound.find(
									(a: any) =>
										a.name === (agSelected as any).short + "-main_" + language
							  )?.file
							: undefined
						// getApiUrl() +
						// '/subffile/get/' +
						// props.datas.maudioguids
						// 	.find((ag: any) => ag === agSelected)
						// 	?.media.data.subffiles.find((sf: any) => sf.channel === 'main' || sf.channel === 'main_' + language)
						// 	?.path.replace('\\', '/')
						// 	.slice(6)
					}
					onEnded={() => {
						_to.current = setTimeout(() => {
							hidePlayer();
						}, 1000);
					}}
					onTimeUpdate={(event: any) => {
						let elemAudio = document.getElementById(
							"audio"
						) as HTMLAudioElement;

						if (elemAudio) {
							setAudioDuration(elemAudio.currentTime);
						}
					}}
				></audio>
			)}
			{/* {agState === 'player' && <div id='ag-arrowLeft' className='ag-arrowLeft'></div>}
			{agState === 'player' && <div id='ag-arrowRight' className='ag-arrowRight'></div>} */}

			{/* Popup */}
			<Popup
				setPopup={setPopup}
				icon={popup?.icon}
				title={popup?.title}
				desc={popup?.desc}
			/>

			{/* AudioGuids */}
			{agState === "player" && agSelected && (
				<div id='audioguid' className='audioguid'>
					<AudioGuid
						ag={agSelected}
						onClick={() => setLecture()}
						currentTime={audioDuration}
						close={() => setAgSelected(null)}
						closeDisplay={true}
					/>
				</div>
			)}

			{/* 3D viewer */}
			{viewer3d && (
				<div className='videoStory-container'>
					<div className='videoStory-top'>
						<div
							className='videoStory-back clickable '
							onClick={() => {
								clearFinger();
								setViewer3d(null);
							}}
						></div>
					</div>
					<Viewer3D
						view3d={viewer3d}
						setViewer3d={setViewer3d}
						movement={_movement.current}
						moveFinger={moveFinger}
						fingerTimeout={_fingerTimeout}
						clearFinger={clearFinger}
					/>
				</div>
			)}

			<Details
				page={props.page}
				card={card}
				setCard={setCard}
				poi={props.datas.spheres
					.find((s: any) => s.short === sphere)
					?.pois.find((p: any) => p.short === poi)}
				setSphere={setSphere}
				sphere={props.datas.spheres.find((s: any) => s.short === sphere)}
				setPoi={setPoi}
				assets={props.assets}
				cityDatas={props.datas}
				setVideo={setVideo}
				setViewer3d={setViewer3d}
				localize={localize}
				setPage={props.setPage}
				setPopup={setPopup}
				showCompass={showCompass}
				resetCompass={resetCompass}
			/>

			{/* SPHERE */}
			{props.datas.spheres && (
				<Sphere
					AR={_AR}
					fe={fe}
					setFe={setFe}
					datas={props.datas}
					spheres={props.datas.spheres}
					sphere={sphere}
					assets={props.assets}
					ags={props.datas.maudioguids}
					setCard={setCard}
					setPoi={setPoi}
					poi={poi}
					towerColor={towerColor}
					setAgSelected={setAgSelected}
					cameraScanQuit={cameraScanQuit}
					resetCompass={resetCompass}
					localize={localize}
					showCompass={showCompass}
					setVideo={setVideo}
					dispAudioGuids={dispAudioGuids}
				/>
			)}
			{props.page === "explorer" && (
				<Filters
					datas={props.datas}
					sphere={sphere}
					assets={props.assets}
					setSphere={(a: any) => setSphere(a)}
					setPopup={setPopup}
				/>
			)}
			{props.page === "explorer" && (
				<Buttons
					fe={fe}
					qrcode={_qrcode.current}
					setQrcode={setQrcode}
					cameraScanQuit={cameraScanQuit}
					changeOffsetAngle={changeOffsetAngle}
					changeAR={changeAR}
					setPopup={setPopup}
				/>
			)}
			<div
				id='layers'
				className='layers'
				style={{
					position: "absolute",
					zIndex: "20000",
					width: "20%",
					height: "calc(100% - 60px)",
					top: "0px",
					left: "0px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "start",
					transformStyle: "flat",
					/*position: 'absolute',
					height: '0px',
					width: '0px',
					//top: '350px',
					left: '18px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'flex-start',*/
				}}
			></div>

			{/* CITY */}
			{props.page === "city" && (
				<City
					cityDatas={props.datas}
					assets={props.assets}
					setAG={(ag: any) => {
						setAgSelected(ag);

						let sphereTop = document.getElementById("sphereTop");
						if (sphereTop) {
							sphereTop.style.display = "flex";
							sphereTop.style.zIndex = "99000";
						}
						resetCompass();
						let poi = props.datas.spheres
							.find((s: any) => s._id === ag.msphere)
							.pois.find((p: any) => p._id === ag.mpoi);
						if (poi) {
							localize(poi.short);
						} else {
							localize("");
						}

						let transition = document.getElementById("transition");

						if (transition) {
							transition.style.opacity = "1";
						}

						setTimeout(() => {
							setSphere(
								props.datas.spheres.find((s: any) => s._id === ag.msphere).short
							);

							setTimeout(() => {
								if (transition) {
									transition.style.opacity = "0";
								}
							}, 500);

							setTimeout(() => setLecture(), 200);
						}, 500);

						//props.setPage('explorer')
					}}
					sphere={props.datas.spheres.find((s: any) => s.short === sphere)}
					setPoi={setPoi}
					setCard={setCard}
					setVideo={setVideo}
					setViewer3d={setViewer3d}
					setQuizPage={setQuizPage}
					changeOffsetAngle={changeOffsetAngle}
					setPage={props.setPage}
				/>
			)}
			{quizPage && <QuizPage setQuizPage={setQuizPage} assets={props.assets} />}

			{/* TOWER */}
			{props.page === "tower" && (
				<Tower
					cityDatas={props.datas}
					assets={props.assets}
					setCard={setCard}
					setVideo={setVideo}
					setViewer3d={setViewer3d}
					setPage={props.setPage}
					setPoi={setPoi}
					sphere={props.datas.spheres.find((s: any) => s.short === sphere)}
				/>
			)}

			{/* Credits */}
			{props.page === "credits" && (
				<Credits language={language} goBack={() => props.setPage("tower")} />
			)}
			{/* CGU */}
			{props.page === "cgu" && (
				<Cgu language={language} goBack={() => props.setPage("tower")} />
			)}
			{/* Legals */}
			{props.page === "legals" && (
				<Legals language={language} goBack={() => props.setPage("tower")} />
			)}
			{/* Privacy Policy */}
			{props.page === "privacypolicyloading" && (
				<Privacypolicy
					language={language}
					goBack={() => props.setPage("intro")}
				/>
			)}
			{props.page === "privacypolicy" && (
				<Privacypolicy
					language={language}
					goBack={() => props.setPage("tower")}
				/>
			)}

			{/* MENU */}
			{showMenu.includes(props.page) && (
				<Menu
					page={props.page}
					setPage={props.setPage}
					card={card}
					setCard={setCard}
					setVideo={setVideo}
					setViewer3d={setViewer3d}
					setQuizPage={setQuizPage}
					playing={
						(document.getElementById("audio") as HTMLAudioElement)
							?.currentTime > 0
					}
					setAgState={setAgState}
					setAgSelected={setAgSelected}
					setLecture={setLecture}
					clearFinger={clearFinger}
					cameraScanQuit={cameraScanQuit}
					localize={localize}
					resetCompass={resetCompass}
					showCompass={showCompass}
				/>
			)}

			{/* FINGER */}
			<div id='finger' className='finger'></div>
		</React.Fragment>
	);
}
