// React
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLanguage } from '../../actions/user';
import Select from 'react-select';
// Components
import { Stories } from './Stories';
import { Buildings } from './Buildings';
import { Buildings3D } from './Buildings3D';
import { OtherPois } from './OtherPois';
// CSS
import './city.css';
// Assets
import enFlag from '../../style/assets/flags/en.png';
import frFlag from '../../style/assets/flags/fr.png';
import deFlag from '../../style/assets/flags/de.png';
import esFlag from '../../style/assets/flags/es.png';
import nlFlag from '../../style/assets/flags/nl.png';
// Trads
import { trads } from '../../datas/trads.js';
import { useEffect } from 'react';
import { Quiz } from './Quiz';
import { AudioGuids } from './AudioGuids';

export const City = (props: {
	cityDatas: any;
	assets: any;
	setCard: any;
	setVideo: any;
	setViewer3d: any;
	setQuizPage: any;
	setAG: any;
	changeOffsetAngle: any;
	setPoi: any;
	sphere: any;
	setPage: any;
}) => {
	const { language, towerColor, place } = useSelector((state: any) => state.user);

	const navigate = useNavigate();
	const dispatch: Dispatch<any> = useDispatch();

	let cityName;
	switch (place) {
		case 'montparnasse':
			cityName = 'PARIS';
			break;
		case 'berlin':
			cityName = 'BERLIN';
			break;
		case 'rotterdam':
			cityName = 'ROTTERDAM';
			break;
		case 'chicago':
			cityName = 'CHICAGO';
			break;
	}

	const options = [
		{ value: 'en', label: 'English', image: enFlag, places: ['montparnasse', 'berlin', 'chicago', 'rotterdam'] },
		{ value: 'fr', label: 'French', image: frFlag, places: ['montparnasse'] },
		{ value: 'de', label: 'German', image: deFlag, places: ['berlin'] },
		{ value: 'nl', label: 'Neetherlands', image: nlFlag, places: ['rotterdam'] },
		{ value: 'es', label: 'Español', image: esFlag, places: ['chicago'] },
	];

	const setLang = (lang: string) => {
		dispatch(setLanguage(lang));
	};

	const optionsPlaces = [
		{ value: 'montparnasse', label: 'Paris' },
		{ value: 'berlin', label: 'Berlin' },
		{ value: 'chicago', label: 'Chicago' },
		{ value: 'rotterdam', label: 'Rotterdam' },
	];

	useEffect(() => {
		let titleElem = document.getElementsByClassName('city-placeSelection__control');

		if (titleElem) {
			for (let elem of titleElem) {
				(elem as HTMLElement).style.backgroundColor = towerColor;
			}
		}
	}, []);

	return (
		<div id='city' className='city'>
			<div className='city-lang'>
				<Select
					id={'city-langSelection'}
					className={'city-langSelection'}
					classNamePrefix={'city-langSelection'}
					defaultValue={options[0]}
					value={options.filter((e) => e.value === language)[0]}
					options={options.filter((e) => e.places.includes(place))}
					isSearchable={false}
					formatOptionLabel={(country) => (
						<div className={'city-langOption'}>
							<div
								className='city-langFlag'
								style={{
									backgroundImage: 'url(' + country.image + ')',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
								}}
							></div>
							<div className={'city-langLang'}>{country.value}</div>
						</div>
					)}
					onChange={(e) => {
						if (e) {
							setLang(e.value);
						}
					}}
					menuPlacement={'auto'}
				/>
			</div>
			<div className='city-title'>
				<Select
					id={'city-placeSelection'}
					className={'city-placeSelection'}
					classNamePrefix={'city-placeSelection'}
					defaultValue={options[0]}
					value={optionsPlaces.filter((e) => e.value === place)[0]}
					options={optionsPlaces}
					isSearchable={false}
					formatOptionLabel={(country) => (
						<div className={'city-placeOption'}>
							<div className={'city-placePlace'}>{country.label}</div>
						</div>
					)}
					onChange={(e) => {
						if (e) {
							if (e.value !== place) {
								if (
									window.location.href.includes('app.360chicago.com') ||
									window.location.href.includes('app.tourmontparnasse56.com') ||
									window.location.href.includes('app.tv-turm.de') ||
									window.location.href.includes('app.euromast.nl')
								) {
									if (e.value === 'chicago') {
										window?.open('https://app.360chicago.com', '_self');
									} else if (e.value === 'montparnasse') {
										window?.open('https://app.tourmontparnasse56.com', '_self');
									} else if (e.value === 'berlin') {
										window?.open('https://app.tv-turm.de', '_self');
									} else if (e.value === 'rotterdam') {
										window?.open('https://app.euromast.nl', '_self');
									}
								} else {
									navigate('/' + e.value, { replace: true });
									document.location.reload();
								}
							}
						}
					}}
					menuPlacement={'auto'}
				/>
				<div className='tower-title-discover'>{trads.find((t) => t.id === language)?.discover}</div>
			</div>

			{/* <div className='city-title'>
				<div
					className='city-title-cityName'
					style={{
						backgroundColor: towerColor,
					}}
				>
					<div className='city-title-cityNameContent'>{cityName}</div>
				</div>
				<div className='city-title-discover'>{trads.find((t) => t.id === language)?.discover}</div>
			</div> */}

			<Stories cityDatas={props.cityDatas} assets={props.assets} setVideo={props.setVideo} />
			{
				/*props.cityDatas.maudioguids && props.cityDatas.maudioguids.length > 0 &&*/ <AudioGuids
					ags={props.cityDatas.maudioguids}
					setAG={(ag: any) => {
						//props.changeOffsetAngle(ag.angle);
						props.setAG(ag);
					}}
				/>
			}
			<Buildings
				title={trads.find((t) => t.id === language)?.iconicBuildings}
				cityDatas={props.cityDatas}
				assets={props.assets}
				setCard={props.setCard}
				setPoi={props.setPoi}
				sphere={props.sphere}
				setPage={props.setPage}
			/>
			{/* <Quiz /> */}
			<Quiz setQuizPage={props.setQuizPage} assets={props.assets} />
			<Buildings3D cityDatas={props.cityDatas} assets={props.assets} setViewer3d={props.setViewer3d} />
			<OtherPois cityDatas={props.cityDatas} assets={props.assets} setCard={props.setCard} setPoi={props.setPoi} sphere={props.sphere} />
			<div style={{ color: 'white', width: '100%', height: '100px' }}></div>
		</div>
	);
};
