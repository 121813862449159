import * as THREE from "three";
// import Ammo from "ammojs-typed";
import { FikscopeEngine } from "../index";
import { Object_cons_type, FikscopeObject3D } from "../engine/Object";

export type Box_cons_type = {
  fe: FikscopeEngine;
  material: THREE.Material;
  pos: THREE.Vector3;
  quat: THREE.Quaternion;
  w?: number;
  l?: number;
  h?: number;
  mass?: number;
  friction?: number;
  physic?: boolean;
  body?: Ammo.btRigidBody;
  speed?: number;
  feUpdatable?: boolean;
};

export class Box extends FikscopeObject3D {
  private _material: THREE.Material;

  private _w: number;
  private _l: number;
  private _h: number;

  private _mass: number;
  private _friction: number;

  get material(): THREE.Material {
    return this._material;
  }
  set material(material: THREE.Material) {
    this._material = material;
  }

  get w(): number {
    return this._w;
  }
  set w(w: number) {
    this._w = w;
  }

  get l(): number {
    return this._l;
  }
  set l(l: number) {
    this._l = l;
  }

  get h(): number {
    return this._h;
  }

  set h(h: number) {
    this._h = h;
  }

  get mass(): number {
    return this._mass;
  }
  set mass(mass: number) {
    this._mass = mass;
  }

  get friction(): number {
    return this._friction;
  }
  set friction(friction: number) {
    this._friction = friction;
  }

  constructor(
    //should not include fe… FIXME
    {
      fe,
      material,
      pos,
      quat,
      w = 1,
      l = 1,
      h = 1,
      mass = 0,
      friction = 1,
      physic = true,
      feUpdatable = true,
    }: Box_cons_type
  ) {
    //Geometry stuff
    let shape = new THREE.BoxGeometry(w, l, h, 1, 1, 1);

    let mesh = new THREE.Mesh(shape, material);
    mesh.position.copy(pos);
    mesh.quaternion.copy(quat);

    let body = undefined;

    if (fe.physic_enabled && physic) {
      let ammo = fe.ammo;
      let geometry = new ammo.btBoxShape(
        new ammo.btVector3(w * 0.5, l * 0.5, h * 0.5)
      );
      var transform = new ammo.btTransform();
      transform.setIdentity();
      transform.setOrigin(new ammo.btVector3(pos.x, pos.y, pos.z));
      transform.setRotation(
        new ammo.btQuaternion(quat.x, quat.y, quat.z, quat.w)
      );
      var motionState = new ammo.btDefaultMotionState(transform);

      var localInertia = new ammo.btVector3(0, 0, 0);
      geometry.calculateLocalInertia(mass, localInertia);

      var rbInfo = new ammo.btRigidBodyConstructionInfo(
        mass,
        motionState,
        geometry,
        localInertia
      );
      body = new ammo.btRigidBody(rbInfo);

      body.setFriction(friction);
      body.setRestitution(0.9);
      //body.setDamping(1, 1);

      //should be done outside too FIXME ↓
      fe.bulletWorld.physicsWorld.addRigidBody(body);

      if (mass > 0) {
        body.setActivationState(fe.bulletWorld!.DISABLE_DEACTIVATION);
      }
    }

    super({
      pos: pos,
      quat: quat,
      mesh: mesh,
      body: body,
      physic: physic,
      speed: 0,
    } as Object_cons_type);
    this.feUpdatable = feUpdatable;

    let oldupdate = this.update;
    this.update = (args: {
      timestep: number;
      auxTransform?: Ammo.btTransform;
    }) => {
      if (fe.bulletWorld)
        oldupdate({
          timestep: args.timestep,
          auxTransform: fe.bulletWorld.TRANSFORM_AUX,
        });
      else
        oldupdate({
          timestep: args.timestep,
        });
    };

    this._material = material;
    this._w = w;
    this._l = l;
    this._h = h;
    this._mass = mass;
    this._friction = friction;

    this.bindFe(fe);
  }
}
