// Natscope imports
import { SocketInterface } from "./socketInterface"
import { SocketId } from "./utils";
import { log, sockRcv, dataChanRcv } from "./logMode";

let NodeRTCPeerConnection:any
if (typeof window !== 'undefined' || ((typeof process !== 'undefined') && (process.release.name === 'node'))) {
    NodeRTCPeerConnection = require('wrtc').RTCPeerConnection;
}

type Signal<T> = {
  to: SocketId;
  from: SocketId;
  data: T;
};

type SignalingType = "Offer" | "Answer" | "Candidate";

type Signaling =
  | { type: "Offer"; signal: Signal<RTCSessionDescriptionInit> }
  | { type: "Answer"; signal: Signal<RTCSessionDescriptionInit> }
  | { type: "Candidate"; signal: Signal<RTCIceCandidate | null> };

export class PeerConnect {
  /* Variables */
  public peerConnection: RTCPeerConnection;
  public dataChannel: RTCDataChannel;
  public peerSocketId: SocketId;
  public socketInterface: SocketInterface;

  /* Constructor */
  public constructor(peerSocketId: SocketId, socket: SocketInterface, fromServer: boolean) {
    log("PeerConnect ! Constructor !");
    this.peerSocketId = peerSocketId;
    this.socketInterface = socket;
    log("Before RTCPeerConnection");

    let myRTCPeerConnection = null;

    if (fromServer) {
      myRTCPeerConnection = NodeRTCPeerConnection;
    } else {
      myRTCPeerConnection = RTCPeerConnection;
    }

    this.peerConnection = new myRTCPeerConnection({
      iceServers: [
        {
          urls: [
            "stun:stun.l.google.com:19302",
            "stun:stun1.l.google.com:19302",
            "stun:stun2.l.google.com:19302",
          ],
        },
      ],
    });

    log("After RTCPeerConnection");

    /* Connection */
    this.peerConnection.onicecandidate = (e) => this.onIceCandidate(e);
    this.peerConnection.onconnectionstatechange = (e) => this.onConnectionStateChange(e);

    /* Datachannel */
    this.dataChannel = this.peerConnection.createDataChannel("data");
    this.dataChannel.onopen = (e) => this.dataChannelOpened(e);
    this.dataChannel.onclose = (e) => this.dataChannelClosed(e);
    this.dataChannel.onmessage = (e) => this.dataChannelOnMessage(e);

    log("End of constructor");
  }

  /* Initializing connection */
  private createSignal<T>(
    data: T,
    toid: SocketId = this.peerSocketId
  ): Signal<T> {
    if (toid === undefined) console.error("to id not defined !?");
    return {
      to: toid,
      from: this.socketInterface.id,
      data: data,
    };
  }

  private waitUntilSignal = async function (
    pc: PeerConnect,
    e: RTCPeerConnectionIceEvent
  ) {
    return new Promise<Signal<RTCIceCandidate | null>>(
      async (resolve, reject) => {
        const s = pc.createSignal<RTCIceCandidate | null>(e.candidate);
        resolve(s);
      }
    );
  };

  private waitUntilCandidate = async function (
    pc: PeerConnect,
    s: Signal<RTCIceCandidate | null>
  ) {
    return new Promise<Signaling>(async (resolve, reject) => {
      const c = pc.createCandidate(s);
      resolve(c);
    });
  };

  private onIceCandidate(e: RTCPeerConnectionIceEvent) {
    log("SIGNAL 0 - (RTCPeerConnectionIceEvent)", e);
    //let s = this.createSignal<RTCIceCandidate | null>(e.candidate);

    // Callback here
    this.waitUntilSignal(this, e).then((s) => {
      log("SIGNAL 0 - (signal)", s); // TODO : s.data => not null
      this.waitUntilCandidate(this, s).then((c) => {
        log("SIGNAL 0 - (candidate)", c);
        this.socketInterface.emit("signaling", c);
      });
    });
  }

  private onConnectionStateChange(e: Event) {
    console.warn(
      this.peerSocketId +
      ": connection state changed :" +
      this.peerConnection.connectionState
    );
  }

  private createCandidate(s: Signal<RTCIceCandidate | null>): Signaling {
    return { type: "Candidate", signal: s };
  }

  private createOffer(s: Signal<RTCSessionDescriptionInit>): Signaling {
    return { type: "Offer", signal: s };
  }

  private createAnswer(s: Signal<RTCSessionDescriptionInit>): Signaling {
    return { type: "Answer", signal: s };
  }

  /* Datachannel */
  private dataChannelOpened(e: Event) {
    console.warn(this.peerSocketId + ": [Default] dataChannel opened !");
  }

  private dataChannelClosed(e: Event) {
    console.warn(this.peerSocketId + ": [Default] dataChannel closed !");
  }

  private dataChannelOnMessage(e: MessageEvent) {
    log(...dataChanRcv, this.peerSocketId + ": [Default] Message = " + e.data);
  }

  /* Public functions */
  public connect() {
    this.peerConnection.createOffer().then((offer) => {
      this.peerConnection.setLocalDescription(offer).then(() => {
        const s = this.createOffer(
          this.createSignal<RTCSessionDescriptionInit>(offer)
        );
        // log("Sending offer signal to " + this.peerSocketId);
        log("SIGNAL 1 :", s);
        this.socketInterface.emit("signaling", s);
      }).catch((err) => console.error(err));
    }).catch((error) => {
      if (error instanceof Error) {
        console.error(error.message);
      }
    });
  }

  public addConnection(offer: Signal<RTCSessionDescriptionInit>) {
    log(...sockRcv, "Received offer from " + offer.from);
    this.peerConnection.setRemoteDescription(new RTCSessionDescription(offer.data)).then(() => {
      return this.peerConnection.createAnswer();
    }).then((answer) => {
      this.peerConnection.setLocalDescription(answer).then(() => {
        const s = this.createAnswer(
          this.createSignal<RTCSessionDescriptionInit>(answer, offer.from)
        );
        log("SIGNAL 2 -", s);
        this.socketInterface.emit("signaling", s);
      }).catch((err) => console.error(err));
    });
  }
}

export function typeOfSignal(s: Required<Signaling>): SignalingType {
  return s.type;
}
