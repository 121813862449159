export const trads = [
	{
		id: 'en',
		name: 'English',
		deviceOrientation: 'Please keep your mobile phone in portrait mode.',
		// IntroPage
		welcomeTo: 'Welcome to',
        selectLang: 'Select your language',
		startBtn: 'START EXPERIENCE',
		loading: 'Loading...',
		// Menu
		tower: 'The Tower',
		view: 'The View',
		city: 'The city',
		// ViewPage
		yes: 'Yes',
		no: 'No',
		filters: 'Filters',
		exitAudioguid: 'Would you exit view and stop audioguid?',
		// TowerPage
		welcomeTower: 'Welcome to the tower',
		discoverTower: 'Discover the tower',
		view3D: '3D view',
		ticketing: 'Buy tickets',
		magnicityInstagram: ' on instagram',
		followInstagram: ' on instagram',
		exploreTowers: 'Explore other 360 towers',
		exploreTowersText: 'Did you know Magnicity creates expriences for other high POVs around the world? Preview them here and pay us a visit someday!',
		about: 'About',
		credits: 'Copyrights',
		cgu: 'CGU',
		legals: 'Legal Notice',
        privacypolicy: 'Privacy Policy',

		// CityPage
		discoverTreasures: 'Discover other treasures of %%',
		discover: 'Discover the city',
		watchStories: 'Watch our stories',
		audioguids: 'Listen to our stories',
		audioguidsDesc: 'Discover the most interesting anecdotes about the iconic monuments of %%',
		iconicBuildings: 'Iconic buildings',
		iconicBuildings3D: 'Discover iconic monuments in 3D',
		allExplorer: 'Explore All',
		// Poi
		locate: 'Localize',
		// Quiz
		pauseQuiz: 'Pause Quiz',
		didYouKnowBefore: 'How well do you know ',
		didYouKnowAfter: '?',
		startQuiz: 'Start Quiz!',
		answerWell: 'Answers all questions and get the best score!',
		start: 'Start',
		badAnswer: 'Bad answer',
		goodAnswer: 'Good answer',
		badAnswerAnim: 'Almost...',
		goodAnswerAnim: 'Good answer!',
		nextQuestion: 'Next question',
		endQuestion: 'Results',
		quizOver: 'Quiz over!',
		forPlaying: 'Thank you for playing with us',
		score: 'Your score',
		playAgain: 'Play again',
		backMenu: 'Back to menu',
		// Popups
		viewCentered: 'La vue a été recentrée',
		viewCenteredDesc: 'Cliquez sur la boussole pour recentrer à nouveau',
		disableAR: 'AR sensors have been disabled',
		disableARDesc: 'Click again to reactivate',
		enableAR: 'AR sensors have been enabled',
		enableARDesc: 'Click again to disactivate',
		cameraScan: 'Update orientation',
		cameraScanDesc: 'Scan a QR Code to update orientation',
		filterApply: 'Filter applied',
		localize: 'View pointing on this point',

		zoomIn: 'View have been zoomed',
		zoomOut: 'View have been unzoomed',
		zoomOutDesc: 'x1',
		zoomInDesc75: 'x1.3',
		zoomInDesc60: 'x1.6',
		zoomInDesc45: 'x2.2',
		zoomInDesc30: 'x3.3',

		noLandscape: "Please leave your device in portrait format.",

        cookiesTitle: "This website uses cookies",
        cookiesContent: "We use cookies to personalize content and ads, to provide social media features, and to analyze our traffic. We also share information about your use of our site with our social media, advertising, and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.",
        cookiesAccept: "Allow",
        cookiesDenied: "Deny",
        cookiesReadPrivacyPolicy: "Read the privacy policy"
    },
	{
		id: 'fr',
		name: 'Francais',
		deviceOrientation: 'Veuillez garder votre téléphone en mode portrait.',
		// IntroPage
		welcomeTo: 'Bienvenue à',
        selectLang: 'Sélectionnez votre langage',
		startBtn: "COMMENCER L'EXPERIENCE",
		loading: 'Chargement...',
		// Menu
		tower: 'La Tour',
		view: 'La Vue',
		city: 'La Ville',
		// ViewPage
		yes: 'Oui',
		no: 'Non',
		filters: 'Filtres',
		exitAudioguid: 'Voulez-vous quitter la vue et arrêter l\'audioguide ?',
		// TowerPage
		welcomeTower: 'Bienvenue à la tour',
		discoverTower: 'Découvrez la tour',
		view3D: 'Vue 3D',
		ticketing: 'Acheter tickets',
		magnicityInstagram: ' sur instagram',
		followInstagram: ' sur instagram',
		exploreTowers: 'Explorer les autres tours en 360',
		exploreTowersText: 'Saviez-vous que Magnicity a créé des expériences pour d\'autres grands lieux autour du monde ? Prévisualisez les ici et réservez une visite un prochain jour !',
		about: 'À propos',
		credits: 'Crédits',
		cgu: 'CGU',
		legals: 'Mentions légales',
        privacypolicy: 'Politique de confidentialité',

		// CityPage
		discoverTreasures: 'Découvrez les trésors de %%',
		discover: 'Découvrez la ville',
		watchStories: 'Regardez nos histoires',
		audioguids: 'Écoutez nos histoires',
		audioguidsDesc: 'Découvrez des anecdotes croustillantes sur les monuments iconiques de %%',
		iconicBuildings: 'Monuments iconiques',
		iconicBuildings3D: 'Découvrez des monuments iconiques en 3D',
		allExplorer: 'Tout explorer',
		// Poi
		locate: 'Localiser',
		// Quiz
		pauseQuiz: 'Pause Quiz',
		didYouKnowBefore: 'Connaissez-vous bien ',
		didYouKnowAfter: ' ?',
		startQuiz: 'Commencer le quiz !',
		answerWell: 'Répondez à toutes les questions et obtenez le meilleur score !',
		start: 'Démarrer',
		badAnswer: 'Mauvaise réponse :',
		goodAnswer: 'Bonne réponse :',
		badAnswerAnim: 'Pas tout à fait...',
		goodAnswerAnim: 'Bonne réponse !',
		nextQuestion: 'Question suivante',
		endQuestion: 'Résultats',
		quizOver: 'Quiz terminé !',
		forPlaying: 'Merci d\'avoir joué avec nous',
		score: 'Votre score',
		playAgain: 'Rejouer',
		backMenu: 'Revenir au menu',
		// Popups
		viewCentered: 'La vue a été recentrée',
		viewCenteredDesc: 'Cliquez sur la boussole pour recentrer à nouveau',
		disableAR: 'Les capteurs AR ont été désactivés',
		disableARDesc: 'Cliquez à nouveau pour les réactiver',
		enableAR: 'Les capteurs AR ont été activés',
		enableARDesc: 'Cliquez à nouveau pour les désactiver',
		cameraScan: 'Mise à jour de l\'orientation',
		cameraScanDesc: 'Scannez un code QR pour actualiser l\'orientation',
		filterApply: 'Filtre appliqué',
		localize: 'La vue pointe sur le lieu',

		zoomIn: 'La vue a été zoomée',
		zoomOut: 'Niveau de zoom réinitialisé',
		zoomOutDesc: 'x1',
		zoomInDesc75: 'x1.3',
		zoomInDesc60: 'x1.6',
		zoomInDesc45: 'x2.2',
		zoomInDesc30: 'x3.3',

		noLandscape: "Merci de laisser votre appareil au format portrait.",

        cookiesTitle: "Ce site internet utilise des cookies",
        cookiesContent: "Nous utilisons des cookies pour nous assurer de vous offrir la meilleure expérience sur notre site Web. Si vous continuez à utiliser ce site, nous supposerons que vous en êtes satisfait.",
        cookiesAccept: "Accepter",
        cookiesDenied: "Refuser",
        cookiesReadPrivacyPolicy: "Lire la politique de confidentialité"
	},
	{
		id: 'de',
		name: 'Deutsch',
		deviceOrientation: 'Please keep your mobile phone in portrait mode. (ge)',
		// IntroPage
		welcomeTo: 'Willkommen in',
        selectLang: 'Wählen Sie Ihre Sprache',
		startBtn: 'STARTE DAS ABENTEUER!',
		loading: 'Laden',
		// Menu
		tower: 'Der Turm',
		view: 'Die Aussicht',
		city: 'Die Stadt',
		// ViewPage
		yes: 'Ja',
		no: 'No',
		filters: 'Filter',
		exitAudioguid: 'Würden Sie die Ansicht verlassen und audioguid beenden?',
		// TowerPage
		welcomeTower: 'Willkommen im Turm',
		discoverTower: 'Entdecken Sie den Turm',
		view3D: '3D-Ansicht',
		ticketing: 'Tickets kaufen',
		magnicityInstagram: ' auf instagram',
		followInstagram: ' auf instagram',
		exploreTowers: 'Entdecken Sie andere 360-Türme',
		exploreTowersText: 'Wussten Sie, dass Magnicity Erlebnisse für andere hohe POVs auf der ganzen Welt schafft? Schauen Sie sich diese hier an und besuchen Sie uns eines Tages!',
		about: 'Über',
		credits: 'Urheberrechte',
		cgu: 'CGU',
		legals: 'Derechos de autor',
        privacypolicy: 'Privacy Policy',

		// CityPage
		discoverTreasures: 'Entdecken Sie weitere Schätze der %%',
		discover: 'Entdecken Sie die Stadt',
		watchStories: 'Unsere Geschichten ansehen',
		audioguids: 'Hören Sie sich unsere Geschichten an',
		audioguidsDesc: 'Entdecken Sie pikante Anekdoten über die ikonischen Sehenswürdigkeiten von %%',
		iconicBuildings: 'Ikonische Gebäude',
		iconicBuildings3D: 'Entdecken Sie ikonische Denkmäler in 3D',
		allExplorer: 'Alles erforschen',
		// Poi
		locate: 'Lokalisieren',
		// Quiz
		pauseQuiz: 'Quiz Pause',
		didYouKnowBefore: 'Kennen Sie sich gut aus ',
		didYouKnowAfter: ' ?',
		startQuiz: 'Starten Sie das Quiz!',
		answerWell: 'Beantworte alle Fragen und erreiche die höchste Punktzahl!',
		start: 'Start',
		badAnswer: 'Schlechte Antwort',
		goodAnswer: 'Gute Antwort',
		badAnswerAnim: 'Fast...',
		goodAnswerAnim: 'Gute Antwort!',
		nextQuestion: 'Next Frage',
		endQuestion: 'Ergebnisse',
		quizOver: 'Quiz beendet!',
		forPlaying: 'Danke, dass Sie mit uns spielen',
		score: 'Ihre Punktzahl',
		playAgain: 'Erneut spielen',
		backMenu: 'Zurück zum Menü',
		// Popups
		viewCentered: 'Die Aussicht wurde neu zentriert',
		viewCenteredDesc: 'Klicken Sie auf den Kompass, um wieder zu zentrieren',
		disableAR: 'Die AR-Sensoren wurden deaktiviert',
		disableARDesc: 'Zum Reaktivieren erneut klicken',
		enableAR: 'AR-Sensoren wurden aktiviert',
		enableARDesc: 'Zum Deaktivieren erneut klicken',
		cameraScan: 'Ausrichtung aktualisieren',
		cameraScanDesc: 'Scannen eines QR-Codes zur Aktualisierung der Ausrichtung',
		filterApply: 'Angewandter Filter',
		localize: 'View pointing on this point (ge)',

		zoomIn: 'Die Ansicht wurde vergrößert',
		zoomOut: 'Die Ansicht wurde entzoomt',
		zoomOutDesc: 'x1',
		zoomInDesc75: 'x1.3',
		zoomInDesc60: 'x1.6',
		zoomInDesc45: 'x2.2',
		zoomInDesc30: 'x3.3',

		noLandscape: "Bitte lassen Sie Ihre Device im Hochformat.",

        cookiesTitle: "This website uses cookies",
        cookiesContent: "We use cookies to personalize content and ads, to provide social media features, and to analyze our traffic. We also share information about your use of our site with our social media, advertising, and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.",
        cookiesAccept: "Allow",
        cookiesDenied: "Deny",
        cookiesReadPrivacyPolicy: "Read the privacy policy"
	},
	{
		id: 'nl',
		name: 'NEDERLANDS',
		deviceOrientation: 'Please keep your mobile phone in portrait mode. (nl)',
		// IntroPage
		welcomeTo: 'Welkom in',
        selectLang: 'Selecteer uw taal',
		startBtn: 'START ERVARING',
		loading: 'Laden...',
		// Menu
		tower: 'De Toren',
		view: 'Het Uitzicht',
		city: 'De Stad',
		// ViewPage
		yes: 'Ja',
		no: 'Nee',
		filters: 'Filters',
		exitAudioguid: 'Wil je view afsluiten en audioguid stoppen?',
		// TowerPage
		welcomeTower: 'Welkom in de Euromast',
		discoverTower: 'Ontdek de toren',
		view3D: '3D-weergave',
		ticketing: 'Koop tickets',
		magnicityInstagram: ' op instagram',
		followInstagram: ' op instagram',
		exploreTowers: 'Ontdek de andere Magnicity torens!',
		exploreTowersText: 'Wist je dat Magnicity wereldwijd belevenissen creëert op hoge uitkijktorens? Bekijk de andere torens hier!',
		about: 'Over',
		credits: 'Auteursrechten',
		cgu: 'CGU',
		legals: 'Wettelijke kennisgeving',
        privacypolicy: 'Privacy Policy',

		// CityPage
		discoverTreasures: 'Ontdek andere schatten van %%',
		discover: 'Ontdek de stad',
		watchStories: 'Bekijk onze verhalen',
		audioguids: 'Luister naar onze verhalen',
		audioguidsDesc: 'Ontdek de meest interessante anekdotes over de iconische monumenten van %%',
		iconicBuildings: 'Iconische gebouwen',
		iconicBuildings3D: 'Ontdek iconische monumenten in 3D',
		allExplorer: 'Alles verkennen',
		// Poi
		locate: 'Lokaliseren',
		// Quiz
		pauseQuiz: 'Quiz Pauze',
		didYouKnowBefore: 'Hoe goed ken je ',
		didYouKnowAfter: ' ?',
		startQuiz: 'Start de quiz!',
		answerWell: 'Beantwoord alle vragen en haal de hoogste score!',
		start: 'Start',
		badAnswer: 'Slecht antwoord',
		goodAnswer: 'Goed antwoord',
		badAnswerAnim: 'Bijna...',
		goodAnswerAnim: 'Goed antwoord!',
		nextQuestion: 'Volgende vraag',
		endQuestion: 'Resultaten',
		quizOver: 'Quiz voorbij!',
		forPlaying: 'Bedankt voor het meespelen',
		score: 'Uw score',
		playAgain: 'Speel opnieuw',
		backMenu: 'Terug naar menu',
		// Popups
		viewCentered: 'Het uitzicht is geheroriënteerd',
		viewCenteredDesc: 'Klik op het kompas om opnieuw scherp te stellen',
		disableAR: 'AR-sensoren zijn uitgeschakeld',
		disableARDesc: 'Klik nogmaals om opnieuw te activeren',
		enableAR: 'AR-sensoren zijn ingeschakeld',
		enableARDesc: 'Klik nogmaals om te deactiveren',
		cameraScan: 'Update oriëntatie',
		cameraScanDesc: 'Scan een QR-code om de oriëntatie bij te werken',
		filterApply: 'Filter toegepast',
		localize: 'View pointing on this point (nl)',

		zoomIn: 'Beeld is ingezoomd',
		zoomOut: 'De weergave is uitgezoomd',
		zoomOutDesc: 'x1',
		zoomInDesc75: 'x1.3',
		zoomInDesc60: 'x1.6',
		zoomInDesc45: 'x2.2',
		zoomInDesc30: 'x3.3',

		noLandscape: "Laat je apparaat in portretformaat staan.",

        cookiesTitle: "This website uses cookies",
        cookiesContent: "We use cookies to personalize content and ads, to provide social media features, and to analyze our traffic. We also share information about your use of our site with our social media, advertising, and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.",
        cookiesAccept: "Allow",
        cookiesDenied: "Deny",
        cookiesReadPrivacyPolicy: "Read the privacy policy"
	},
	{
		id: 'es',
		name: 'SPANISH',
		deviceOrientation: 'Please keep your mobile phone in portrait mode. (sp)',
		// IntroPage
		welcomeTo: 'Bienvenido a',
        selectLang: 'Seleccione su idioma',
		startBtn: 'COMIENZA LA AVENTURA!',
		loading: 'Cargando...',
		// Menu
		tower: 'La Torre',
		view: 'La Vista',
		city: 'La Ciudad',
		// ViewPage
		yes: 'Sí',
		no: 'No',
		filters: 'Filtros',
		exitAudioguid: '¿Podría salir de la vista y detener el audioguía?',
		// TowerPage
		welcomeTower: 'Bienvenido a la torre',
		discoverTower: 'Descubra la torre',
		view3D: 'Vista en 3D',
		ticketing: 'Comprar boletos',
		magnicityInstagram: ' en instagram',
		followInstagram: ' en instagram',
		exploreTowers: 'Explora otras torres 360',
		exploreTowersText: '¿Sabías que Magnicity crea experiencias para otros POVs de alto nivel en todo el mundo? ¡Prepáralas aquí y visítanos algún día!',
		about: 'Sobre',
		credits: 'Derechos de autor',
		cgu: 'CGU',
		legals: 'Aviso legal',
        privacypolicy: 'Privacy Policy',
        
		// CityPage
		discoverTreasures: 'Descubra otros tesoros del %%',
		discover: 'Descubre la ciudad',
		watchStories: 'Vea nuestras historias',
		audioguids: 'Escuche nuestras historias',
		audioguidsDesc: 'Descubra las anécdotas más interesantes sobre los monumentos emblemáticos de %%',
		iconicBuildings: 'Edificios emblemáticos',
		iconicBuildings3D: 'Descubra monumentos emblemáticos en 3D',
		allExplorer: 'Explorar todo',
		// Poi
		locate: 'Localizar',
		// Quiz
		pauseQuiz: 'Pausa para la prueba',
		didYouKnowBefore: '¿Cómo de bien conoces ',
		didYouKnowAfter: '?',
		startQuiz: '¡Comienza el concurso!',
		answerWell: '¡Responde a todas las preguntas y consigue la máxima puntuación!',
		start: 'Inicie',
		badAnswer: 'Mala respuesta',
		goodAnswer: 'Buena respuesta',
		badAnswerAnim: 'Casi...',
		goodAnswerAnim: 'Buena respuesta!',
		nextQuestion: 'Siguiente pregunta',
		endQuestion: 'Resultados',
		quizOver: 'Se acabó el concurso',
		forPlaying: 'Gracias por jugar con nosotros',
		score: 'Su puntuación',
		playAgain: 'Volver a jugar',
		backMenu: 'Volver al menú',
		// Popups
		viewCentered: 'La vista se ha reorientado',
		viewCenteredDesc: 'Haz clic en la brújula para reenfocar',
		disableAR: 'Los sensores AR han sido desactivados',
		disableARDesc: 'Haga clic de nuevo para reactivar',
		enableAR: 'Se han habilitado los sensores de RA',
		enableARDesc: 'Haga clic de nuevo para desactivar',
		cameraScan: 'Orientación de actualización',
		cameraScanDesc: 'Escanee un código QR para actualizar la orientación',
		filterApply: 'Filtro aplicado',
		localize: 'View pointing on this point (es)',

		zoomIn: 'La vista se ha ampliado',
		zoomOut: 'La vista se ha desdoblado',
		zoomOutDesc: 'x1',
		zoomInDesc75: 'x1.3',
		zoomInDesc60: 'x1.6',
		zoomInDesc45: 'x2.2',
		zoomInDesc30: 'x3.3',

		noLandscape: "Por favor, deje su aparato en formato vertical.",

        cookiesTitle: "This website uses cookies",
        cookiesContent: "We use cookies to personalize content and ads, to provide social media features, and to analyze our traffic. We also share information about your use of our site with our social media, advertising, and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.",
        cookiesAccept: "Allow",
        cookiesDenied: "Deny",
        cookiesReadPrivacyPolicy: "Read the privacy policy"
	},
];

