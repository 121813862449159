// React
import { createReducer } from '@reduxjs/toolkit';
// Types
import { USER_LANGUAGE, USER_SENSORS, USER_PLACE, USER_TOWERCOLOR, USER_PAWN, IAction } from '../actions/types';
import { PSphere } from '../sphere/pawn/PSphere';

export interface IUserState {
	language: string;
	sensors: boolean | null;
	place: string;
	towerColor: string;
	pawn?: PSphere;
}

const initialState: IUserState = {
	language: 'en',
	sensors: null,
	place: 'montparnasse',
	towerColor: '#2BCEF0'
};

export const reducer = createReducer(initialState, (builder) => {
	builder.addCase(USER_LANGUAGE, (state, action: IAction) => {
		state.language = action.payload;
	});
	builder.addCase(USER_SENSORS, (state, action: IAction) => {
		state.sensors = action.payload;
	});
	builder.addCase(USER_PLACE, (state, action: IAction) => {
		state.place = action.payload;
	});
	builder.addCase(USER_TOWERCOLOR, (state, action: IAction) => {
		state.towerColor = action.payload;
	});
	builder.addCase(USER_PAWN, (state, action: IAction) => {
		state.pawn = action.payload;
	});
});
