import * as THREE from "three";

//Core
import { Player } from "./Player";
import { GameStateBase } from "./GameStateBase";
import { CameraOperator } from "./CameraOperator";
import { CameraComponent } from "./CameraComponent";
import { InputManager } from "./InputManager";
import { BulletWorld } from "./BulletWorld";
import { FikscopeLevel } from "./FikscopeLevel";
import { NatscopeClient } from "./../../../natscope_modules/natscope-core";
import { Kompote } from "./../../../settings/fikscopeengine";
import { FENatscope } from "./NatscopeInterface";

import Ammo from "ammojs-typed";
import { Socket } from "socket.io-client";
import { camera_operator_type, Controller } from "..";

import { log } from '../logMode';
import { FEKompote } from "./KompoteInterface";

export type update_input_type = {
	timestep: number;
	auxTransform?: Ammo.btTransform;
};
export type update_type = (args: update_input_type) => void;
export type fikscope_input_type = {
	container?: HTMLElement | HTMLDivElement | null;
	graphic_enabled?: boolean;
	input_enabled?: boolean;
	physic_enabled?: boolean;
	peer_physic?: boolean;
	multi?: boolean;
	orthographic?: boolean;
	natscopeClient?: NatscopeClient;
	kompote?: Kompote<any>;
	gameState?: GameStateBase;
	debugXRInfos?: { url: string, port: number };
};

export interface IUpdatable {
	update(args: update_input_type): void;
}

export class FikscopeEngine {
	//Parameters that have to be defined
	private _clock: THREE.Clock;
	private _scene: THREE.Scene;
	private _physic_enabled: boolean;
	private _peer_physic: boolean;
	private _graphic_enabled: boolean;
	private _input_enabled: boolean;
	private _readyToAnimate: boolean;
	private _pointLock: boolean;
	//FIXME apparemment les objets dans syncList doivent implémenter la "update"
	//updatables ?
	//Ce ne seraient pas des objets THREE.Object3D ?
	private _syncList: IUpdatable[];
	private _gameState: GameStateBase;
	private _orthographic: boolean;

	//Parameters that can be undef
	//(if fikscope is running in server side for instance)
	//↓ Those param are related to _graphic_enabled ↓
	private _renderer: THREE.WebGLRenderer | undefined;
	// private stats: Stats | undefined //FIXME can't be loaded as typescripted
	private _bulletWorld: BulletWorld | undefined;
	private _inputManager: InputManager | undefined;
	// Why do we have _camera AND _cameraOperator containing _camera ? FIXME
	private _cameraOperator: CameraOperator | undefined;

	//Container DOM html quelque chose… FIXME
	private _container: HTMLElement | HTMLDivElement | null;
	private _containerWidth: number;
	private _containerHeight: number;
	// With or without network
	private _INatscope: FENatscope | undefined;
	private _kompote: FEKompote | undefined;

	//Can be undef
	private _ammo: typeof Ammo | undefined;

	private _multi: boolean;

	private _debugxr: Socket | undefined;
	private _debugXRInfos: { url: string; port: number; } | undefined;

	get gameState(): GameStateBase {
		return this._gameState;
	}

	set gameState(gs: GameStateBase) {
		this.updateGameState(gs)
		// this.refreshGameState();
	}

	get players(): Map<string, Player> {
		return this._gameState.players;
	}

	get multi(): boolean {
		if (this._multi === undefined) console.error("multi not defined !");
		return this._multi!;
	}

	set multi(multi: boolean) {
		this._multi = multi;
	}

	//Functions ref
	private _functions: Map<string, () => any> | undefined;

	get ammo(): any {
		if (this._ammo === undefined) console.error("ammo not defined !");
		return this._ammo!;
	}

	set ammo(ammo: any) {
		this._ammo = ammo;
	}

	get map(): FikscopeLevel {
		return this._gameState.map!;
	}

	set map(map: FikscopeLevel) {
		this._gameState.map = map;
	}

	get INatscope(): FENatscope | undefined {
		return this._INatscope;
	}

	set INatscope(natscope: FENatscope | undefined) {
		this._INatscope = natscope;
	}

	get pointLock(): boolean {
		return this._pointLock;
	}

	get readyToAnimate(): boolean {
		return this._readyToAnimate;
	}

	get input_enabled(): boolean {
		return this._input_enabled;
	}

	get graphic_enabled(): boolean {
		return this._graphic_enabled;
	}

	get physic_enabled(): boolean {
		return this._physic_enabled;
	}

	get peer_physic(): boolean {
		return this._peer_physic;
	}

	get scene(): THREE.Scene {
		return this._scene;
	}

	set scene(scene: THREE.Scene) {
		this._scene = scene;
	}

	get clock(): THREE.Clock {
		return this._clock;
	}

	set clock(clock: THREE.Clock) {
		this._clock = clock;
	}

	get syncList(): IUpdatable[] {
		return this._syncList;
	}

	get container(): HTMLElement | HTMLDivElement | null {
		return this._container;
	}

	get bulletWorld(): BulletWorld {
		if (this._bulletWorld === undefined)
			console.error("Puffyland | FE -BulletWorld not defined !");
		return this._bulletWorld!;
	}

	get cameraOperator(): CameraOperator {
		if (this._cameraOperator === undefined)
			console.error("Puffyland | FE -CameraOperator not defined !");
		return this._cameraOperator!;
	}

	get renderer(): THREE.WebGLRenderer {
		if (this._renderer === undefined)
			console.error("Puffyland | FE -renderer not defined !");
		return this._renderer!;
	}

	set renderer(renderer: THREE.WebGLRenderer) {
		this._renderer = renderer;
	}

	get camera(): THREE.Camera {
		if (this.cameraOperator.camera === undefined) {
			console.error("Puffyland | FE -camera not defined !");
		}
		return this.cameraOperator.camera;
	}

	get inputManager(): InputManager | undefined {
		if (this._inputManager === undefined) {
			console.error("Puffyland | FE -inputManager not defined !");
			return undefined;
		} else {
			return this._inputManager;
		}
	}

	get IKompote(): FEKompote | undefined {
		return this._kompote
	}

	constructor({
		graphic_enabled = true,
		input_enabled = true,
		physic_enabled = true,
		peer_physic = false,
		container = null,
		natscopeClient = undefined,
		multi = true,
		orthographic = false,
		gameState = new GameStateBase({}),
		kompote = undefined,
		debugXRInfos
	}: fikscope_input_type) {
		//Parameters that have to be defined
		this._graphic_enabled = graphic_enabled;
		this._physic_enabled = physic_enabled;
		this._peer_physic = peer_physic;
		this._input_enabled = input_enabled;
		this._clock = new THREE.Clock();
		this._scene = new THREE.Scene();
		this._readyToAnimate = false;
		this._syncList = [];

		this._gameState = gameState;
		this._pointLock = true;
		this._ammo = undefined;

		//Parameters that can be undef
		//(if fikscope is running in server side for instance)
		this._bulletWorld = undefined;
		//Defined in cameraOperator
		/* this._camera = undefined; */
		this._orthographic = orthographic;
		this._renderer = undefined;
		// this.stats = undefined; //FIXME can't load stats typed
		this._cameraOperator = undefined;
		this._inputManager = undefined;

		this._container = null;
		this._containerWidth = 640;
		this._containerHeight = 480;

		//Only client side ?
		if (this.graphic_enabled) {
			this._container = container;
			this._containerWidth = window.innerWidth;
			this._containerHeight = window.innerHeight;

			if (this.container) {
				if (this.container.clientWidth !== window.innerWidth && this.container.clientHeight !== window.innerHeight) {
					this._containerWidth = this.container.clientWidth;
					this._containerHeight = this.container.clientHeight;
				}
			}
		}

		this._multi = multi;
		this._debugXRInfos = debugXRInfos;

		log("Puffyland | FE - Init");

		//natscope
		/* if (natscopeClient) { */
		/*   setInterval(function () { */
		/*     log(natscopeClient); */
		/*   }, 5000); */
		/* } */

		if (kompote) {
			this._kompote = new FEKompote({
				kompote
			})
		}

		if (graphic_enabled && this.container) {
			this.initGraphics();
		}

		//Default graphics ↓ Specifics one should be initiated in Maps
		//the camera should be defined after initGraphics
		var aspect = this._containerHeight / this._containerWidth;

		//Camera
		let camera = undefined;
		if (this._orthographic) {
			camera = new THREE.OrthographicCamera(
				-25,
				25,
				25 * aspect,
				-25 * aspect,
				-200,
				2000000
			);
		} else {
			camera = new THREE.PerspectiveCamera(
				45,
				this._containerWidth / this._containerHeight,
				0.01,
				2000000
			);
		}
		// var ISO_QUATERNION: THREE.Quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0.96, 0, 0.79), Math.PI / 2)
		camera.up.set(0, 0, 1);
		camera.position.set(30, 30, 30);

		const defaultCameraPawn = new CameraComponent({
			camera,
			renderer: this.renderer,
		});
		this._cameraOperator = new CameraOperator({
			defaultCameraComponent: defaultCameraPawn,
		} as camera_operator_type);

		const defaultController = new Controller();

		if (graphic_enabled){
			if (input_enabled && defaultController) {
				this._inputManager = new InputManager(
					this.renderer.domElement,
					this.pointLock
				);
				if (this.inputManager) this.inputManager.setInputReceiver(defaultController);
			}
		}

		this.init(this.gameState.map);

		let _this = this;
		if (multi && natscopeClient) {
			this._INatscope = new FENatscope({
				fe: _this,
				natscope: natscopeClient,
			});
		}

		// Debug xr
		if (this._debugXRInfos) {
			let url = this._debugXRInfos.url + ':' + this._debugXRInfos.port;

			import("socket.io-client")
				.then(({ io }) => {
					this._debugxr = io(url, {
						rejectUnauthorized: false,
						secure: true,
					});

					this._debugxr.on("connect_error", function () {
						log("XR - Connection Failed");
					});

					this._debugxr.on("who", function () {
						var who = "win64";
						var nAgt = navigator.userAgent;

						if (nAgt.indexOf("Quest") !== -1) {
							who = "Quest";
						}

						if (_this && _this._debugxr) {
							_this._debugxr.emit("who", who);
						}
					});
				})
		}
	}

	public init(map: FikscopeLevel) {
		this.map = map;
		if (this.physic_enabled) {
			Ammo(Ammo).then(() => {
				this._ammo = Ammo;
				this._bulletWorld = new BulletWorld(this._ammo);
				log("Puffyland | FE - Physic enabled");
				log("Puffyland | FE - ", this.bulletWorld);
				this._readyToAnimate = true;
				if (this.map) {
					this.map.init(this);
				} else {
					alert("Puffyland | FE - No map loaded !");
				}
				this.map.onStart({});
				this.start();
			});
		} else {
			this._readyToAnimate = true;

			if (this.map) {
				this.map.init(this);
			} else {
				alert("Puffyland | FE - No map loaded !");
			}
			this.map.onStart({});
			this.start();
		}
	}

	public debugxrlog(data: any) {
		if (this._debugXRInfos) {
			if (this._debugxr?.connected) {
				this._debugxr.emit("log", data);
			}
		}
	}

	public start() {
		if (this.graphic_enabled) {
			this.renderer.setAnimationLoop(() => {
				// Update
				this.subanimate();

				// Render
				if (this.graphic_enabled) {
					this.renderer.render(this.scene, this.camera);
				}
			});
			log("Starting Natscope loop");
			log(this.gameState);
			if (this.INatscope) this.INatscope.start();

			this.refreshGameState();
		}
	}

	public stop() {
		this.renderer.setAnimationLoop(null);
		if (this.INatscope) this.INatscope.stop();
	}

	public manualAnimate(dt:number){
		this.subanimate(dt);
	}

	private async subanimate(dt?:number) {
		// TODO : Behavior to remove from module :
		let me = null;

		if (this.INatscope && this.INatscope.natscope.me) {
			me = this.players.get(this.INatscope.natscope.me.id);
		} else {
			me = this.players.get('me');
		}

		//console.log(me)

		if (me) {
			if (this.map.winGame && this.map.winGame({ player: me })) {
				alert("You won !");
				// this.natscope.callFunc("send_won"); // TODON
				this.map.onWin({ player: me });
				this.map.onStop({});
			} else {

				if (this.map.looseGame && this.map.looseGame({ player: me })) {
					this.map.onLoose({ player: me });
				}

				//window.requestAnimationFrame(() => this.subanimate());

				if (this.readyToAnimate) {
					//Animate target by gamepad
					//gamepadd(input);

					this.scene.updateMatrixWorld();

					var newdt = this.clock.getDelta();

					if(dt){
						newdt = dt;
					}

					for (var i = 0; i < this.syncList.length; i++) {
						this.syncList[i].update({ timestep: newdt });
					}

					if (this.physic_enabled) this.bulletWorld.physicsWorld.stepSimulation(newdt, 10);
					if (this.graphic_enabled) this.cameraOperator.currentCameraPawn.update({ timestep: newdt });

					this._gameState.map.update(newdt);

				}
			}
		} else {
			console.warn('could not get me', this.players);
		}
	}

	private animate() {
		this.subanimate();
		//var delta = clock.getDelta();
	}

	private initGraphics() {
		//Already done
		// this.clock = new THREE.Clock();

		THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);

		//Scene already init in constructor
		// this.scene = new THREE.Scene();

		//Renderer
		let paramsRenderer = {
			antialias: true,
		};

		// FIXME fikscopeEngine never had a canvas…
		// if (this.canvas !== null) {
		//   paramsRenderer.canvas = this.canvas;
		// }

		this.renderer = new THREE.WebGLRenderer(paramsRenderer);
		log("Puffyland | FE - ", this.renderer);
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(this._containerWidth, this._containerHeight);
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

		//renderer.gammaInput = true;
		//renderer.gammaOutput = true;

		if (this.container) {
			this.container.innerHTML = "";
			this.container.appendChild(this.renderer.domElement);
		} else {
			console.warn('this.container undefined');
		}

		let _this = this;
		window.addEventListener("resize", function () { _this.onWindowResize(_this); }, false);

		// stats FIXME stats for typescript does not exist…
		// this.stats = new Stats();
		// this.container.appendChild(this.stats.dom);
	}

	public onWindowResize(fe: FikscopeEngine) {

		if (fe.container) {
			this._containerWidth = fe.container.clientWidth;
			this._containerHeight = fe.container.clientHeight;
		}

		if (fe.graphic_enabled && fe.container) {
			if (fe.camera instanceof THREE.PerspectiveCamera) {
				log('Perspective Camera - Resize');

				fe.camera.aspect = this._containerWidth / this._containerHeight;
				fe.renderer.setSize(this._containerWidth, this._containerHeight);
				fe.camera.updateProjectionMatrix();

			} else if (fe.camera instanceof THREE.OrthographicCamera) {
				log('Orthographic Camera - Resize');

				fe.renderer.setSize(this._containerWidth, this._containerHeight);

				var aspect = this._containerHeight / this._containerWidth;

				fe.camera.left = -25;
				fe.camera.right = 25;
				fe.camera.top = 25 * aspect;
				fe.camera.bottom = -25 * aspect;
			}
		}
	}

	private send_update() {
		//Refresh rate of this player position over network
		setTimeout(() => this.send_update(), 20);
		this.send_player_position();
	}

	public send_player_position() {
		//let me = this.players.get("me")!;
		// let mesh = me.pawn?.feObject?.mesh;
		/* if (mesh)
				this.natscope.send_pos(mesh.position, mesh.quaternion); */
		// TODON
	}

	// Functions ref
	public call(name: string, args: any[]): any {
		let tmp = this._functions?.get(name);
		if (tmp) tmp(...(args as []));
	}

	public register(name: string, func: () => any): () => any {
		if (this._functions === undefined) this._functions = new Map();
		this._functions.set(name, func);
		return func;
	}

	public updateGameState(gs: GameStateBase) {
		// TODO stuff with map a.k.a fikscopeLevel
		/* this._scene = new THREE.Scene(); */
		/* this._syncList = []; */
		gs.players.forEach((p: Player) => {
			let our_p = this.players.has(p.id)
				? this.players.get(p.id)
				: this.map.newPlayerPeer(this, p.nick, p.id);
			log(p)
			our_p!.move(p.pawn!.position);
		});

	}

	public refreshGameState() {
		// TODO stuff with map a.k.a fikscopeLevel
		/* this._scene = new THREE.Scene(); */
		/* this._syncList = []; */
		this.players.forEach((p: Player) => {
			p.bindFe(this);
		});
	}

	public async new_map(map: FikscopeLevel) {
		this._readyToAnimate = false;
		this.map = map;
		this.refreshGameState();

		if (this.physic_enabled) {
			this._bulletWorld = new BulletWorld(await this._ammo!);
			log("Puffyland | FE - Physic enabled");
			log("Puffyland | FE - ", this.bulletWorld);
		}

		this._readyToAnimate = true;

		if (this.map) {
			this.map.init(this);
		} else {
			alert("Puffyland | FE - No map loaded !");
		}

		this.map.onStart({});
		this.animate();
		this.send_update();
	}

	/* Create Object */
	public new_object(mesh: THREE.Mesh) {
		this._scene.add(mesh);
	}

	public remove_object(mesh: THREE.Mesh | THREE.Group) {
		this._scene.remove(mesh)
	}

	public execFunc(func: any) {
		func();
	}

	public me(): Player | void {
		let gotMe = null;

		if (this.INatscope && this.INatscope.natscope.me) {
			gotMe = this.players.get(this.INatscope.natscope.me.id);
		} else {
			gotMe = this.players.get('me');
		}

		if (gotMe) {
			return gotMe;
		} else {
			console.warn('could not get me');
		}

	}
}
