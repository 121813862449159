// React
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Modules
import axios from 'axios';
// Utils
import getApiUrl from '../../utils/getApiUrl';
import parseText from '../utils/parseText';
// Style
import './details.css';
// Assets
import playIcon from '../../style/assets/icons/playIcon.png';
import pointFocus from '../../style/assets/icons/pointFocus.png';
// Trads
import { trads } from '../../datas/trads.js';
import { Buildings } from '../city/Buildings';
import { completeString } from '../utils/completeString';

type dataCard = {
	name: {
		translations: Array<{
			value: String;
		}>;
	};
	m3dmodel: any;
	mstory: any;
	section1Title: {
		translations: Array<{
			value: String;
		}>;
	};
	section1Content: {
		translations: Array<{
			value: String;
		}>;
	};
	section2Title: {
		translations: Array<{
			value: String;
		}>;
	};
	section2Content: {
		translations: Array<{
			value: String;
		}>;
	};
	section3Title: {
		translations: Array<{
			value: String;
		}>;
	};
	section3Content: {
		translations: Array<{
			value: String;
		}>;
	};
	sectionAddTitle: {
		translations: Array<{
			value: String;
		}>;
	};
	sectionAddContent: {
		translations: Array<{
			value: String;
		}>;
	};
};

export function Details(props: any) {
	const { language, towerColor, place } = useSelector((state: any) => state.user);
	const [data, setData] = useState<null | dataCard>(null);
	const [img, setImg] = useState<String>('');

	useEffect(() => {
		if (props.card == null) {
		} else {
			let elem = document.getElementById('transitionDetails');

			if (elem) {
				console.log('Passage');
				elem.style.transition = 'opacity 0s';
				elem.style.opacity = '1';
			}

			axios.get(getApiUrl() + '/mcard/id/' + props.card, {}).then(function (response) {
				if (response.data.mcard && response.data.mcard.ffile && response.data.mcard.ffile.data && response.data.mcard.ffile.data.subffiles) {
					if (response.data.mcard.ffile.data.subffiles.length > 0) {
						for (let file of response.data.mcard.ffile.data.subffiles) {
							if (file.status === 'active') {
								let fileName = file.path.split('\\');
								if (fileName.length > 0) {
									console.log(fileName[1]);

									setImg(getApiUrl() + '/subffile/get/' + fileName[1]);
								}
							}
						}
					}
				}

				if (response.data.mcard.mstory?._id) {
					axios.get(getApiUrl() + '/mstory/id/' + response.data.mcard.mstory._id, {}).then(function (responseStory) {
						response.data.mcard.mstory = responseStory.data.mstory;

						if (response.data.mcard.m3dmodel._id) {
							axios.get(getApiUrl() + '/m3dmodel/id/' + response.data.mcard.m3dmodel._id, {}).then(function (responseModel) {
								response.data.mcard.m3dmodel = responseModel.data.m3dmodel;

								setData(response.data.mcard);
							});
						} else {
							setData(response.data.mcard);
						}
					});
				} else if (response.data.mcard.m3dmodel?._id) {
					axios.get(getApiUrl() + '/m3dmodel/id/' + response.data.mcard.m3dmodel._id, {}).then(function (responseModel) {
						response.data.mcard.m3dmodel = responseModel.data.m3dmodel;

						setData(response.data.mcard);
					});
				} else {
					setData(response.data.mcard);
				}
			});
		}
	}, [props.card]);

	useEffect(() => {
		if (data) {
			let elem = document.getElementById('transitionDetails');

			if (elem) {
				elem.style.transition = 'opacity 1s';
				elem.style.opacity = '0';
			}
		}
	}, [data]);

	let close = function () {
		if (props.setCard && props.setPoi) {
			props.setCard(null);
			props.setPoi(null);
		}
		if (props.page === 'explorer') {
			props.showCompass(false);
		}
	};

	useEffect(() => {
		let scroll = document.getElementById('scrollElem');

		if (scroll) {
			scroll.scrollTop = 0;
		}
	});

	const cutFunction = (string?: String) => {
		if (!string) {
			return [''];
		}
		let res = [];
		let cutted = string.split(' ');

		let part = '';
		let i = 0;
		while (i < cutted.length) {
			if (part.length + cutted[i].length > 20) {
				res.push(part);
				part = '';
			} else {
				part += cutted[i] + ' ';
				i++;
			}
		}
		res.push(part);
		return res;
	};

	let poiLinked;
	for (let s of props.cityDatas.spheres) {
		for (let p of s.pois) {
			if (p.mcard === props.card) {
				poiLinked = p.short;
			}
		}
	}

	return (
		<React.Fragment>
			<div
				className='details-container'
				style={{
					top: props.card ? '0' : '100%',
				}}
			>
				<div
					id='transitionDetails'
					style={{
						position: 'absolute',
						height: '100%',
						width: '100%',
						zIndex: 30000,

						opacity: '0',
						backgroundColor: 'black',
						pointerEvents: 'none',
						transition: 'opacity 1s',
					}}
				></div>
				<div
					id='scrollElem'
					className='details-content'
					style={
						{
							// backgroundColor: towerColor
						}
					}
				>
					<div className='details-menuTop'>
						<div className='details-menuTop-back' onClick={() => close()}></div>
						{poiLinked && (
							<div
								className='details-menuTop-locate'
								onClick={() => {
									//props.setCard(null);
									//props.setPage('explorer');

									let exitLoop = false;
									for (let s of props.cityDatas.spheres) {
										for (let p of s.pois) {
											if (p.mcard === props.card) {
												props.resetCompass();
												props.localize(p.short);

												let transition = document.getElementById('transition');

												if (transition) {
													transition.style.opacity = '1';
												}

												setTimeout(() => {
													props.setSphere(s.short);
													props.setPoi(p.short);

													setTimeout(() => {
														if (transition) {
															transition.style.opacity = '0';

															props.setPopup({
																title: 'localize',
																desc: '',
																icon: pointFocus,
															});
														}
													}, 500);
												}, 500);

												exitLoop = true;
												break;
											}
										}
										if (exitLoop) {
											break;
										}
									}
								}}
							>
								<div className='details-menuTop-locateIcon'></div>
								<div className='details-menuTop-locateText'>{trads.find((t) => t.id === language)?.locate}</div>
							</div>
						)}
					</div>
					{data !== null && data.hasOwnProperty('name') ? (
						<div>
							<div
								className='details-img'
								style={{
									backgroundImage: "url('" + img + "')",
								}}
							></div>
							{cutFunction(data.name.translations.find((t: any) => t.langage === language)?.value).map((elem: any, index: any) => {
								return (
									<span
										key={'details-title' + index}
										className='details-title'
										style={{
											backgroundColor: towerColor,
										}}
									>
										{elem}
										<br />
									</span>
								);
							})}
							<div className='details-text'>
								{data.section1Title ? <h3>{data.section1Title.translations.find((t: any) => t.langage === language)?.value}</h3> : null}
								{data.section1Content ? (
									<p>{parseText(data.section1Content.translations.find((t: any) => t.langage === language)?.value, towerColor)}</p>
								) : null}
								{data.section2Title ? <h3>{data.section2Title.translations.find((t: any) => t.langage === language)?.value}</h3> : null}
								{data.section2Content ? (
									<p>{parseText(data.section2Content.translations.find((t: any) => t.langage === language)?.value, towerColor)}</p>
								) : null}
								{data.section3Title ? <h3>{data.section3Title.translations.find((t: any) => t.langage === language)?.value}</h3> : null}
								{data.section3Content ? (
									<p>{parseText(data.section3Content.translations.find((t: any) => t.langage === language)?.value, towerColor)}</p>
								) : null}
								{data.sectionAddTitle ? <h3>{data.sectionAddTitle.translations.find((t: any) => t.langage === language)?.value}</h3> : null}
								{data.sectionAddContent ? (
									<p>{parseText(data.sectionAddContent.translations.find((t: any) => t.langage === language)?.value, towerColor)}</p>
								) : null}
							</div>
							{(data.mstory || data.m3dmodel) && (
								<div className='details-complement'>
									<div className='details-complementTitle'>{data.name.translations.find((t: any) => t.langage === language)?.value}</div>
									<div className='details-complementContent'>
										{data.mstory && (
											<div
												onClick={() => {
													let story = props.cityDatas.stories.find((s: any) => s.short === props.cityDatas.mstory.short);

													let channelName = 'main_' + language;

													let pathMovie = story.media.data.subffiles.find(
														(sf: any) => sf.status === 'active' && sf.channel === channelName,
													);
													if (!pathMovie) {
														pathMovie = story.media.data.subffiles.find(
															(sf: any) => sf.status === 'active' && sf.channel === 'main',
														);
													}

													pathMovie = pathMovie.path;

													let pathMovieFinal = pathMovie.split('\\')[1];
													if (!pathMovieFinal) {
														pathMovieFinal = pathMovie.split('/')[1];
													}

													let finalPathMovie = getApiUrl() + '/content/file/' + pathMovieFinal + '?play';

													props.setVideo(finalPathMovie);
												}}
												className={'details-complementContent-item '}
											>
												<div className={'details-complementContent-itemContent '}>
													<div
														className={'details-complementContent-itemContentInside '}
														style={{
															backgroundImage:
																"url('" +
																getApiUrl() +
																'/content/file/' +
																place +
																'/' +
																data.mstory.media.data?.subffiles
																	.find((sf: any) => sf.status === 'active' && sf.channel === 'miniature')
																	.path.replace('\\', '/') +
																"')",
														}}
													>
														<div
															className={'details-complementContent-itemContentInsideIcon '}
															style={{
																backgroundImage: "url('" + playIcon + "')",
															}}
														></div>
													</div>
												</div>
												<div
													className={'details-complementContent-desc '}
													style={{
														color: towerColor,
													}}
												>
													{data.mstory?.name?.translations?.find((t: any) => t.langage === language)?.value}
												</div>
											</div>
										)}
										{data.m3dmodel && (
											<div
												onClick={() => {
													console.log('Launch Building3d : ', data.m3dmodel);

													let view = props.cityDatas.m3dmodels.find((m: any) => m.short === data.m3dmodel.short);

													let tmp = view.ffile.data.subffiles.find((sf: any) => sf.status === 'active' && sf.channel === 'viewer3d');
													let pathMovie = tmp.path;

													if (pathMovie) {
														let pathMovieFinal = pathMovie.split('\\')[1];
														if (!pathMovieFinal) {
															pathMovieFinal = pathMovie.split('/')[1];
														}

														let finalPathMovie = getApiUrl() + '/content/file/' + place + '/upload/' + pathMovieFinal;

														let movie = {
															url: finalPathMovie,
															size: tmp.size,
														};

														props.setViewer3d(movie);
													}
												}}
												className={'details-complementContent-item '}
											>
												<div className={'details-complementContent-itemContent '}>
													<div
														className={'details-complementContent-itemContentInside '}
														style={{
															backgroundImage:
																"url('" +
																getApiUrl() +
																'/content/file/' +
																place +
																'/' +
																data.m3dmodel.ffile.data?.subffiles
																	.find((sf: any) => sf.status === 'active' && sf.channel === 'miniature')
																	.path.replace('\\', '/') +
																"')",
														}}
													></div>
												</div>
												<div
													className={'details-complementContent-desc '}
													style={{
														color: towerColor,
													}}
												>
													{data.m3dmodel?.name?.translations?.find((t: any) => t.langage === language)?.value}
												</div>
											</div>
										)}
									</div>
								</div>
							)}
							<Buildings
								cityDatas={props.cityDatas}
								assets={props.assets}
								setCard={props.setCard}
								sphere={props.sphere}
								setPoi={props.setPoi}
								title={completeString(trads.find((t) => t.id === language)?.discoverTreasures, [
									place !== 'montparnasse' ? place : 'paris',
								]).toUpperCase()}
								setPage={props.setPage}
							/>
						</div>
					) : null}
					<p
						style={{
							textAlign: 'center',
						}}
					>
						<span className='btnClose' onClick={() => close()}>
							Close
						</span>
					</p>
				</div>
			</div>
		</React.Fragment>
	);
}
