// React
import { useSelector } from 'react-redux';
// CSS
import './city.css';
// Utils
import getApiUrl from '../../utils/getApiUrl';

export const Buildings = (props: { title?: string; cityDatas: any; assets: any; setCard: any; setPoi: any; sphere: any; setPage: any }) => {
	const { language, place, towerColor } = useSelector((state: any) => state.user);

	const scroll = (e: any) => {
		let index = Math.round(e.target.scrollLeft / 240);

		let elemsGradientLeft = document.getElementsByClassName('city-buildings-caroussel-item-gradientLeft');
		for (let elemGradientLeft of elemsGradientLeft) {
			if (index > 0) {
				(elemGradientLeft as HTMLElement).style.opacity = '1';
			} else {
				(elemGradientLeft as HTMLElement).style.opacity = '0';
			}
		}
		let elemsGradientRight = document.getElementsByClassName('city-buildings-caroussel-item-gradientRight');
		for (let elemGradientRight of elemsGradientRight) {
			if (index <= props.cityDatas.cards.length) {
				(elemGradientRight as HTMLElement).style.opacity = '1';
			} else {
				(elemGradientRight as HTMLElement).style.opacity = '0';
			}
		}
	};

	return (
		<div className='city-buildings'>
			<div className='city-buildings-title'>{props.title}</div>
			{props.cityDatas.cards && (
				<div
					className='city-buildings-caroussel'
					style={{
						margin: '0 0 0 0',
					}}
				>
					<div
						id='city-buildings-caroussel-item-gradientLeft'
						className={'city-buildings-caroussel-item city-buildings-caroussel-item-gradientLeft '}
						style={{
							margin: '0 0 0 -24px',
							pointerEvents: 'none',
							position: 'absolute',
							zIndex: 95000,
							left: '0px',
							background: 'linear-gradient(90deg, rgba(2,0,36,0.8) 0%, rgba(8,13,23,0) 20%, rgba(8,13,23,0) 100%)',
							top: '0px',
							width: '240px',
							height: '100%',
							opacity: '0',
							transition: 'opacity 0.5s',
						}}
					></div>
					<div
						id='city-buildings-caroussel-item-gradientRight'
						className={'city-buildings-caroussel-item city-buildings-caroussel-item-gradientRight '}
						style={{
							margin: '0 0 0 -24px',
							pointerEvents: 'none',
							position: 'absolute',
							zIndex: 95000,
							left: 'calc(100vw - 240px)',
							background: 'linear-gradient(270deg, rgba(2,0,36,0.8) 0%, rgba(8,13,23,0) 20%, rgba(8,13,23,0) 100%)',
							top: '0px',
							width: '240px',
							height: '100%',
							transition: 'opacity 0.5s',
						}}
					></div>
					<div id='city-buildings-caroussel' className='city-buildings-caroussel' style={{ overflowX: 'auto' }} onScroll={(e) => scroll(e)}>
						<div
							id='city-buildings-carousselContent'
							className='city-buildings-carousselContent'
							style={{ width: props.cityDatas.cards.length * 240 + 120 + 'px' }}
						>
							{props.cityDatas.cards.map((card: any, index: number) => {
								let path = card.ffile.data.subffiles
									.find((sf: any) => sf.status === 'active' && sf.channel === 'preview')
									.path.replace('\\', '/');
								let finalPath = getApiUrl() + '/content/file/' + place + '/' + path;

								return (
									<div
										key={'city-buildings-caroussel-item-' + index}
										onClick={() => {
											props.setPoi(props.sphere.pois.find((p: any) => p.mcard === card._id)?.short);
											props.setCard(card._id);
										}}
										className={'city-buildings-caroussel-item '}
										style={{ position: 'relative', left: '24px' }}
									>
										<div className={'city-buildings-caroussel-itemContent '}>
											<div
												className={'city-buildings-caroussel-itemContentInside '}
												style={{
													backgroundImage: "url('" + finalPath + "')",
												}}
											></div>
										</div>
										<div className='city-buildings-caroussel-desc'>
											<span
												style={{
													backgroundColor: towerColor,
												}}
											>
												{card.name.translations.find((t: any) => t.langage === language)?.value}
											</span>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
