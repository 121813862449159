// User Actions
export const USER_LANGUAGE = 'user_language';
export const USER_SENSORS = 'user_sensors';
export const USER_PLACE = 'user_place';
export const USER_TOWERCOLOR = 'user_towercolor';
export const USER_PAWN = 'user_pawn';
// Quiz Actions
export const QUIZ_PROGRESS = 'quiz_progress';
// Sounds Action
export const SOUNDS_CURRENT = 'sounds_current';
export const SOUNDS_PROGRESS = 'sounds_progress';
export const SOUNDS_READY = 'sounds_ready';

// DispatchType
export type DispatchType = (args: any) => any;
// Iaction
export interface IAction {
	type: string;
	payload?: any;
}
