// React
import { useSelector } from 'react-redux';
// CSS
import './city.css';
// Assets
import { trads } from '../../datas/trads';

export const Quiz = (props: { setQuizPage: any; assets: any }) => {
	const { language, towerColor, place } = useSelector((state: any) => state.user);

	return (
		<div
			className='city-quiz'
			style={{
				backgroundColor: towerColor,
				backgroundImage: 'url(' + props.assets.find((a: any) => a.name === 'quiz')?.url?.replace('\\', '/') + ')',
			}}
		>
			<div className='city-quizTop'></div>
			<div className='city-quizTopText'>
				<div className='city-quizTopTextTitle'>{trads.find((t: any) => t.id === language)?.pauseQuiz}</div>
				<div
                    className='city-quizTopTextSubtitle'
                    style={{
                        color: towerColor,
                    }}
                >
					<div style={{width: '8px', height: '48px', float: "left"}}></div>
					<span style={{backgroundColor: 'black', padding: '0 8px 0 0'}}>{trads.find((t: any) => t.id === language)?.didYouKnowBefore + (place === 'montparnasse' ? 'Paris' : place[0].toUpperCase() + place.slice(1)) + trads.find((t: any) => t.id === language)?.didYouKnowAfter}</span></div>
			</div>
            <div className='city-quizButton' onClick={() => props.setQuizPage(true)}>
                {trads.find((t: any) => t.id === language)?.startQuiz}
            </div>
		</div>
	);
};
