import axios from 'axios';
import { Dispatch } from 'react';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
// utils
import getApiUrl from '../../utils/getApiUrl';
import { parseFiles } from './parseFiles';

const data = [
	// Modèles
	{
		name: 'sphere360_left',
		url: '/assets/models/sphere/SPHERE_LEFT_8K.fbx',
		target: 'models',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
	{
		name: 'sphere360_right',
		url: '/assets/models/sphere/SPHERE_RIGHT_8K.fbx',
		target: 'models',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},

	// Poi
	{
		name: 'texturePoi',
		url: '/assets/textures/poi_zoom.png',
		target: 'textures',
		total: 0,
		progress: 0,
		ready: false,
		file: null,
	},
];

export class Loader {
	public assetsList: any;
	public total: number;
	public progress: number;
    private debug: boolean;
	public audio: boolean;
	public setProgress: any;
	public dispatch: any;

	private lastCall: number;

	constructor(dispatch: Dispatch<any>, place: string, setDatas?: any, quizProgress?: any[], audio?: boolean, setProgress?: any) {
		this.dispatch = dispatch
		this.assetsList = data; // change with assetsList
		this.total = 0;
		this.progress = 0;
        this.debug = false;
		this.audio = audio ? true : false;
		this.setProgress = setProgress;
		this.lastCall = Date.now();

		axios
			.get(getApiUrl() + '/content/file/' + place + '/' + place + '.json')
			.then((x) => {
				//console.log('↳ Datas retrieved: ', x.data);
				if (setDatas) {
					setDatas(x.data);
				}
				axios
					.get(getApiUrl() + '/content/file/' + place + '/' + place + '_list.json')
					.then((y) => {
						this.assetsList = parseFiles(dispatch, place, x.data, y.data.files, quizProgress);

						if(this.audio){

							setDatas(this.assetsList);

							this.assetsList = (this.assetsList as Array<any>).filter((asset) => asset.target === "audios");
						}else{
							this.assetsList = (this.assetsList as Array<any>).filter((asset) => asset.target !== "audios");
						}

						for (let entry of data) {
							this.assetsList.push(entry);
						}

						let interval = setInterval(() => {
							if (document.readyState === 'complete') {
								clearInterval(interval);
								this.download();
							} else {
								//alert('need other loop')
							}
						}, 1000);
					})
					.catch(function (error) {
						//alert("Magnicity failed to retrieve file's size list.");
					});
			})
			.catch(function (error) {
				//alert('Magnicity failed to retrieve file list.');
			});
	}

	updateLoadingDetails(){
		if((Date.now() - this.lastCall) > 200){
			this.updateLoading();
			this.lastCall = Date.now();
		}
	}

    writeDebug = (data: string) => {
        if(this.debug){
            let debugLoader = document.getElementById("debugLoader");

            if(debugLoader){
                debugLoader.innerHTML += data;
            }
        }
    }

    clearDebug = () => {
        if(this.debug){
            let debugLoader = document.getElementById("debugLoader");

            if(debugLoader){
                debugLoader.innerHTML = "";
            }
        }
    }

	addRessources = (list: any) => {};

	waitReady = () =>
		new Promise((resolve, reject) => {
			let lastTime = Date.now();
			let tmp = this.progress;

			let interval = setInterval(() => {
				if (this.progress >= 100) {
					clearInterval(interval);
					resolve('Assets are ready to use.');
				}
				if (tmp === this.progress) {
					if (Date.now() - lastTime > 20000) {
						lastTime = Date.now();
						tmp = 0;
						setTimeout(() => {
                            //console.log("DL AGAIN")
							//this.download();
						}, 1000);
						//clearInterval(interval);
						//reject('Assets loading failed');
					}
				} else {
					tmp = this.progress;
				}
			}, 500);
		});

	updateLoading = () => {
		let total = 0;
		let progress = 0;
		for (let a of this.assetsList) {
			total += a.total;
			progress += a.progress;
		}
		console.log('progress/total', progress, total);
		this.progress = ((progress / total) * 100);

		if(this.setProgress){
			console.log(this.progress)
			this.dispatch(this.setProgress(this.progress))
		}

        this.clearDebug();

        let loadingOver = true;

        for (let i = 0; i < this.assetsList.length; i++) {
			if (!this.assetsList[i].ready) {
                loadingOver = false;
                this.writeDebug(this.assetsList[i].name+" NO<br />")
            }else{
                //this.writeDebug(this.assetsList[i].name+" YES<br />")
                //this.writeDebug(this.progress+"<br />")
            }
        }

        if(loadingOver){
            this.progress = 100;
            //this.writeDebug("OVER<br />")

            let total = 0;

            for (let i = 0; i < this.assetsList.length; i++) {
                console.log(this.assetsList[i].name+" - "+(this.assetsList[i].total/1000000)+"");
                total += this.assetsList[i].total;
            }

            console.log("TOTAL : "+(total/1000000))

        }else{
            this.writeDebug(this.progress+"<br />")
        }

        //this.writeDebug(this.progress+"<br />")
	};

	download = () => {

        this.clearDebug()

		// let fileLoader = new THREE.FileLoader();
		// let fontLoader = new THREE.FontLoader();
		let audioLoader = new THREE.AudioLoader();
		let textureLoader = new THREE.TextureLoader();
		// let gltfLoader = new GLTFLoader();
		let fbxLoader = new FBXLoader();

		let _this = this;

		let getSize = function (index: number) {
			var xhr = new XMLHttpRequest();
			xhr.open('HEAD', _this.assetsList[index].url, true);
			xhr.onreadystatechange = function () {
				if (this.readyState === this.DONE) {
					let size = xhr.getResponseHeader('Content-Length');
					if (size) {
						_this.assetsList[index].total = parseInt(size);
						_this.total += parseInt(size);
					} else {
						_this.assetsList[index].total = 0;
					}
				}
			};
			xhr.send();
		};

		for (let i = 0; i < this.assetsList.length; i++) {
			if (!this.assetsList[i].ready) {
				if (this.assetsList[i].url === '') {
					this.assetsList[i].ready = true;
					this.assetsList[i].total = 0;
				} else if (this.assetsList[i].total === undefined || this.assetsList[i].total === 0) {
					getSize(i);
				}
				switch (this.assetsList[i].target) {
					case 'models':
						fbxLoader
							.loadAsync(this.assetsList[i].url, (xhr) => {
								this.assetsList[i].progress = xhr.loaded;
								//console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
								this.updateLoadingDetails();
							})
							.then((object) => {
								this.assetsList[i].progress = this.assetsList[i].total;
								this.assetsList[i].file = object;
								this.assetsList[i].ready = true;
								this.updateLoading();
							})
							.catch((error) => {
								//alert('Magnicity failed to load asset : ' + this.assetsList[i].name);
								console.log(error);
							});
						break;
					case 'textures':
						textureLoader
							.loadAsync(this.assetsList[i].url, (xhr) => {
								this.assetsList[i].progress = xhr.loaded;
								//console.log((xhr.loaded / xhr.total) * 100 + '% loaded Texture');
								this.updateLoadingDetails();
							})
							.then((texture: THREE.Texture) => {
								this.assetsList[i].progress = this.assetsList[i].total;
								this.assetsList[i].file = texture;
								this.assetsList[i].ready = true;
								this.updateLoading();
							})
							.catch((error) => {
								//alert('Magnicity failed to load asset : ' + this.assetsList[i].name);
								console.log(error);
							});
						break;
					case 'audios':
							let _this = this;
						(async function () {
							await axios
								.get(_this.assetsList[i].url, {
									responseType: 'blob',
									onDownloadProgress: (progressEvent) => {
										//console.log(progressEvent);
										_this.assetsList[i].progress = progressEvent.loaded;
										_this.updateLoadingDetails();
									},
								})
								.then((res) => {
									let myUrl = (window.URL || window.webkitURL).createObjectURL(res.data); // response.data.data

									_this.assetsList[i].progress = _this.assetsList[i].total;
									_this.assetsList[i].file = myUrl;
									_this.assetsList[i].ready = true;
									_this.updateLoading();

									/*let videoElem = document.getElementById('videoViewer3D') as HTMLVideoElement;
									if (videoElem) {
										videoElem.src = myUrl;
										videoElem.setAttribute('type', res.headers['content-type']);
										videoElem.load();
									}*/
			
									return res.data;
								});
						})();



						// audioLoader
						// 	.loadAsync(this.assetsList[i].url, (xhr) => {
						// 		this.assetsList[i].progress = xhr.loaded;
						// 		this.updateLoading();
						// 	})
						// 	.then((audio) => {
						// 		this.assetsList[i].progress = this.assetsList[i].total;
						// 		this.assetsList[i].file = audio;
						// 		this.assetsList[i].ready = true;
						// 		this.updateLoading();
						// 	})
						// 	.catch((error) => {
						// 		//alert('Magnicity failed to load asset : ' + this.assetsList[i].name);
						// 		console.log(error);
						// 	});
						break;
					default:
						alert('Magnicity failed to load asset : ' + this.assetsList[i].name);
						console.warn('ERROR in loading');
						this.assetsList[i].ready = true;
						this.updateLoading();
						break;
				}
			}
		}

		console.log('assetlist', this.assetsList);
	};
}
