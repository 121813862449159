// {
//     name: 'texturePoi',
//     url: './assets/textures/poi_zoom.png',
//     target: 'textures',
//     total: 0,
//     progress: 0,
//     ready: false,
//     file: null,
// },
// React
import { Dispatch } from 'react';
import { setQuizProgress } from '../../actions/quiz';

import getApiUrl from '../../utils/getApiUrl';

export const parseFiles = (dispatch: Dispatch<any>, place: string, content: any, sizes: { n: string; s: number }[], quizProgress?: any[]) => {
	let datas: any[] = [];

	console.log('content=', content);

	for (let sphere of content.spheres) {
		// console.log('Sphere = ', sphere);
		if (sphere.ffile && sphere.ffile.data) {
			for (let file of sphere.ffile.data.subffiles) {
				//console.log('file=', file);
				if (file.status === 'active' && !file.streaming) {
					// Add data
					let split = file.path.split('\\');
					let fileName = split[split.length - 1];

					let data = {
						name: sphere.short + '-' + file.channel,
						url: getApiUrl() + '/content/file/' + place + '/' + file.path,
						target: /* file.short.includes('-poi') ? 'texturesAnimated' : */ 'textures',
						total: sizes.find((s) => s.n === fileName)?.s,
						progress: 0,
						ready: false,
						file: null,
					};

					datas.push(data);
				}
			}
		}
	}

	// Layers
	for (let layer of content.mlayers) {
		// console.log('Sphere = ', sphere);
		if (layer.media && layer.media.data) {
			for (let file of layer.media.data.subffiles) {
				//console.log('file=', file);
				if (file.status === 'active' && !file.streaming) {
					// Add data
					let split = file.path.split('\\');
					let fileName = split[split.length - 1];

					let data = {
						name: layer.short + '-' + file.channel,
						url: getApiUrl() + '/content/file/' + place + '/' + file.path,
						target: /* file.short.includes('-poi') ? 'texturesAnimated' : */ 'textures',
						total: sizes.find((s) => s.n === fileName)?.s,
						progress: 0,
						ready: false,
						file: null,
					};

					datas.push(data);
				}
			}
		}
	}

	let fileQuiz = content.ffile.data.subffiles.find((sf: any) => sf.channel === 'quiz' && sf.status === 'active');
	if (fileQuiz) {
		let split = fileQuiz.path.split('\\');
		let fileName = split[split.length - 1];

		let data = {
			name: fileQuiz.channel,
			url: getApiUrl() + '/content/file/' + place + '/' + fileQuiz.path,
			target: 'textures',
			total: sizes.find((s) => s.n === fileName)?.s,
			progress: 0,
			ready: false,
			file: null,
		};

		datas.push(data);
	}

	// Audioguids
	for (let ag of content.maudioguids) {
		// console.log('Sphere = ', sphere);
		if (ag.media && ag.media.data) {
			for (let file of ag.media.data.subffiles) {
				//console.log('file=', file);
				if (file.status === 'active' && !file.streaming) {
					// Add data
					let split = file.path.split('\\');
					let fileName = split[split.length - 1];

					let data = {
						name: ag.short + '-' + file.channel,
						url: getApiUrl() + '/content/file/' + place + '/' + file.path,
						target: 'audios',
						total: sizes.find((s) => s.n === fileName)?.s,
						progress: 0,
						ready: false,
						file: null,
					};

					datas.push(data);
				}
			}
		}
	}


	// Quiz Managment
	if (!quizProgress || !quizProgress.find((qP) => qP.city === place)) {
		let cityContent = {
			city: place,
			score: 0,
			questions: content.mquestions,
			answers: [],
		};

		let progress: any[];
		if (quizProgress) {
			progress = [...quizProgress];
		} else {
			progress = [];
		}

		progress?.push(cityContent);

		dispatch(setQuizProgress(progress));
	}

	

	console.log('datas =', datas);

	return datas;
};
