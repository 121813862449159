// React
import { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../actions/user';
import Select from 'react-select';
// Components
import { Discover } from './Discover';
// CSS
import './tower.css';
// Trads
import { trads } from '../../datas/trads.js';
import { useNavigate } from 'react-router-dom';
// Assets
import enFlag from '../../style/assets/flags/en.png';
import frFlag from '../../style/assets/flags/fr.png';
import deFlag from '../../style/assets/flags/de.png';
import esFlag from '../../style/assets/flags/es.png';
import nlFlag from '../../style/assets/flags/nl.png';
import folderIcon from '../../style/assets/icons/credits_icon.png';
import cguIcon from '../../style/assets/icons/cgu_icon.png';
import instagramWallMontparnasse from '../../style/assets/instagramWallMontparnasse.jpg';
import instagramWallBerlin from '../../style/assets/instagramWallBerlin.png';
import instagramWallChicago from '../../style/assets/instagramWallChicago.png';
import instagramWallRotterdam from '../../style/assets/instagramWallRotterdam.png';

export const Tower = (props: { cityDatas: any; assets: any; setCard: any; setVideo: any; setViewer3d: any; setPage: any; setPoi: any; sphere: any }) => {
	const { language, towerColor, place } = useSelector((state: any) => state.user);

	const navigate = useNavigate();
	const dispatch: Dispatch<any> = useDispatch();

	const getInstaUrl = () => {
		switch (place) {
			case 'montparnasse':
				return 'https://instagram.com/parismontparnasse.topofthecity?igshid=wkh449ynhw67';
			case 'berlin':
				return 'https://instagram.com/berliner_fernsehturm?igshid=1l8u6pbcty13o';
			case 'chicago':
				return 'https://instagram.com/360chicago?utm_medium=copy_link';
			case 'rotterdam':
				return 'https://instagram.com/euromast010?utm_medium=copy_link';
		}
	};

	const getTicketingUrl = () => {
		switch (place) {
			case 'montparnasse':
				switch (language) {
					case 'fr':
						return 'https://billetterie-tourmontparnasse56.tickeasy.com/fr-FR/produits?_ga=2.209009111.415059756.1626785860-681861597.1626687147';
					case 'en':
						return 'https://billetterie-tourmontparnasse56.tickeasy.com/en-US/products';
					default:
						break;
				}
				break;
			case 'berlin':
				switch (language) {
					case 'en':
						return 'https://tv-turm.de/en/';
					case 'de':
						return 'https://tv-turm.de';
					default:
						break;
				}
				break;
			case 'chicago':
				switch (language) {
					case 'en':
						return 'https://360chicago.com/360-chicago-offers';
					case 'es':
						return 'https://360chicago.com/360-chicago-offers';
					default:
						break;
				}
				break;
			case 'rotterdam':
				switch (language) {
					case 'nl':
						return 'https://euromast.nl/tickets';
					case 'en':
						return 'https://euromast.nl/en/buy-tickets';
					default:
						break;
				}
				break;
			default:
				break;
		}
		return 'http://google.com';
	};

	const instagramUrl = getInstaUrl();
	const ticketingUrl = getTicketingUrl();

	const options = [
		{ value: 'en', label: 'English', image: enFlag, places: ['montparnasse', 'berlin', 'chicago', 'rotterdam'] },
		{ value: 'fr', label: 'French', image: frFlag, places: ['montparnasse'] },
		{ value: 'de', label: 'German', image: deFlag, places: ['berlin'] },
		{ value: 'nl', label: 'Neetherlands', image: nlFlag, places: ['rotterdam'] },
		{ value: 'es', label: 'Español', image: esFlag, places: ['chicago'] },
	];

	const setLang = (lang: string) => {
		dispatch(setLanguage(lang));
	};

	const optionsPlaces = [
		{ value: 'montparnasse', label: 'montparnasse' },
		{ value: 'berlin', label: 'berlin' },
		{ value: 'chicago', label: 'chicago' },
		{ value: 'rotterdam', label: 'rotterdam' },
	];

	useEffect(() => {
		let titleElem = document.getElementsByClassName('tower-placeSelection__control');

		if (titleElem) {
			for (let elem of titleElem) {
				(elem as HTMLElement).style.backgroundColor = towerColor;
			}
		}
	}, []);

	let towerName = '';
	let instagramWall;
	let followInstagram = '';
	switch (place) {
		case 'montparnasse':
			towerName = 'Montparnasse';
			instagramWall = instagramWallMontparnasse;
			followInstagram = '@parismontparnasse.topofthecity';
			break;
		case 'berlin':
			towerName = language === 'de' ? 'Berliner Fernsehturm' : 'TV Tower';
			instagramWall = instagramWallBerlin;
			followInstagram = '@Berliner_fernsehturm';
			break;
		case 'chicago':
			towerName = '360 chicago';
			instagramWall = instagramWallChicago;
			followInstagram = '@360CHICAGO';
			break;
		case 'rotterdam':
			towerName = 'Euromast';
			instagramWall = instagramWallRotterdam;
			followInstagram = '@euromast010';
			break;
	}

	return (
		<div id='tower' className='tower'>
			<div className='tower-lang'>
				<Select
					id={'tower-langSelection'}
					className={'tower-langSelection'}
					classNamePrefix={'tower-langSelection'}
					defaultValue={options[0]}
					value={options.filter((e) => e.value === language)[0]}
					options={options.filter((e) => e.places.includes(place))}
					isSearchable={false}
					formatOptionLabel={(country) => (
						<div className={'tower-langOption'}>
							<div
								className='tower-langFlag'
								style={{
									backgroundImage: 'url(' + country.image + ')',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'center',
								}}
							></div>
							<div className={'tower-langLang'}>{country.value}</div>
						</div>
					)}
					onChange={(e) => {
						if (e) {
							setLang(e.value);
						}
					}}
					menuPlacement={'auto'}
				/>
			</div>
			<div className='tower-title'>
				<Select
					id={'tower-placeSelection'}
					className={'tower-placeSelection'}
					classNamePrefix={'tower-placeSelection'}
					defaultValue={options[0]}
					value={optionsPlaces.filter((e) => e.value === place)[0]}
					options={optionsPlaces}
					isSearchable={false}
					formatOptionLabel={(country) => (
						<div className={'tower-placeOption'}>
							<div className={'tower-placePlace'}>{country.value}</div>
						</div>
					)}
					onChange={(e) => {
						if (e) {
							if (e.value !== place) {
								if (
									window.location.href.includes('app.360chicago.com') ||
									window.location.href.includes('app.tourmontparnasse56.com') ||
									window.location.href.includes('app.tv-turm.de') ||
									window.location.href.includes('app.euromast.nl')
								) {
									if (e.value === 'chicago') {
										window?.open('https://app.360chicago.com', '_self');
									} else if (e.value === 'montparnasse') {
										window?.open('https://app.tourmontparnasse56.com', '_self');
									} else if (e.value === 'berlin') {
										window?.open('https://app.tv-turm.de', '_self');
									} else if (e.value === 'rotterdam') {
										window?.open('https://app.euromast.nl', '_self');
									}
								} else {
									navigate('/' + e.value, { replace: true });
									document.location.reload();
								}
							}
						}
					}}
					menuPlacement={'auto'}
				/>
				<div className='tower-title-discover'>{trads.find((t) => t.id === language)?.welcomeTower}</div>
			</div>

			{/* Discover */}
			<Discover
				cityDatas={props.cityDatas}
				assets={props.assets}
				setCard={props.setCard}
				setVideo={props.setVideo}
				setViewer3d={props.setViewer3d}
				setPoi={props.setPoi}
				sphere={props.sphere}
			/>

			{/* Ticketing */}
			<div
				className='tower-button clickable '
				onClick={() => {
					window?.open(ticketingUrl, '_blank')?.focus();
				}}
			>
				<div className='tower-buttonText'>{trads.find((t) => t.id === language)?.ticketing.toUpperCase()}</div>
			</div>

			{/* Instagram */}
			<div
				className={'tower-instagram clickable '}
				onClick={() => {
					window?.open(instagramUrl, '_blank')?.focus();
				}}
			>
				<div className={'tower-instagram-title'}>
					<div className={'tower-instagram-titleLogo'}></div>
					<div className={'tower-instagram-titleText'}>{towerName + trads.find((t) => t.id === language)?.magnicityInstagram}</div>
				</div>
				<div className='tower-instagram-wallContainer'>
					<div className='tower-instagram-wall' style={{ backgroundImage: `url(${instagramWall})` }}>
						<div className='tower-instagram-wall-textBackground'>
							<div className='tower-instagram-wall-text'>{followInstagram + trads.find((t) => t.id === language)?.followInstagram}</div>
						</div>
					</div>
				</div>
			</div>

			{/* Other towers */}
			<div className='tower-explorer'>
				<div className={'tower-explorer-title'}>{trads.find((t) => t.id === language)?.exploreTowers}</div>
				<div className='tower-explorer-text'>{trads.find((t) => t.id === language)?.exploreTowersText}</div>
			</div>
			<div className='tower-explorerImgContainer'>
				<div className='tower-explorerImg'>
					<div
						className='tower-explorerImg-Part clickable '
						onClick={() => {
							if (
								window.location.href.includes('app.360chicago.com') ||
								window.location.href.includes('app.tourmontparnasse56.com') ||
								window.location.href.includes('app.tv-turm.de') ||
								window.location.href.includes('app.euromast.nl')
							) {
								window?.open('https://app.360chicago.com', '_self');
							} else {
								navigate('/chicago', { replace: true });
								document.location.reload();
							}
						}}
					></div>
					<div
						className='tower-explorerImg-Part clickable '
						onClick={() => {
							if (
								window.location.href.includes('app.360chicago.com') ||
								window.location.href.includes('app.tourmontparnasse56.com') ||
								window.location.href.includes('app.tv-turm.de') ||
								window.location.href.includes('app.euromast.nl')
							) {
								window?.open('https://app.tourmontparnasse56.com', '_self');
							} else {
								navigate('/montparnasse', { replace: true });
								document.location.reload();
							}
						}}
					></div>
					<div
						className='tower-explorerImg-Part clickable '
						onClick={() => {
							if (
								window.location.href.includes('app.360chicago.com') ||
								window.location.href.includes('app.tourmontparnasse56.com') ||
								window.location.href.includes('app.tv-turm.de') ||
								window.location.href.includes('app.euromast.nl')
							) {
								window?.open('https://app.euromast.nl', '_self');
							} else {
								navigate('/rotterdam', { replace: true });
								document.location.reload();
							}
						}}
					></div>
					<div
						className='tower-explorerImg-Part clickable '
						onClick={() => {
							if (
								window.location.href.includes('app.360chicago.com') ||
								window.location.href.includes('app.tourmontparnasse56.com') ||
								window.location.href.includes('app.tv-turm.de') ||
								window.location.href.includes('app.euromast.nl')
							) {
								window?.open('https://app.tv-turm.de', '_self');
							} else {
								navigate('/berlin', { replace: true });
								document.location.reload();
							}
						}}
					></div>
				</div>
			</div>
			<div className='tower-about'>
				<div className={'tower-about-title'}>{trads.find((t) => t.id === language)?.about}</div>
				<div className='tower-about-text clickable ' onClick={() => props.setPage('credits')}>
					<div
						className='tower-about-icon'
						style={{
							backgroundImage: 'url(' + folderIcon + ')',
						}}
					></div>
					{trads.find((t) => t.id === language)?.credits}
				</div>
				<div onClick={() => props.setPage('cgu')} className='tower-about-text clickable '>
					<div
						className='tower-about-icon'
						style={{
							backgroundImage: 'url(' + cguIcon + ')',
						}}
					></div>
					{trads.find((t) => t.id === language)?.cgu}
				</div>
				<div className='tower-about-text clickable ' onClick={() => props.setPage('legals')}>
					<div
						className='tower-about-icon'
						style={{
							backgroundImage: 'url(' + cguIcon + ')',
						}}
					></div>
					{trads.find((t) => t.id === language)?.legals}
				</div>
                <div className='tower-about-text clickable ' onClick={() => props.setPage('privacypolicy')}>
					<div
						className='tower-about-icon'
						style={{
							backgroundImage: 'url(' + cguIcon + ')',
						}}
					></div>
					{trads.find((t) => t.id === language)?.privacypolicy}
				</div>
			</div>

			<div style={{ color: 'white', width: '100%', height: '100px' }}></div>
		</div>
	);
};
