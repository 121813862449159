import { NatscopeClient } from "./client";
import { User, Peer } from "./user";
import { ChiefRoom, PeerRoom } from "./room";
import { send_safeEvent, send_safeMessage } from "./messaging";

import { log, sockSnd } from "./logMode";

export type SocketId = string;

export function replacer(_: any, value: any) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}

export function reviver(_: any, value: any) {
  if (typeof value === "object" && value !== null) {
    if (value.dataType === "Map") {
      return new Map(value.value);
    }
  }
  return value;
}

export function updateUserInfos(nat: NatscopeClient, user: User): void {
  const myUser = nat.users.get(user.id);

  if (myUser) {

    if (nat.me && myUser.id === nat.me.id) {
      Object.assign(nat.me, user);
      //console.log(user)
    }

    nat.users.set(user.id, user)
  }
}

export function updateUserInfosInRooms(nat: NatscopeClient, userToUpdate: User, chiefId: SocketId): void { 
  const userRoom = nat.rooms.get(chiefId);

  if (userRoom) {
    if (nat.myroom && userRoom.name === nat.myroom.name) {
      const foundUser = nat.myroom.users.find(myUser => myUser.id === userToUpdate.id);
      if (foundUser) {
        Object.assign(foundUser, userToUpdate);
      }

      if (userToUpdate.id === chiefId) {
        Object.assign(nat.myroom.chief, userToUpdate);
      }
    }

    const foundUser = userRoom.users.find(myUser => myUser.id === userToUpdate.id);
    if (foundUser) {
      Object.assign(foundUser, userToUpdate);
      nat.rooms.set(chiefId, userRoom);
    }

    if (userToUpdate.id === chiefId) {
      Object.assign(userRoom.chief, userToUpdate);
    }
    
  }
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const waitUntilRooms = (nat: NatscopeClient) => {
  return new Promise<void>(async (resolve, reject) => {
    const delta = 20; // Time between two tests in ms
    let limit = 2000; // Limit time in ms
    let next = false;

    while (next === false && limit > 0) {
      limit -= delta;

      if (nat.rooms.size > 0) {
        next = true;
      } else {
        await sleep(delta);
      }
    }

    if (next) {
      resolve();
    } else {
      reject();
    }
    
  })
}

export const waitUntilUsers = async (nat: NatscopeClient) => {
  return new Promise<void>(async (resolve, reject) => {
    const delta = 20; // Time between two tests in ms
    let limit = 2000; // Limit time in ms
    let next = false;

    while (next === false && limit > 0) {
      limit -= delta;
      if (nat.users.size > 0) {
        next = true;
      } else {
        await sleep(delta);
      }
    }

    if (next) {
      resolve();
    } else {
      reject();
    }
  })
}

export const checkUser = (_this: NatscopeClient, user: User) => {
  let userFound = _this.users.get(user.id);
  
  if (!userFound) {
    userFound = new User(user.id, user.nick, user.groups, user.webRTC);
    _this.users.set(user.id, user);
  } else {
    Object.assign(userFound, user);
  }
  return userFound;
}

export const chatMessageEmit = (_this: PeerRoom | ChiefRoom, msg: string) => {
  const toSendChat = {
    sender: {
      id: _this.chief.id,
      nick: _this.chief.nick,
      groups: _this.chief.groups
    },
    created: new Date(),
    data: msg
  };

  _this.chat.push(toSendChat);

  if (!_this.fromServer) {
    _this.onChatMessage.emit(toSendChat);
  }

  const socketUsers = _this.users.filter(user => user.webRTC === false && user.id !== _this.socketInterface.id);
  const arraySocketId = socketUsers.map(user => user.id);

  if (_this instanceof ChiefRoom) {
    _this.peers.forEach((p) => send_safeMessage(p, msg));
  }
  
  if (arraySocketId.length > 0) {
    log(sockSnd, 'chatMessage');
    _this.socketInterface.emit('chatMessage', { msg: toSendChat, arraySocketId: arraySocketId });
  }
}

export const eventEmit = (_this: PeerRoom | ChiefRoom, eventName:string, event: customRoomEvent<any, any>, target: string, ...args: any) => {
  const finalUsers = _this.users.slice();
  const chiefFound = _this.users.find(user => user.id === _this.chief.id);

  if (!chiefFound) {
    finalUsers.push(_this.chief);
  }

  const socketUsers = finalUsers.filter(user => user.webRTC === false && user.id !== _this.socketInterface.id);
  let arraySocketId = socketUsers.map(user => user.id);
  let peers = new Map<string, Peer>();

  switch (event.type) {
    case 'unreplicated':
      arraySocketId = [];
      peers = new Map<string, Peer>();
      break;
    case 'multicast':
      if (_this instanceof ChiefRoom) {
        _this.peers.forEach(p => peers.set(p.id, p));
      }
      break;
    case 'onlychief':
      if (_this instanceof PeerRoom) {
        arraySocketId = [_this.chief.id];
      } else if (_this instanceof ChiefRoom) {
        arraySocketId = [];
        peers = new Map<string, Peer>();
      }
      break;
    case 'group':
      const groupSocketUsers = socketUsers.filter(user => user.groups === event.target);
      arraySocketId = groupSocketUsers.map(user => user.id);
      if (_this instanceof ChiefRoom) {
        _this.peers.forEach(p => {
          if(p.groups === event.target){
              peers.set(p.id, p)
          }
        });
      }
      // TODO better deal with peers
      break;
    case 'target':
      const targetSocketUsers = socketUsers.filter(user => user.id === target);
      arraySocketId = targetSocketUsers.map(user => user.id);
      if (_this instanceof ChiefRoom) {
        _this.peers.forEach(p => {
          if(p.id === target){
            peers.set(p.id, p)
          }
        });
      }
      break;
  
    default:
      break;
  }

  const toSend = JSON.stringify({ eventName: eventName, args: args })

  if (_this instanceof ChiefRoom) {
    peers.forEach((p) => send_safeEvent(p, toSend));
  }
  
  if (arraySocketId.length > 0) {
    log(...sockSnd, 'event ' + eventName);
    _this.socketInterface.emit('event', { eventName: eventName, args: args, arraySocketId: arraySocketId });
  }
}

export class NatEvent<T, U> {
  private listeners: Array<(detail:T) => U>;

  constructor() {
    this.listeners = new Array<(detail:T) => U>();
  }
  
  on(fn:(detail:T) => U): NatEvent<T, U> {
    this.listeners.push(fn);
    return this;
  }

  emit(arg:T): boolean {
    let fns = this.listeners;
    if (!fns) return false;
    fns.forEach((f) => {
      f(arg);
    });
    return true;
  }
}

export type T_customRoomEventType = 'unreplicated' | 'multicast' | 'onlychief' | 'group' | 'target';

export class customRoomEvent<T, U> extends NatEvent<T, U> {
  public type: T_customRoomEventType;
  public target: string;

  constructor(type: T_customRoomEventType, target?: string) {
    super();
    this.type = type;
    if(target){
        this.target = target;
    }else{
        this.target = "";
    }
  }
}
