// React
import { createReducer } from '@reduxjs/toolkit';
// Types
import { SOUNDS_CURRENT, SOUNDS_PROGRESS, SOUNDS_READY, IAction } from '../actions/types';

export interface ISoundsState {
	current: string;
	progress: number;
	ready: boolean;
}

const initialState: ISoundsState = {
	current: '',
	progress: 0,
	ready: false
};

export const reducer = createReducer(initialState, (builder) => {
	builder.addCase(SOUNDS_CURRENT, (state, action: IAction) => {
		state.current = action.payload;
	});
	builder.addCase(SOUNDS_PROGRESS, (state, action: IAction) => {
		state.progress = action.payload;
	});
	builder.addCase(SOUNDS_READY, (state, action: IAction) => {
		state.ready = action.payload;
	});
});
