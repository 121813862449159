// React
import { useSelector } from 'react-redux';
// CSS
import './explorer.css';
// Utils
import { trads } from '../../datas/trads.js';
// Assets
import filterIcon from '../../style/assets/icons/filter.png';

export const Filters = (props: any) => {
	const { language, towerColor } = useSelector((state: any) => state.user);

	return (
		<div id='filters' className='filters' style={{ pointerEvents: 'none' }}>
			<div className='filters-content'>
				<div className='filters-title'>{trads.find((t: any) => t.id === language)?.filters}</div>
				{props.datas.spheres.map((elem: any, index: number) => {
					return (
						<div
							key={'sphere-' + index}
							className='filters-sphere clickable disableNaturalClick '
							onClick={() => {
								let transition = document.getElementById('transition');

								if (transition) {
									transition.style.opacity = '1';
								}

								setTimeout(() => {
									props.setSphere(elem.short);

									setTimeout(() => {
										if (transition) {
											transition.style.opacity = '0';

											props.setPopup({
												title: 'filterApply',
												desc: '',
												icon: filterIcon,
											});
										}
									}, 500);
								}, 500);
							}}
							style={{
								width: 100 / props.datas.spheres.length + '%',
							}}
						>
							<div
								className='filters-sphere-img'
								style={{
									backgroundImage: `url('${
										props.assets.find((asset: any) => asset.name.includes(elem.short) && asset.name.includes('mini') && !asset.name.includes('miniature')).file.image.src
									}')`,
									border: props.sphere === elem.short ? towerColor : 'rgba(0, 0, 0, 0.8)',
								}}
							></div>
							<div
								className='filters-sphere-text'
								style={{
									opacity: props.sphere === elem.short ? '1' : '0.6',
								}}
							>
								{elem.name.translations.find((x: any) => x.langage === language)?.value}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
