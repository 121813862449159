import './pointPosition.css';

export const PointPosition = (props: { current: number; total: number }) => {
	return (
		<div className='pointPosition'>
			{[...Array(props.total)].map((e, i) => {
				return <div key={'pointPosition-'+i} className='pointPosition-point' style={{
                    backgroundColor: i === props.current ? '#F9FAFB' : '#6B7280'
                }}></div>;
			})}
		</div>
	);
};
