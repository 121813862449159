// React
import { useSelector } from "react-redux";
// CSS
import './audioguid.css';
// Imports
import closeIcon from '../../style/assets/icons/closeIcon.png'

export const AudioGuid = (props: {
    ag: any, 
    onClick: any,
    currentTime?: any,
    close: any,
    closeDisplay: boolean
}) => {
    const { language, towerColor } = useSelector((state: any) => state.user);
	const { progress, ready } = useSelector((state: any) => state.sounds);
    
	const formatTime = (val: number) => {
		if (!val) {
			return '0:00';
		}

		let rest : string | number = Math.floor(val % 60);
		if (String(rest).length < 2) {
			rest = '0'+rest;
		}

		let ret = Math.floor(val / 60) + ':' + rest;

		return ret;
	};

	const onClickPlay = () => {
		if(ready){
			props.onClick();
		}
	}

	return (
		<div className='city-audioguids-card'>
			<div id={'card-'+props.ag.short} className="city-audioguids-id" style={{display: 'none'}}></div>
			<div className='city-audioguids-cardIcon clickable ' onClick={onClickPlay}>
				<div
                    className='city-audioguids-cardIconCircle'
                    style={{
                        border: "1px solid "+towerColor+"",
                    }}
                >
					{ ready ?
					<div
                        id='city-audioguids-cardIconCircleButton'
                        className='city-audioguids-cardIconCirclePlay'
                    ></div>
					: <div
						style={{
							color:"white"
						}}
					>{Math.floor(progress)+"%"}</div>
					}
				</div>
				<div className='city-audioguids-cardIconText'>{formatTime(props.ag.duration - (props.currentTime !== undefined ? Math.floor(props.currentTime) : 0))}</div>
			</div>
			<div className='city-audioguids-cardContent'>
				<div className='city-audioguids-cardContentTop'>
					<div
                        className={"city-audioguids-cardImg city-audioguids-cardImg_"+towerColor.replace("#", "")+""}
                        style={{
                            /*backgroundImage: "url(../../style/assets/audioguid/headphones_"+towerColor+".png)"*/
                        }}
                    ></div>
					<div className='city-audioguids-cardSubtitle'>{props.ag.subtitle.translations.find((t: any) => t.langage === language)?.value}</div>
				</div>
				<div className='city-audioguids-cardTitle'>{props.ag.title.translations.find((t: any) => t.langage === language)?.value}</div>
			</div>
			<div className='city-audioguids-cardClose' style={{
                backgroundImage: props.closeDisplay ? "url("+closeIcon+")" : ""
            }} onClick={() => props.close()}>
			</div>
		</div>
	);
};
